import Vue from "vue"
import store from "../store"

const wait = (time = 100) => new Promise((r) => setTimeout(() => r(), time))

const check = (name, related_id) =>
  new Promise((resolve, reject) => {
    const find = () => {
      if (store.state.user && store.state.user.user) {
        const user = store.state.user.user
        if (user.admin) {
          return resolve(true)
        } else if (user.permissions && user.permissions.length > 0) {
          for (let i = 0, len = user.permissions.length; i < len; i++) {
            const permission = user.permissions[i]
            if (
              (!name || permission.name === name) &&
              (!related_id || permission.related_id === related_id)
            ) {
              return resolve(true)
            }
          }
        }
      }
      resolve(false)
    }

    let attempt = 10
    const recurse = () => {
      attempt--
      if (attempt === 0) return reject()
      //
      const isReady = store.state.app.ready
      if (!isReady) {
        wait(100).then(recurse)
      } else {
        find()
      }
    }
    recurse()
  })

const update = () => {
  throw new Error("permission.update() is not available yet.")
}

const permission = {
  check,
  update,
}

const vm = {
  install: function(Vue) {
    Vue.prototype.$permission = permission
  },
}

Vue.use(vm)

export default permission
