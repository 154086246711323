export default {
  TITLE: "Feed",
  TITLES: "Feeds",
  TITLE_COUNT: "no feed | 1 feed | {n} feeds",

  REMOVE: "Throw in the basket",
  RESET: "Discard changes",
  REFRESH: "Analyze the job",
  SAVE: "Save",
  PREVIOUS: "Previous",
  NEXT: "Next",

  FORM_TITLE_UPDATE: "Edit feed",
  FORM_TITLE_CREATE: "Create feed",
  FORM_TITLE_NEW: "Enter the information for the new feed",

  FIELD_STATUS: "",
  FIELD_STATUS_TYPE: "",
  FIELD_STATUS_CODE: "",
  FIELD_STATUS_MESSAGE: "",

  FIELD_TITLE: "Feed name",
  FIELD_TITLE_HELP: "",
  FIELD_TITLE_PLACEHOLDER: "my main feed",
  FIELD_AUTO_REFRESH: "Update feed automatically",
  FIELD_LEGACY_HTML_TO_MD: "Use the legacy HTML-Markdown converter (deprecated)",
  FIELD_MAX_CHURN_PERCENT: "Max churn % during a refresh",
  PROTOCOL_TEXT: "How do you want to load file?",
  PROTOCOL_TITLE: "Upload file",
  PROTOCOL_BTN: "Configure",
  PROTOCOL: "Protocol",
  PROTOCOL_COUNT: "The field is not filled | | ",
  FIELD_PROTOCOL: "How do you want to load file?",
  FIELD_PROTOCOL_HTTP: "Upload file from URL",
  FIELD_PROTOCOL_FTP: "Upload file from FTP",
  FIELD_PROTOCOL_UPLOAD: "Upload file from my computer",
  FIELD_PROTOCOL_API: "Upload data from API or ATS",
  FIELD_PROTOCOL_API_CONTROLLER_NAME: "API or ATS",
  FIELD_PROTOCOL_METHOD_GET: "GET",
  FIELD_PROTOCOL_METHOD_POST: "POST",
  FIELD_PROTOCOL_METHOD_PUT: "PUT",
  FIELD_PROTOCOL_METHOD_PATCH: "PATCH",
  FIELD_PROTOCOL_PARAMS_NEED_AUTH: "Authentication",
  FIELD_PROTOCOL_PARAMS: "",
  FIELD_PROTOCOL_PARAMS_URL: "URL",
  FIELD_PROTOCOL_PARAMS_URL_PLACEHOLDER: "https://www.mywebsite.com/file.xml",
  FIELD_PROTOCOL_PARAMS_METHOD: "Method",
  FIELD_PROTOCOL_PARAMS_USER: "User",
  FIELD_PROTOCOL_PARAMS_PASSWORD: "Password",
  FIELD_PROTOCOL_PARAMS_NEED_DATA: "Additional request data",
  FIELD_PROTOCOL_PARAMS_AUTH: "Authentication",
  FIELD_PROTOCOL_PARAMS_QUERY_DATA: "Query data (JSON)",
  FIELD_PROTOCOL_PARAMS_HOST: "Host",
  FIELD_PROTOCOL_PARAMS_PORT: "Port",
  FIELD_PROTOCOL_PARAMS_PATH: "File path",
  FIELD_PROTOCOL_PARAMS_SECURE: "Enable secure connection (SSL)",
  FIELD_PROTOCOL_PARAMS_SECURE_OPTION: "Security options (JSON)",

  FILEFORMAT_FIELDS_TITLE: "Data fields",
  FILE_ERROR: "The file was not recovered, check the connection settings.",
  TEST_FILE: "Test the file",
  TEST_FILE_AGAIN: "Retest the file",
  FILEFORMAT_CUSTOM_FIELDS_TITLE: "Custom data fields",
  FILEFORMAT_CUSTOM_FIELDS_LIMIT:
    "You have reached the limit of 15 custom fields.",
  FILEFORMAT_FIELDS_COLUMNS_ERROR:
    "You must upload a valid XML, CSV, or JSON file to set up the fields and complete your feed.",
  FILEFORMAT_FIELDS_COUNT:
    "The field is not filled | Completed 1 field | {n} fields filled",
  FILEFORMAT_FIELDS_BTN: "Configure",
  FILEFORMAT_CUSTOM_TITLE: "Custom data fields",
  FILEFORMAT_HOOKS_TITLE: "Automated tasks",
  FILEFORMAT_HOOKS_BTN: "Configure",
  FILEFORMAT_HOOKS_COUNT:
    "Task not entered | 1 completed task | {n} completed tasks",

  FIELD_FILEFORMAT: "Format",
  FIELD_FILEFORMAT_LIST: "",
  FIELD_FILEFORMAT_FIELDS: "",
  FIELD_FILEFORMAT_COLUMNS: "",
  FIELD_FILEFORMAT_HOOKS: "",

  FIELD_FILEFORMAT_EXAMPLES: "Examples",
  FIELD_FILEFORMAT_FORCE: "Force this task",
  FIELD_FILEFORMAT_SPACING: "Spacing",
  FIELD_FILEFORMAT_SPACING_DEFAULT_VALUE: "(empty by default)",
  FIELD_FILEFORMAT_SEPARATOR: "Separator",
  LANDING_PAGE_EMAIL_WARNING:
    'Landing page needs an email to work as expected, be sure that field "Contact >E-mail" is set below.',
  FIELD_FILEFORMAT_DEFAULT_VALUE: "Default value",
  FIELD_FILEFORMAT_EXCLUDE_VALUE: "Excluded values",
  FIELD_FILEFORMAT_CHANGES: "Content changes",
  FIELD_FILEFORMAT_CHANGES_BTN: "Add",
  FIELD_FILEFORMAT_CHANGES_FUNCTION: "Function",
  FIELD_FILEFORMAT_CHANGES_FUNCTION_ARG_REGEX: "Expression",
  FIELD_FILEFORMAT_CHANGES_FUNCTION_ARG_VALUE: "Value",
  FIELD_FILEFORMAT_CHANGES_FUNCTION_ARG_REPLACE: "Replace",

  FIELD_CUSTOM_KEY: "Field name",

  STEP_INTRO_TITLE: "Introduction",
  STEP_INTRO_DESCRIPTION: "Name feed and choose update frequency.",

  STEP_PROTOCOL_TITLE: "Data source",
  STEP_PROTOCOL_DESCRIPTION:
    "Set the data source and load it automatically from a file or an external address.",

  STEP_FIELD_TITLE: "Data structure",
  STEP_FIELD_DESCRIPTION: "Link file data fields with Xtramile database.",

  STEP_CUSTOM_FIELD_TITLE: "Custom data fields",
  STEP_CUSTOM_FIELD_DESCRIPTION:
    "Create custom data fields for later use in Xtramile systems.",

  STEP_HOOK_TITLE: "Automated tasks",
  STEP_HOOK_DESCRIPTION:
    "Automate the transformation and publication of your jobs.",

  STEP_CONFIRM_TITLE: "Confirmation",
  STEP_CONFIRM_DESCRIPTION: "Check and confirm feed informations.",

  HOOK_TEXT: "Automate operations",
  HOOK_DESCRIPTION: "Transform or duplicate data with new entries.",

  HOOK_NAME: "Task name",
  HOOK_DUPLICATE_BY_CITIES_IN_COUNTRY: "Duplicate cities of a country",
  HOOK_DUPLICATE_BY_CITIES_IN_REGION: "Duplicate cities in a region",
  HOOK_DUPLICATE_BY_CITIES_IN_DEPARTMENT: "Duplicate cities of a department",
  HOOK_DUPLICATE_BY_CITIES_IN_DESCRIPTION: "Duplicate cities from a field",

  NO_PROTOCOL: "No protocol",

  INTRODUCTION_TEXT: "Name your feed",
  PROTOCOL_API_TEXT: "Set connection params",
  PROTOCOL_PARAMS_TEXT: "Set file connection params",
  PROTOCOL_PARAMS_FILE_TYPE_WARNING: "Accepted file types: XML, CSV, JSON",

  FILEFORMAT_TEXT: "Set data fields",
  FILEFORMAT_DESCRIPTION:
    "Select the columns or tags found in the file, or enter a default value.",

  FILEFORMAT_CUSTOM_TEXT: "Free data fields",
  FILEFORMAT_CUSTOM_DESCRIPTION:
    "Add additional data fields to the offer that you can use later.",

  HEADER_HOOK_NAME: "Task name",
  HEADER_KEY_NAME: "Field name",
  HEADER_KEY_SEPARATOR_CONTACT: "Contact",
  HEADER_KEY_SEPARATOR_COMPANY: "Business",
  HEADER_KEY_SEPARATOR_ADVANCED: "Additional parameters",
  HEADER_CUSTOM_KEY_NAME: "Free field name",
  HEADER_COLUMNS: "Columns / Tags",

  ADD_FIELDS: "Add a field",

  PROTOCOL_UPLOAD_TEXT: "Upload a file",
  PROTOCOL_DROPZONE_TEXT_1: "Drop the file in the area to load the content",
  PROTOCOL_DROPZONE_TEXT_2: "or click to select the file from your computer.",

  MAPPED_FIELD: "No field | 1 field | {n} fields",
  DEFAULT_FIELD: "No default field | 1 default field | {n} default fields",
  NO_FILEFORMAT: "No fileformat",
  ICON: "mdi-rss",
  TEST_ICON: "mdi-test-tube",
  TEST_SUCCESS_MESSAGE: "The feed has been extracted correctly!",
  TEST_JOBS_MESSAGE: "No jobs found. | {n} job found. | {n} jobs found.",

  REFRESH_SUCCESS_ICON: "mdi-check-circle",
  REFRESH_SUCCESS: "Update",

  REFRESH_RUNNING_ICON: "mdi-refresh-circle",
  REFRESH_RUNNING: "Being processed",

  REFRESH_STOPPED_ICON: "mdi-alert-circle",
  REFRESH_STOPPED: "Update canceled",

  REFRESH_FAILED_ICON: "mdi-alert-circle",
  REFRESH_FAILED: "Update failed",

  REFRESH_WAIT_ICON: "mdi-refresh-circle",
  REFRESH_WAIT: "Waiting",

  REMOVED: "Removed",
  UNMODIFIED: "Unmodified",
  CREATED: "Created",
  UPDATED: "Updated",
  ERRORS: "Errors",
  TOTAL: "Total Jobs",
  IN_FILE: "In the file",

  HOOK_RESULTS: "Duplication result(s)",

  DISABLED: "Feed is disabled. Please use it as read-only, any feed updates will not work.",
};
