import Vue from 'vue'
import moment from 'moment'

const vm = {
  install: function (Vue) {
    Vue.prototype.$moment = moment
  }
}
Vue.use(vm)

export default moment
