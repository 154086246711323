import Vue from 'vue'
import i18n from './vueI18n'

/* eslint-disable */
const regexEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
const regexUrl = /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi

const selectErrorField = () => {
  const el = document.querySelector('.error--text input, .error--text select, .error--text textarea')
  if (el && el.focus) el.focus()
  return true
}

const required = v => {
  if (v instanceof Array) {
    return v.length > 0
  } else if (v instanceof Object) {
    return Object.keys(v).length > 0
  } else {
    return v === 0 || v === false || !!v
  }
  return i18n.t('RULE.REQUIRED')
}

// const url = v => {
//   // console.log(v, regexUrl.test(v))
//   return regexUrl.test(v)
//     || i18n.t('RULE.URL')
// }

// const email = v => {
//   // console.log(v, regexEmail.test(v))
//   return regexEmail.test(v)
//     || i18n.t('RULE.EMAIL')
// }

// const password = v => {
//   return ((v===0 || !!v) && (v.length >= 6 && v.length <= 256))
//     || i18n.t('RULE.PASSWORD')
// }

const ruleFunctions = {
  // static rules
  selectErrorField: selectErrorField,
  required:   required,
  email:      v => v => regexEmail.test(v) || i18n.t('RULE.EMAIL'),
  url:        v => v => regexUrl.test(v) || i18n.t('RULE.URL'),
  password:   v => v => (v===0 || !!v) && (v.length >= 6 && v.length <= 16) || i18n.t('RULE.PASSWORD'),
  birthdate:  v => (v && v.length === 10 && new Date(v).toString() !== 'Invalid Date') || i18n.t('RULE.DATE'),
  country:    v => (!!v) || i18n.t('RULE.COUNTRY'),
  date:       v => (!!new Date(v).toString() !== 'Invalid Date') || i18n.t('RULE.DATE'),
  // dynamical rules
  maxlength: (max) => (v => v && v.length <= max || !v || i18n.t('RULE.MAXLENGTH', {max:max})),
  minlength: (min) => (v => v && v.length >= min || i18n.t('RULE.MINLENGTH', {min:min})),
  max:       (max) => (v => v && +v <= max || i18n.t('RULE.MAX', {max:max})),
  min:       (min) => (v => v && +v >= min || i18n.t('RULE.MIN', {min:min}))
}

const vm = {
  install: function (Vue) {
    Vue.prototype.$rules = ruleFunctions
  }
}

Vue.use(vm)

export default ruleFunctions
