export default {
  getJob({ job }) {
    return job
  },

  getCompanyData({ job: { company} }) {
    return company
  },

  getOfferInformationData({ job: { descriptionInfo } }) {
    return descriptionInfo
  },

  getMissionsData({ job: { missionInfo } }) {
    return missionInfo
  },

  getSkillsData({ job: { skillsInfo } }) {
    return skillsInfo
  },

  getRequirementsData({ job: { requirementsInfo } }) {
    return requirementsInfo
  },

  getContent({ job: { content } }) {
    return content
  },

  getExamplesJob({ examplesJob }) {
    return examplesJob
  },

  getJobMatch({ jobMatch }) {
    return jobMatch
  },

  getJobs({ jobs }) {
    return jobs
  },

  getJobsParsed({ jobsParsed }) {
    return jobsParsed
  },

  getJobParsedResult({ jobParsed }) {
    return jobParsed
  },

  getActiveTabContent({ activeTabContent }) {
    return activeTabContent
  }
}
