import * as types from "@/store/mutations.types"
import services from "@/plugins/services"
import vueI18n from "@/plugins/vueI18n"
import moment from "@/plugins/moment"
import router from "@/router"

const _changeLocale = (lang) => {
  if (vueI18n.availableLocales.indexOf(lang) !== -1) {
    vueI18n.locale = lang
  } else {
    vueI18n.locale = vueI18n.fallbackLocale
  }
  moment.locale(vueI18n.locale[0])
}

const _defaultLocale = () => {
  const navLang = navigator.language.split("-")[0] || navigator.language
  vueI18n.locale =
    vueI18n.availableLocales.indexOf(navLang) !== -1
      ? navLang
      : vueI18n.fallbackLocale
  moment.locale(vueI18n.locale[0])
}

export default {
  // check user
  switch({ state, dispatch, commit }, data) {
    if (state.token) {
      return services.api_user.user_session
        .switch({
          company_id: data.company_id,
          office_id: data.office_id,
          token_ra_refresh: state.token_ra_refresh,
        })
        .then((response) => {
          services.set("token", response.data.token)
          services.set("token_ra", response.data.token_ra)
          services.set(
            "token_parse",
            state.token_parse || localStorage.getItem("token_parse")
          )
          commit(types.USER_SESSION, {
            info: response.data,
            token_parse: state.token_parse,
          })
          dispatch("office/setOffice", null, { root: true })

          this.dispatch("messenger/add", {
            type: "info",
            code: "USER_SWITCH",
            message: "User session have been switched.",
            time: 8000,
          })
        })
        .catch((response) => {
          this.dispatch("messenger/add", {
            type: "warning",
            code: response.code,
            message: response.message,
            time: 8000,
          })
        })
    } else {
      dispatch("logout", true)
      return Promise.resolve()
    }
  },

  // check user
  check({ state, dispatch, commit }) {
    if (state.token) {
      services.set("token", state.token)
      services.set("token_ra", state.token_ra)
      services.set(
        "token_parse",
        state.token_parse || localStorage.getItem("token_parse")
      )
      return services.api_user.user_session
        .check()
        .then((response) => {
          // redirect if user is connected and in login page
          if (router.currentRoute.path === "/login") router.push("/")
          _changeLocale(response.data.user.country.language)
          if (!response.data.token_ra) {
            // We keep the old token for accessing the ra backend if we don't receive a new one
            response.data.token_ra = state.token_ra
            response.data.token_ra_refresh = state.token_ra_refresh
          }
          commit(types.USER_SESSION, {
            info: response.data,
            token_parse: state.token_parse,
          })
        })
        .catch((response) => {
          dispatch("logout", true)
          this.dispatch("messenger/add", {
            type: "warning",
            code: response.code,
            message: response.message,
            time: 8000,
          })
        })
    } else {
      dispatch("logout", true)
      return Promise.resolve()
    }
  },

  // login user
  login({ state, dispatch, commit }, data) {
    return services.api_user.user_session
      .login({
        email: data.email,
        password: data.password,
      })
      .then((response) => {
        router.push({ name: "Home" })
        dispatch
        services.set("token", response.data.token)
        services.set("token_ra", response.data.token_ra)
        services.set("token_parse", state.token_parse)

        _changeLocale(response.data.user.country.language)
        commit(types.USER_SESSION, {
          info: response.data,
          token_parse: state.token_parse,
        })
        dispatch("office/setOffice", null, { root: true })
        this.dispatch("messenger/add", {
          type: "success",
          code: response.code,
          message: response.message,
          time: 8000,
        })
      })
      .catch((response) => {
        dispatch("logout")
        this.dispatch("messenger/add", {
          type: "error",
          code: response.code,
          message: response.message,
          time: 8000,
        })
      })
  },

  // logout
  logout({ state, dispatch, commit }, redirect = false) {
    if (redirect) router.push({ name: "Login", query: { t: Date.now() } })
    services.set("token", null)
    services.set("token_ra", null)
    services.set("token_parse", null)
    localStorage.removeItem("token_parse")
    _defaultLocale()
    commit(types.USER_SESSION, {})
    commit(types.SET_TOKEN_PARSE, null)
  },

  setTokenParse({ commit }, data) {
    return commit(types.SET_TOKEN_PARSE, data)
  },
}
