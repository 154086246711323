import Vue from 'vue'
import clone from './clone'

const _isEmpty = (v) => {
  if (v instanceof Array) return v.length < 1
  if (v instanceof Object) return Object.keys(v).length < 1
  if (v === 0) return false
  return !v
}

const _getValue = (element) => {
  element = element instanceof Array ? element[0] : element
  // console.log('ELEMENT', element)
  if (element.value) return element.value
  if (element.$data && element.$data.internalLazyValue) return element.$data.internalLazyValue
  if (element.$data && element.$data.lazyValue) return element.$data.lazyValue
  return undefined
}

const _rangeSlider = () => {}

const _durationDate = () => {}

const setNestedData = (keys, obj, value) => {
  const key = keys.shift()
  // boolean value is a shortcut to load includes,
  // but we need to set nested data inside
  // so we replace <boolean> to <object> with params instead,
  if (typeof obj === 'boolean' && key) obj = {}
  if (keys.length === 0) {
    if (!(obj[key] instanceof Array)) {
      value instanceof Array
        ? obj[key] = obj[key] ? [obj[key]].concat(value) : [].concat(value)
        : obj[key] = value
    } else {
      value instanceof Array
        ? obj[key].concat(value)
        : obj[key].push(value)
    }
  } else {
    obj[key] = setNestedData(
      keys,
      obj[key] ? obj[key] : {},
      value
    )
  }
  return obj
}

function queryFormat (query = { where: {}, options: {}}) {
  const refs = this.$refs || {}

  query.where = Object.assign({}, {...query.where})
  query.options = Object.assign({}, {...query.options})
  Object.keys(refs).forEach((ref) => {
    if (ref.includes('where.') || ref.includes('options.')) {
      if (ref.includes('&')) {
        ref.split('&').forEach((elem,index) => {
          const keys = elem.split('.')
          const type = keys.shift()
          const value =  refs[ref].value[index]
          if(!_isEmpty(value)){
            query[type] = setNestedData(keys, query[type], clone(value))
            index++
          }
        })
      } else {
        // console.log('BEFORE SHIFT', ref.split('.'), value)
        const keys = ref.split('.')
        const type = keys.shift()
        const value = _getValue(refs[ref])
        if (!_isEmpty(value)) {
          query[type] = setNestedData(keys, query[type], clone(value))
          // if (query[type][name]) {
          //   if (query[type][name] instanceof Array) {
          //     query[type][name].push(value)
          //   } else {
          //     query[type][name] = [query[type][name]]
          //     query[type][name].push(value)
          //   }
          // } else {
          //   query[type][name] = value
          // }
        }
      }
    }
  })
  return query
}


const vm = {
  install: function (Vue) {
    Vue.prototype.$queryFormat = queryFormat
  }
}

Vue.use(vm)


export default queryFormat
