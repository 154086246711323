export default {
  TITLES: {
    MAIN: "Impact HR Analytics",
    HEADER_POWER_BI: "Power Bi",
    POWER_BI: "XImpact HR Macro",
    PARSING_JOBS: "XImpact HR Micro",
    ENTER_JOB: "Entrez le contenu de votre offre d'emploi"
  },
  ICONS: {
    IFRAME: "mdi-application-brackets",
    PARSING_JOBS: "mdi-briefcase-account",
  },
  BUTTONS: {
    PARSE_BUTTON: "Parser les jobs",
    PARSE_BUTTON_JOB: "Parser les jobs",
    CANCEL_BUTTON: "Annuler",
    VALIDATE: "Valider",
  },
  LABELS: {
    ENTER_JOB_NAME: "Entrez le titre du poste",
  }
}