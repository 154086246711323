export default store => {
  // raw modules
  const modules = JSON.parse(JSON.stringify(store._modules.root._rawModule.modules))
  // short alias to reset
  store.reset = (name, exclude = []) => {
    if (name) {
      const excluded = {}
      if (exclude) exclude.forEach(key => {
        excluded[key] = store.state[name][key]
      })
      store.state[name] = Object.assign({}, modules[name].state, excluded)
    }
  }
}
