export default {
  TITLE: "Contrat",
  TITLES: "Contrats",
  TITLE_COUNT: "Aucun contrat | 1 contrat | {n} contrats",
  TITLE_ACTIVE_COUNT:
    "Aucun contrat actif | 1 contrat actif | {n} contrats actifs",
  ICON: "mdi-file",
  ADD: "Ajouter un contrat",
  TEXT:
    "Maîtrisez et quantifiez votre budget en créant des contrats qui encadrent vos coûts.",

  CONTRACT_TITLES: "Contrats",
  CONTRACT_TITLE: "Contrat",
  CANCELED: "Annulé",
  FINISHED: "Terminé",
  NO_CONTRACT: "Aucun contrat",
  WILL_FINISHED: "Se termine {date}",
  DOWNLOAD_FILE: "Télécharger le fichier généré",
  NO_DATE_LIMIT: "Aucune date limite",
  NO_LIMIT_TIME: "Ne pas limiter la durée de validité",
  NO_LIMIT_VALUE: "Ne pas limiter les coûts",
  DAYS_AFTER_FIRST_USE: "jours après la première utilisation",
  LAST_UPDATED: "Dernière mise à jour le",
  AMOUNT: "Coûts",
  AMOUNT_TEXT: "Définissez un budget pour limiter vos coûts sur le canal.",
  VALIDITY: "Durée de validité",
  VALIDITY_TEXT:
    "Définissez une durée de validité du contrat pour limiter vos coûts dans le temps.",
  VALIDITY_DAYS_AFTER: "jours après la 1ère utilisation.",

  FORM_TITLE_UPDATE: "Modifier un contrat",
  FORM_TITLE_CREATE: "Ajouter un contrat",
  EXPIRED: "Expired",
  ALREADY_COMMITED_COUNT: " | dont 1 déjà dépensé | dont {n} déjà dépensés"
}
