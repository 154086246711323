import * as types from '@/store/mutations.types'

export default {

  add ({commit, dispatch}, data) {
    if (!data.type) data.type = 'info'
    if (!data.code) data.code = 'DEFAULT_MESSAGE'
    if (!data.message) data.message = 'No message'
    if (!data.id) data.id = `${Date.now()}_${Math.ceil(Math.random() * 1000)}`
    if (!data.time) data.time = 30000
    if (!data.timeEnd) data.timeEnd = Date.now() + data.time
    data.timeout = setTimeout(() => {
      dispatch('remove', data.id)
    }, data.time)
    return commit(types.MESSENGER_ADD, data)
  },

  remove ({commit}, data) {
    clearTimeout(data.timeout)
    return commit(types.MESSENGER_REMOVE, data)
  }


}
