import Vue from 'vue'
import plugins from './plugins'
import App from './App.vue'
import VueMask from 'v-mask'

Vue.use(VueMask, {   
    placeholders: {
      '#': /[#]/, // HEX simbol
      H: /[0-9a-fA-F]/, // HEX format
      N: /[0-9]/, // number format
      L: /[a-zA-Z]/, // letter format
    }
  })


Vue.config.productionTip = false
const vueInstance = plugins
vueInstance.render = h => h(App)
new Vue(vueInstance).$mount('#app')
