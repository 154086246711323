export default {
  START_DATE: "Début",
  END_DATE: "Fin",

  // CAMPAIGN
  TITLE: "Campagne",
  TITLES: "Campagnes",
  TITLE_COUNT: "aucune campagne | 1 campagne | {n} campagnes",
  ICON: "mdi-hexagon-multiple",
  SEE_PUBLICATION: "Voir les publications",
  PERIOD: "Période de campagne",
  IMPORTS: "Imports",

  IMPORT_ICON: "mdi-swap-vertical-bold",
  IMPORT: "Imports",

  FORM_TITLE_FEED_IMPORT: "Importer des flux",
  FORM_TITLE_JOB_IMPORT: "Importer des offres",
  FORM_TITLE_UPDATE: "Modifier une campagne",
  FORM_TITLE_CREATE: "Créer une campagne",
  FORM_TITLE_NEW: "Créer une campagne",

  FIELD_GA4_PARAMS_TITLE: "Configurer l'intégration avec Google Analytics",
  FIELD_GA4_PARAMS_PROPERTY_ID: "Numéro d'identification de la propriété",
  FIELD_GA4_PARAMS_PROPERTY_ID_PLACEHOLDER: "292480871",
  FIELD_GA4_PARAMS_CONVERSION_EVENT_NAME: "Événement de conversion",
  FIELD_GA4_PARAMS_CONVERSION_EVENT_NAME_PLACEHOLDER: "apply",

  TOTAL: "Publications",
  PUBLISHED: "Publié",
  UNPUBLISHED: "Dépublié",
  WAITING: "En attente",
  ERRORS: "Erreur",

  // CAMPAIGN FEED
  // TO REPLACE
  FEED_HOW:
    "How jobs in campaign could be updated when its feed is refreshing ?",
  FEED: " flux de campagne | flux de campagne | flux de campagne ",
  FEED_ICON: "mdi-rss",

  // CAMPAIGN JOB
  CAMPAIGN_JOB:
    "offre de la campagne | offre de la campagne | offres de la campagne",
  CAMPAIGN_JOB_ICON: "mdi-briefcase",

  NEED_REFRESH: "En attente",
  NEED_REFRESH_ICON: "mdi-refresh-circle",

  PLANNED_ICON: "mdi-clock-time-four",
  PLANNED: "Plannifié",

  RUNNING_ICON: "mdi-play-circle",
  RUNNING: "En cours",

  FINISHED_ICON: "mdi-stop-circle",
  FINISHED: "Terminé",

  REFRESH_FAILED_ICON: "mdi-alert-circle",
  REFRESH_FAILED: "Rafraichissement a échoué",

  REFRESH_RUNNING_ICON: "mdi-refresh-circle",
  REFRESH_RUNNING: "Rafraichissement en cours",

  STEP_INTRO_TITLE: "Introduction",
  STEP_INTRO_DESCRIPTION:
    "Veuillez renseigner le titre ainsi que le type de campagne.",

  STEP_DATES_TITLE: "Période de publication",
  STEP_DATES_DESCRIPTION:
    "Indiquez la période d'activité des publications de votre campagne.",

  STEP_MODE: "Mode",
  STEP_MODE_DESCRIPTION:
    "Veuillez choisir un type de campagne qui affectera le choix des canaux de diffusion",

  STEP_STATS_TITLE: "Statistiques",
  STEP_STATS_DESCRIPTION:
    "Connectez votre campagne à vos systèmes de statistiques externes.",

  STEP_CONFIRM_TITLE: "Confirmation",
  STEP_CONFIRM_DESCRIPTION:
    "Vérifiez et confirmez les informations de votre campagne",

  STATS_TITLE: "Connectez vos systèmes de statistiques externes",

  INTRODUCTION_TITLE: "Nommez votre campagne",
  MODES_TITLE: "Catégorie de canaux",
  MODES_TEXT:
    "Choisissez les catégories de canaux disponibles dans votre campagne.",

  DATES_TITLE: "Dates",
  DATES_TEXT:
    "Selectionnez l'interval de dates où vos publications seront visibles.",

  REFRESH: "Relancer la campagne",

  FIELD_CHANNEL_CATEGORY: "Catégorie de canaux",
  FIELD_TITLE: "Titre et categorie",
  FIELD_TITLE_PLACEHOLDER: "Campagne 01",
  FIELD_DATES: "Dates",
  FIELD_START_DATE: "Date de début",
  FIELD_END_DATE: "Date de fin",

  SELECT_ALL_CATEGORY: "Sélectionner tout",
  CHANNEL_CATEGORY_ITEMS: [
    {
      category: "credit",
      title: "Crédit",
      description: "Site d'offres d'emplois à credits ou slots.",
    },
    {
      category: "aggregator",
      title: "Agrégateur",
      description: "Site d'offres d'emplois agrégées.",
    },
    {
      category: "display",
      title: "Display",
      description: "Supports interactifs: Ads, Images, Vidéos et Audios.",
    },
    // {
    //   channel_categories: ["credit", "aggregator", "display"],
    //   title: "Performance",
    //   description: "Tous les canaux.",
    // },
  ],

  MISSING_FIELDS: 'Champs manquants',
  MESSAGE_SALARY: 'salaire',

}
