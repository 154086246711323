export default {
  TITLE: "Media",
  TITLES: "Media",
  TITLE_COUNT: "media | 1 media | {n} media",
  ICON: "mdi-antenna",

  METHOD_NONE: "All",
  METHOD_ORGANIC: "Organic",
  METHOD_CREDITS: "Credits",
  METHOD_CREDIT: "Credits",
  METHOD_AGGREGATOR: "Aggregator",
  METHOD_DISPLAY: "Display",
  METHOD_XTRADING: "Xtrading",

  ACCOUNT_USE_BY: "Account: {name}",
  REMOVE: "Delete subscription",
  RESET: "Discard changes",
  SAVE: "Save",
  PREVIOUS: "Previous",
  NEXT: "Next",

  FORM_TITLE_UPDATE: "Edit media",
  FORM_TITLE_CREATE: "Create media",
  FORM_TITLE_NEW: "Enter new media information",

  STEP_CHANNEL_TITLE: "Choice of channel",
  STEP_CHANNEL_DESCRIPTION:
    "Choose on which channel you would like to broadcast your jobs.",

  STEP_PARAMS_TITLE: "Account and channel settings",
  STEP_PARAMS_DESCRIPTION: "Set up your broadcast and your account.",

  STEP_CONTRACT_TITLE: "Contract and cost management",
  STEP_CONTRACT_DESCRIPTION:
    "Monitor and control your expenses with a contract.",

  STEP_CONFIRM_TITLE: "Confirmation",
  STEP_CONFIRM_DESCRIPTION: "Check and confirm media information.",

  CHANNEL_TEXT: "Choose a broadcast channel",
  CHANNEL_JOBBOARDS_TITLE: "Jobboards",
  CHANNEL_JOBBOARDS_DESCRIPTION: "Credit channels",
  CHANNEL_AGGREGATORS_TITLE: "Aggregators",
  CHANNEL_AGGREGATORS_DESCRIPTION: "Free and cost-per-clicks/views/acquisitions channels",
  CHANNEL_DISPLAY_TITLE: "Display",
  CHANNEL_DISPLAY_DESCRIPTION: "Audiovisual channels",
  CHANNEL_NOT_FOUNDED: "Channels were not found",

  NO_CONTRACT_ERROR:
    "You must create at least one contract to use this channel.",
  TEST_MODE_TEXT: "Mode: Test",

  BUDGET_TITLE: "Budget",
  BUDGET_TITLE_FOR: "for channels: ",
  BUDGET_OPEN: "Open",
  BUDGET_OPEN_TEXT: "Some of your channels have no contract limit.",
  TOTAL_BUDGET_SPENT: "Spent Budget",
  TOTAL_BUDGET_BASE: "Estimated budget",
  TOTAL_BUDGET_SPENT_PERCENT: "Breakdown of expenses by channel",

  ACCOUNT: "Account",
  ACCOUNT_XTRAMILE: "Account: Xtramile",
  ACCOUNT_CLIENT: "Account: User",
  ACCOUNT_TITLE: "Account setting",
  CHANNEL_PARAMS_TITLE: "Channel parameters",
  PRICES_TITLE: "Pricing model",
  PRICES_TEXT: "Define the pricing models you want to use when posting.",

  USE_XTRAMILE_ACCOUNT: "Use the Xtramile account on {a}",
  TEST_MODE: "Activate test mode",
  GROUP_BY: "Group jobs by",
  APPLY_METHOD: "PUBLICATION METHOD",

  CONTRACT_TITLES: "Contracts",
  CONTRACT_TITLE: "Contract",
  CANCELED: "Canseled",
  NO_CONTRACT: "No contract",
  DOWNLOAD_FILE: "Download the generated file",
  NO_DATE_LIMIT: "No date",
  DAYS_AFTER_FIRST_USE: "days after first use",
  LAST_UPDATED: "Last updated on",

  FILE_OFFICE: "File: Office jobs",
  FILE_COMPANY: "File: Company jobs",
  FILE_XTRAMILE_ACCOUNT: "Feature: Xtramile Masterfeed",

  GENERATED_FILE: "Generated files",

  GROUP_BY_ITEMS: [
    { value: "office", text: "Office" },
    { value: "company", text: "Company (All offices)" },
  ],
  BOOLEAN_ITEMS: [
    { value: true, text: "Actived" },
    { value: false, text: "Deactivated" },
  ],
  APPLY_METHOD_ITEMS: [
    { value: "APPLY_URL", text: "Use only URL" },
    { value: "APPLY_EMAIL", text: "Use only email" },
    { value: "APPLY_URL_EMAIL", text: "Try to use URL, then email" },
    { value: "APPLY_EMAIL_URL", text: "Try to use email, then URL" },
    { value: "APPLY_BOTH", text: "Use both URL and email" },
  ],

  UNLIMITED: "Unlimited",

  SUBSCRIPTION_PUBLICATION_DISABLED: 'Subscription Disabled',
  CHANNEL_PUBLICATION_DISABLED: 'Channel Disabled',
  PUBLICATION_ENABLED: 'Subscription Enabled',
  DISABLE_LABEL: 'Disable',
  DISABLE_TEXT: 'Stop generating XML feeds/API calls',
}
