import Vue from 'vue'

// get data with nested keys as path
// ex: path: 'my.nested.object'
const nestedData = (path, obj, defaultValue = null, avoidSingleArray = false) => {
  const keys = path instanceof Array ? path : path.split('.')
  let value = obj
  for (let key of keys) {
    // if key is number as string, use it as number
    if (String(Number(key)) === key) key = Number(key)
    if (typeof value[key] !== 'undefined') {
      // avoidSingleArray, if value[key] === ['content'], so return value[key] = 'content'
      if (avoidSingleArray && value[key] instanceof Array && typeof key !== 'number' && value[key].length === 1) {
        value = value[key][0]
      } else {
        value = value[key]
      }
    } else {
      return defaultValue
    }
  }
  return value || defaultValue
}

const vm = {
  install: function (Vue) {
    Vue.prototype.$nestedData = nestedData
  }
}
Vue.use(vm)

export default nestedData
