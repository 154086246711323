export default {
  TITLE: "Bureau",
  TITLES: "Bureaux",
  TITLE_COUNT: "Aucun bureau | 1 bureau | {n} bureaux",
  ICON: "mdi-office-building",
  INFO: "Informations",
  FIELD_TITLE: "Titre (doit finir en '[Mdif/A]', '[Mdif/I]', '[Prog/A]' ou '[Prog/I]')",
  FIELD_IMAGE: "Image",
  FIELD_TITLE_PLACEHOLDER: "Mon entreprise",
  FIELD_DESCRIPTION: "Description",
  FIELD_DESCRIPTION_PLACEHOLDER: "Fort de ses 150 collaborateurs ...",
  CONTACT: "Contact",
  FIELD_NAME: "Nom",
  FIELD_NAME_PLACEHOLDER: "Eric Dupond",
  FIELD_EMAIL: "E-mail",
  FIELD_COMPANY: "Entreprise",
  FIELD_COMPANY_PLACEHOLDER: "Choississez dans la liste",
  FIELD_EMAIL_PLACEHOLDER: "eric.dupond@monentreprise.fr",
  FIELD_ADDRESS: "Adresse",
  FIELD_ADDRESS_PLACEHOLDER:
    "6 rue du géneral de Gaulle, 75001 Paris, France",
  FORM_TITLE_UPDATE: "Modifier un bureau",
  FORM_TITLE_NEW: "Nouveau bureau",
  FORM_TITLE_CREATE: "Créer un bureau",
}