import Vue from "vue"
import axios from "axios"
import io from "socket.io-client"
import store from "@/store"

axios.defaults.headers["post"]["Content-Type"] =
  "application/x-www-form-urlencoded"
axios.defaults.headers["Access-Control-Allow-Origin"] = "*"
axios.defaults.headers["Access-Control-Allow-Methods"] =
  "GET,PUT,POST,DELETE,PATCH,OPTIONS"


// make a request, then handle it
const call = (serviceName, method, url, data, options) =>
  new Promise((resolve, reject) => {
    let request = {
      method: "POST",
    }
    if (options) request = Object.assign({}, request, options)
    if (method) request.method = method
    if (url) request.url = url
    if (data)
      request.method === "GET" ? (request.params = data) : (request.data = data)

    if (services[serviceName] && services[serviceName]._instance) {
      // make request
      services[serviceName]._instance
        .request(request)

        // Request succeed
        .then((response) => {
          console.info("Service ", serviceName, ": Request succeed", url)
          console.info(response)
          if (response.data) {
            if (response.data.type === "error") return reject(response.data)
            if (response.data.type) return resolve(response.data)
            // Allow csv responses
            if (response.headers["content-type"] === "text/csv")
              return resolve(response.data)
          }

          if (response.status) {
            return resolve(response)
          }

          // We dont understand the response
          return reject({
            code: "SERVICE_REQUEST_ERROR",
            type: "error",
            message: "invalid response type.",
          })
        })

        // Request failed
        .catch((error) => {
          // is it an Error ?
          if (error instanceof Error) {
            console.error("Services", serviceName, ": Request failed", url)
            console.error(error)

            if (error.response && error.response.status === 403) {
              // problem in user session
              store.dispatch("user/logout", true)
              return reject({
                code: "USER_SESSION.EXPIRED",
                type: "warning",
                error:
                  error.response && error.response.data
                    ? JSON.stringify(error.response.data)
                    : {},
              })
            }

            if (error.response && error.response.status === 413) {
              // PayloadTooLargeError: request entity too large
              return reject({
                code: "SERVICE_REQUEST_TOO_LARGE",
                type: "warning",
                error:
                  error.response && error.response.data
                    ? JSON.stringify(error.response.data)
                    : {},
              })
            }

            // is response from server ?
            if (error.response && error.response.data)
              return reject(error.response.data)

            return reject({
              code: "SERVICE_REQUEST_ERROR",
              type: "error",
              message: error.message,
              stack: error.stack,
              error:
                error.response && error.response.data
                  ? JSON.stringify(error.response.data)
                  : {},
            })
          }

          // we dont understand the response
          return reject({
            code: "SERVICE_REQUEST_ERROR",
            type: "error",
            message: "invalid response type.",
            error:
              error.response && error.response.data
                ? JSON.stringify(error.response.data)
                : {},
          })
        })
    } else
      reject({
        type: "error",
        code: "SERVICE_REQUEST_ERROR",
        message: "service or it instance is not found.",
      })
  })

// Services

// find path from BASE_URL
const getBaseURL = (serviceName, localUrl) => {
  const url = `${process.env.VUE_APP_KONG_URL}/${serviceName}`
  return process.env.VUE_APP_KONG_URL ? url : localUrl
}

const getBaseURLWithKongBypass = (serviceName, localUrl) => {
  let k8sUrl = ""
  switch (serviceName) {
    case "api-programmatic":
      k8sUrl = "https://api-programmatic.xtramile.io"
      break
    case "api-user":
      k8sUrl = "https://api-user.xtramile.io"
      break
    case "api-statistic":
      k8sUrl = "https://api-statistic.xtramile.io"
      break
    case "api-rnd":
      k8sUrl = "https://api-rnd.xtramile.io"
      break
    // TODO: The three worker URLs seem to be invalid and have always been invalid even through Kong
    case "api-worker-publication":
      k8sUrl = "https://api-worker-publication.xtramile.io"
      break
    case "api-worker-feed":
      k8sUrl = "https://api-worker-feed.xtramile.io"
      break
    case "api-worker-job":
      k8sUrl = "https://api-worker-job.xtramile.io"
      break
    case "api-notification":
      k8sUrl = "https://api-notification.xtramile.io"
      break
    default:
      k8sUrl = `${process.env.VUE_APP_KONG_URL}/${serviceName}`
      break
  }
  return process.env.VUE_APP_KONG_URL ? k8sUrl : localUrl
}

const getBaseURLMatching = (serviceName, localUrl) => {
  const url = `${process.env.VUE_APP_MATCHING_URL}/${serviceName}`
  return process.env.VUE_APP_MATCHING_URL ? url : localUrl
}

const getBaseURLXranking = (serviceName, localUrl) => {
  return process.env.VUE_APP_REQUEST_API_XRANKING
    ? `${process.env.VUE_APP_REQUESTS_API_XRANKING}/${serviceName}`
    : localUrl
}

const getBaseURLBiXparsing = (serviceName, localUrl) => {
  return process.env.VUE_APP_PROGRAMMATIC_JOB_PARSER ?
    `${process.env.VUE_APP_PROGRAMMATIC_JOB_PARSER}/${serviceName}` : localUrl
}

const services = {}

// Service: api_programmatic
services.api_programmatic = {
  _baseURL: getBaseURLWithKongBypass("api-programmatic", "http://localhost:3023"), // docker ip http://172.26.0.4:3023
  _headers: {},
  _instance: null,
  // methods
  ping: (data, options) => call("api_programmatic", "GET", "/", data, options),
  application_email: {
    create: (data, options) =>
      call(
        "api_programmatic",
        "PUT",
        "/application_email/create",
        data,
        options
      ),
    remove: (data, options) =>
      call(
        "api_programmatic",
        "DELETE",
        "/application_email/remove",
        data,
        options
      ),
    search: (data, options) =>
      call(
        "api_programmatic",
        "POST",
        "/application_email/search",
        data,
        options
      ),
    update: (data, options) =>
      call(
        "api_programmatic",
        "PATCH",
        "/application_email/update",
        data,
        options
      ),
  },
  campaign: {
    create: (data, options) =>
      call("api_programmatic", "PUT", "/campaign/create", data, options),
    remove: (data, options) =>
      call("api_programmatic", "DELETE", "/campaign/remove", data, options),
    search: (data, options) =>
      call("api_programmatic", "POST", "/campaign/search", data, options),
    update: (data, options) =>
      call("api_programmatic", "PATCH", "/campaign/update", data, options),
    refresh: (data, options) =>
      call("api_programmatic", "POST", "/campaign/refresh", data, options),
  },
  campaign_feed: {
    create: (data, options) =>
      call("api_programmatic", "PUT", "/campaign_feed/create", data, options),
    remove: (data, options) =>
      call(
        "api_programmatic",
        "DELETE",
        "/campaign_feed/remove",
        data,
        options
      ),
    search: (data, options) =>
      call("api_programmatic", "POST", "/campaign_feed/search", data, options),
    update: (data, options) =>
      call("api_programmatic", "PATCH", "/campaign_feed/update", data, options),
    refresh: (data, options) =>
      call("api_programmatic", "POST", "/campaign_feed/refresh", data, options),
  },
  campaign_job: {
    create: (data, options) =>
      call("api_programmatic", "PUT", "/campaign_job/create", data, options),
    remove: (data, options) =>
      call("api_programmatic", "DELETE", "/campaign_job/remove", data, options),
    search: (data, options) =>
      call("api_programmatic", "POST", "/campaign_job/search", data, options),
  },
  channel: {
    create: (data, options) =>
      call("api_programmatic", "PUT", "/channel/create", data, options),
    remove: (data, options) =>
      call("api_programmatic", "DELETE", "/channel/remove", data, options),
    search: (data, options) =>
      call("api_programmatic", "POST", "/channel/search", data, options),
    update: (data, options) =>
      call("api_programmatic", "PATCH", "/channel/update", data, options),
  },
  channel_subscription: {
    create: (data, options) =>
      call(
        "api_programmatic",
        "PUT",
        "/channel_subscription/create",
        data,
        options
      ),
    remove: (data, options) =>
      call(
        "api_programmatic",
        "DELETE",
        "/channel_subscription/remove",
        data,
        options
      ),
    search: (data, options) =>
      call(
        "api_programmatic",
        "POST",
        "/channel_subscription/search",
        data,
        options
      ),
    update: (data, options) =>
      call(
        "api_programmatic",
        "PATCH",
        "/channel_subscription/update",
        data,
        options
      ),
  },
  channel_subscription_contract: {
    create: (data, options) =>
      call(
        "api_programmatic",
        "PUT",
        "/channel_subscription_contract/create",
        data,
        options
      ),
    remove: (data, options) =>
      call(
        "api_programmatic",
        "DELETE",
        "/channel_subscription_contract/remove",
        data,
        options
      ),
    search: (data, options) =>
      call(
        "api_programmatic",
        "POST",
        "/channel_subscription_contract/search",
        data,
        options
      ),
    update: (data, options) =>
      call(
        "api_programmatic",
        "PATCH",
        "/channel_subscription_contract/update",
        data,
        options
      ),
  },
  creative: {
    create: (data, options) =>
      call("api_programmatic", "PUT", "/creative/create", data, options),
    remove: (data, options) =>
      call("api_programmatic", "DELETE", "/creative/remove", data, options),
    search: (data, options) =>
      call("api_programmatic", "POST", "/creative/search", data, options),
    update: (data, options) =>
      call("api_programmatic", "PATCH", "/creative/update", data, options),
  },
  feed: {
    create: (data, options) =>
      call("api_programmatic", "PUT", "/feed/create", data, options),
    upload: (data, options) =>
      call("api_programmatic", "PUT", "/feed/upload", data, options),
    remove: (data, options) =>
      call("api_programmatic", "DELETE", "/feed/remove", data, options),
    search: (data, options) =>
      call("api_programmatic", "POST", "/feed/search", data, options),
    update: (data, options) =>
      call("api_programmatic", "PATCH", "/feed/update", data, options),
    test: (data, options) =>
      call("api_programmatic", "POST", "/feed/test", data, options),
    refresh: (data, options) =>
      call("api_programmatic", "POST", "/feed/refresh", data, options),
  },
  job: {
    create: (data, options) =>
      call("api_programmatic", "PUT", "/job/create", data, options),
    remove: (data, options) =>
      call("api_programmatic", "DELETE", "/job/remove", data, options),
    search: (data, options) =>
      call("api_programmatic", "POST", "/job/search", data, options),
    check: (data, options) =>
      call("api_programmatic", "POST", "/job/check", data, options),
    update: (data, options) =>
      call("api_programmatic", "PATCH", "/job/update", data, options),
    refresh: (data, options) =>
      call("api_programmatic", "POST", "/job/refresh", data, options),
    duplicate: (data, options) =>
      call("api_programmatic", "PUT", "/job/duplicate", data, options),
  },
  job_evaluation: {
    create: (data, options) =>
      call("api_programmatic", "PUT", "/job_evaluation/create", data, options),
    update: (data, options) =>
      call("api_programmatic", "POST", "/job_evaluation/update", data, options),
    remove: (data, options) =>
      call(
        "api_programmatic",
        "DELETE",
        "/job_evaluation/remove",
        data,
        options
      ),
    search: (data, options) =>
      call("api_programmatic", "POST", "/job_evaluation/search", data, options),
  },
  job_recommendation: {
    create: (data, options) =>
      call(
        "api_programmatic",
        "PUT",
        "/job_recommendation/create",
        data,
        options
      ),
    remove: (data, options) =>
      call(
        "api_programmatic",
        "DELETE",
        "/job_recommendation/remove",
        data,
        options
      ),
    search: (data, options) =>
      call(
        "api_programmatic",
        "POST",
        "/job_recommendation/search",
        data,
        options
      ),
  },
  job_sector: {
    // create: (data, options) => call("api_programmatic", "PUT", "/job_sector/create", data, options),
    // remove: (data, options) => call("api_programmatic", "DELETE", "/job_sector/remove", data, options),
    // update: (data, options) => call("api_programmatic", "PATCH", "/job_sector/update", data, options),
    search: (data, options) =>
      call("api_programmatic", "POST", "/job_sector/search", data, options),
  },
  job_occupation: {
    // create: (data, options) => call("api_programmatic", "PUT", "/job_occupation/create", data, options),
    // remove: (data, options) => call("api_programmatic", "DELETE", "/job_occupation/remove", data, options),
    // update: (data, options) => call("api_programmatic", "PATCH", "/job_occupation/update", data, options),
    search: (data, options) =>
      call("api_programmatic", "POST", "/job_occupation/search", data, options),
  },
  limitation: {
    create: (data, options) =>
      call("api_programmatic", "PUT", "/limitation/create", data, options),
    remove: (data, options) =>
      call("api_programmatic", "DELETE", "/limitation/remove", data, options),
    search: (data, options) =>
      call("api_programmatic", "POST", "/limitation/search", data, options),
    update: (data, options) =>
      call("api_programmatic", "PATCH", "/limitation/update", data, options),
  },
  publication: {
    create: (data, options) =>
      call("api_programmatic", "PUT", "/publication/create", data, options),
    createBulk: (data, options) =>
      call("api_programmatic", "PUT", "/publication/create/bulk", data, options),
    remove: (data, options) =>
      call("api_programmatic", "DELETE", "/publication/remove", data, options),
    search: (data, options) =>
      call("api_programmatic", "POST", "/publication/search", data, options),
    update: (data, options) =>
      call("api_programmatic", "PATCH", "/publication/update", data, options),
    dispatch: (data, options) =>
      call("api_programmatic", "POST", "/publication/dispatch", data, options),
  },
  publication_url: {
    create: (data, options) =>
      call("api_programmatic", "PUT", "/publication_url/create", data, options),
    remove: (data, options) =>
      call(
        "api_programmatic",
        "DELETE",
        "/publication_url/remove",
        data,
        options
      ),
    search: (data, options) =>
      call(
        "api_programmatic",
        "POST",
        "/publication_url/search",
        data,
        options
      ),
    update: (data, options) =>
      call(
        "api_programmatic",
        "PATCH",
        "/publication_url/update",
        data,
        options
      ),
  },
  location: {
    search: (data, options) =>
      call("api_programmatic", "POST", "/location/search", data, options),
  },
  location_city: {
    search: (data, options) =>
      call("api_programmatic", "POST", "/location_city/search", data, options),
  },
  location_region: {
    search: (data, options) =>
      call(
        "api_programmatic",
        "POST",
        "/location_region/search",
        data,
        options
      ),
  },
  location_department: {
    search: (data, options) =>
      call(
        "api_programmatic",
        "POST",
        "/location_department/search",
        data,
        options
      ),
  },
  location_country: {
    search: (data, options) =>
      call(
        "api_programmatic",
        "POST",
        "/location_country/search",
        data,
        options
      ),
  },
  statistics: {
    general: (data, options) =>
      call("api_programmatic", "POST", "/statistics/general", data, options),
    channels: (data, options) =>
      call("api_programmatic", "POST", "/statistics/channels", data, options),
    history: (data, options) =>
      call("api_programmatic", "POST", "/statistics/history", data, options),
    ga_import: (data, options) =>
      call("api_programmatic", "POST", "/statistics/ga_import", data, options),
    csv_export_blob: (data, options) =>
      call("api_programmatic", "POST", "/statistics/csv_export", data, options),
  },
}

// Service: api_user
services.api_user = {
  _baseURL: getBaseURLWithKongBypass("api-user", "http://localhost:3022"), // docker ip http://172.26.0.5:3022
  _headers: {},
  _instance: null,
  // methods
  ping: (data, options) => call("api_user", "GET", "/", data, options),
  company: {
    create: (data, options) =>
      call("api_user", "PUT", "/company/create", data, options),
    remove: (data, options) =>
      call("api_user", "DELETE", "/company/remove", data, options),
    search: (data, options) =>
      call("api_user", "POST", "/company/search", data, options),
    update: (data, options) =>
      call("api_user", "PATCH", "/company/update", data, options),
  },
  currency: {
    create: (data, options) =>
      call("api_user", "PUT", "/currency/create", data, options),
    remove: (data, options) =>
      call("api_user", "DELETE", "/currency/remove", data, options),
    search: (data, options) =>
      call("api_user", "POST", "/currency/search", data, options),
    update: (data, options) =>
      call("api_user", "PATCH", "/currency/update", data, options),
  },
  country: {
    create: (data, options) =>
      call("api_user", "PUT", "/country/create", data, options),
    remove: (data, options) =>
      call("api_user", "DELETE", "/country/remove", data, options),
    search: (data, options) =>
      call("api_user", "POST", "/country/search", data, options),
    update: (data, options) =>
      call("api_user", "PATCH", "/country/update", data, options),
  },
  office: {
    create: (data, options) =>
      call("api_user", "PUT", "/office/create", data, options),
    remove: (data, options) =>
      call("api_user", "DELETE", "/office/remove", data, options),
    search: (data, options) =>
      call("api_user", "POST", "/office/search", data, options),
    update: (data, options) =>
      call("api_user", "PATCH", "/office/update", data, options),
  },
  product: {
    create: (data, options) =>
      call("api_user", "PUT", "/product/create", data, options),
    remove: (data, options) =>
      call("api_user", "DELETE", "/product/remove", data, options),
    search: (data, options) =>
      call("api_user", "POST", "/product/search", data, options),
    update: (data, options) =>
      call("api_user", "PATCH", "/product/update", data, options),
  },
  role: {
    create: (data, options) =>
      call("api_user", "PUT", "/role/create", data, options),
    remove: (data, options) =>
      call("api_user", "DELETE", "/role/remove", data, options),
    search: (data, options) =>
      call("api_user", "POST", "/role/search", data, options),
    update: (data, options) =>
      call("api_user", "PATCH", "/role/update", data, options),
  },
  user: {
    create: (data, options) =>
      call("api_user", "PUT", "/user/create", data, options),
    remove: (data, options) =>
      call("api_user", "DELETE", "/user/remove", data, options),
    search: (data, options) =>
      call("api_user", "POST", "/user/search", data, options),
    update: (data, options) =>
      call("api_user", "PATCH", "/user/update", data, options),
    recovery: (data, options) =>
      call("api_user", "POST", "/user/recovery", data, options),
  },
  user_permission: {
    create: (data, options) =>
      call("api_user", "PUT", "/user_permission/create", data, options),
    remove: (data, options) =>
      call("api_user", "DELETE", "/user_permission/remove", data, options),
    search: (data, options) =>
      call("api_user", "POST", "/user_permission/search", data, options),
    update: (data, options) =>
      call("api_user", "PATCH", "/user_permission/update", data, options),
  },
  user_product: {
    create: (data, options) =>
      call("api_user", "PUT", "/user_product/create", data, options),
    remove: (data, options) =>
      call("api_user", "DELETE", "/user_product/remove", data, options),
    search: (data, options) =>
      call("api_user", "POST", "/user_product/search", data, options),
    update: (data, options) =>
      call("api_user", "PATCH", "/user_product/update", data, options),
  },
  user_session: {
    switch: (data, options) =>
      call("api_user", "POST", "/user_session/switch", data, options),
    check: (data, options) =>
      call("api_user", "POST", "/user_session/check", data, options),
    login: (data, options) =>
      call("api_user", "PUT", "/user_session/login", data, options),
    search: (data, options) =>
      call("api_user", "POST", "/user_session/search", data, options),
  },
}

// Service: api_statistic
services.api_statistic = {
  _baseURL: getBaseURLWithKongBypass("api-statistic", "http://localhost:3022"),
  _headers: {},
  _instance: null,
  // methods
  ping: (data, options) => call("api_statistic", "GET", "/", data, options),
  report: {
    download: (data, options) =>
      call("api_statistic", "GET", "/report/download", data, options),
    upload: (data, options) =>
      call("api_statistic", "PUT", "/report/upload", data, options),
  },
}

services.api_rnd = {
  _baseURL: getBaseURLWithKongBypass("api-rnd", "http://localhost:3000"),
  _headers: {},
  _instance: null,
  // methods
  ping: (data, options) => call("api_rnd", "GET", "/", data, options),
}

services.api_worker_publication = {
  _baseURL: getBaseURLWithKongBypass("api-worker-publication", "http://localhost:3000"),
  _headers: {},
  _instance: null,
  // methods
  ping: (data, options) =>
    call("api_worker_publication", "GET", "/", data, options),
}

services.api_worker_feed = {
  _baseURL: getBaseURLWithKongBypass("api-worker-feed", "http://localhost:3022"),
  _headers: {},
  _instance: null,
  // methods
  ping: (data, options) => call("api_worker_feed", "GET", "/", data, options),
}

services.api_worker_job = {
  _baseURL: getBaseURLWithKongBypass("api-worker-job", "http://localhost:3024"),
  _headers: {},
  _instance: null,
  // methods
  ping: (data, options) => call("api_worker_job", "GET", "/", data, options),
}

// Service: api_statistic
services.api_notification = {
  _baseURL: getBaseURLWithKongBypass("api-notification", "http://localhost:3028"),
  _headers: {},
  _instance: null,
  // methods
  notification: {
    create: (data, options) =>
      call("api_notification", "PUT", "/notification/create", data, options),
    remove: (data, options) =>
      call("api_notification", "DELETE", "/notification/remove", data, options),
    search: (data, options) =>
      call("api_notification", "POST", "/notification/search", data, options),
    update: (data, options) =>
      call("api_notification", "PATCH", "/notification/update", data, options),
    view: (data, options) =>
      call("api_notification", "POST", "/notification/view", data, options),
  },
}

services.files_ra = {
  _baseURL: getBaseURL("", "https://kong-api.xtramile.tech"),
  _headers: {},
  _instance: null,
  // methods
  files: {
    get: (data, options) => call("files_ra", "GET", "/files", data, options),
  },
}

services.xranking_ra = {
  _baseURL: getBaseURL("", "https://kong-api.xtramile.tech"),
  _headers: {},
  _instance: null,
  // methods
  v2_cvs: {
    get: (data, options) =>
      call("xranking_ra", "GET", "/xranking/v2/cvs", data, options),
    getById: (data) => call("xranking_ra", "GET", `/xranking/v2/cvs/${data}`),
  },
  v2_jobs: {
    get: (data, options) =>
      call("xranking_ra", "GET", "/xranking/v2/jobs", data, options),
    getById: (data) => call("xranking_ra", "GET", `/xranking/v2/jobs/${data}`),
  },
  v2_offers: {
    get: (data, options) =>
      call("xranking_ra", "GET", "/xranking/v2/jobs", data, options),
    getById: (data) => call("xranking_ra", "GET", `/xranking/v2/jobs/${data}`),
  },
  cvs: {
    remove: (data, options) =>
      call("xranking_ra", "DELETE", `/xranking/cvs/${data}`),
  },
  city: {
    get: (data) =>
      call("xranking_ra", "GET", `/xranking/v2/city/autocomplete?q=${data}`),
  },
}

services.xmatching_ra = {
  _baseURL: getBaseURLMatching("", "https://ranking.xtramile.tech"),
  _headers: {},
  _instance: null,
  //methods
  v2_job: {
    match: (job_id, cityURL, data, options) =>
      call(
        "xmatching_ra",
        "POST",
        `/api/v2/job/match?jobId=${job_id}${cityURL}`,
        data,
        options,
      ),
  },
  v2_cvs: {
    match: (cvId, limit, data, options) =>
      call(
        "xmatching_ra",
        "POST",
        `/api/v1/cv/match?applicantId=${cvId}&limit=${limit}`,
        data,
        options
      ),
  },
}

services.xusers_ra = {
  _baseURL: getBaseURL("", "https://kong-api.xtramile.tech"),
  _headers: {},
  _instance: null,
  // methods
  organization: {
    describe: (data, options) =>
      call("xusers_ra", "GET", "/organization", data, options),
  },
}

services.xxlsfiles_ra = {
  _baseURL: getBaseURL("", "https://kong-api.xtramile.tech"),
  _headers: {},
  _instance: null,
  // methods
  file: {
    detailes: (fileId, data, options) =>
      call("xxlsfiles_ra", "GET", `/files/${fileId}`, data, options),
  },
}

services.xrankingact_ra = {
  _baseURL: getBaseURLXranking("", "https://xranking-backend.xtramile.tech"),
  _headers: {},
  _instance: null,
  // methods
  cv: {
    remove: (id, data, options) =>
      call("xrankingact_ra", "DELETE", `/cvs/${id}`, data, options),
    get: (id) => call("xrankingact_ra", "GET", `/v2/cvs/${id}`),
  },
  job: {
    remove: (id, data, options) => 
      call("xrankingact_ra", "DELETE", `/jobs/${id}`, data, options),
  },
}

services.xrate_ra = {
  _baseURL: getBaseURL("", "https://kong-api.xtramile.tech"),
  _headers: {},
  _instance: null,
  // methods
  rate: {
    set: (id, data, options) =>
      call("xrate_ra", "PUT", `xranking/v2/cvs/${id}/feedback`, data, options),
  },
}

services.xfiles_ra = {
  _baseURL: getBaseURL("", "https://kong-api.xtramile.tech"),
  _headers: {},
  _instance: null,
  // methods
  file: {
    upload: (data, options) =>
      call(
        "xfiles_ra",
        "POST",
        "/api-programmatic/landing_page/file/upload",
        data,
        options
      ),
    isParsed: (fileId, data, options) =>
      call("xfiles_ra", "GET", `/files/${fileId}`, data, options),
  },
}

services.xbi_pr = {
  _baseURL: getBaseURLBiXparsing('', 'https://programmatic-job-parser.xtramile.tech'),
  _headers: {},
  _instance: null,
  // methods
  job: {
    parse: (data, options) => call('xbi_pr', 'POST', '/api/v2/jobs/parse/norm?generate_bi=true', data, options),
    token: (data, options) => call('xbi_pr', 'POST', '/token', data, options),
  },
}

// export const getFileDetailsRequest = fileId => xtramileParsedFiles.get(`/files/${fileId}`)
//   .then(response => response.data)
//   .catch(err => errorHandler(err));

// Socket handler
const createSocket = () => {
  const baseURL = process.env.VUE_APP_WEBSOCKET_URL
    ? process.env.VUE_APP_WEBSOCKET_URL
    : "ws://localhost:3028/"
  const basePath = process.env.VUE_APP_WEBSOCKET_URL
    ? "/socket.io/"
    : "/socket.io/"
  const socket = io(baseURL, {
    path: basePath,
    transports: ["websocket", "polling"],
  })
  socket.on("connect", () => `WebSocket connection established with ${baseURL}`)
  socket.on("connect_error", (error) =>
    console.warn("Connection socket error", { error })
  )
  socket.on("disconnect", () => `WebSocket connection closed with ${baseURL}`)
  socket.on("message", (message) =>
    console.log(`[WebSocket: message] ${message}`)
  )
  socket.emit("auth", getToken())
  return socket
}
const destroySocket = (socket) => {
  socket.offAny()
  socket.disconnect()
}

// get authorization token
const getToken = () => {
  return services.api_user._instance.defaults.headers["Authorization"]
}

// init all axios instances
const init = (options = {}) => {
  Object.keys(services).forEach((serviceName, i) => {
    console.log(serviceName)
    const service = Object.assign(
      services[serviceName],
      options[serviceName] || {}
    )
    service._instance = axios.create({
      baseURL: service._baseURL,
      timeout: 120000,
      headers: service._headers,
    })
    services[serviceName] = service
  })
}

// set instances params
const set = (name, value, serviceName = null) => {
  if (!serviceName)
    return Object.keys(services).forEach((sName, i) => {
      set(name, value, sName)
    })

  if (!services[serviceName])
    throw new Error(`Services: ${serviceName}does not exists.`)
  const service = services[serviceName]
  console.info("Services: Connected to", serviceName, service)

  switch (name) {
    case "token":
      if (value && serviceName !== 'xbi_pr') service._instance.defaults.headers["Authorization"] = value
      else delete service._instance.defaults.headers["Authorization"]
      break
    case "token_ra":
      if (serviceName.endsWith("_ra")) {
        if (value)
          service._instance.defaults.headers[
            "Authorization"
            ] = `Bearer ${value}`
        else delete service._instance.defaults.headers["Authorization"]
      }
      break
    case "token_parse":
      if (serviceName.endsWith("_pr")) {
        if (value)
          service._instance.defaults.headers[
            "Authorization"
            ] = `Bearer ${value}`
        else delete service._instance.defaults.headers["Authorization"]
      }
      break
    case "baseURL":
      service._instance.defaults.baseURL = value
      break
    case "timeout":
      service._instance.defaults.timeout = +value
      break
    case "headers":
      service._instance.defaults.headers = Object.assign(
        service.instance.headers,
        value
      )
      break
    default:
      throw new Error(`Services: unknows params: ${name}.`)
  }
  services[serviceName] = service
}

const vm = {
  install: function(Vue, options) {
    init(options)
    Vue.prototype.$services = {
      ...services,
      set,
      init,
      getToken,
      createSocket,
      destroySocket,
    }
  },
}
Vue.use(vm)

export default { ...services, set, init }
