export default {
  TITLE: "Permission",
  TITLES: "Permissions",
  TITLE_COUNT: "Aucune permission | 1 permission | {n} permissions",
  ICON: "mdi-key-chain-variant",
  DEFAULT_PERMISSION:
    "permission par défaut  | permissions par défaut | permissions par défaut",
  TIME_USAGE: "Temps d'utilisation",
  LIMIT_USAGE: "Limite d'utilisation",
  FORM_TITLE_UPDATE: "Modifier une permission",
  FORM_TITLE_NEW: "Nouvelle permission",
  FORM_TITLE_CREATE: "Créer une permission",
}