export default {
  TITLE: "Notification",
  TITLES: "Notifications",
  TITLE_COUNT: "No notification | 1 notification | {n} notifications",
  ICON: "mdi-bell-outline",

  SCOPE_FEED: 'Feed',
  SCOPE_JOB: 'Job',
  SCOPE_CAMPAIGN: 'Campaign',
  SCOPE_CHANNEL_SUBSCRIPTION: 'Média',

  FEED_FIRST_REFRESH_SUCCESS: "Feed {_1} has been successfully initialized.",
  FEED_REFRESH_SUCCESS: "Feed {_1} has been successfully updated.",
  FEED_REFRESH_ERROR: "Feed {_1} has encountered an issue. {_2}",
  FEED_REFRESH_REPORT: "Offers have been updated from feed {_1}: {_2} created, {_3} updated, {_4} removed, {_5} error(s).",

  CAMPAIGN_PUBLICATION_ERROR: "{_1} publications has failed.",
  CAMPAIGN_PUBLICATION_PUBLISHED: "{_1} publications has been published.",

  VIEW_ALL: "View all",
  ALREADY_VIEW: "Previous notifications",
  RECENTLY_VIEW: "Recently viewed",
  NO_NEW_NOTIFICATION: "No new notification",

  JOB_PARSED_SUCCESS: "All Offers has been analyzed.",

  CAMPAIGN_FEED_REFRESH_SUCCESS: "Campaign offers have been updated: {_1} added, {_2} removed.",

  CAMPAIGN_PUBLICATION_CREATE_SUCCESS: "Publications have been created by campaign rules: {_1} created",
  CAMPAIGN_PUBLICATION_CREATE_WARNING: "Publications have been created by campaign rules, but there are some errors: {_1} created, {_2} error",
  CAMPAIGN_PUBLICATION_CREATE_ERROR: "Publications via campaign rules have failed: {_2} error",
  CAMPAIGN_PUBLISH_SUCCESS: "Campaign {_1} updated: {_2} published offers(s), {_3} error(s).",

  CHANNEL_SUBSCRIPTION_CONTRACT_ERROR: "The contract is over.",

  LARGE_FILE: "File is too large. Repeat please other file",
  FORMAT_NOT_SUPPORTED: "Format not supported. Use the above format.",
  UPLOAD_ERROR: "Sorry, upload failed. Try again!",
  NEED_SELECT: "Please select a document!",
  MANY_FILES: "The number of uploaded files is limited to 10. Try uploading less."
}
