export default {
  CV_PARSING: "Analyse de CV",
  UPLOAD_YOUR_CV: "Télécharger votre CV",
  AVAILABLE_EXTENSIONS: "Extensions disponibles",
  CLICK_FOR_UPLOAD_FILE: "Cliquez ou faites glisser le fichier à télécharger",
  UPLOAD_CV_ICON: "mdi-file-upload",
  UPLOAD: "Télécharger",
  CLEAR: "Dégager",
  FILE_SIZE_RULE: "La taille du fichier ne doit pas dépasser 2 Mo!",
  FILE_REQUIRED_RULE: "Aucun fichier sélectionné",
  IDENTITIES: "Identités",
  TITLES: "Titres",
  INTRODUCTION: " Introduction",
  EDUCATION: "Éducation",
  WORK_EXPERIENCE: "Expérience Professionnelle",
  SKILLS: "Compétences",
  OTHERS: "Autres",
  CITY: "Ville",
  COUNTRY: "Pays",
  SCHOOL_NAME: "Nom de l'école",
  START_DATE: "Date de début",
  END_DATE: "Date de fin",
  LEVEL: "Niveau",
  SPECIALISATION: "Spécialisation",
  FIRST_NAME: "Prénom",
  LAST_NAME: "Nom de famille",
  ADDRESS: "Adresse",
  POSTAL_CODE: "Code postal",
  STATE: "État",
  EMAIL: "E-mail",
  PHONE_NUMBER: "Numéro de téléphone",
  BIRTHDAY: "Date de naissance",
  CITIZENSHIP: "Citoyenneté",
  DRIVING_LICENCE: "Permis de conduire",
  OCCUPATION: "Occupation",
  OCCUPATION_LINK: "Lien d’occupation",
  CONTRACT_TYPE: "Type de contrat",
  DURATION: "Durée",
  HARD_SKILLS: "Compétences techniques",
  SOFT_SKILLS: "Compétences générales",
  OTHER_OCCUPATIONS: "Autres occupations",
  OTHER_HARD_SKILLS: "Autres compétences techniques",
  OTHER_SOFT_SKILLS: "Autres compétences générales",
  LANGUAGES: "Langues",
  INTERESTS: "Intérêts",
  ORGANISATION_NAME: "Nom de l'organisme",
  TASKS: "Tâches",
  WEIGHT: "Importance du filtre",
}
