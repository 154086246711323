<template>
  <v-list-group id="xranking" :value="opened" class="xranking white--text" v-if="productXranking">
    <template v-slot:activator>
      <v-list-item-title id="xranking__menu" class="xranking__menu d-flex accent--text">
        <strong><h3 id="xranking__title" class="xranking__title">X<span class="white--text">Ranking</span></h3></strong>
      </v-list-item-title>
    </template>

    <v-list-item id="xranking_offers" class="xranking_offers pl-6" to="/xranking/offers">
      <v-list-item-icon id="xranking_offers__item-icon" class="xranking_offers__item-icon">
        <v-icon
          id="xranking_offers__icon"
          color="accent"
          v-text="$t('JOB.ICON')"
          size="20"
          class="xranking_offers__icon"
        />
      </v-list-item-icon>
      <v-list-item-content id="xranking_offers__item-content" class="xranking_offers__item-content">
        <v-list-item-title id="xranking_offers__item-title" class="xranking_offers__item-title">
          <strong v-text="$t('JOB.TITLES')" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item id="xranking_cvs" class="xranking_cvs pl-6" to="/xranking/cvs">
      <v-list-item-icon id="xranking_cvs__item-icon" class="xranking_cvs__item-icon">
        <v-icon
          id="xranking_cvs__icon"
          color="accent"
          v-text="$t('CV.ICON')"
          size="20"
          class="xranking_cvs__icon"
        />
      </v-list-item-icon>
      <v-list-item-content id="xranking_cvs__item-content" class="xranking_cvs__item-content">
        <v-list-item-title id="xranking_cvs__item-title" class="xranking_cvs__item-title">
          <strong v-text="$t('CV.TITLES')" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item id="xranking_transformation" class="xranking_transformation pl-6" to="/xranking/transformation">
      <v-list-item-icon id="xranking_transformation__item-icon" class="xranking_transformation__item-icon">
        <v-icon
          id="xranking_transformation__icon"
          color="accent"
          v-text="$t('INSIGHT.ICON')"
          size="20"
          class="xranking_transformation__icon"
        />
      </v-list-item-icon>
      <v-list-item-content id="xranking_transformation__item-content" class="xranking_transformation__item-content">
        <v-list-item-title id="xranking_transformation__item-title" class="xranking_transformation__item-title">
          <strong v-text="$t('INSIGHT.TITLE')" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "MenuMainXranking",
  components: {
  },
  data: (_this) => ({
    productXranking: false,
    language: "en",
  }),
  async created () {
    this.productXranking = !!(await this.$permission.check('product.xranking'))
  },
  computed: {
    opened () {
      return this.page.includes('/xranking')
    },
    ...mapState("app", {
      page: (state) => {
        return state.page
      },
    }),
    ...mapState("user", {
      username: (state) =>
        state.user ? `${state.user.firstname[0]}. ${state.user.lastname}` : "-",
      fullname: (state) =>
        state.user ? `${state.user.firstname} ${state.user.lastname}` : "-",
      role: (state) =>
        state.user && state.user.role ? state.user.role.title : "-",
      company: (state) =>
        state.user && state.user.company ? state.user.company.title : "-",
      office: (state) =>
        state.user && state.user.office ? state.user.office.title : "-",
      userIsXtramileAdmin: (state) =>
        state.user && state.user.role && state.user.role.title[0] === "x",
      userIsAdmin: (state) =>
        state.user &&
        state.user.role &&
        state.user.role.title.includes("admin"),
    }),
  },

  methods: {
  },
}
</script>
