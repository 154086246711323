export default {
  BUDGET_INCREMENT: "Increment budget",
  BUDGET_DEPOSITS: "deposit(s)",
  CREATED: "Created",
  UPDATED: "Updated",
  CREATED_AT: "Created date",
  REF: "Reference",
  EMAIL: "Email",
  PASSWORD: "Password",
  STATUS: "Status",
  STATUS__1: "Removed",
  STATUS_0: "Not published",
  STATUS_1: "Published",
  ID: "ID",
  TITLE: "Title",
  TITLES: "Titles",
  SALARY: "Salary",
  LEVEL: "Level",
  DESCRIPTION: "Description",
  FULL_DESCRIPTION: "Full description",
  UPLOAD_IMAGE: "Upload image",
  DELETE_IMAGE: "Delete image",
  COUNTRY: "Country",
  ADMIN: "Admin",
  CHANNEL: "Channel",
  BUDGET: "Budget",
  TOTAL_BUDGET: "Total budget",
  COMMISSION: "Commission",
  TOTAL: "Total",
  GROUP_BY: "Group by",
  IMAGE: "Image",
  INFORMATIONS: "Informations",
  CHANNEL_TYPES: "Channel type",
  CONTRACT_START_DATE: "Contract start date",
  CONTRACT_END_DATE: "Contract end date",
  CONTRACT_TYPES: "Contrat type",
  CONTROLLER_NAME: "Controller name",
  CREDIT: "Crédits",

  TEST_MODE: "Mode: Test",
  USE_XTRAMILE_ACCOUNT: "Use Xtramile account",

  CAMPAIGN_TYPES: "Campaign types:",
  CAMPAIGN_METHODS: "Campaign methods:",
  CAMPAIGN_MODE: "Campaign mode:",
  MODE_JOBBOARD: "jobboard",
  MODE_AGGREGATOR: "aggregator",
  MODE_DISPLAY: "display",
  MODE_PERFORMANCE: "performance",
  MODE_PERFORMANCE_ADVANCED: "advanced",

  SHOW_PUBLIC: "Afficher au public (Landing page)",

  INTRO: "Intro",

  MIN: "Min",
  MAX: "Max",
  PERIOD: "Period",
  DIPLOMAS: "Diplomas",

  PROFILE: "Profile",

  AUTOREFRESH: "Update automatically each day",
  NEED_REFRESH: "Need to be refresh ?",
  NEED_DATA: "Query data",
  NEED_AUTH: "Authentication",

  CREATE_LANDING_PAGES: "Generate landing pages on landing.xtramile.io",

  HASH: "Hash",
  NAME: "Name",
  URL: "Url",
  ACTION: "Action",
  SCOPES: "Scopes",
  LASTNAME: "Lastname",
  FIRSTNAME: "Firstname",
  PHONE: "Phone",
  COMPANY: "Company",
  OFFICE: "Office",
  ROLE: "Role",
  USER: "User",
  LIMIT: "Limit",
  DATE: "Date",
  DATE_START: "Date start",
  DATE_END: "Date end",

  PRICE: "Price",
  COUNTRY_CODE: "Code (ISO 3166-1 alpha3)",
  LANGUAGE: "Languages",
  DOMAINS: "Domains",
  DOMAINS_SALE: "Commercial",
  FEE_RATE: "Fee rate",
  TAX_RATE: "Tax rate",
  TAX_AMOUNT: "Tax amount",

  CURRENCY: "Currency",
  CURRENCY_EUR: "Euro",
  CURRENCY_USD: "US Dollar",

  LOCATION: "Location",
  LOCATION_COUNTRY: "Country",
  LOCATION_COUNTRY_CODE: "Country code",
  LOCATION_REGION: "Region",
  LOCATION_REGION_CODE: "Region code",
  LOCATION_DEPARTMENT: "Department",
  LOCATION_DEPARTMENT_CODE: "Department code",
  LOCATION_CITY: "City",
  LOCATION_CITY_CODE: "City code (zip)",
  LOCATION_LAT: "Lattitude",
  LOCATION_LON: "Longitude",

  AGE: "Age",
  AGE_MIN: "Age min.",
  AGE_MAX: "Age max.",

  DURATION_CAMPAIGN: "Duration of campaign",
  DURATION: "Duration",
  DURATION_MIN: "Duration min.",
  DURATION_MAX: "Duration max.",
  DURATION_PERIOD: "Period",
  START_DATE: "Start date",
  END_DATE: "End date",

  EDUCATION: "Education",
  EDUCATION_LEVEL: "Education level",
  EDUCATION_VOCATIONAL: "Vocational",
  EDUCATION_HIGHSCHOOL: "High-school",
  EDUCATION_ASSOCIATE: "Associate",
  EDUCATION_BACHELOR: "Bachelor",
  EDUCATION_EXECUTIVE_BACHELOR: "Bachelor <+",
  EDUCATION_MASTER: "Master",
  EDUCATION_EXECUTIVE_MASTER: "Master <+",
  EDUCATION_DOCTORANT: "Phd",
  EDUCATION_EXECUTIVE_DOCTORANT: "Phd <+",

  EXPERIENCE: "Experience",
  EXPERIENCE_LEVEL: "Experience level",
  EXPERIENCE_NONE: "None",
  EXPERIENCE_BEGINNER: "Beginner (0-1)",
  EXPERIENCE_JUNIOR: "Junior (2-4)",
  EXPERIENCE_EXPERIENCED: "Experienced (5-9)",
  EXPERIENCE_SENIOR: "Senior (10+)",

  FORCE: "Force",

  CATEGORY: "Category",
  CONVERSION_RATE: "Conversion rate",
  ZERO_DECIMAL: "Zero decimal",

  METHODS: "Methods",
  METHOD: "Method",
  TYPES: "Types",
  CONTRACT_REMOTE: "Accept remote",
  CONTRACT: "Contract",
  CONTRACT_TYPE: "Type",
  CONTRACT_TYPE_PERMANENT: "Permanent",
  CONTRACT_TYPE_FIXED: "Fixed",
  CONTRACT_TYPE_TEMPORARY: "Temporary",
  CONTRACT_TYPE_INTERIM: "Interim",
  CONTRACT_TYPE_INTERNSHIP: "Internship",
  CONTRACT_TYPE_WORK_STUDY: "Work-study",
  CONTRACT_TYPE_INTERMITTENT: "Intermittent",
  CONTRACT_TYPE_OFFICIAL: "Official",
  CONTRACT_TYPE_FREELANCE: "Freelance",
  CONTRACT_TYPE_SEASONAL: "Seasonal",
  CONTRACT_TYPE_APPRENTICESHIP: "Apprenticeship",
  CONTRACT_TYPE_VOLUNTEER: "Volunteering",

  LANGUAGE_FR: "French",
  LANGUAGE_EN: "English",
  LANGUAGE_DE: "Deutsch",
  LANGUAGE_NL: "Dutch",
  LANGUAGE_IT: "Italian",
  LANGUAGE_ES: "Spanish",
  LANGUAGE_RU: "Russian",
  LANGUAGE_CN: "Chinese",

  CONTRACT_SCHEDULE: "Schedule",
  CONTRACT_SCHEDULE_FULL_TIME: "full-time",
  CONTRACT_SCHEDULE_PART_TIME: "part-time",
  CONTRACT_SCHEDULE_FULL_PART_TIME: "full and part-time",
  CONTRACT_SCHEDULE_ONE_SHOT: "one-off",

  PROTOCOL: "Protocol",
  PROTOCOL_PARAMS: "Protocol params",
  PROTOCOL_HTTP: "HTTP",
  PROTOCOL_FTP: "FTP",
  PROTOCOL_STORAGE: "STORAGE",
  PROTOCOL_METHOD: "Call method",
  PROTOCOL_METHOD_GET: "GET",
  PROTOCOL_METHOD_POST: "POST",
  PROTOCOL_METHOD_PUT: "PUT",
  PROTOCOL_METHOD_PATCH: "PATCH",
  PROTOCOL_METHOD_DELETE: "DELETE",
  FILEFORMAT: "File format",
  FILEFORMAT_CSV: "CSV",
  FILEFORMAT_JSON: "JSON",
  FILEFORMAT_XML: "XML",
  AUTH_TOKEN: "Authorization Token",
  QUERY_DATA: "Query data",
  HOST: "host",
  PORT: "port",
  SECURE: "Secure mode (ssl)",
  SECURE_OPTION: "Secure options",
  FILE_PATH: "File path",
  FILEFORMAT_FIELDS: "Fields mapping",
  FILEFORMAT_HOOKS: "Automate tasks",
  FILEFORMAT_LIST: "Start point of job list",

  CONTACT: "Contact",
  QUANTITY: "Quantity",

  IMAGE_URL: "Image Url",
  TAGS: "Tags",
  PERIOD_YEAR: "year",
  PERIOD_MONTH: "month",
  PERIOD_DAY: "day",
  PERIOD_HOUR: "hour",

  FIELDS: "Fields",
  VALUE: "Value",
  VALUES: "Values",
  DEFAULT_VALUE: "Default values",
  MULTIPLE_VALUE: "Choose one or more values",

  URL_TEMPLATE: "URL template",
  MACRO: "Macro name",
  MACRO_TYPE: "Type",
  MACRO_TYPE_REGEX: "RegExp",
  MACRO_TYPE_DATA: "Data",
  MACRO_ON: "On",
  MACRO_TRIGGER: "Trigger value",
  MACRO_VALUE: "Value",
  MACRO_DEFAULT_VALUE: "Default value",
  MACROS: "Macros",

  FEED_CREATE_JOB: "Add jobs automatically",
  FEED_REMOVE_JOB: "Remove jobs automatically",

  FILE_UPLOAD_NO_IMAGE: "No image",

  DUPLICATE_BY_CITIES_IN_COUNTRY: "Duplicate cities from country",
  DUPLICATE_BY_CITIES_IN_DEPARTMENT: "Duplicate cities from department",
  DUPLICATE_BY_CITIES_IN_REGION: "Duplicate cities from region",
  DUPLICATE_BY_CITIES_IN_DESCRIPTION:
    "Duplicate cities from description",

  CONTACT_NAME: "Name",
  CONTACT_POSITION: "Position",
  CONTACT_EMAIL: "Email",
  CONTACT_PHONE: "Phone num.",
  COMPANY_TITLE: "Company name",
  COMPANY_IMAGE_URL: "Image URL",
  COMPANY_URL: "Website URL",
  COMPANY_DESCRIPTION: "Company Description",
  TYPE: "Type",
  NO_LIMIT_VALUE: "No limit",

  GA4_PARAMS_TITLE: "Configure the integration with Google Analytics",
  GA4_PARAMS_PROPERTY_ID: "Property ID",
  GA4_PARAMS_PROPERTY_ID_PLACEHOLDER: "292480871",
  GA4_PARAMS_CONVERSION_EVENT_NAME: "Conversion event",
  GA4_PARAMS_CONVERSION_EVENT_NAME_PLACEHOLDER: "apply",

  OCCUPATIONS: "Occupations",
  SECTOR: "Sector",
}
