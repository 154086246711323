import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import plugins from './plugins'

Vue.config.devtools = process.env.NODE_ENV && process.env.NODE_ENV !== 'production'

/*
* Don't put any action, state, or whatever that reactive in this files,
* do it in modules/app if you want to use a no-namespaced store
*/
Vue.use(Vuex)

const store =  new Vuex.Store({
  modules: modules,
  plugins: plugins,
  // Relative to: https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV && process.env.NODE_ENV !== 'production'
})

// singleton
export default store
