export default {
  TITLE: "URL tag management",
  TITLES: "URL tag management",
  ICON: "mdi-routes",
  ADD_MACRO: "Add macro",
  DELETE_MACRO: "Delete macro",
  SAVE: "To safeguard",
  MACRO_NAME: "Macro name",
  ADD_CONFIG: "Add configuration",
  REMOVE_CONFIG: "Delete configuration",
}