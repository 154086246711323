export default {
  TITLE: "Company",
  TITLES: "Companys",
  TITLE_COUNT: "entreprise | 1 entreprise | {n} entreprises",
  ICON: "mdi-domain",
  INFO: "Informations",
  FIELD_TITLE: "Title",
  FIELD_IMAGE: "Image",
  FIELD_TITLE_PLACEHOLDER: "My company",
  FIELD_DESCRIPTION: "Description",
  FIELD_DESCRIPTION_PLACEHOLDER: "With 150 employees ...",
  CONTACT: "Contact",
  FIELD_NAME: "Name",
  FIELD_NAME_PLACEHOLDER: "Eric Dupond",
  FIELD_EMAIL: "Email",
  FIELD_EMAIL_PLACEHOLDER: "eric.dupond@monentreprise.fr",
  FIELD_ADDRESS: "Address",
  FIELD_ADDRESS_PLACEHOLDER:
    "6 rue du géneral de Gaulle, 75001 Paris, France",
  FORM_TITLE_UPDATE: "Update company",
  FORM_TITLE_NEW: "New company",
  FORM_TITLE_CREATE: "Create company",
}