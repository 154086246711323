export default {
  TITLE: "Règle",
  TITLES: "Règles",
  ICON: "mdi-arrow-decision",
  TITLE_COUNT: "aucune règle | 1 règle | {n} règles",
  FULL_TITLE: "Règle automatisée",
  FULL_TITLES: "Règles automatisées",

  EVENT_COUNT: "aucun événement | 1 événement | {n} événements",
  ACTION_COUNT: "aucune action | 1 action | {n} actions",

  ADD_RULE: "Ajouter une règle",

  FIELD_STATUS: "Statut",
  FORM_RULE_CREATE: "Ajouter une règle",
  FORM_RULE_UPDATE: "Modifier une règle",
  FIELD_RULE: "Règle",
  FIELD_LIMIT: "Limite",
  FIELD_NAME: "Nom de la règle",
  FIELD_COUNT: "",

  FORM_EVENT_CREATE: "Ajouter un événement",
  FORM_EVENT_UPDATE: "Modifier un événement",
  FIELD_EVENT: "Evénements",
  FIELD_EVENT_NAME: "Type d'évenement",
  FIELD_EVENT_FILTERS: "Filtres",
  FORM_ACTION_CREATE: "Ajouter une action",
  FORM_ACTION_UPDATE: "Modifier une action",
  FIELD_ACTION: "Actions",
  FIELD_ACTION_NAME: "Type d'action",
  FIELD_ACTION_VALUES: "Paramètres",

  FIELD_CUSTOM: 'Champs personnalisés',
  FIELD_CUSTOM_NAME: 'Champ',
  FIELD_CUSTOM_VALUES: 'Valeurs',
  FIELD_CUSTOM_OPERATOR: 'opérateur',
  CUSTOM_OPERATORS_ITEMS: [
    {text:'égal à', value: 'eq'},
    {text:'différent de', value: 'not'},
    {text:'contient', value: 'like'},
    {text:'ne contient pas', value: 'notlike'},
    {text:'moins que', value: 'lt'},
    {text:'moins ou egal à', value: 'lte'},
    {text:'plus que', value: 'gt'},
    {text:'plus ou egal à', value: 'gte'},
    {text:'avant', value: 'before'},
    {text:'après', value: 'after'},
  ],

  EVENT_NAMES: {
    CAMPAIGN_JOB_CREATE: "Ajout d'une/plusieurs offres",
    CAMPAIGN_JOB_CREATE_ICON: "mdi-briefcase-download",
    CAMPAIGN_JOB_UPDATE: "Modification d'une/plusieurs offres",
  },
  EVENT_NAME_ITEMS: [
    {text:'Ajout d\'une/plusieurs offres', value: 'campaign_job_create'},
    // {text:'Modification d\'une/plusieurs offres', value: 'campaign_job_update'},
  ],
  ACTION_NAMES: {
    PUBLICATION_CREATE: "Créer une/plusieurs publications",
    PUBLICATION_CREATE_ICON: "mdi-plus-box-multiple",
    PUBLICATION_UPDATE: "Modifier une/plusieurs publications",
    CAMPAIGN_UPDATE: "Modifier la campagne en cours",
    CAMPAIGN_STOP: "Arreter la campagne en cours",
  },
  ACTION_NAME_ITEMS: [
    {text:'Créer une/plusieurs publications', value: 'publication_create'},
    {text:'Modifier une/plusieurs publications', value: 'publication_update'},
    {text:'Modifier la campagne en cours', value: 'campaign_update'},
    {text:'Arreter la campagne en cours', value: 'campaign_stop'},
  ],
  STATUS_ITEMS: [
    {text:'Active', value: 1},
    {text:'Inactive', value: 0},
  ]
}
