export default {
  REQUIRED: "Required",
  EMAIL: "E-mail must be valid (ex: myname@gmail.com)",
  EMAIL_ALREADY_EXISTS:
    "There already exists a user account with this email address, please use another one or login with it.",
  URL: "Url must be valid (ex: https://www.gmail.com)",
  PASSWORD: "Min. 6 characters and max. 16 characters",
  DATE: "Select a valid date",
  COUNTRY: "Select your country",
  MIN: "Min. {min}",
  MAX: "Max. {max}",
  MINLENGTH: "Min. {min} characters",
  MAXLENGTH: "Max. {max} characters",
}