export default {
  TITLE: "Flux",
  TITLES: "Flux",
  TITLE_COUNT: "aucun flux | 1 flux | {n} flux",

  REMOVE: "Mettre à la corbeille",
  RESET: "Annuler les modifications",
  REFRESH: "Mettre à jour les offres",
  SAVE: "Enregistrer",
  PREVIOUS: "Précedent",
  NEXT: "Suivant",

  FORM_TITLE_UPDATE: "Modifier un flux",
  FORM_TITLE_CREATE: "Créer un flux",
  FORM_TITLE_NEW: "Saisissez les informations du nouveau flux",

  FIELD_STATUS: "",
  FIELD_STATUS_TYPE: "",
  FIELD_STATUS_CODE: "",
  FIELD_STATUS_MESSAGE: "",

  FIELD_TITLE: "Nom du flux",
  FIELD_TITLE_HELP: "",
  FIELD_TITLE_PLACEHOLDER: "mon flux principal",
  FIELD_AUTO_REFRESH: "Mettre à jour le flux automatiquement",
  FIELD_LEGACY_HTML_TO_MD: "Utiliser le vieux convertisseur HTML-Markdown (obsolète)",
  FIELD_MAX_CHURN_PERCENT: "Max churn % pendant un rafraîchissement",

  LANDING_PAGE_EMAIL_WARNING:
    "L'option Landing page nécessite une addresse email dans les offres d'emploi, veillez à configurer le champs \"E-mail du contact\" ci-dessous.",
  PROTOCOL_TEXT: "Comment voulez vous charger le fichier ?",
  PROTOCOL_TITLE: "Chargement du fichier",
  PROTOCOL_BTN: "Configurer",
  PROTOCOL: "Protocol",
  PROTOCOL_COUNT: "Aucun champ renseigné | | ",
  FIELD_PROTOCOL: "Comment voulez-vous charger le fichier ?",
  FIELD_PROTOCOL_HTTP: "Charger le fichier depuis une URL",
  FIELD_PROTOCOL_FTP: "Charger le fichier depuis un FTP",
  FIELD_PROTOCOL_UPLOAD: "Charger le fichier depuis mon ordinateur",
  FIELD_PROTOCOL_API: "Charger les données depuis une API ou ATS",
  FIELD_PROTOCOL_API_CONTROLLER_NAME: "API ou ATS",
  FIELD_PROTOCOL_METHOD_GET: "GET",
  FIELD_PROTOCOL_METHOD_POST: "POST",
  FIELD_PROTOCOL_METHOD_PUT: "PUT",
  FIELD_PROTOCOL_METHOD_PATCH: "PATCH",
  FIELD_PROTOCOL_PARAMS_NEED_AUTH: "Authentification",
  FIELD_PROTOCOL_PARAMS: "",
  FIELD_PROTOCOL_PARAMS_URL: "URL",
  FIELD_PROTOCOL_PARAMS_FILE: "Fichier",
  FIELD_PROTOCOL_PARAMS_URL_PLACEHOLDER: "https://www.monsite.fr/fichier.xml",
  FIELD_PROTOCOL_PARAMS_METHOD: "Methode",
  FIELD_PROTOCOL_PARAMS_USER: "Utilisateur",
  FIELD_PROTOCOL_PARAMS_PASSWORD: "Mot de passe",
  FIELD_PROTOCOL_PARAMS_NEED_DATA: "Données de requête supplementaires",
  FIELD_PROTOCOL_PARAMS_AUTH: "Authentification",
  FIELD_PROTOCOL_PARAMS_QUERY_DATA: "Données de requête (JSON)",
  FIELD_PROTOCOL_PARAMS_HOST: "Hôte",
  FIELD_PROTOCOL_PARAMS_PORT: "Port",
  FIELD_PROTOCOL_PARAMS_PATH: "Chemin du fichier",
  FIELD_PROTOCOL_PARAMS_SECURE: "Activer la connexion sécurisée (SSL)",
  FIELD_PROTOCOL_PARAMS_SECURE_OPTION: "Options de sécurité (JSON)",

  FILEFORMAT_FIELDS_TITLE: "Champs de données",
  FILE_ERROR:
    "Le fichier n'a pas été récupéré, vérifier les paramètres de connexion.",
  TEST_FILE: "Tester le fichier",
  TEST_FILE_AGAIN: "Retester le fichier",
  FILEFORMAT_CUSTOM_FIELDS_TITLE: "Champs de données personnalisés",
  FILEFORMAT_CUSTOM_FIELDS_LIMIT:
    "Vous avez atteint la limite de 15 champs personnalisés.",
  FILEFORMAT_FIELDS_COLUMNS_ERROR:
    "Vous devez charger un fichier au format XML, CSV ou JSON valide pour configurer les champs et terminer la création du flux.",
  FILEFORMAT_FIELDS_COUNT:
    "Aucun champ renseigné | 1 champ renseigné | {n} champs renseignés",
  FILEFORMAT_FIELDS_BTN: "Configurer",
  FILEFORMAT_CUSTOM_TITLE: "Champs de données personalisés",
  FILEFORMAT_HOOKS_TITLE: "Tâches automatisées",
  FILEFORMAT_HOOKS_BTN: "Configurer",
  FILEFORMAT_HOOKS_COUNT:
    "Aucune tâche renseignée | 1 tâche renseignée | {n} tâches renseignées",
  FIELD_FILEFORMAT: "Format",
  FIELD_FILEFORMAT_LIST: "",
  FIELD_FILEFORMAT_FIELDS: "",
  FIELD_FILEFORMAT_COLUMNS: "",
  FIELD_FILEFORMAT_HOOKS: "",

  FIELD_FILEFORMAT_EXAMPLES: "Exemples",
  FIELD_FILEFORMAT_FORCE: "Forcer cette tâche",
  FIELD_FILEFORMAT_SPACING: "Espacement",
  FIELD_FILEFORMAT_SPACING_DEFAULT_VALUE: "(vide par défaut)",
  FIELD_FILEFORMAT_SEPARATOR: "Séparateur",
  FIELD_FILEFORMAT_DEFAULT_VALUE: "Valeur par défaut",
  FIELD_FILEFORMAT_EXCLUDE_VALUE: "Valeurs exclues",
  FIELD_FILEFORMAT_CHANGES: "Altérations du contenu",
  FIELD_FILEFORMAT_CHANGES_BTN: "Ajouter",
  FIELD_FILEFORMAT_CHANGES_FUNCTION: "Fonction",
  FIELD_FILEFORMAT_CHANGES_FUNCTION_ARG_REGEX: "Expression",
  FIELD_FILEFORMAT_CHANGES_FUNCTION_ARG_VALUE: "Valeur",
  FIELD_FILEFORMAT_CHANGES_FUNCTION_ARG_REPLACE: "Remplacer",

  FIELD_CUSTOM_KEY: "Nom de champs",

  STEP_INTRO_TITLE: "Introduction",
  STEP_INTRO_DESCRIPTION:
    "Nommez et activez ou non la mise à jour de votre source.",

  STEP_PROTOCOL_TITLE: "Chargement du fichier",
  STEP_PROTOCOL_DESCRIPTION:
    "Paramétrez la source de données et chargez automatiquement les offres depuis un fichier ou une adresse externe.",

  STEP_FIELD_TITLE: "Champs de données",
  STEP_FIELD_DESCRIPTION:
    "Liez les champs de données du fichier avec les bases de données Xtramile.",

  STEP_CUSTOM_FIELD_TITLE: "Champs de données personnalisés",
  STEP_CUSTOM_FIELD_DESCRIPTION:
    "Créer des champs de données personnalisés pour une exploitation ultérieure dans les système Xtramile.",

  STEP_HOOK_TITLE: "Tâches automatisées",
  STEP_HOOK_DESCRIPTION:
    "Automatisez la transformation et la publication de vos offres.",

  STEP_CONFIRM_TITLE: "Confirmation",
  STEP_CONFIRM_DESCRIPTION: "Vérifiez et confirmez les informations du flux.",

  HOOK_TEXT: "Automatisez des opérations",
  HOOK_DESCRIPTION:
    "Transformez ou dupliquez les données avec de nouvelle entrées.",

  HOOK_NAME: "Nom de la tâche",
  HOOK_DUPLICATE_BY_CITIES_IN_COUNTRY: "Dupliquer les villes d'un pays",
  HOOK_DUPLICATE_BY_CITIES_IN_REGION: "Dupliquer les villes d'une région",
  HOOK_DUPLICATE_BY_CITIES_IN_DEPARTMENT:
    "Dupliquer les villes d'un department",
  HOOK_DUPLICATE_BY_CITIES_IN_DESCRIPTION:
    "Dupliquer les villes depuis un champs",

  NO_PROTOCOL: "Aucun protocole",

  INTRODUCTION_TEXT: "Nommez votre flux",

  PROTOCOL_API_TEXT: "Remplissez les informations de connexion",

  PROTOCOL_PARAMS_TEXT: "Remplissez les informations de connexion au fichier",
  PROTOCOL_PARAMS_FILE_TYPE_WARNING:
    "Types de fichier acceptés: XML, CSV, JSON",

  FILEFORMAT_TEXT: "Remplissez les champs de données",
  FILEFORMAT_DESCRIPTION:
    "Séléctionnez les colonnes ou les balises présentes dans le fichier, ou saisir une valeur par défaut.",

  FILEFORMAT_CUSTOM_TEXT: "Champs de données libres",
  FILEFORMAT_CUSTOM_DESCRIPTION:
    "Ajoutez des champs de données supplémentaires dans l'offre que vous pourrez exploiter par la suite.",

  HEADER_HOOK_NAME: "Nom de la tâche",
  HEADER_KEY_NAME: "Nom de champs",
  HEADER_KEY_SEPARATOR_CONTACT: "Contact",
  HEADER_KEY_SEPARATOR_COMPANY: "Entreprise",
  HEADER_KEY_SEPARATOR_ADVANCED: "Paramètres supplémentaires",
  HEADER_CUSTOM_KEY_NAME: "Nom de champs libre",
  HEADER_COLUMNS: "Colonnes / Balises",

  ADD_FIELDS: "Ajouter un champ",

  PROTOCOL_UPLOAD_TEXT: "Charger un fichier",
  PROTOCOL_DROPZONE_TEXT_1:
    "Déposez le fichier dans la zone pour charger le contenu",
  PROTOCOL_DROPZONE_TEXT_2:
    "ou cliquez pour sélectionner le fichier depuis votre ordinateur.",

  MAPPED_FIELD: "Aucun champ | 1 champ | {n} champs",
  DEFAULT_FIELD:
    "Aucun champ par défaut | 1 champ par défaut | {n} champs par défaut",
  NO_FILEFORMAT: "No fileformat",
  ICON: "mdi-rss",
  TEST_ICON: "mdi-test-tube",
  TEST_SUCCESS_MESSAGE: "Le flux a été extrait correctement !",
  TEST_JOBS_MESSAGE:
    "Aucune offre trouvée. | {n} offre trouvé. | {n} offres trouvées.",

  REFRESH_SUCCESS_ICON: "mdi-check-circle",
  REFRESH_SUCCESS: "Mis à jour",

  REFRESH_RUNNING_ICON: "mdi-refresh-circle",
  REFRESH_RUNNING: "En cours de traitement",

  REFRESH_STOPPED_ICON: "mdi-alert-circle",
  REFRESH_STOPPED: "Mise à jour annulée",

  REFRESH_FAILED_ICON: "mdi-alert-circle",
  REFRESH_FAILED: "Mise à jour échouée",

  REFRESH_WAIT_ICON: "mdi-refresh-circle",
  REFRESH_WAIT: "En attente",

  REMOVED: "Supprimé",
  UNMODIFIED: "Non Modifié",
  CREATED: "Nouveau",
  UPDATED: "Modifié",
  ERRORS: "Erreur",
  TOTAL: "Total Offres",
  IN_FILE: "Dans le fichier",

  HOOK_RESULTS: "Résultat(s) de duplication",

  DISABLED: "Le flux est désactivé. Veuillez l'utiliser en lecture seule, les mises à jour du flux ne fonctionneront pas.",
};
