<template>
  <v-list-group
    v-if="productXtrading"
    :value="opened"
    class="xtrading white--text"
  >
    <template v-slot:activator>
      <v-list-item-title class="xtrading__menu accent--text pt-1">
        <strong >
          <h3 class="xtrading__title">X<span class="white--text">MultiDiff
              <span v-if="officeData.options.multidiff360" class="xtrading__title-content">
                <v-chip class="trading__content ml-3 px-2 mb-1" color="accent" outlined x-small> 360°
                </v-chip>
              </span>
            </span>
          </h3>
        </strong>
      </v-list-item-title>
    </template>

    <v-list-item
      id="xtrading-channel_subscriptions"
      class="xtrading-channel_subscriptions pl-6"
      to="/xtrading/channel_subscriptions"
    >
      <v-list-item-icon
        id="xtrading-channel_subscriptions__item-icon"
        class="xtrading-channel_subscriptions__item-icon"
      >
        <v-icon
          id="xtrading-channel_subscriptions__icon"
          color="accent"
          v-text="$t('CHANNEL_SUBSCRIPTION.ICON')"
          size="20"
          class="xtrading-channel_subscriptions__icon"
        />
      </v-list-item-icon>
      <v-list-item-content id="xtrading-channel_subscriptions__item-content" class="xtrading-channel_subscriptions__item-content">
        <v-list-item-title id="xtrading-channel_subscriptions__item-title" class="xtrading-channel_subscriptions__item-title">
          <strong
            id="xtrading-channel_subscriptions__title"
            class="xtrading-channel_subscriptions__title"
            v-text="$t('CHANNEL_SUBSCRIPTION.TITLES')"
          />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      id="xtrading_feeds"
      v-if="officeData.options.multidiff360"
      class="xtrading_feeds pl-6"
      to="/xtrading/feeds"
    >
      <v-list-item-icon id="xtrading_feeds__item-icon" class="xtrading_feeds__item-icon">
        <v-icon
          id="xtrading_feeds__icon"
          color="accent"
          v-text="$t('FEED.ICON')"
          size="20"
          class="xtrading_feeds__icon"
        />
      </v-list-item-icon>
      <v-list-item-content id="xtrading_feeds__item-content" class="xtrading_feeds__item-content">
        <v-list-item-title id="xtrading_feeds__item-title" class="xtrading_feeds__item-title">
          <strong id="xtrading_feeds__title" v-text="$t('FEED.TITLES')" class="xtrading_feeds__title" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item id="xtrading_jobs" class="xtrading_jobs pl-6" to="/xtrading/jobs">
      <v-list-item-icon id="xtrading_jobs__item-icon" class="xtrading_jobs__item-icon">
        <v-icon
          id="xtrading_jobs__icon"
          color="accent"
          v-text="$t('JOB.ICON')"
          size="20"
          class="xtrading_jobs__icon"
        />
      </v-list-item-icon>
      <v-list-item-content id="xtrading_jobs__item-content" class="xtrading_jobs__item-content">
        <v-list-item-title id="xtrading_jobs__item-title" class="xtrading_jobs__item-title">
          <strong
            id="xtrading_jobs__title"
            v-text="$t('JOB.TITLES')"
            class="xtrading_jobs__title"
          />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="!officeData.options.multidiff360"
      id="xtrading_publications"
      class="xtrading_publications pl-6"
      to="/xtrading/publications"
    >
      <v-list-item-icon id="xtrading_publications__item-icon" class="xtrading_publications__item-icon">
        <v-icon
          id="xtrading_publications__icon"
          color="accent"
          v-text="$t('PUBLICATION.ICON')"
          size="20"
          class="xtrading_publications__icon"
        />
      </v-list-item-icon>
      <v-list-item-content id="xtrading_publications__item-content" class="xtrading_publications__item-content">
        <v-list-item-title id="xtrading_publications__item-title" class="xtrading_publications__item-title">
          <strong v-text="$t('PUBLICATION.TITLES')" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item
      v-if="officeData.options.multidiff360"
      id="xtrading_campaigns"
      class="xtrading_campaigns pl-6"
      to="/xtrading/campaigns"
    >
      <v-list-item-icon id="xtrading_campaigns__item-icon" class="xtrading_campaigns__item-icon">
        <v-icon
          color="accent"
          v-text="$t('CAMPAIGN.ICON')"
          size="20"
          id="xtrading_campaigns__icon"
          class="xtrading_campaigns__icon"
        />
      </v-list-item-icon>
      <v-list-item-content id="xtrading_campaigns__item-content" class="xtrading_campaigns__item-content">
        <v-list-item-title id="xtrading_campaigns__item-title" class="xtrading_campaigns__item-title">
          <strong
            v-text="$t('CAMPAIGN.TITLES')"
            id="xtrading_campaigns__title"
            class="xtrading_campaigns__title"
          />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item id="xtrading_ratios" class="xtrading_ratios pl-6" to="/xtrading/ratios">
      <v-list-item-icon id="xtrading_ratios__item-icon" class="xtrading_ratios__item-icon">
        <v-icon
          id="xtrading_ratios__icon"
          color="accent"
          v-text="$t('RATIO.ICON')"
          size="20"
          class="xtrading_ratios__icon"
        />
      </v-list-item-icon>
      <v-list-item-content id="xtrading_ratios__item-content" class="xtrading_ratios__item-content">
        <v-list-item-title id="xtrading_ratios__item-title" class="xtrading_ratios__item-title">
          <strong v-text="$t('RATIO.TITLES')" id="xtrading_ratios__title" class="xtrading_ratios__title" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "MenuMainXprogrammatic",
  components: {
  },
  data: (_this) => ({
    productXtrading: false,
    language: "en",
    office_id: [],
    officeData: {
      options: {
        multidiff360: false
      },
    }
  }),

  async created () {
    this.productXtrading = !!(await this.$permission.check('product.xtrading'))
  },

  mounted () {
    this.loadOptions()
  },

  computed: {
    opened () {
      return this.page.includes('/xtrading')
    },
    ...mapState("app", {
      page: (state) => {
        return state.page
      },
    }),
    ...mapState("user", {
      username: (state) =>
        state.user ? `${state.user.firstname[0]}. ${state.user.lastname}` : "-",
      fullname: (state) =>
        state.user ? `${state.user.firstname} ${state.user.lastname}` : "-",
      role: (state) =>
        state.user && state.user.role ? state.user.role.title : "-",
      company: (state) =>
        state.user && state.user.company ? state.user.company.title : "-",
      office: (state) =>
        state.user && state.user.office ? state.user.office.title : "-",
      userIsXtramileAdmin: (state) =>
        state.user && state.user.role && state.user.role.title[0] === "x",
      userIsAdmin: (state) =>
        state.user &&
        state.user.role &&
        state.user.role.title.includes("admin"),
    }),
  },

  methods: {
    async loadOptions () {
      const userSession = await this.$services.api_user.user_session.check()
      this.office_id.push(userSession.data.user.office.id)
      const response = await this.$services.api_user.office.search({where: { id: this.office_id[0] }})
      
      let hasOption360 = true;
      if (response.data && response.data.length > 0) {
        const options = response.data[0].options        
        if (options.multidiff360 !== undefined && typeof options.multidiff360 == "boolean") {
          hasOption360 = options.multidiff360;
        }
      }
      this.officeData.options.multidiff360 = hasOption360;
      
    }
  },
}
</script>

<style lang="scss">
</style>
