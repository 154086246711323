export default {
  TITLE: "Offre",
  TITLES: "Offres",
  TITLE_COUNT: "aucune offre | {n} offre | {n} offres",
  ICON: "mdi-briefcase-account",
  PARSING_TITLES: "Tâches d'analyse",
  PARSING_CONTENT_LABELS: {
    global_occupation: "occupations xtramile",
    cities: "géographie",
    experience_level: "expérience",
    bi_info: "cpa",
    xtramile1: "xtramile I",
    xtramile2: "xtramile II",
    xtramile3: "xtramile III",
    address: "adresse",
    state: "etat",
    city: "ville",
    postal_code: "code postal",
    experience_requise: "expérience requise",
    cpa_min: "cpa min",
    cpa_mean: "cpa mean",
    cpa_max: "cpa max",
    cpa_qualified: "cpa qualified",
    cpa_hired: "cpa hired",
    cpa_currency: "cpa currency",
  },

  STATUS_ERROR_COUNT: " | {n} erreur à revoir | {n} erreurs à revoir",
  REMOVE: "Mettre à la corbeille",
  RESET: "Annuler les modifications",
  REFRESH: "Analyser l'offre",
  DUPLICATED: "Duplicata",
  SAVE: "Enregistrer",
  PREVIOUS: "Précedent",
  NEXT: "Suivant",
  CHECK: "Vérifier l'offre",
  ARCHIVED: "Archivée",
  ARCHIVED_TEXT:
    "Offre archivée, celle-ci ne sera pas prise en charge dans les publications.",

  PARENT: "Cacher les offres dupliquées",

  JOB_DRAFTS: "Brouillons",
  JOB_ARCHIVED: "Archivés",
  JOB_REMOVED: "Supprimés",

  FORM_TITLE_PUBLISH_COUNT:
    "Aucune offre séléctionnée | 1 offre séléctionnée | {n} offres séléctionnées",
  FORM_TITLE_PUBLISH: "Publier des offres",
  FORM_TITLE_UPDATE: "Modifier une offre",
  FORM_TITLE_CREATE: "Créer une offre",
  FORM_TITLE_NEW: "Saisissez les informations de la nouvelle offre d'emploi",

  FIELD_OCCUPATION_PLACEHOLDER: "Saissisez un métier",
  FIELD_CAMPAIGN_TITLE: "Titre",
  FIELD_CAMPAIGN_START_DATE: "Début",
  FIELD_CAMPAIGN_END_DATE: "Fin",

  FORM_CAMPAIGN_CHOOSE_TITLE: "Choisissez une campagne",
  FORM_CHANNEL_SUBSCRIPTION_CHOOSE_TITLE: "Choisissez un ou plusieurs médias",
  FORM_CHANNEL_SUBSCRIPTION_CHOOSE_TEXT:
    "Des publications seront créées sur les médias sélectionnés pour les offres qui ne sont pas déjà publiées.",

  FORM_CHANNEL_SUBSCRIPTION_PARAMS_TITLE: "",
  FORM_CHANNEL_SUBSCRIPTION_PARAMS_CHOOSE_PRICING_MODEL: "Modèle de prix",
  FIELD_CAMPAIGN_PLACEHOLDER: "Campagnes",

  HAS_PARENT:
    "Cette offre provient d'une offre dupliquée et sera modifiée en même temps que son parent.",
  HAS_PARENT_BTN: "Accéder à l'offre parente",

  FILTER_TITLE: "Sélectionnez vos filtres",
  FIELD_TITLE: "Titre",
  FIELD_TITLE_PLACEHOLDER: "Saisissez un titre...",
  FIELD_DATE: "Date de parution",
  FIELD_DATE_PLACEHOLDER: "Date",
  CLASSIFICATION: "Classification",
  FIELD_OFFICE: "Bureau",
  FIELD_OFFICE_PLACEHOLDER: "Bureau",
  FIELD_SECTOR: "Secteur",
  FIELD_SECTOR_PLACEHOLDER: "",
  FIELD_CATEGORY: "Catégorie",
  FIELD_CATEGORY_PLACEHOLDER: "",
  FIELD_URL: "URL",
  FIELD_URL_PLACEHOLDER: "https://www.monsite.fr/mon-offre",
  CONTRACT: "Contrat",
  CONTRACT_STATUS: "Status",
  CONTRACT_DATE: "Dates du contract",
  FIELD_CONTRACT_STATUS: "Statut",
  FIELD_CONTRACT_STATUS_PLACEHOLDER: "",
  CONTRACT_TYPE: "Type de contrat",
  FIELD_CONTRACT_TYPE: "Contrat",
  FIELD_CONTRACT_TYPE_PLACEHOLDER: "",
  CONTRACT_SCHEDULE: "Horaire",
  FIELD_CONTRACT_SCHEDULE: "Plage horaire",
  FIELD_CONTRACT_SCHEDULE_PLACEHOLDER: "",
  FIELD_CONTRACT_REMOTE: "Télétravail accepté",
  FIELD_LOCATION: "Localisation",
  FIELD_LOCATION_PLACEHOLDER: "Saisissez une ville ...",
  FIELD_DESCRIPTION: "Mission",
  FIELD_DESCRIPTION_PLACEHOLDER: "Saisissez une description de la mission...",
  FIELD_PROFILE: "Profil",
  FIELD_PROFILE_PLACEHOLDER:
    "Personnes assidue et réactive ayant une expérience dans le contact humain....",
  FIELD_TAGS: "Mot clés",
  FIELD_TAGS_PLACEHOLDER: "Saissisez une valeur puis appuyez sur Entrée",

  ADVANCED_PARAMS: "Paramètres avancés",
  SALARY: "Salaire",
  SALARY_MIN: "Salaire minimum",
  FIELD_SALARY_MIN: "Min",
  FIELD_SALARY_MIN_PLACEHOLDER: "Min",
  SALARY_MAX: "Salaire maximum",
  FIELD_SALARY_MAX: "Max",
  FIELD_SALARY_MAX_PLACEHOLDER: "Max",
  SALARY_PERIOD: "Période du salaire",
  FIELD_SALARY_PERIOD: "Période",
  FIELD_SALARY_PERIOD_PLACEHOLDER: "",
  SALARY_CURRENCY: "Devise du salaire",
  FIELD_SALARY_CURRENCY: "Devise",
  FIELD_SALARY_CURRENCY_PLACEHOLDER: "",
  DURATION: "Durée de contrat",
  DURATION_MIN: "Durée minimum",
  FIELD_DURATION_MIN: "Min",
  FIELD_DURATION_MIN_PLACEHOLDER: "Min",
  DURATION_MAX: "Durée maximum",
  FIELD_DURATION_MAX: "Max",
  FIELD_DURATION_MAX_PLACEHOLDER: "Max",
  DURATION_PERIOD: "Période de la durée",
  FIELD_DURATION_PERIOD: "Période",
  FIELD_DURATION_PERIOD_PLACEHOLDER: "",
  PUBLICATION_DATES: "Dates limite de publication",
  START_DATE: "Début",
  FIELD_START_DATE: "Date de début",
  FIELD_START_DATE_PLACEHOLDER: "",
  END_DATE: "Fin",
  FIELD_END_DATE: "Date de fin",
  FIELD_END_DATE_PLACEHOLDER: "",
  CONTRACT_START_DATE: "Début du contrat",
  FIELD_CONTRACT_START_DATE: "Date de début de contract",
  FIELD_CONTRACT_START_DATE_PLACEHOLDER: "",
  CONTRACT_END_DATE: "Fin du contrat",
  FIELD_CONTRACT_END_DATE: "Date de fin du contract",
  FIELD_CONTRACT_END_DATE_PLACEHOLDER: "",
  AGES: "Tranche d'âges",
  AGE_MIN: "Âge minimum",
  FIELD_AGE_MIN: "Min",
  FIELD_AGE_MIN_PLACEHOLDER: "Min",
  AGE_MAX: "Âge maximum",
  FIELD_AGE_MAX: "Max",
  FIELD_AGE_MAX_PLACEHOLDER: "Max",
  EXPERIENCE: "Expérience",
  EXPERIENCE_LEVEL: "Expérience",
  FIELD_EXPERIENCE_LEVEL: "Niveau d'expérience",
  FIELD_EXPERIENCE_LEVEL_PLACEHOLDER: "",
  EDUCATION: "Education",
  EDUCATION_LEVEL: "Education",
  FIELD_EDUCATION_LEVEL: "Niveau scolaire",
  FIELD_EDUCATION_LEVEL_PLACEHOLDER: "",
  DIPLOMA: "Diplôme",
  FIELD_DIPLOMAS: "Diplômes",
  FIELD_DIPLOMAS_PLACEHOLDER: "Saissisez une valeur puis appuyez sur Entrée",
  QUANTITY: "Places disponibles",
  FIELD_QUANTITY: "",
  FIELD_QUANTITY_PLACEHOLDER: "1",

  PROFILE_VALIDATION: "",

  RECRUITER: "Informations sur l'entreprise",
  COMPANY: "Entreprise",
  COMPANY_URL: "Adresse URL",
  COMPANY_IMAGE_URL: "Image (URL)",
  FIELD_COMPANY_TITLE: "Titre",
  FIELD_COMPANY_TITLE_PLACEHOLDER: "Mon Entreprise",
  FIELD_COMPANY_DESCRIPTION: "Description",
  FIELD_COMPANY_DESCRIPTION_PLACEHOLDER:
    "Mon Entreprise est basée à Paris, nous sommes les spécialistes de la vente avec plus de 200 collaborateurs à travers la France...",
  FIELD_COMPANY_URL: "Adresse URL",
  FIELD_COMPANY_URL_PLACEHOLDER: "https://www.monentreprise.fr",
  FIELD_COMPANY_IMAGE_URL: "Image (URL)",
  FIELD_COMPANY_IMAGE_URL_PLACEHOLDER: "",
  CONTACT: "Contact",
  CONTACT_NAME: "Nom du contact",
  CONTACT_EMAIL: "Adresse e-mail",
  FIELD_OCCUPATION: "Métiers",
  FIELD_CONTACT_NAME: "Nom du contact",
  FIELD_CONTACT_NAME_PLACEHOLDER: "M. Dupont",
  FIELD_CONTACT_EMAIL: "Adresse e-mail",
  FIELD_CONTACT_EMAIL_PLACEHOLDER: "dupond@monentreprise.fr",

  CUSTOM_PARAMS: "Champs personnalisés",

  STEP_INTRO: "Introduction",
  STEP_INTRO_TITLE: "Introduction",
  STEP_INTRO_DESCRIPTION:
    "Veuillez saisir les informations essentielles pour le job",

  STEP_CONTRACT: "Contrat",
  STEP_CONTRACT_TITLE: "Spécifications du contrat",
  STEP_CONTRACT_DESCRIPTION:
    "Veuillez saisir les informations contractuelles liées au job",

  STEP_COMPANY_SPECIFICATION: "Entreprise",
  STEP_COMPANY_SPECIFICATION_TITLE: "Spécification de l'entreprise",
  STEP_COMPANY_SPECIFICATION_DESCRIPTION: "",

  STEP_CONFIRM: "Confirmation",
  STEP_CONFIRM_TITLE: "Confirmer la création de l'offre",
  STEP_CONFIRM_DESCRIPTION:
    "Veuillez vérifier l’exactitude des informations renseignées",

  SUBTITLE_JOB: "Offre",
  SUBTITLE_CONTRACT_SECTOR: "Secteur",
  SUBTITLE_CONTRACT_CONDITIONS: "Conditions",
  SUBTITLE_CONTRACT_SALARY: "Salaire",
  SUBTITLE_CONTRACT_DURATION: "Durée",
  SUBTITLE_CONTRACT_EXPERIENCE: "Expérience",

  CATEGORY_ICON: "mdi-billiards-rack",

  PARSE_SUCCESS_ICON: "mdi-check-circle",
  PARSE_SUCCESS: "Analysé",

  PARSE_WAIT_ICON: "mdi-refresh-circle",
  PARSE_WAIT: "En attente",

  PARSE_FAILED_ICON: "mdi-alert-circle",
  PARSE_FAILED: "Échec de l'analyse",

  PARSE_FAILED_FINAL_ICON: "mdi-alert-circle",
  PARSE_FAILED_FINAL: "Échec final de l'analyse",

  DUPLICATION_FAILED_ICON: "mdi-alert-circle",
  DUPLICATION_FAILED: "Échec de la duplication",

  PARSE_RUNNING_ICON: "mdi-refresh-circle",
  PARSE_RUNNING: "Analyse en cours",

  // XTRAMILE_SECTOR: "XSector",
  // XTRAMILE_CATEGORY: "XCategory",
  // ESCO4_CODE: "Esco4 code",
  // ROME_CODE: "Rome code",
  // LOCATION: "Location",
  // GEO: "Geoloc",
  COMPANY_TITLE: "Titre de l'entreprise",
  // COMPANY_IMAGE: "Image de l'entreprise",
  // COMPANY_URL: "Company url",
  // CONTACT_NAME: "Nom du contact",
  // CONTACT_PHONE: "Numéro de téléphone du contact ",
  // CONTACT_EMAIL: "Adresse e-mail du contact",
  // SALARY: "Salaire",
  // MIN: "Min.",
  // MAX: "Max.",
  // AGE: "Age",
  // CONTRACT_TYPE: "Type",
  // CONTRACT_SCHEDULE: "Plage horaire",
  // CONTRACT_DETAILS: "Details du contrat",
  // CONTRACT_REMOTE: "Pas de télétravail | Télétravail | Télétravail partiel",
  // EXPERIENCE_LEVEL: "Niveau d'expérience",
  // EXPERIENCE_DOMAINS: "Domaines d'expérience ",
  // EDUCATION_LEVEL: "Niveau de formation ",
  // EDUCATION_DOMAINS: "Domaine de formation",
  // DIPLOMAS: "Diplômes",
  // EDUCATION_LANGUAGES: "Langues",

  EXACT_TEXT_MATCH: "Correspondance exacte du texte",
  MANDATORY: "Obligatoire",

  DRAFT: "Brouillon",

  STATUS: "Afficher: Tous",
  STATUS_TYPE_ITEMS: [
    { text: "Afficher: Erreurs", value: "error" },
    { text: "Afficher: Attentes", value: "info" },
    { text: "Afficher: En cours d'analyse", value: "warning" },
  ],

  CONTRACT_SCHEDULES_ITEMS: [
    { value: "fulltime", text: "Temps plein" },
    { value: "parttime", text: "Temps partiel" },
    { value: "fullpartime", text: "Temps plein ou partiel" },
  ],

  CONTRACT_STATUS_ITEMS: [
    { value: "employee", text: "Employé" },
    { value: "frame", text: "Cadre" },
  ],

  CONTRACT_TYPES_ITEMS: [
    { value: "permanent", text: "CDI" },
    { value: "fixed", text: "CDD" },
    { value: "interim", text: "Interim" },
    { value: "intermittent", text: "Intermittent" },
    { value: "internship", text: "Interne/Stage" },
    { value: "work_study", text: "Alternance" },
    { value: "apprenticeship", text: "Apprentissage" },
    { value: "seasonal", text: "Saisonnier" },
    { value: "volunteering", text: "Volontariat" },
    { value: "freelance", text: "Indépendant" },
  ],

  EXPERIENCE_LEVEL_ITEMS: [
    { value: "beginner", text: "Débutant (<2ans)" },
    { value: "junior", text: "Junior (2-4ans)" },
    { value: "experienced", text: "Expérimenté (5-9ans)" },
    { value: "senior", text: "Senior (>10ans)" },
  ],

  EDUCATION_LEVEL_ITEMS: [
    { value: "vocationnal", text: "CAP/BEP" },
    { value: "highschool", text: "Bac" },
    { value: "associate", text: "Bac+2" },
    { value: "bachelor", text: "Licence" },
    { value: "executive_bachelor", text: "Bac+4" },
    { value: "master", text: "Maîtrise" },
    { value: "executive_master", text: "Bac+6" },
    { value: "doctorant", text: "Doctorat" },
    { value: "executive_doctorant", text: "Bac+10" },
  ],

  PERIOD_ITEMS: [
    { value: "day", text: "Jour" },
    { value: "week", text: "Semaine" },
    { value: "month", text: "Mois" },
    { value: "year", text: "An" },
  ],

  CURRENCY_ITEMS: [
    { value: "EUR", text: "Euro" },
    { value: "USD", text: "US dollar" },
    { value: "GBP", text: "Pound" },
  ],

  SECTOR: {
    1: "Agriculture, Forêt, Pêche",
    2: "Banque, Finance",
    3: "Bâtiment Travaux Publics",
    4: "Distribution, Commerce",
    5: "Communication, Publicité, Edition",
    6: "Culture, Arts, Spectacles",
    7: "Enseignement, Formation",
    8: "Energie",
    9: "Hôtellerie, Restauration",
    10: "Informatique, Technologies",
    11: "Santé",
    12: "Défense, Sécurité",
    13: "Services aux particuliers",
    14: "Logistique, Transport de biens",
    15: "Industrie",
    16: "Pharmaceutique, Biotechnologies",
    17: "Agroalimentaire",
    18: "Automobile",
    19: "Aéronautique, Naval et Défense",
    20: "Electronique, Equipements",
    21: "Assurances, Mutuelle",
    22: "Audiovisuel, Médias",
    23: "Services aux entreprises",
    24: "Conseils aux entreprises",
    25: "Commerce de gros, Import/Export",
    26: "Environnement, Contrôle qualité",
    27: "Fonction publique",
    28: "Immobilier, Foncier",
    29: "Chimie, Plastique",
    30: "Ingénierie",
    31: "E-commerce, Services en ligne",
    32: "Ressources Humaines, Recrutement",
    33: "Associatif",
    34: "Audit, Comptabilité",
    35: "Sports",
    36: "Télécommunications",
    37: "Tourisme, Loisirs",
    38: "Paysagerie, Espace verts",
  },

  XTRAMILE_CATEGORIES_ITEMS: [{ value: undefined, text: "coming soon" }],

  XTRAMILE_SECTORS_ITEMS: [
    { value: 1, text: "Agriculture, Forêt, Pêche" },
    { value: 2, text: "Banque, Finance" },
    { value: 3, text: "Bâtiment Travaux Publics" },
    { value: 4, text: "Distribution, Commerce" },
    { value: 5, text: "Communication, Publicité, Edition" },
    { value: 6, text: "Culture, Arts, Spectacles" },
    { value: 7, text: "Enseignement, Formation" },
    { value: 8, text: "Energie" },
    { value: 9, text: "Hôtellerie, Restauration" },
    { value: 10, text: "Informatique, Technologies" },
    { value: 11, text: "Santé" },
    { value: 12, text: "Défense, Sécurité" },
    { value: 13, text: "Services aux particuliers" },
    { value: 14, text: "Logistique, Transport de biens" },
    { value: 15, text: "Industrie" },
    { value: 16, text: "Pharmaceutique, Biotechnologies" },
    { value: 17, text: "Agroalimentaire" },
    { value: 18, text: "Automobile" },
    { value: 19, text: "Aéronautique, Naval et Défense" },
    { value: 20, text: "Electronique, Equipements" },
    { value: 21, text: "Assurances, Mutuelle" },
    { value: 22, text: "Audiovisuel, Médias" },
    { value: 23, text: "Services aux entreprises" },
    { value: 24, text: "Conseils aux entreprises" },
    { value: 25, text: "Commerce de gros, Import/Export" },
    { value: 26, text: "Environnement, Contrôle qualité" },
    { value: 27, text: "Fonction publique" },
    { value: 28, text: "Immobilier, Foncier" },
    { value: 29, text: "Chimie, Plastique" },
    { value: 30, text: "Ingénierie" },
    { value: 31, text: "E-commerce, Services en ligne" },
    { value: 32, text: "Ressources Humaines, Recrutement" },
    { value: 33, text: "Associatif" },
    { value: 34, text: "Audit, Comptabilité" },
    { value: 35, text: "Sports" },
    { value: 36, text: "Télécommunications" },
    { value: 37, text: "Tourisme, Loisirs" },
    { value: 38, text: "Paysagerie, Espace verts" },
  ],

  OCCUPATION: {
    1: "Conducteur d'Engins Agricoles et Forestiers",
    2: "Bûcheron",
    3: "Chef Jardinier Paysagiste",
    4: "Employé des Espaces Verts",
    5: "Jardinier Paysagiste",
    6: "Ouvrier Paysagiste",
    7: "Forestier",
    8: "Conseiller Agricole",
    9: "Conseiller d'élevage",
    10: "Conseiller Forestier",
    11: "Technicien Halieute",
    12: "Chargé d'études en biodiversité",
    13: "Ingénieur Agriculture et Environnement Naturel",
    14: "Aide Agricole de Production fruitière ou Viticole",
    15: "Cueilleur",
    16: "Aide Agricole de Production légumière ou végétale",
    17: "Aide d'élevage Agricole ou Aquacole",
    18: "Aquaculteur",
    19: "Arboriculteur ou Viticulteur",
    20: "Chef Arboriculteur ou Viticulteur",
    21: "Éleveur bovin, équin",
    22: "Responsable d'élevage bovin, équin",
    23: "Apiculteur",
    24: "Dresseur de chiens",
    25: "Éleveur lapins, volailles",
    26: "Éleveur ovin, caprin",
    27: "Caviste",
    28: "Oenologue",
    29: "Chef Horticulteur, Maraîchage",
    30: "Horticulteur Maraîchage",
    31: "Marin Pêcheur",
    32: "Exploitant Agricole",
    33: "Ouvrier Polyculture élevage",
    34: "Auxiliare Spécialisé Vétérinaire",
    35: "Soigneur Animaux",
    36: "Marechal ferrant",
    37: "Ceramiste",
    38: "Décorateur d'espaces de vente",
    39: "Relieur",
    40: "Ferronnier Metallier",
    41: "Verrier",
    42: "Vitrailliste",
    43: "Bijoutier",
    44: "Horloger",
    45: "Chapelier",
    46: "Maroquinier",
    47: "Sellier",
    48: "Couturier",
    49: "Brodeur",
    50: "Styliste",
    51: "Tapissier d'Ameublement",
    52: "Tapissier Décorateur",
    53: "Commercial Assurances",
    54: "Conseiller Assurances",
    55: "Agent Général d'assurances",
    56: "Actuaire",
    57: "Expert Assurance",
    58: "Gestionnaire de Contrats en Assurance",
    59: "Souscripteur en Assurance",
    60: "Chargé d'Accueil en Banque",
    61: "Guichetier",
    62: "Analyste de crédit",
    63: "Gestionnaire Risques Bancaires",
    64: "Conseiller en Gestion de Patrimoine",
    65: "Gestionnaire Patrimoine Financier",
    66: "Chargé de Clientèle Bancaire",
    67: "Courtier en prêt Immobilier",
    68: "Directeur d'Agence",
    69: "Directeur d'Agence Bancaire",
    70: "Trader",
    71: "Agent Immobilier",
    72: "Directeur d'Agence Immobilière",
    73: "Gestionnaire Immobilier",
    74: "Gestionnaire Patrimoine Immobilier",
    75: "Programmiste",
    76: "Courtier Immobilier",
    77: "Boucher",
    78: "Boulanger",
    79: "Charcutier",
    80: "Pâtissier",
    81: "Poissonnier",
    82: "Vendeur en alimentation",
    83: "Vendeur en boulangerie",
    84: "Coiffeur",
    85: "Agent Thermal",
    86: "Cordonnier",
    87: "Esthéticien, Cosméticien",
    88: "Praticien de Spa",
    89: "Spa manager",
    90: "Fleuriste",
    91: "Fleuriste Décorateur",
    92: "Vendeur de Végétaux",
    93: "Vendeur d'articles de Sport",
    94: "Vendeur",
    95: "Vendeur d'article de Pêche ou Chasse",
    96: "Vendeur d'Equipement",
    97: "Vendeur en Prêt-à-Porter",
    98: "Vendeur en Horlogerie",
    99: "Responsable de Magasin",
    100: "Assistant Commercial",
    101: "Commercial en Orthopédie",
    102: "Vendeur de Véhicule",
    103: "Commercial",
    104: "Commercial Export",
    105: "Conseiller Clientèle",
    106: "Conseiller vendeur à  domicile",
    107: "Chef des ventes",
    108: "Attaché Technico Commercial",
    109: "Technico Commercial",
    110: "Technico Commercial en chimie",
    111: "Technico Commercial en Electronique et Automatisme",
    112: "Superviseur de centre d'appels",
    113: "Téléconseiller",
    114: "Animateur Commercial",
    115: "Chef de Rayon",
    116: "Caissier",
    117: "Marchandiseur",
    118: "Employé en Grande Surface",
    119: "Attaché de Presse",
    120: "Chargé de Communication Interne",
    121: "Chargé de Relations Publiques",
    122: "Chef de projet Communication Digitale",
    123: "Responsable de la Communication",
    124: "Concepteur Multimedia",
    125: "Concepteur Rédacteur",
    126: "Directeur Artistique",
    127: "Game designer, Jeux Vidéo",
    128: "UI designer",
    129: "Webmaster",
    130: "Journaliste",
    131: "Rédacteur",
    132: "Rédacteur en Chef",
    133: "Sécretaire de Rédaction",
    134: "Chef de projet Événementiel",
    135: "Interprête Traducteur",
    136: "Traducteur Technique",
    137: "Photographe",
    138: "Projectionniste",
    139: "Designer graphique",
    140: "Illustrateur",
    141: "Infographiste",
    142: "Webdesigner",
    143: "Sérigraphe",
    144: "Chef de fabrication dans l'industrie graphique",
    145: "Correcteur, Rélecteur",
    146: "Maquettiste",
    147: "Opérateur prépresse PAO",
    148: "Traffic Manager",
    149: "Architecte",
    150: "Architecte urbaniste",
    151: "Architecte intérieur",
    152: "Décorateur intérieur",
    153: "Diagnostiqueur Immobilier",
    154: "Ingénieur contrôle technique de construction",
    155: "BIM Modeleur",
    156: "Dessinateur Projeteur en BTP",
    157: "Géologue",
    158: "Géophysicien",
    159: "Géotechnicien",
    160: "Hydrogéologue",
    161: "Hydrologue",
    162: "Sondeur Géologue",
    163: "BIM manager",
    164: "Chargé d'affaires",
    165: "Chargé d'affaires BTP",
    166: "Chargé d'affaires en électricité",
    167: "Chargé d'affaires génie climatique et énergetique",
    168: "Chargé d'affaires menuiserie",
    169: "Chef de projet BTP",
    170: "Domoticien",
    171: "Ingénieur d'études CVC",
    172: "Ingénieur en Acoustique",
    173: "Ingénieur en génie civil",
    174: "Ingénieur structure BTP",
    175: "Géometre topographe",
    176: "Conducteur de travaux",
    177: "Assistant Chef de chantier",
    178: "Chef de chantier",
    179: "Chef de chantier électricité",
    180: "Chef de chantier fibre optique",
    181: "Chef de chantier génie climatique",
    182: "Responsable QHSE",
    183: "Grutier",
    184: "Conducteur d'engins BTP",
    185: "Monteur poseur bois",
    186: "Echafaudeur",
    187: "Aide charpentier",
    188: "Charpentier",
    189: "Plâtrier",
    190: "Staffeur platrier",
    191: "Électricien",
    192: "Électricien de chantier",
    193: "Plombier",
    194: "Plombier chauffagiste",
    195: "Plaquiste enduiseur",
    196: "Plaquiste jointeur",
    197: "Électricien d'éclairage public",
    198: "Monteur câbleur",
    199: "Peintre",
    200: "Menuisier poseur",
    201: "Serrurier métallier",
    202: "Carreleur",
    203: "Solier moquettiste",
    204: "Couvreur",
    205: "Facadier",
    206: "Sculpteur",
    207: "Bardeur etancheur",
    208: "Étancheur",
    209: "Coffreur bancheur",
    210: "Maçon VRD",
    211: "Paveur",
    212: "Maçon",
    213: "Ouvrier polyvalent batiment",
    214: "Manoeuvre de chantier",
    215: "Canalisateur",
    216: "Conseiller en séjour touristique",
    217: "Hotesse d'accueil",
    218: "Animateur du patrimoine",
    219: "Directeur d'office du tourisme",
    220: "Accompagnateur de tourisme equestre",
    221: "Accompagnateur de voyages",
    222: "Accompagnateur tourisme",
    223: "Guide de musee",
    224: "Animateur",
    225: "Animateur d'atelier en maison de retraite",
    226: "Animateur d'atelier sciences",
    227: "Animateur nature environnement",
    228: "Mediateur scientifique",
    229: "Directeur d'accueil collectif de mineurs",
    230: "Coach sportif",
    231: "Éducateur sportif",
    232: "Entraineur sportif",
    233: "Maître nageur",
    234: "Moniteur d'equitation",
    235: "Moniteur de ski",
    236: "Moniteur sportif",
    237: "Professeur de sport",
    238: "Surveillant de baignade",
    239: "Agent de developpement touristique",
    240: "Chef de produit tourisme",
    241: "yield'manager",
    242: "Agent de reservation",
    243: "Conseiller voyages",
    244: "Directeur d'hôtel",
    245: "Directeur de restaurant",
    246: "Gerant en restauration collective",
    247: "Valet de chambre",
    248: "Gouvernant d'hôtel",
    249: "Chef cuisinier",
    250: "Commis de cuisine",
    251: "Cuisinier",
    252: "Second de cuisine",
    253: "Employé de restaurant",
    254: "Plongeur en restaurant",
    255: "Voiturier",
    256: "Receptionniste",
    257: "Barman",
    258: "Garçon de café",
    259: "Maître d'hôtel",
    260: "Serveur",
    261: "Sommelier",
    262: "Bid'manager",
    263: "Chargé d'affaires industrie",
    264: "Ingénieur d'affaires industrie",
    265: "Responsable d'affaires industrie",
    266: "Dessinateur projeteur électricité électronique",
    267: "Dessinateur bureau d'études",
    268: "Dessinateur industriel",
    269: "Dessinateur projeteur mécanique",
    270: "Technicien bureau d'études",
    271: "Modeliste",
    272: "Chargé de recherche developement",
    273: "Chef d'équipe électricité",
    274: "Chef de projet éolien",
    275: "Chef de projet packaging",
    276: "Chimiste",
    277: "Ergonome en industrie",
    278: "Ingénieur architecture navale",
    279: "Ingénieur biochimiste",
    280: "Ingénieur brevet",
    281: "Ingénieur calculateur",
    282: "Ingénieur chimiste",
    283: "Ingénieur conception mécanique",
    284: "Ingénieur construction navale",
    285: "Ingénieur d'essais",
    286: "Ingénieur eco conception",
    287: "Ingénieur électricien",
    288: "Ingénieur électronicien",
    289: "Ingénieur en aeronautique",
    290: "Ingénieur en automatismes",
    291: "Ingénieur en électronique numerique",
    292: "Ingénieur en électrotechnique",
    293: "Ingénieur en metrologie",
    294: "Ingénieur gestion des risques",
    295: "Ingénieur mécatronicien",
    296: "Ingénieur structure aeronautique",
    297: "Ingénieur textile",
    298: "Ingénieur validation systeme",
    299: "Responsable de projet",
    300: "Automaticien informaticien industrielle",
    301: "Automaticien roboticien",
    302: "Technicien en automatismes",
    303: "Technicien en électronique",
    304: "Technicien en électrotechnique",
    305: "Technicien biologiste",
    306: "Technicien chimiste",
    307: "Technicien d'essais",
    308: "Technicien de laboratoire",
    309: "Chef projet sites sols pollués",
    310: "Ingénieur en radioprotection",
    311: "Ingénieur environnement",
    312: "Ingénieur sûreté nucléaire",
    313: "Responsable QHSE industrie",
    314: "Animateur QHSE",
    315: "Conseiller en environnement",
    316: "Ingénieur supply chain",
    317: "Planificateur de production",
    318: "Planificateur de projet",
    319: "Responsable methodes process",
    320: "Agent de planification",
    321: "Ordonnanceur",
    322: "Ingénieur analyste de l'air",
    323: "Ingénieur qualite",
    324: "Responsable de laboratoire d'analyse",
    325: "Contrôleur qualite",
    326: "Agent de laboratoire d'analyse",
    327: "Technicien chimiste",
    328: "Technicien qualite",
    329: "Formulateur en arômes",
    330: "Technicien en metrologie",
    331: "Boucher industriel",
    332: "Boulanger industriel",
    333: "Charcutier industriel",
    334: "Conducteur de ligne de production alimentaire",
    335: "Patissier industriel",
    336: "Pilote de ligne automatisee",
    337: "Responsable de production alimentaire",
    338: "Traiteur industriel",
    339: "Conducteur machine usinage bois",
    340: "Menuisier bois",
    341: "Agent de maitrise de scierie",
    342: "Aide menuisier",
    343: "Menuisier",
    344: "Tonnelier",
    345: "Encadreur",
    346: "Sculpteur modeleur sur bois",
    347: "Dessinateur projeteur bois",
    348: "Chef de projet pharmaceutique",
    349: "Prototypiste confection",
    350: "Contremaitre Chef d'équipe",
    351: "Directeur de production",
    352: "Directeur technique industrie",
    353: "Ingénieur agronome",
    354: "Ingénieur chimiste",
    355: "Ingénieur de fonderie",
    356: "Ingénieur en mécanique de production",
    357: "Ingénieur excellence opérationnelle",
    358: "Ingénieur mécanicien",
    359: "Ingénieur métallurgiste",
    360: "Ingénieur plasturgiste",
    361: "Ingénieur procedes en chimie",
    362: "Responsable de production",
    363: "Responsable excellence opérationnelle",
    364: "Ingénieur papier",
    365: "Mécatronicien",
    366: "Électricien industriel",
    367: "Ajusteur monteur",
    368: "Mécanicien monteur",
    369: "Mécanicien outilleur",
    370: "Chaudronnier tolier",
    371: "Mécanicien usineur",
    372: "Tourneur fraiseur",
    373: "Usineur",
    374: "Estampeur forgeron",
    375: "Operateur sur machine à commande numerique",
    376: "Conducteur de machine ou de ligne de production",
    377: "Mécanicien d'assemblage",
    378: "Charpentier métallique",
    379: "Regleur industriel",
    380: "Soudeur",
    381: "Chef d'équipe tuyauteur",
    382: "Ouvrier industrie du papier",
    383: "Stratifieur drapeur",
    384: "Agent de conditionnement",
    385: "Chef de service maintenance industrielle",
    386: "Facility manager",
    387: "Ingénieur maintenance industrielle",
    388: "Ingénieur methodes maintenance",
    389: "Responsable maintenance industrielle",
    390: "Contrôleur technique auto",
    391: "Gestionnaire de flotte auto",
    392: "Agent technique d'entretien",
    393: "Ascensoriste",
    394: "Technicien remontees mécaniques",
    395: "Hydraulicien",
    396: "Instrumentiste industriel",
    397: "Technicien en robotique",
    398: "Technicien de maintenance industrielle",
    399: "Technicien électrotechnicien de maintenance",
    400: "Technicien maintenance électronique et électromagnetique",
    401: "Chargé d'opérations CVC",
    402: "Technicien maintenance climatisation",
    403: "Technicien reseaux télecom",
    404: "Technicien systeme de securité alarme",
    405: "Technicien systeme de securité incendie",
    406: "Technicien systeme de securité videosurveillance",
    407: "Mécanicien en robinetterie",
    408: "Mécanicien machines tournantes",
    409: "Technicien de maintenance informatique",
    410: "Technicien help desk informatique",
    411: "Cordiste",
    412: "Plongeur subaquatique",
    413: "Désamianteur",
    414: "Mécanicien aeronautique maintenance",
    415: "Technicien aeronautique",
    416: "Technicien en engins de travaux publics",
    417: "Expert technique automobile",
    418: "Mécanicien auto",
    419: "Mécanicien poids lourds",
    420: "Technicien automobile",
    421: "Mécanicien peche",
    422: "Carrossier",
    423: "Mécanicien cycles motocycles",
    424: "Mécanicien moto",
    425: "Chirurgien",
    426: "Medecin anesthesiste reanimateur",
    427: "Medecin generaliste",
    428: "Medecin spécialiste",
    429: "Chirurgien dentiste",
    430: "Sage femme",
    431: "Biologiste",
    432: "Pharmacien",
    433: "Agent hospitalier",
    434: "Brancardier",
    435: "Assistant dentaire",
    436: "Auxiliaire de puériculture",
    437: "Ambulancier",
    438: "Manipulateur d'électroradiologie médicale",
    439: "Préparateur en phamacie",
    440: "Audioprothesiste",
    441: "Dieteticien",
    442: "Ergothérapeute",
    443: "Kinésithérapeute",
    444: "Masseur",
    445: "Opticien lunetier",
    446: "Orthophoniste",
    447: "Prothesiste dentaire",
    448: "Psychomotricien",
    449: "Aide soignant",
    450: "Cadre de sante",
    451: "Infirmier",
    452: "Puericulteur",
    453: "Mediateur familial",
    454: "Conseiller en naturopathie",
    455: "Art thérapeute",
    456: "Psychologue",
    457: "Assistant de service social",
    458: "Conseiller en economie sociale et familiale",
    459: "Éducateur d'enfants",
    460: "Éducateur technique spécialiste",
    461: "Animateur socioculturel",
    462: "Responsable projets culturels",
    463: "Éducateur de la protection judiciaire",
    464: "Éducateur spécialiste",
    465: "Moniteur educateur",
    466: "Accompagnant educatif et social",
    467: "Assistant de soins en gerontologie",
    468: "Aide aux personnes agees",
    469: "Auxiliaire de vie",
    470: "Dame de compagnie",
    471: "Garde malade",
    472: "Agent spécialiste ecoles maternelles",
    473: "Assistante maternelle",
    474: "Garde d'enfant",
    475: "Aide à domicile",
    476: "Aide ménager",
    477: "Employé de ménage",
    478: "Employé familial",
    479: "Technicien intervention sociale et familiale",
    480: "Directeur d'etablissement hospitalier",
    481: "Directeur de maison de retraite",
    482: "Attache territorial",
    483: "Chef de projet territorial",
    484: "Redacteur territorial",
    485: "Fonctionnaire permis de conduire divers",
    486: "Contrôleur des impots",
    487: "Contrôleur des douanes",
    488: "Archiviste",
    489: "Bibliothecaire",
    490: "Documentaliste",
    491: "Conservateur du patrimoine",
    492: "Maître chien de l'armee",
    493: "Militaire domaine air",
    494: "Militaire domaine mer",
    495: "Militaire domaine terre",
    496: "Moniteur sportif pour l'armee",
    497: "Commissaire de police",
    498: "Officier de gendarmerie",
    499: "Officier de police",
    500: "Pisteur secouriste",
    501: "Sapeur pompier",
    502: "Gendarme",
    503: "Policier",
    504: "Policier municipal",
    505: "Conseiller en insertion sociale professionnelle",
    506: "Chargé de developpement local",
    507: "Administrateur judiciaire",
    508: "Huissier de justice",
    509: "Mandataire de justice",
    510: "Notaire",
    511: "Clerc d'huissier",
    512: "Clerc de notaire",
    513: "Collaborateur de notaire",
    514: "Secretaire juridique",
    515: "Avocat",
    516: "Fiscaliste",
    517: "Juriste d'entreprise",
    518: "Juriste en droit de l'environnement",
    519: "Juriste en droit social",
    520: "Juriste propriete intellectuelle",
    521: "Assistante de service social",
    522: "Accompagnant d'eleves en situation de handicap",
    523: "Conseiller principal d'education",
    524: "Professeur de chant",
    525: "Professeur de danse",
    526: "Professeur de guitare",
    527: "Professeur de piano",
    528: "Enseignant spécialiste",
    529: "Professeur des ecoles",
    530: "Professeur d'allemand",
    531: "Professeur d'anglais",
    532: "Professeur d'arts plastiques",
    533: "Professeur d'education physique",
    534: "Professeur d'espagnol",
    535: "Professeur d'italien",
    536: "Professeur de francais",
    537: "Professeur de maths",
    538: "Professeur de russe",
    539: "Professeur matieres scientifiques",
    540: "Enseignant chercheur",
    541: "Moniteur d'auto ecole",
    542: "Formateur",
    543: "Formateur de langues",
    544: "Formateur francais fle",
    545: "Formateur informatique",
    546: "Laveur de vitres",
    547: "Agent de proprete",
    548: "Agent de proprete urbaine",
    549: "Eboueur ripeur",
    550: "Chef d'exploitation d'usine d'incineration",
    551: "Archeologue",
    552: "Historien",
    553: "Attache de recherche clinique",
    554: "Biologiste chercheur",
    555: "Biotechnologue",
    556: "Botaniste",
    557: "Chargé d'études naturalistes",
    558: "Chargé de valorisation de la recherche",
    559: "Chercheur en chimie",
    560: "Chercheur en physique",
    561: "Généticien",
    562: "Microbiologiste",
    563: "Gardien",
    564: "Agent de securité",
    565: "Agent de sûreté aeroportuaire",
    566: "Agent de telesurveillance",
    567: "Convoyeur",
    568: "Maître chien",
    569: "Veilleur de nuit",
    570: "Porteur funeraire",
    571: "Conseiller funeraire",
    572: "Choregraphe",
    573: "Danseur",
    574: "Chanteur",
    575: "Musicien",
    576: "Comedien",
    577: "Figurant",
    578: "Artiste de cirque",
    579: "Chargé de production",
    580: "Costumier",
    581: "Regisseur lumiere",
    582: "Technicien exploitation audiovisuelle",
    583: "Monteur audiovisuel",
    584: "Regisseur de salle",
    585: "Acheteur",
    586: "Responsable des achats",
    587: "Analyste financier",
    588: "Conseiller fusion acquisition",
    589: "Risk manager",
    590: "Auditeur financier",
    591: "Auditeur interne",
    592: "Collaborateur comptable",
    593: "Expert comptable",
    594: "Assistant comptable",
    595: "Comptable",
    596: "Contrôleur de gestion",
    597: "Responsable contrôle de gestion",
    598: "Adjoint administratif",
    599: "Responsable administratif financier",
    600: "Responsable de gestion",
    601: "Responsable des services generaux",
    602: "Responsable financier budget",
    603: "Chef comptable",
    604: "Credit manager",
    605: "Responsable agence travail temporaire ou recrutement",
    606: "Responsable d'activite",
    607: "Responsable d'activite télecom",
    608: "Responsable de departement",
    609: "Enqueteur client mystere",
    610: "Conseiller conduite du changement",
    611: "Consultant financement de l'innovation",
    612: "Data analyst",
    613: "Data scientist",
    614: "Statisticien",
    615: "Assistant ressources humaines",
    616: "Gestionnaire paie",
    617: "Assistant formation",
    618: "Chargé d'affaires en recrutement",
    619: "Chargé de recrutement",
    620: "Chargé études RH",
    621: "Consultant RH",
    622: "Gestionnaire RH",
    623: "Responsable formation",
    624: "Responsable recrutement",
    625: "Responsable paie",
    626: "Responsable ressources humaines",
    627: "Facteur",
    628: "Assistant de direction",
    629: "Attache de direction administration",
    630: "Assistant administratif",
    631: "Assistant de gestion",
    632: "Operateur de saisie",
    633: "Secretaire",
    634: "Secretaire administratif",
    635: "Telesecretaire",
    636: "Secretaire médical",
    637: "Assistant marketing",
    638: "Chargé de marketing digital",
    639: "Responsable marketing",
    640: "Web marketer",
    641: "Promoteur des ventes",
    642: "Responsable e-commerce",
    643: "Administrateur systemes et reseaux",
    644: "Architecte logiciel",
    645: "Architecte technique informatique",
    646: "Cryptologue",
    647: "Devops",
    648: "Expert cybersecurité",
    649: "Ingénieur cloud",
    650: "Ingénieur systeme et reseau",
    651: "Ingénieur systemes embarques",
    652: "Ingénieur test logiciel",
    653: "Referent technique IT",
    654: "Securite si",
    655: "Chef de projet IT",
    656: "Chef de projet IT moa",
    657: "Chef de projet IT technique",
    658: "Chef de projet web IT",
    659: "Delivery manager",
    660: "Directeur systeme d'information",
    661: "Directeur technique informatique",
    662: "Ingénieur informatique industrielle",
    663: "Responsable informatique",
    664: "Responsable informatique industrielle",
    665: "Responsable production informatique",
    666: "Ingénieur reseaux télecoms",
    667: "Analyste programmeur",
    668: "Business analyst",
    669: "Developpeur dotnet",
    670: "Developpeur frontend",
    671: "Developpeur fullstack",
    672: "Developpeur IT",
    673: "Developpeur java",
    674: "Developpeur javascript",
    675: "Developpeur mobile",
    676: "Developpeur php",
    677: "Developpeur ruby",
    678: "Developpeur symfony",
    679: "Developpeur web",
    680: "Informaticien industriel",
    681: "Integrateur d'applications informatiques",
    682: "Scrum master",
    683: "Testeur informatique",
    684: "Chargé d'affaires informatique",
    685: "Consultant BI",
    686: "Consultant IT",
    687: "Product manager",
    688: "Product owner",
    689: "Cartographe Géomaticien",
    690: "Géographe",
    691: "Météorologue",
    692: "Gestionnaire du parc informatique",
    693: "Cariste",
    694: "Demenageur",
    695: "Emballeur",
    696: "Magasinier cariste",
    697: "Préparateur de commandes",
    698: "Pontier",
    699: "Manutentionnaire",
    700: "Affréteur",
    701: "Agent de transit",
    702: "Declarant en douane",
    703: "Responsable logistique",
    704: "Responsable preparation de commandes",
    705: "Responsable supply chain",
    706: "Technicien logistique",
    707: "Hotesse de l'air",
    708: "Pilote d'avion",
    709: "Pilote d'helicoptere",
    710: "Pilote de l'armee",
    711: "Agent d'escale",
    712: "Contrôleur aerien",
    713: "Timonier de la marine nationale",
    714: "Chauffeur",
    715: "Chauffeur de taxi",
    716: "Chauffeur poids lourd",
    717: "Conducteur de bus",
    718: "Conducteur receveur",
    719: "Coursier",
    720: "Chauffeur livreur",
    721: "Livreur à domicile",
    722: "Conducteur de train",
  },

  XTRAMILE_OCCUPATIONS_ITEMS: [
    { value: 1, text: "Conducteur d'Engins Agricoles et Forestiers" },
    { value: 2, text: "Bûcheron" },
    { value: 3, text: "Chef Jardinier Paysagiste" },
    { value: 4, text: "Employé des Espaces Verts" },
    { value: 5, text: "Jardinier Paysagiste" },
    { value: 6, text: "Ouvrier Paysagiste" },
    { value: 7, text: "Forestier" },
    { value: 8, text: "Conseiller Agricole" },
    { value: 9, text: "Conseiller d'élevage" },
    { value: 10, text: "Conseiller Forestier" },
    { value: 11, text: "Technicien Halieute" },
    { value: 12, text: "Chargé d'études en biodiversité" },
    { value: 13, text: "Ingénieur Agriculture et Environnement Naturel" },
    { value: 14, text: "Aide Agricole de Production fruitière ou Viticole" },
    { value: 15, text: "Cueilleur" },
    { value: 16, text: "Aide Agricole de Production légumière ou végétale" },
    { value: 17, text: "Aide d'élevage Agricole ou Aquacole" },
    { value: 18, text: "Aquaculteur" },
    { value: 19, text: "Arboriculteur ou Viticulteur" },
    { value: 20, text: "Chef Arboriculteur ou Viticulteur" },
    { value: 21, text: "Éleveur bovin, équin" },
    { value: 22, text: "Responsable d'élevage bovin, équin" },
    { value: 23, text: "Apiculteur" },
    { value: 24, text: "Dresseur de chiens" },
    { value: 25, text: "Éleveur lapins, volailles" },
    { value: 26, text: "Éleveur ovin, caprin" },
    { value: 27, text: "Caviste" },
    { value: 28, text: "Oenologue" },
    { value: 29, text: "Chef Horticulteur, Maraîchage" },
    { value: 30, text: "Horticulteur Maraîchage" },
    { value: 31, text: "Marin Pêcheur" },
    { value: 32, text: "Exploitant Agricole" },
    { value: 33, text: "Ouvrier Polyculture élevage" },
    { value: 34, text: "Auxiliare Spécialisé Vétérinaire" },
    { value: 35, text: "Soigneur Animaux" },
    { value: 36, text: "Marechal ferrant" },
    { value: 37, text: "Ceramiste" },
    { value: 38, text: "Décorateur d'espaces de vente" },
    { value: 39, text: "Relieur" },
    { value: 40, text: "Ferronnier Metallier" },
    { value: 41, text: "Verrier" },
    { value: 42, text: "Vitrailliste" },
    { value: 43, text: "Bijoutier" },
    { value: 44, text: "Horloger" },
    { value: 45, text: "Chapelier" },
    { value: 46, text: "Maroquinier" },
    { value: 47, text: "Sellier" },
    { value: 48, text: "Couturier" },
    { value: 49, text: "Brodeur" },
    { value: 50, text: "Styliste" },
    { value: 51, text: "Tapissier d'Ameublement" },
    { value: 52, text: "Tapissier Décorateur" },
    { value: 53, text: "Commercial Assurances" },
    { value: 54, text: "Conseiller Assurances" },
    { value: 55, text: "Agent Général d'assurances" },
    { value: 56, text: "Actuaire" },
    { value: 57, text: "Expert Assurance" },
    { value: 58, text: "Gestionnaire de Contrats en Assurance" },
    { value: 59, text: "Souscripteur en Assurance" },
    { value: 60, text: "Chargé d'Accueil en Banque" },
    { value: 61, text: "Guichetier" },
    { value: 62, text: "Analyste de crédit" },
    { value: 63, text: "Gestionnaire Risques Bancaires" },
    { value: 64, text: "Conseiller en Gestion de Patrimoine" },
    { value: 65, text: "Gestionnaire Patrimoine Financier" },
    { value: 66, text: "Chargé de Clientèle Bancaire" },
    { value: 67, text: "Courtier en prêt Immobilier" },
    { value: 68, text: "Directeur d'Agence" },
    { value: 69, text: "Directeur d'Agence Bancaire" },
    { value: 70, text: "Trader" },
    { value: 71, text: "Agent Immobilier" },
    { value: 72, text: "Directeur d'Agence Immobilière" },
    { value: 73, text: "Gestionnaire Immobilier" },
    { value: 74, text: "Gestionnaire Patrimoine Immobilier" },
    { value: 75, text: "Programmiste" },
    { value: 76, text: "Courtier Immobilier" },
    { value: 77, text: "Boucher" },
    { value: 78, text: "Boulanger" },
    { value: 79, text: "Charcutier" },
    { value: 80, text: "Pâtissier" },
    { value: 81, text: "Poissonnier" },
    { value: 82, text: "Vendeur en alimentation" },
    { value: 83, text: "Vendeur en boulangerie" },
    { value: 84, text: "Coiffeur" },
    { value: 85, text: "Agent Thermal" },
    { value: 86, text: "Cordonnier" },
    { value: 87, text: "Esthéticien, Cosméticien" },
    { value: 88, text: "Praticien de Spa" },
    { value: 89, text: "Spa manager" },
    { value: 90, text: "Fleuriste" },
    { value: 91, text: "Fleuriste Décorateur" },
    { value: 92, text: "Vendeur de Végétaux" },
    { value: 93, text: "Vendeur d'articles de Sport" },
    { value: 94, text: "Vendeur" },
    { value: 95, text: "Vendeur d'article de Pêche ou Chasse" },
    { value: 96, text: "Vendeur d'Equipement" },
    { value: 97, text: "Vendeur en Prêt-à-Porter" },
    { value: 98, text: "Vendeur en Horlogerie" },
    { value: 99, text: "Responsable de Magasin" },
    { value: 100, text: "Assistant Commercial" },
    { value: 101, text: "Commercial en Orthopédie" },
    { value: 102, text: "Vendeur de Véhicule" },
    { value: 103, text: "Commercial" },
    { value: 104, text: "Commercial Export" },
    { value: 105, text: "Conseiller Clientèle" },
    { value: 106, text: "Conseiller vendeur à  domicile" },
    { value: 107, text: "Chef des ventes" },
    { value: 108, text: "Attaché Technico Commercial" },
    { value: 109, text: "Technico Commercial" },
    { value: 110, text: "Technico Commercial en chimie" },
    { value: 111, text: "Technico Commercial en Electronique et Automatisme" },
    { value: 112, text: "Superviseur de centre d'appels" },
    { value: 113, text: "Téléconseiller" },
    { value: 114, text: "Animateur Commercial" },
    { value: 115, text: "Chef de Rayon" },
    { value: 116, text: "Caissier" },
    { value: 117, text: "Marchandiseur" },
    { value: 118, text: "Employé en Grande Surface" },
    { value: 119, text: "Attaché de Presse" },
    { value: 120, text: "Chargé de Communication Interne" },
    { value: 121, text: "Chargé de Relations Publiques" },
    { value: 122, text: "Chef de projet Communication Digitale" },
    { value: 123, text: "Responsable de la Communication" },
    { value: 124, text: "Concepteur Multimedia" },
    { value: 125, text: "Concepteur Rédacteur" },
    { value: 126, text: "Directeur Artistique" },
    { value: 127, text: "Game designer, Jeux Vidéo" },
    { value: 128, text: "UI designer" },
    { value: 129, text: "Webmaster" },
    { value: 130, text: "Journaliste" },
    { value: 131, text: "Rédacteur" },
    { value: 132, text: "Rédacteur en Chef" },
    { value: 133, text: "Sécretaire de Rédaction" },
    { value: 134, text: "Chef de projet Evenementiel" },
    { value: 135, text: "Interprête Traducteur" },
    { value: 136, text: "Traducteur Technique" },
    { value: 137, text: "Photographe" },
    { value: 138, text: "Projectionniste" },
    { value: 139, text: "Designer graphique" },
    { value: 140, text: "Illustrateur" },
    { value: 141, text: "Infographiste" },
    { value: 142, text: "Webdesigner" },
    { value: 143, text: "Sérigraphe" },
    { value: 144, text: "Chef de fabrication dans l'industrie graphique" },
    { value: 145, text: "Correcteur, Rélecteur" },
    { value: 146, text: "Maquettiste" },
    { value: 147, text: "Opérateur prépresse PAO" },
    { value: 148, text: "Traffic Manager" },
    { value: 149, text: "Architecte" },
    { value: 150, text: "Architecte urbaniste" },
    { value: 151, text: "Architecte intérieur" },
    { value: 152, text: "Décorateur intérieur" },
    { value: 153, text: "Diagnostiqueur Immobilier" },
    { value: 154, text: "Ingénieur contrôle technique de construction" },
    { value: 155, text: "BIM Modeleur" },
    { value: 156, text: "Dessinateur Projeteur en BTP" },
    { value: 157, text: "Géologue" },
    { value: 158, text: "Géophysicien" },
    { value: 159, text: "Géotechnicien" },
    { value: 160, text: "Hydrogéologue" },
    { value: 161, text: "Hydrologue" },
    { value: 162, text: "Sondeur Géologue" },
    { value: 163, text: "BIM manager" },
    { value: 164, text: "Chargé d'affaires" },
    { value: 165, text: "Chargé d'affaires BTP" },
    { value: 166, text: "Chargé d'affaires en électricité" },
    { value: 167, text: "Chargé d'affaires génie climatique et énergetique" },
    { value: 168, text: "Chargé d'affaires menuiserie" },
    { value: 169, text: "Chef de projet BTP" },
    { value: 170, text: "Domoticien" },
    { value: 171, text: "Ingénieur d'études CVC" },
    { value: 172, text: "Ingénieur en Acoustique" },
    { value: 173, text: "Ingénieur en génie civil" },
    { value: 174, text: "Ingénieur structure BTP" },
    { value: 175, text: "Géometre topographe" },
    { value: 176, text: "Conducteur de travaux" },
    { value: 177, text: "Assistant Chef de chantier" },
    { value: 178, text: "Chef de chantier" },
    { value: 179, text: "Chef de chantier électricité" },
    { value: 180, text: "Chef de chantier fibre optique" },
    { value: 181, text: "Chef de chantier génie climatique" },
    { value: 182, text: "Responsable QHSE" },
    { value: 183, text: "Grutier" },
    { value: 184, text: "Conducteur d'engins BTP" },
    { value: 185, text: "Monteur poseur bois" },
    { value: 186, text: "Echafaudeur" },
    { value: 187, text: "Aide charpentier" },
    { value: 188, text: "Charpentier" },
    { value: 189, text: "Plâtrier" },
    { value: 190, text: "Staffeur platrier" },
    { value: 191, text: "Électricien" },
    { value: 192, text: "Électricien de chantier" },
    { value: 193, text: "Plombier" },
    { value: 194, text: "Plombier chauffagiste" },
    { value: 195, text: "Plaquiste enduiseur" },
    { value: 196, text: "Plaquiste jointeur" },
    { value: 197, text: "Électricien d'éclairage public" },
    { value: 198, text: "Monteur câbleur" },
    { value: 199, text: "Peintre" },
    { value: 200, text: "Menuisier poseur" },
    { value: 201, text: "Serrurier métallier" },
    { value: 202, text: "Carreleur" },
    { value: 203, text: "Solier moquettiste" },
    { value: 204, text: "Couvreur" },
    { value: 205, text: "Facadier" },
    { value: 206, text: "Sculpteur" },
    { value: 207, text: "Bardeur etancheur" },
    { value: 208, text: "Étancheur" },
    { value: 209, text: "Coffreur bancheur" },
    { value: 210, text: "Maçon VRD" },
    { value: 211, text: "Paveur" },
    { value: 212, text: "Maçon" },
    { value: 213, text: "Ouvrier polyvalent batiment" },
    { value: 214, text: "Manoeuvre de chantier" },
    { value: 215, text: "Canalisateur" },
    { value: 216, text: "Conseiller en séjour touristique" },
    { value: 217, text: "Hotesse d'accueil" },
    { value: 218, text: "Animateur du patrimoine" },
    { value: 219, text: "Directeur d'office du tourisme" },
    { value: 220, text: "Accompagnateur de tourisme equestre" },
    { value: 221, text: "Accompagnateur de voyages" },
    { value: 222, text: "Accompagnateur tourisme" },
    { value: 223, text: "Guide de musee" },
    { value: 224, text: "Animateur" },
    { value: 225, text: "Animateur d'atelier en maison de retraite" },
    { value: 226, text: "Animateur d'atelier sciences" },
    { value: 227, text: "Animateur nature environnement" },
    { value: 228, text: "Mediateur scientifique" },
    { value: 229, text: "Directeur d'accueil collectif de mineurs" },
    { value: 230, text: "Coach sportif" },
    { value: 231, text: "Éducateur sportif" },
    { value: 232, text: "Entraineur sportif" },
    { value: 233, text: "Maître nageur" },
    { value: 234, text: "Moniteur d'equitation" },
    { value: 235, text: "Moniteur de ski" },
    { value: 236, text: "Moniteur sportif" },
    { value: 237, text: "Professeur de sport" },
    { value: 238, text: "Surveillant de baignade" },
    { value: 239, text: "Agent de developpement touristique" },
    { value: 240, text: "Chef de produit tourisme" },
    { value: 241, text: "yield'manager" },
    { value: 242, text: "Agent de reservation" },
    { value: 243, text: "Conseiller voyages" },
    { value: 244, text: "Directeur d'hôtel" },
    { value: 245, text: "Directeur de restaurant" },
    { value: 246, text: "Gerant en restauration collective" },
    { value: 247, text: "Valet de chambre" },
    { value: 248, text: "Gouvernant d'hôtel" },
    { value: 249, text: "Chef cuisinier" },
    { value: 250, text: "Commis de cuisine" },
    { value: 251, text: "Cuisinier" },
    { value: 252, text: "Second de cuisine" },
    { value: 253, text: "Employé de restaurant" },
    { value: 254, text: "Plongeur en restaurant" },
    { value: 255, text: "Voiturier" },
    { value: 256, text: "Receptionniste" },
    { value: 257, text: "Barman" },
    { value: 258, text: "Garçon de café" },
    { value: 259, text: "Maître d'hôtel" },
    { value: 260, text: "Serveur" },
    { value: 261, text: "Sommelier" },
    { value: 262, text: "Bid'manager" },
    { value: 263, text: "Chargé d'affaires industrie" },
    { value: 264, text: "Ingénieur d'affaires industrie" },
    { value: 265, text: "Responsable d'affaires industrie" },
    { value: 266, text: "Dessinateur projeteur électricité et électronique" },
    { value: 267, text: "Dessinateur bureau d'études" },
    { value: 268, text: "Dessinateur industriel" },
    { value: 269, text: "Dessinateur projeteur mécanique" },
    { value: 270, text: "Technicien bureau d'études" },
    { value: 271, text: "Modeliste" },
    { value: 272, text: "Chargé de recherche developement" },
    { value: 273, text: "Chef d'équipe électricité" },
    { value: 274, text: "Chef de projet éolien" },
    { value: 275, text: "Chef de projet packaging" },
    { value: 276, text: "Chimiste" },
    { value: 277, text: "Ergonome en industrie" },
    { value: 278, text: "Ingénieur architecture navale" },
    { value: 279, text: "Ingénieur biochimiste" },
    { value: 280, text: "Ingénieur brevet" },
    { value: 281, text: "Ingénieur calculateur" },
    { value: 282, text: "Ingénieur chimiste" },
    { value: 283, text: "Ingénieur conception mécanique" },
    { value: 284, text: "Ingénieur construction navale" },
    { value: 285, text: "Ingénieur d'essais" },
    { value: 286, text: "Ingénieur eco conception" },
    { value: 287, text: "Ingénieur électricien" },
    { value: 288, text: "Ingénieur électronicien" },
    { value: 289, text: "Ingénieur en aeronautique" },
    { value: 290, text: "Ingénieur en automatismes" },
    { value: 291, text: "Ingénieur en électronique numerique" },
    { value: 292, text: "Ingénieur en électrotechnique" },
    { value: 293, text: "Ingénieur en metrologie" },
    { value: 294, text: "Ingénieur gestion des risques" },
    { value: 295, text: "Ingénieur mécatronicien" },
    { value: 296, text: "Ingénieur structure aeronautique" },
    { value: 297, text: "Ingénieur textile" },
    { value: 298, text: "Ingénieur validation systeme" },
    { value: 299, text: "Responsable de projet" },
    { value: 300, text: "Automaticien informaticien industrielle" },
    { value: 301, text: "Automaticien roboticien" },
    { value: 302, text: "Technicien en automatismes" },
    { value: 303, text: "Technicien en électronique" },
    { value: 304, text: "Technicien en électrotechnique" },
    { value: 305, text: "Technicien biologiste" },
    { value: 306, text: "Technicien chimiste" },
    { value: 307, text: "Technicien d'essais" },
    { value: 308, text: "Technicien de laboratoire" },
    { value: 309, text: "Chef projet sites sols pollués" },
    { value: 310, text: "Ingénieur en radioprotection" },
    { value: 311, text: "Ingénieur environnement" },
    { value: 312, text: "Ingénieur sûreté nucléaire" },
    { value: 313, text: "Responsable QHSE industrie" },
    { value: 314, text: "Animateur QHSE" },
    { value: 315, text: "Conseiller en environnement" },
    { value: 316, text: "Ingénieur supply chain" },
    { value: 317, text: "Planificateur de production" },
    { value: 318, text: "Planificateur de projet" },
    { value: 319, text: "Responsable methodes process" },
    { value: 320, text: "Agent de planification" },
    { value: 321, text: "Ordonnanceur" },
    { value: 322, text: "Ingénieur analyste de l'air" },
    { value: 323, text: "Ingénieur qualite" },
    { value: 324, text: "Responsable de laboratoire d'analyse" },
    { value: 325, text: "Contrôleur qualite" },
    { value: 326, text: "Agent de laboratoire d'analyse" },
    { value: 327, text: "Technicien chimiste" },
    { value: 328, text: "Technicien qualite" },
    { value: 329, text: "Formulateur en aromes" },
    { value: 330, text: "Technicien en metrologie" },
    { value: 331, text: "Boucher industriel" },
    { value: 332, text: "Boulanger industriel" },
    { value: 333, text: "Charcutier industriel" },
    { value: 334, text: "Conducteur de ligne de production alimentaire" },
    { value: 335, text: "Patissier industriel" },
    { value: 336, text: "Pilote de ligne automatisee" },
    { value: 337, text: "Responsable de production alimentaire" },
    { value: 338, text: "Traiteur industriel" },
    { value: 339, text: "Conducteur machine usinage bois" },
    { value: 340, text: "Menuisier bois" },
    { value: 341, text: "Agent de maitrise de scierie" },
    { value: 342, text: "Aide menuisier" },
    { value: 343, text: "Menuisier" },
    { value: 344, text: "Tonnelier" },
    { value: 345, text: "Encadreur" },
    { value: 346, text: "Sculpteur modeleur sur bois" },
    { value: 347, text: "Dessinateur projeteur bois" },
    { value: 348, text: "Chef de projet pharmaceutique" },
    { value: 349, text: "Prototypiste confection" },
    { value: 350, text: "Contremaitre Chef d'équipe" },
    { value: 351, text: "Directeur de production" },
    { value: 352, text: "Directeur technique industrie" },
    { value: 353, text: "Ingénieur agronome" },
    { value: 354, text: "Ingénieur chimiste" },
    { value: 355, text: "Ingénieur de fonderie" },
    { value: 356, text: "Ingénieur en mécanique de production" },
    { value: 357, text: "Ingénieur excellence opérationnelle" },
    { value: 358, text: "Ingénieur mécanicien" },
    { value: 359, text: "Ingénieur métallurgiste" },
    { value: 360, text: "Ingénieur plasturgiste" },
    { value: 361, text: "Ingénieur procedes en chimie" },
    { value: 362, text: "Responsable de production" },
    { value: 363, text: "Responsable excellence opérationnelle" },
    { value: 364, text: "Ingénieur papier" },
    { value: 365, text: "Mécatronicien" },
    { value: 366, text: "Électricien industriel" },
    { value: 367, text: "Ajusteur monteur" },
    { value: 368, text: "Mécanicien monteur" },
    { value: 369, text: "Mécanicien outilleur" },
    { value: 370, text: "Chaudronnier tolier" },
    { value: 371, text: "Mécanicien usineur" },
    { value: 372, text: "Tourneur fraiseur" },
    { value: 373, text: "Usineur" },
    { value: 374, text: "Estampeur forgeron" },
    { value: 375, text: "Operateur sur machine à commande numerique" },
    { value: 376, text: "Conducteur de machine ou de ligne de production" },
    { value: 377, text: "Mécanicien d'assemblage" },
    { value: 378, text: "Charpentier métallique" },
    { value: 379, text: "Regleur industriel" },
    { value: 380, text: "Soudeur" },
    { value: 381, text: "Chef d'équipe tuyauteur" },
    { value: 382, text: "Ouvrier industrie du papier" },
    { value: 383, text: "Stratifieur drapeur" },
    { value: 384, text: "Agent de conditionnement" },
    { value: 385, text: "Chef de service maintenance industrielle" },
    { value: 386, text: "Facility manager" },
    { value: 387, text: "Ingénieur maintenance industrielle" },
    { value: 388, text: "Ingénieur methodes maintenance" },
    { value: 389, text: "Responsable maintenance industrielle" },
    { value: 390, text: "Contrôleur technique auto" },
    { value: 391, text: "Gestionnaire de flotte auto" },
    { value: 392, text: "Agent technique d'entretien" },
    { value: 393, text: "Ascensoriste" },
    { value: 394, text: "Technicien remontees mécaniques" },
    { value: 395, text: "Hydraulicien" },
    { value: 396, text: "Instrumentiste industriel" },
    { value: 397, text: "Technicien en robotique" },
    { value: 398, text: "Technicien de maintenance industrielle" },
    { value: 399, text: "Technicien électrotechnicien de maintenance" },
    {
      value: 400,
      text: "Technicien maintenance électronique et électromagnetique",
    },
    { value: 401, text: "Chargé d'opérations CVC" },
    { value: 402, text: "Technicien maintenance climatisation" },
    { value: 403, text: "Technicien reseaux télecom" },
    { value: 404, text: "Technicien systeme de securité alarme" },
    { value: 405, text: "Technicien systeme de securité incendie" },
    { value: 406, text: "Technicien systeme de securité videosurveillance" },
    { value: 407, text: "Mécanicien en robinetterie" },
    { value: 408, text: "Mécanicien machines tournantes" },
    { value: 409, text: "Technicien de maintenance informatique" },
    { value: 410, text: "Technicien help desk informatique" },
    { value: 411, text: "Cordiste" },
    { value: 412, text: "Plongeur subaquatique" },
    { value: 413, text: "Désamianteur" },
    { value: 414, text: "Mécanicien aeronautique maintenance" },
    { value: 415, text: "Technicien aeronautique" },
    { value: 416, text: "Technicien en engins de travaux publics" },
    { value: 417, text: "Expert technique automobile" },
    { value: 418, text: "Mécanicien auto" },
    { value: 419, text: "Mécanicien poids lourds" },
    { value: 420, text: "Technicien automobile" },
    { value: 421, text: "Mécanicien peche" },
    { value: 422, text: "Carrossier" },
    { value: 423, text: "Mécanicien cycles motocycles" },
    { value: 424, text: "Mécanicien moto" },
    { value: 425, text: "Chirurgien" },
    { value: 426, text: "Medecin anesthesiste reanimateur" },
    { value: 427, text: "Medecin generaliste" },
    { value: 428, text: "Medecin spécialiste" },
    { value: 429, text: "Chirurgien dentiste" },
    { value: 430, text: "Sage femme" },
    { value: 431, text: "Biologiste" },
    { value: 432, text: "Pharmacien" },
    { value: 433, text: "Agent hospitalier" },
    { value: 434, text: "Brancardier" },
    { value: 435, text: "Assistant dentaire" },
    { value: 436, text: "Auxiliaire de puériculture" },
    { value: 437, text: "Ambulancier" },
    { value: 438, text: "Manipulateur d'électroradiologie médicale" },
    { value: 439, text: "Préparateur en phamacie" },
    { value: 440, text: "Audioprothesiste" },
    { value: 441, text: "Dieteticien" },
    { value: 442, text: "Ergothérapeute" },
    { value: 443, text: "Kinésithérapeute" },
    { value: 444, text: "Masseur" },
    { value: 445, text: "Opticien lunetier" },
    { value: 446, text: "Orthophoniste" },
    { value: 447, text: "Prothesiste dentaire" },
    { value: 448, text: "Psychomotricien" },
    { value: 449, text: "Aide soignant" },
    { value: 450, text: "Cadre de sante" },
    { value: 451, text: "Infirmier" },
    { value: 452, text: "Puericulteur" },
    { value: 453, text: "Mediateur familial" },
    { value: 454, text: "Conseiller en naturopathie" },
    { value: 455, text: "Art thérapeute" },
    { value: 456, text: "Psychologue" },
    { value: 457, text: "Assistant de service social" },
    { value: 458, text: "Conseiller en economie sociale et familiale" },
    { value: 459, text: "Éducateur d'enfants" },
    { value: 460, text: "Éducateur technique spécialiste" },
    { value: 461, text: "Animateur socioculturel" },
    { value: 462, text: "Responsable projets culturels" },
    { value: 463, text: "Éducateur de la protection judiciaire" },
    { value: 464, text: "Éducateur spécialiste" },
    { value: 465, text: "Moniteur educateur" },
    { value: 466, text: "Accompagnant educatif et social" },
    { value: 467, text: "Assistant de soins en gerontologie" },
    { value: 468, text: "Aide aux personnes agees" },
    { value: 469, text: "Auxiliaire de vie" },
    { value: 470, text: "Dame de compagnie" },
    { value: 471, text: "Garde malade" },
    { value: 472, text: "Agent spécialiste ecoles maternelles" },
    { value: 473, text: "Assistante maternelle" },
    { value: 474, text: "Garde d'enfant" },
    { value: 475, text: "Aide à domicile" },
    { value: 476, text: "Aide menager" },
    { value: 477, text: "Employé de menage" },
    { value: 478, text: "Employé familial" },
    { value: 479, text: "Technicien intervention sociale et familiale" },
    { value: 480, text: "Directeur d'etablissement hospitalier" },
    { value: 481, text: "Directeur de maison de retraite" },
    { value: 482, text: "Attache territorial" },
    { value: 483, text: "Chef de projet territorial" },
    { value: 484, text: "Redacteur territorial" },
    { value: 485, text: "Fonctionnaire permis de conduire divers" },
    { value: 486, text: "Contrôleur des impots" },
    { value: 487, text: "Contrôleur des douanes" },
    { value: 488, text: "Archiviste" },
    { value: 489, text: "Bibliothecaire" },
    { value: 490, text: "Documentaliste" },
    { value: 491, text: "Conservateur du patrimoine" },
    { value: 492, text: "Maître chien de l'armee" },
    { value: 493, text: "Militaire domaine air" },
    { value: 494, text: "Militaire domaine mer" },
    { value: 495, text: "Militaire domaine terre" },
    { value: 496, text: "Moniteur sportif pour l'armee" },
    { value: 497, text: "Commissaire de police" },
    { value: 498, text: "Officier de gendarmerie" },
    { value: 499, text: "Officier de police" },
    { value: 500, text: "Pisteur secouriste" },
    { value: 501, text: "Sapeur pompier" },
    { value: 502, text: "Gendarme" },
    { value: 503, text: "Policier" },
    { value: 504, text: "Policier municipal" },
    { value: 505, text: "Conseiller en insertion sociale professionnelle" },
    { value: 506, text: "Chargé de developpement local" },
    { value: 507, text: "Administrateur judiciaire" },
    { value: 508, text: "Huissier de justice" },
    { value: 509, text: "Mandataire de justice" },
    { value: 510, text: "Notaire" },
    { value: 511, text: "Clerc d'huissier" },
    { value: 512, text: "Clerc de notaire" },
    { value: 513, text: "Collaborateur de notaire" },
    { value: 514, text: "Secretaire juridique" },
    { value: 515, text: "Avocat" },
    { value: 516, text: "Fiscaliste" },
    { value: 517, text: "Juriste d'entreprise" },
    { value: 518, text: "Juriste en droit de l'environnement" },
    { value: 519, text: "Juriste en droit social" },
    { value: 520, text: "Juriste propriete intellectuelle" },
    { value: 521, text: "Assistante de service social" },
    { value: 522, text: "Accompagnant d'eleves en situation de handicap" },
    { value: 523, text: "Conseiller principal d'education" },
    { value: 524, text: "Professeur de chant" },
    { value: 525, text: "Professeur de danse" },
    { value: 526, text: "Professeur de guitare" },
    { value: 527, text: "Professeur de piano" },
    { value: 528, text: "Enseignant spécialiste" },
    { value: 529, text: "Professeur des ecoles" },
    { value: 530, text: "Professeur d'allemand" },
    { value: 531, text: "Professeur d'anglais" },
    { value: 532, text: "Professeur d'arts plastiques" },
    { value: 533, text: "Professeur d'education physique" },
    { value: 534, text: "Professeur d'espagnol" },
    { value: 535, text: "Professeur d'italien" },
    { value: 536, text: "Professeur de francais" },
    { value: 537, text: "Professeur de maths" },
    { value: 538, text: "Professeur de russe" },
    { value: 539, text: "Professeur matieres scientifiques" },
    { value: 540, text: "Enseignant chercheur" },
    { value: 541, text: "Moniteur d'auto ecole" },
    { value: 542, text: "Formateur" },
    { value: 543, text: "Formateur de langues" },
    { value: 544, text: "Formateur francais fle" },
    { value: 545, text: "Formateur informatique" },
    { value: 546, text: "Laveur de vitres" },
    { value: 547, text: "Agent de proprete" },
    { value: 548, text: "Agent de proprete urbaine" },
    { value: 549, text: "Eboueur ripeur" },
    { value: 550, text: "Chef d'exploitation d'usine d'incineration" },
    { value: 551, text: "Archeologue" },
    { value: 552, text: "Historien" },
    { value: 553, text: "Attache de recherche clinique" },
    { value: 554, text: "Biologiste chercheur" },
    { value: 555, text: "Biotechnologue" },
    { value: 556, text: "Botaniste" },
    { value: 557, text: "Chargé d'études naturalistes" },
    { value: 558, text: "Chargé de valorisation de la recherche" },
    { value: 559, text: "Chercheur en chimie" },
    { value: 560, text: "Chercheur en physique" },
    { value: 561, text: "Généticien" },
    { value: 562, text: "Microbiologiste" },
    { value: 563, text: "Gardien" },
    { value: 564, text: "Agent de securité" },
    { value: 565, text: "Agent de sûreté aeroportuaire" },
    { value: 566, text: "Agent de telesurveillance" },
    { value: 567, text: "Convoyeur" },
    { value: 568, text: "Maître chien" },
    { value: 569, text: "Veilleur de nuit" },
    { value: 570, text: "Porteur funeraire" },
    { value: 571, text: "Conseiller funeraire" },
    { value: 572, text: "Choregraphe" },
    { value: 573, text: "Danseur" },
    { value: 574, text: "Chanteur" },
    { value: 575, text: "Musicien" },
    { value: 576, text: "Comedien" },
    { value: 577, text: "Figurant" },
    { value: 578, text: "Artiste de cirque" },
    { value: 579, text: "Chargé de production" },
    { value: 580, text: "Costumier" },
    { value: 581, text: "Regisseur lumiere" },
    { value: 582, text: "Technicien exploitation audiovisuelle" },
    { value: 583, text: "Monteur audiovisuel" },
    { value: 584, text: "Regisseur de salle" },
    { value: 585, text: "Acheteur" },
    { value: 586, text: "Responsable des achats" },
    { value: 587, text: "Analyste financier" },
    { value: 588, text: "Conseiller fusion acquisition" },
    { value: 589, text: "Risk manager" },
    { value: 590, text: "Auditeur financier" },
    { value: 591, text: "Auditeur interne" },
    { value: 592, text: "Collaborateur comptable" },
    { value: 593, text: "Expert comptable" },
    { value: 594, text: "Assistant comptable" },
    { value: 595, text: "Comptable" },
    { value: 596, text: "Contrôleur de gestion" },
    { value: 597, text: "Responsable contrôle de gestion" },
    { value: 598, text: "Adjoint administratif" },
    { value: 599, text: "Responsable administratif financier" },
    { value: 600, text: "Responsable de gestion" },
    { value: 601, text: "Responsable des services generaux" },
    { value: 602, text: "Responsable financier budget" },
    { value: 603, text: "Chef comptable" },
    { value: 604, text: "Credit manager" },
    {
      value: 605,
      text: "Responsable agence travail temporaire ou recrutement",
    },
    { value: 606, text: "Responsable d'activite" },
    { value: 607, text: "Responsable d'activite télecom" },
    { value: 608, text: "Responsable de departement" },
    { value: 609, text: "Enqueteur client mystere" },
    { value: 610, text: "Conseiller conduite du changement" },
    { value: 611, text: "Consultant financement de l'innovation" },
    { value: 612, text: "Data analyst" },
    { value: 613, text: "Data scientist" },
    { value: 614, text: "Statisticien" },
    { value: 615, text: "Assistant ressources humaines" },
    { value: 616, text: "Gestionnaire paie" },
    { value: 617, text: "Assistant formation" },
    { value: 618, text: "Chargé d'affaires en recrutement" },
    { value: 619, text: "Chargé de recrutement" },
    { value: 620, text: "Chargé études RH" },
    { value: 621, text: "Consultant RH" },
    { value: 622, text: "Gestionnaire RH" },
    { value: 623, text: "Responsable formation" },
    { value: 624, text: "Responsable recrutement" },
    { value: 625, text: "Responsable paie" },
    { value: 626, text: "Responsable ressources humaines" },
    { value: 627, text: "Facteur" },
    { value: 628, text: "Assistant de direction" },
    { value: 629, text: "Attache de direction administration" },
    { value: 630, text: "Assistant administratif" },
    { value: 631, text: "Assistant de gestion" },
    { value: 632, text: "Operateur de saisie" },
    { value: 633, text: "Secretaire" },
    { value: 634, text: "Secretaire administratif" },
    { value: 635, text: "Télésecretaire" },
    { value: 636, text: "Secretaire médical" },
    { value: 637, text: "Assistant marketing" },
    { value: 638, text: "Chargé de marketing digital" },
    { value: 639, text: "Responsable marketing" },
    { value: 640, text: "Web marketer" },
    { value: 641, text: "Promoteur des ventes" },
    { value: 642, text: "Responsable e-commerce" },
    { value: 643, text: "Administrateur systemes et reseaux" },
    { value: 644, text: "Architecte logiciel" },
    { value: 645, text: "Architecte technique informatique" },
    { value: 646, text: "Cryptologue" },
    { value: 647, text: "Devops" },
    { value: 648, text: "Expert cybersecurité" },
    { value: 649, text: "Ingénieur cloud" },
    { value: 650, text: "Ingénieur systeme et reseau" },
    { value: 651, text: "Ingénieur systemes embarques" },
    { value: 652, text: "Ingénieur test logiciel" },
    { value: 653, text: "Referent technique IT" },
    { value: 654, text: "Securite si" },
    { value: 655, text: "Chef de projet IT" },
    { value: 656, text: "Chef de projet IT moa" },
    { value: 657, text: "Chef de projet IT technique" },
    { value: 658, text: "Chef de projet web IT" },
    { value: 659, text: "Delivery manager" },
    { value: 660, text: "Directeur systeme d'information" },
    { value: 661, text: "Directeur technique informatique" },
    { value: 662, text: "Ingénieur informatique industrielle" },
    { value: 663, text: "Responsable informatique" },
    { value: 664, text: "Responsable informatique industrielle" },
    { value: 665, text: "Responsable production informatique" },
    { value: 666, text: "Ingénieur reseaux télecoms" },
    { value: 667, text: "Analyste programmeur" },
    { value: 668, text: "Business analyst" },
    { value: 669, text: "Developpeur dotnet" },
    { value: 670, text: "Developpeur frontend" },
    { value: 671, text: "Developpeur fullstack" },
    { value: 672, text: "Developpeur IT" },
    { value: 673, text: "Developpeur java" },
    { value: 674, text: "Developpeur javascript" },
    { value: 675, text: "Developpeur mobile" },
    { value: 676, text: "Developpeur php" },
    { value: 677, text: "Developpeur ruby" },
    { value: 678, text: "Developpeur symfony" },
    { value: 679, text: "Developpeur web" },
    { value: 680, text: "Informaticien industriel" },
    { value: 681, text: "Integrateur d'applications informatiques" },
    { value: 682, text: "Scrum master" },
    { value: 683, text: "Testeur informatique" },
    { value: 684, text: "Chargé d'affaires informatique" },
    { value: 685, text: "Consultant BI" },
    { value: 686, text: "Consultant IT" },
    { value: 687, text: "Product manager" },
    { value: 688, text: "Product owner" },
    { value: 689, text: "Cartographe Géomaticien" },
    { value: 690, text: "Géographe" },
    { value: 691, text: "Météorologue" },
    { value: 692, text: "Gestionnaire du parc informatique" },
    { value: 693, text: "Cariste" },
    { value: 694, text: "Demenageur" },
    { value: 695, text: "Emballeur" },
    { value: 696, text: "Magasinier cariste" },
    { value: 697, text: "Préparateur de commandes" },
    { value: 698, text: "Pontier" },
    { value: 699, text: "Manutentionnaire" },
    { value: 700, text: "Affréteur" },
    { value: 701, text: "Agent de transit" },
    { value: 702, text: "Declarant en douane" },
    { value: 703, text: "Responsable logistique" },
    { value: 704, text: "Responsable preparation de commandes" },
    { value: 705, text: "Responsable supply chain" },
    { value: 706, text: "Technicien logistique" },
    { value: 707, text: "Hôtesse de l'air" },
    { value: 708, text: "Pilote d'avion" },
    { value: 709, text: "Pilote d'helicoptere" },
    { value: 710, text: "Pilote de l'armee" },
    { value: 711, text: "Agent d'escale" },
    { value: 712, text: "Contrôleur aerien" },
    { value: 713, text: "Timonier de la marine nationale" },
    { value: 714, text: "Chauffeur" },
    { value: 715, text: "Chauffeur de taxi" },
    { value: 716, text: "Chauffeur poids lourd" },
    { value: 717, text: "Conducteur de bus" },
    { value: 718, text: "Conducteur receveur" },
    { value: 719, text: "Coursier" },
    { value: 720, text: "Chauffeur livreur" },
    { value: 721, text: "Livreur à domicile" },
    { value: 722, text: "Conducteur de train" },
  ],
  TITLES_FIELDS: {
    offer_information: "Information sur l'offre",
    firstName: "Prénom",
    lastName: "Nom de famille",
    address: "Adresse",
    postal_code: "Code postal",
    city: "Ville",
    country: "Pays",
    email: "Email",
    phone_number: "Numéro de téléphone",
    birthday: "Anniversaire",
    nationality: "Nationalité",
    driver_licence: "Permis de conduire",
    organisation_name: "Nom de l'organisation",
    contract_type: "Contrat",
    duration: "Durée",
    occupations: "Occupations",
    occupation: "Occupation",
    school_name: "Nom de l'école",
    specialisation: "Spécialisation",
    level: "Niveau",
    company: "Nom de l'organisation",
    mission: "Mission",
    hard_skills: "Compétences techniques",
    soft_skills: "Compétences générales",
    languages: "Langues",
    skills: "Compétences",
    date_start: "Date de début",
    date_end: "Date de fin",
    requirements: "Exigences",
    domain: "Domaine",
    specialization: "Spécialisation",
    education_level: "Diplômes",
    experience_level: "Niveau d'expérience",
    state: "Etat",
    schedule: "Programme",
  },
  TOOLTIP: {
    UPLOAD_JOB: "Télécharger le travail",
    MATCHING_CRITERIA: "Critères de correspondance",
  },
}
