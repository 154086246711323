import storage from './storage'
/*

  // How to use storage state in vuex ?

  // Add fields in your modules (it only works for modules):
  storage: {
    dashboardGroup: [],
    dashboardIndex: Number,
    dashboard: Object,
  },

  // OR inline mode
  storage: ['dashboardGroup','dashboardIndex','dashboard']

*/

// create (or get) each instance
const create = (type, states) => {
  return storage.create(type, states).then((/*storageInstance*/) => {
    const promised = []
    // get data to restore
    Object.keys(states).forEach(key => {
      promised.push(storage.get(type, key))
    })

    return Promise.all(promised)
      .then(results => {
        // restore state
        Object.keys(states).map((key, index) => {
          results[index] ? (states[key] = results[index]) : null
        })
        return states
      })
      .catch(e => {
        console.warn('storageCreate() -', e)
      })
  })
}

// init
const init = modules => {
  // array of promises
  const promises = []
  // final states to hydrate
  const states = {}
  Object.keys(modules).forEach(type => {
    // init type
    states[type] = {}

    // // get the data in modules.storage
    const rawStorageStates = modules[type].storage
    if (rawStorageStates) {

      // 'storage' could be:
      // an object { key1: Type, key2: defaultValue, key3: Function}
      // OR an array ['key1', 'key2', ...]
      const rawStorageStatesKeys =
        rawStorageStates.constructor.name === 'Object'
          ? Object.keys(rawStorageStates)
          : rawStorageStates

      // init storage with storage key state
      rawStorageStatesKeys.forEach(key => {
        if (typeof rawStorageStates[key] !== 'undefined') {
          states[type][key] =
            rawStorageStates[key].constructor.name === 'Function'
              // if function, trigger it
              ? new rawStorageStates[key]()
              // default storage values
              : rawStorageStates[key]
        } else {
          // OR put default store values
          states[type][key] = modules[type].state[key]
        }
      })
      // // then send data in storage
      promises.push(create(type, states[type]))
    }
  })
  return Promise.all(promises)
}

// filter and update values
const update = (state, namespace, module) => {
  const keysToStore =
    module.storage.constructor.name === 'Object'
      ? Object.keys(module.storage)
      : module.storage

  // array of promises
  const promised = []

  keysToStore.forEach(item => {
    // get the state for the stored item
    promised.push(storage.set(namespace, item, state[item]))
  })

  return Promise.all(promised)
}

const hydrate = (statesArray, modules) => {
  let index = 0
  const finalStates = {}
  Object.keys(modules).forEach(type => {
    if (modules[type]) {
      if (modules[type].storage) {
        // default state in store will be overrided by local storage state
        finalStates[type] = Object.assign(
          {},
          modules[type].state,
          statesArray[index] ? statesArray[index] : {}
        )
        index++
      } else {
        finalStates[type] = modules[type].state
      }
    }
  })

  return finalStates
}

export default store => {
  // get storage keys in each module
  const modules = store._modules.root._rawModule.modules

  init(modules).then(statesArray => {
    const finalStates = hydrate(statesArray, modules)
    store.replaceState(finalStates)

    store.commit('app/APP_RESTORE_MUTATION')

    store.subscribe((mutation, states) => {
      const { type/*, payload*/ } = mutation
      // get the mutation's namespace
      const namespace = type.split('/')[0]

      // storage restored !
      if (modules[namespace] && modules[namespace].storage) {
        update(states[namespace], namespace, modules[namespace]).catch(e => {
          console.warn('error on mutation', e)
        })
      }
    })
  })

  store.storage = {
    empty: storage.empty,
    instances: storage.instances
  }
}
