import * as types from '@/store/mutations.types'

export default {

  addCV({ commit }, data) {
    return commit(types.ADD_CV, data)
  },

  addCVS({ commit }, data) {
    return commit(types.ADD_CVS, data)
  },

  addCVMatch({ commit }, data) {
    return commit(types.ADD_CV_MATCH, data)
  },
}
