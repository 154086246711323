export default {
  REQUIRED: "Requis",
  EMAIL: "L'adresse e-mail doit être valide (ex: myname@gmail.com)",
  EMAIL_ALREADY_EXISTS:
    "Il existe déjà un utilisateur avec cette adresse email, utilisez une autre adresse ou cliquez sur Se connecter.",
  URL: "Url doît être valide (ex: https://www.gmail.com)",
  PASSWORD: "Min. 6 caractères et max. 16 caractères",
  DATE: "Selectionner une date valide",
  COUNTRY: "Selectionner votre pays",
  MIN: "Min. {min}",
  MAX: "Max. {max}",
  MINLENGTH: "Min. {min} caractères",
  MAXLENGTH: "Max. {max} caractères",
}