export default {
  TITLE: "Ratio",
  TITLES: "Ratios",
  TITLE_COUNT: "Aucun ratio | 1 ratio | {n} ratios",
  ICON: "mdi-chart-line",

  FILTERS: "Filtres",
  FEED: "Flux",
  CAMPAIGN: "Campagne",
  CATEGORY: "Catégorie",
  CATEGORY_ICON: "",
  EXPERIENCE: "Expérience",
  EXPERIENCE_ICON: "",
  LOCATION: "Localisation",
  LOCATION_ICON: "mdi-map-marker",

  CHANNEL_RANKING: "Classement par canal",

  SHORT_CLICK: "clic(s)",
  SHORT_APPLY: "acquisition(s) / candidature(s)",
  SHORT_VIEW: "vue(s)",
  SHORT_CREDIT: "publication(s) (crédit)",
  CLICKS: "Clics",
  APPLICATIONS: "Candidatures",
  CREDITS: "Crédits",
  CREDITS_PRICE: "Prix du crédit",
  COST: "Coût",
  AUDIENCES: "Audiences",
  JOBS: "Offres",
  BUDGET: "Budget",
  APPLY_COUNT: "0 candidature | 1 candidature | {n} candidatures",
  CLICK_COUNT: "0 clic | 1 clic | {n} clics",
  VIEW_COUNT: "0 vue | 1 vue | {n} vues",
  CREDIT_COUNT:
    "0 publication (crédit) | 1 publication (crédit) | {n} publications (crédit)",
  BEST_CHANNEL: "Meilleur canal",
  DISTRIBUTION: "Répartition",
  CHANNELS_STATS: "Statistiques par canal",
  CHANNEL: "Canal",
  PUBLICATIONS: "Publications",
  VIEWS: "Vues",

  UPLOAD_STATISTICS: "Importer des statistiques",
  DOWNLOAD_TEMPLATE: "Télécharger les statistiques",

  CREDITS_TITLE: "Coût par slot (cumulé)",
  CPA_TITLE: "Coût par acquisition (cumulé)",
  CPA: "CPA",
  CPM_TITLE: "Coût par 1000 vues (cumulé)",
  CPM: "CPM",
  CPC_TITLE: "Coût des clics/jour",
  CPC: "CPC",
  BUDGET_USED_TITLE: "Dépenses",

  CLICKED_TOTAL: "(cliqués / total)",
  USED_TOTAL: "(utilisé / total)",

  CHANNEL_SUBSCRIPTION: "Channels",
  START_DATE: "Date de début",
  END_DATE: "Date de fin",

  SHOW_STATS: "Voir Stats",
  BUDGET_TOTAL: "Budget total",
  BUDGET_USED: "Budget utilisé",
  BUDGET_REMAINING: "Budget restant",

  EXPORT_CSV: "Exporter CSV/EXCEL",
  EXPORT_EXCEL: "Exporter en EXCEL",
  EXPORT_PDF: "Exporter en PDF",

  RESULTS: "Résultats de votre recherche",
  OCCUPATIONS: "Métiers",
}
