export default {
  TITLE: "Média",
  TITLES: "Médias",
  TITLE_COUNT: "aucun média | 1 média | {n} médias",
  ICON: "mdi-antenna",

  METHOD_NONE: "Tous",
  METHOD_ORGANIC: "Organiques",
  METHOD_CREDITS: "Crédits",
  METHOD_CREDIT: "Crédits",
  METHOD_AGGREGATOR: "Agrégateurs",
  METHOD_DISPLAY: "Display",
  METHOD_XTRADING: "Xtrading",

  ACCOUNT_USE_BY: "Compte: {name}",
  REMOVE: "Supprimer la souscription",
  RESET: "Annuler les modifications",
  SAVE: "Enregistrer",
  PREVIOUS: "Précedent",
  NEXT: "Suivant",

  FORM_TITLE_UPDATE: "Modifier un média",
  FORM_TITLE_CREATE: "Créer un médias",
  FORM_TITLE_NEW: "Saisissez les informations du nouveau médias",

  STEP_CHANNEL_TITLE: "Choix du canal",
  STEP_CHANNEL_DESCRIPTION:
    "Choisissez sur quel canal vous souhaiteriez diffuser vos offres.",

  STEP_PARAMS_TITLE: "Paramètres du compte et du canal",
  STEP_PARAMS_DESCRIPTION: "Paramétrez votre diffusion et votre compte.",

  STEP_CONTRACT_TITLE: "Contrat et gestion des coûts",
  STEP_CONTRACT_DESCRIPTION:
    "Maîtrisez et encadrez vos coûts avec un contrat.",

  STEP_CONFIRM_TITLE: "Confirmation",
  STEP_CONFIRM_DESCRIPTION:
    "Vérifiez et confirmez les informations du médias.",

  CHANNEL_TEXT: "Choisissez un canal de diffusion",
  CHANNEL_JOBBOARDS_TITLE: "Jobboards",
  CHANNEL_JOBBOARDS_DESCRIPTION: "Canaux à crédits",
  CHANNEL_AGGREGATORS_TITLE: "Aggregateurs",
  CHANNEL_AGGREGATORS_DESCRIPTION: "Canaux gratuits et au coûts par clics/vues/acquisitions",
  CHANNEL_DISPLAY_TITLE: "Display",
  CHANNEL_DISPLAY_DESCRIPTION: "Canaux audiovisuels",
  CHANNEL_NOT_FOUNDED: "Les chaînes n'ont pas été trouvées",

  NO_CONTRACT_ERROR:
    "Vous devez créer au moins un contract pour utiliser ce canal.",
  TEST_MODE_TEXT: "Mode: Test",

  BUDGET_OPEN: "Ouvert",
  BUDGET_OPEN_TEXT: "Certains de vos canaux ne disposent pas de plafond de dépense dans les contrats, le budget prévisionnel est considéré comme illimité.",
  BUDGET_TITLE: "Budget",
  BUDGET_TITLE_FOR: "pour les canaux: ",
  TOTAL_BUDGET_SPENT: "Budget Dépensé",
  TOTAL_BUDGET_BASE: "Budget Prévisionnel",
  TOTAL_BUDGET_SPENT_PERCENT: "Répartition des dépenses par channels",

  ACCOUNT: "Compte",
  ACCOUNT_XTRAMILE: "Compte: Xtramile",
  ACCOUNT_CLIENT: "Compte: Utilisateur",
  ACCOUNT_TITLE: "Paramètre de compte",
  CHANNEL_PARAMS_TITLE: "Paramètre du canal",
  PRICES_TITLE: "Modèle de prix",
  PRICES_TEXT:
    "Définissez les modèles de prix que vous voulez utiliser lors de vos publications.",

  USE_XTRAMILE_ACCOUNT: "Utiliser le compte Xtramile sur {a}",
  TEST_MODE: "Activer le mode test",
  GROUP_BY: "Grouper les offres par",
  APPLY_METHOD: "MÉTHODE DE PUBLICATION",

  CONTRACT_TITLES: "Contrats",
  CONTRACT_TITLE: "Contrat",
  CANCELED: "Annulé",
  NO_CONTRACT: "Aucun contrat",
  DOWNLOAD_FILE: "Télécharger le fichier généré",
  NO_DATE_LIMIT: "Pas de date",
  DAYS_AFTER_FIRST_USE: "jours après la première utilisation",
  LAST_UPDATED: "Dernière mise à jour le",

  FILE_OFFICE: "Fichier : Emplois de bureau",
  FILE_COMPANY: "Dossier : Emplois d'entreprise",
  FILE_XTRAMILE_ACCOUNT: "Masterfeed: Xtramile",
  FILE_HASH_ACCOUNT: "Masterfeed: Compte personnalisé",

  GENERATED_FILE: "Fichiers générés",

  GROUP_BY_ITEMS: [
    { value: "office", text: "Bureau" },
    { value: "company", text: "Entreprise (Tous les bureaux)" },
  ],
  BOOLEAN_ITEMS: [
    { value: true, text: "Activé" },
    { value: false, text: "Desactivé" },
  ],
  APPLY_METHOD_ITEMS: [
    { value: "APPLY_URL", text: "Utiliser seulement URL" },
    { value: "APPLY_EMAIL", text: "Utiliser seulement email" },
    { value: "APPLY_URL_EMAIL", text: "Essayer URL, puis email" },
    { value: "APPLY_EMAIL_URL", text: "Essayer email, puis URL" },
    { value: "APPLY_BOTH", text: "Utiliser URL et email" },
  ],

  UNLIMITED: "Illimité",

  SUBSCRIPTION_PUBLICATION_DISABLED: 'Média Suspendu',
  CHANNEL_PUBLICATION_DISABLED: 'Canal Suspendu',
  PUBLICATION_ENABLED: 'Média Actif',
  DISABLE_LABEL: 'Suspendre',
  DISABLE_TEXT: 'Ne plus générer des flux XML/requêtes API',
}
