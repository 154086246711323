<template>
  <header class="menu-main primary d-flex flex-column">
    <div
      class="menu-main__logo-wrap d-flex pa-6 align-center"
      style="height: 90px"
      @click="$router.push('/')"
    >
      <img class="menu-main__logo" src="/img/xtramile-shortlogo-light.svg" height="30px" />
    </div>

    <div
      :class="['live-notifications', showLiveNotification ? 'show' : '']"
      @click="showLiveNotification = false"
    >
      <div
        v-if="liveNotification"
        class="live-notification pa-4 primary white--text rounded elevation-4"
      >
        <div class="mb-2">
          <v-icon
            size="20"
            class="mr-2"
            :color="liveNotification.status_type"
            v-text="liveNotification.status_type === 'success' ? 'mdi-check-circle' : 'mdi-alert-circle' "
          />
          <strong :class="liveNotification.status_type + '--text'">{{$t('NOTIFICATION.SCOPE_' + liveNotification.scope.toUpperCase())}}</strong>
        </div>
        <div class="text-small" v-text="handleMessage(liveNotification)" v-if="liveNotification.message" />
        <div class="text-right" v-if="liveNotification.redirect_url">
          <a :href="redirectUrl(liveNotification)">
            <v-btn x-small color="white" outlined dark>{{$t('COMMON.CONTINUE')}}</v-btn>
          </a>
        </div>
      </div>
    </div>

    <div class="menu-main__list-wrap scroll" style="flex-grow: 1">
      <v-list dense dark class="menu-main__list transparent pa-0">
        <v-list-item class="home pl-6" to="/">
          <v-list-item-icon class="home__item-icon">
            <v-icon class="home__icon" color="accent" v-text="$t('HOME.ICON')" size="20" />
          </v-list-item-icon>
          <v-list-item-content class="home__item-content">
            <v-list-item-title class="home__item-title">
              <strong v-text="$t('HOME.TITLE')" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="notifications pl-6 mb-6"
          to="/notifications"
          @dblclick="notificationItems.push({ test: true })"
          link
        >
          <v-list-item-icon class="notifications__item-icon">
            <v-icon class="notifications__icon" color="accent" v-text="$t('NOTIFICATION.ICON')" size="20" />
          </v-list-item-icon>
          <v-list-item-content class="notifications__item-content">
            <v-list-item-title class="notifications__item-title">
              <strong v-text="$t('NOTIFICATION.TITLES')" />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <MenuMainXranking />
        <MenuMainXparsing />
        <!-- <MenuMainXdiffusion /> -->
        <MenuMainXtrading />
        <!-- <MenuMainXprogrammatic /> -->
        <MenuMainXimpact />
      </v-list>
    </div>

    <div class="menu-main__footer">
      <Dialog
        :value="showSwitchAccount"
        icon="mdi-account-switch"
        width="600px"
        :subtitle="$t('SWITCH_ACCOUNT.TITLE')"
        component="forms/switch_account/compact/FormSwitchAccountCompact"
        @close="showSwitchAccount = false"
      />

      <v-list dark dense class="menu-main__footer-list transparent pa-0">
        <!-- to="/settings/account/" -->
        <v-list-item link two-line class="menu-main__footer-list-item">
          <div class="menu-main__footer-list-item-content-title" style="display: flex; flex-direction: column; align-items:flex-start">
            <div>
              <v-list-item-icon class="menu-main__footer-user-item-icon mr-6 pt-1">
                <v-avatar class="menu-main__footer-user-avatar" size="46" color="accent">
                  <img
                      :src="$getImgSrc(user.images, 'md')"
                      v-if="user && user.images"
                  />
                </v-avatar>
                <span class="menu-main__footer-list-item-title accent--text ml-2 mt-3">
                  <strong>{{$t('ACCOUNT.TRADING_DESK')}}</strong>
                </span>
              </v-list-item-icon>
            </div>

            <div class="menu-main__footer-user-info" style="display: flex">
              <v-list-item-content class="menu-main__footer-user-item-content">
                <v-list-item-title class="menu-main__footer-user-item-title">
                  <strong>{{ fullname }}</strong>
                </v-list-item-title>
                <v-list-item-subtitle class="menu-main__footer-user-item-subtitle">
                  <v-icon size="12" class="menu-main__footer-user-item-subtitle-icon mr-1" color="accent">
                    {{ $t("COMPANY.ICON") }}
                  </v-icon>
                  <span class="text-small">{{ company }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="menu-main__footer-user-item-subtitle">
                  <v-icon size="12" class="menu-main__footer-user-item-subtitle-icon mr-1" color="accent">
                    {{ $t("OFFICE.ICON") }}
                  </v-icon>
                  <span class="text-small">{{ office.split('[')[0] }}</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle class="menu-main__footer-user-item-subtitle">
                  <v-icon size="12" class="menu-main__footer-user-item-subtitle-icon mr-1" color="accent">
                    {{ $t("PRODUCT.ICON") }}
                  </v-icon>
                  <span class="text-small">{{ office.split('[')[1] ? '[' + office.split('[')[1] : '?' }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>
            </div>
          </div>
        </v-list-item>

        <v-list-item class="menu-main__footer-list-item">
          <v-tooltip class="user_logout__tooltip" top transition="none">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                small
                icon
                class="user_logout__button"
                @click="$store.dispatch('user/logout', true)"
              >
                <v-icon class="user_logout__icon" size="18">{{ $t("LOGOUT.ICON") }}</v-icon>
              </v-btn>
            </template>
            {{ $t("LOGOUT.TITLE") }}
          </v-tooltip>

          <v-tooltip class="settings__tooltip" top transition="none">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                small
                icon
                class="settings__button ml-2"
                to="/settings/"
              >
                <v-icon class="settings__icon" size="18">{{ $t("SETTINGS.ICON") }}</v-icon>
              </v-btn>
            </template>
            {{ $t("SETTINGS.TITLES") }}
          </v-tooltip>


          <v-tooltip class="switch_account__tooltip" top transition="none" v-if="userIsXtramileAdmin || !company.startsWith('Xtramile')">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                small
                icon
                class="switch_account__button ml-2"
                @click="showSwitchAccount = true"
              >
                <v-icon class="switch_account__icon" size="18">{{ $t("SWITCH_ACCOUNT.ICON") }}</v-icon>
              </v-btn>
            </template>
            {{ $t("SWITCH_ACCOUNT.TEXT") }}
          </v-tooltip>

          <v-spacer />

          <v-menu class="languages_menu" offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                depressed
                color="primary"
                rounded
                class="languages_menu__button ml-2"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon size="18" class="languages_menu__icon mr-2">mdi-earth</v-icon>
                <span class="languages_menu__lang text-uppercase">{{ $i18n.locale }}</span>
              </v-btn>
            </template>

            <v-list dense class="languages_menu__list pa-0">
              <v-list-item
                link
                :disabled="$i18n.locale === locale"
                :key="'lo' + i"
                @click="() => changeLocale(locale)"
                v-for="(locale, i) in availableLocales"
                class="languages_menu__list-item"
              >
                <span class="languages_menu__list-item-title text-uppercase">{{ locale }}</span>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item>
      </v-list>
    </div>
  </header>
</template>

<script>
import { mapState } from "vuex";
import MenuMainXdiffusion from "./MenuMainXdiffusion";
import MenuMainXprogrammatic from "./MenuMainXprogrammatic";
import MenuMainXtrading from "./MenuMainXtrading";
import MenuMainXranking from "./MenuMainXranking";
import MenuMainXparsing from "./MenuMainXparsing";
import MenuMainXimpact from "./MenuMainXimpact.vue";
import Dialog from "@/components/Dialog";

export default {
  name: "MenuMain",
  components: {
    Dialog,
    MenuMainXdiffusion,
    MenuMainXprogrammatic,
    MenuMainXtrading,
    MenuMainXranking,
    MenuMainXparsing,
    MenuMainXimpact,
  },
  mounted () {
    this.socket = this.$services.createSocket()
    this.socket.on('notification', (data) => {
      this.setLiveNotificationTimeout(data)
      this.loadNotifications()
    })
    this.loadNotifications()
  },
  beforeDestroy() {
    this.$services.destroySocket(this.socket)
  },
  data: (_this) => ({
    timeout: null,
    showLiveNotification: false,
    liveNotification: null,
    notificationItems: [],
    notificationCount: 0,
    showSwitchAccount: false,
    selectedMenu: 0,
    menu1: [
      {
        icon: _this.$i18n.t("HOME.ICON"),
        text: _this.$i18n.t("HOME.TITLE"),
        to: "/",
      },
    ],
    menu2: [
      {
        icon: _this.$i18n.t("CHANNEL_SUBSCRIPTION.ICON"),
        text: _this.$i18n.tc("CHANNEL_SUBSCRIPTION.TITLE_COUNT", 3),
        to: "/medias",
      },
    ],
    language: "en",
  }),

  /*
  {
   "id": "b81f60e1-2cae-4e28-9fc6-1c7664053dcf",
   "firstname": "Test",
   "lastname": "Xdiffusion",
   "images": null,
   "office_id": "d7496260-8e0a-11eb-a696-6f801b865591",
   "company_id": "c3e3092c-8be2-4d19-a228-db8bf1d7b335",
   "country_id": "3fed6b54-460f-46aa-9ee0-eafdc479f031",
   "email": "j.pirson+xdiffusion01@myxtramile.com",
   "admin": false,
   "level": 50,
   "env": "local",
   "permissions": [],
   "session": {
     "id": "de62f042-3d0d-4a4a-b295-3b0a7c5ba3c4",
     "user_id": "b81f60e1-2cae-4e28-9fc6-1c7664053dcf",
     "user_agent": "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/94.0.4606.81 Safari/537.36",
     "ip": "::ffff:172.18.0.1",
     "expiration_date": "2021-10-19T01:29:17.213Z",
     "created_at": "2021-10-18T13:29:17.213Z",
     "updated_at": "2021-10-18T13:29:17.213Z"
   },
   "country": {
     "id": "3fed6b54-460f-46aa-9ee0-eafdc479f031",
     "title": "France",
     "code": "fr",
     "language": "fr"
   },
   "company": {
     "id": "c3e3092c-8be2-4d19-a228-db8bf1d7b335",
     "title": "Xtramile SAS",
     "images": null
   },
   "office": {
     "id": "d7496260-8e0a-11eb-a696-6f801b865591",
     "title": "Xtramile Metz",
     "images": null
   },
   "role": {
     "level": 50,
     "id": "666d0ed0-2cec-11ec-ab0b-a1a9c3ba97d8",
     "title": "admin"
   }
  }
  */

  computed: {


    liveNotificationMessage () {
      if (this.liveNotification)
        return this.handleMessage(this.liveNotification)
      return ''
    },
    availableLocales() {
      return this.$i18n.availableLocales;
    },
    ...mapState("app", {
      page: (state) => {
        return state.page;
      },
    }),
    ...mapState("user", {
      user: (state) => state.user,
      username: (state) =>
        state.user ? `${state.user.firstname[0]}. ${state.user.lastname}` : "-",
      images: (state) => state.images || {},
      fullname: (state) =>
        state.user ? `${state.user.firstname} ${state.user.lastname}` : "-",
      role: (state) =>
        state.user && state.user.role ? state.user.role.title : "-",
      company: (state) =>
        state.user && state.user.company ? state.user.company.title : "-",
      office: (state) =>
        state.user && state.user.office ? state.user.office.title : "-",
      userIsXtramileAdmin: (state) =>
        state.user && state.user.role && state.user.role.title[0] === "x",

    }),
  },

  methods: {


    redirectUrl (item) {
      let product = ''
      const permissions = this.user.permissions
      for (const permission of permissions) {
        if (permission.name === 'role.xadmin') {
          product = 'xprogrammatic'
          break
        }
        if (permission.name === 'product.xtrading') product = 'xtrading'
        if (permission.name === 'product.xdiffusion' && !product) product = 'xdiffusion'
      }
      return document.location.origin + '/' + product + item.redirect_url
    },

    handleMessage (item) {
      item = this.$clone(item)
      let message = {}
      if (item.message) {
        item.message.split(' | ').map((v,i) => {
          message['_'+(i+1)] = v
        })
      }
      // console.log(item.status_code, message)
      return this.$i18n.t(item.status_code, message)
    },

    setLiveNotificationTimeout (data) {
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.showLiveNotification = false
        // keep the same timeout
        this.timeout = setTimeout(() => {
          this.liveNotification = null
        },1000)
      }, 8000)
      //start
      this.showLiveNotification = true
      this.liveNotification = data
    },

    async loadNotifications () {
      this.$services.api_notification.notification.search({where: {view_at:null}, options: {limit: 100}})
        .then(({data}) => this.notificationItems = data)
      this.$services.api_notification.notification.search({where: {view_at:null}, options: {mode:'count'}})
        .then(({data}) => this.notificationCount = data)
    },
    isActivePage(page) {
      return this.page === page ? "active" : "";
    },
    changeLocale(value) {
      this.$i18n.locale = value;
      this.$moment.locale(value);
    },
  },
};
</script>

<style lang="scss">
.menu-main {
  z-index: 1;
  height: 100%;
  width: 240px;
  position: relative;
}

.menu-main::after {
  z-index: 0;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-right: solid 1px rgba(255, 255, 255, 0.12);
}

.menu-main .v-list-group__header__append-icon > .v-icon {
  border: solid 1px rgba(255, 255, 255, 0.1);
  color: #e0bc37;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.1);
  height: 26px;
  margin-top: -1px;
}

.menu-main > * {
  z-index: 1;
}

.menu-main .v-list-item__icon:not(.v-list-group__header__append-icon) {
  margin-right: 20px !important;
}
.menu-main .v-list-item {
  margin: 0 10px 2px 10px;
  border-radius: 8px;
  // border: solid 1px transparent;
  overflow: hidden;
  transition: all 150ms;
}

.menu-main .v-list-item__icon {
  margin-left: 0px !important;
}
.menu-main a.v-list-item.v-list-item--active {
  color: #fff !important;
  // border: solid 1px #E0BC37;
}
.menu-main .v-list-item--link:before {
  border-radius: 8px;
}
.menu-main .v-list-item.v-list-item--active.v-list-item--link:before {
  opacity: 0.12;
}

.menu-main .v-avatar {
  border: solid 2px;
}

.disabledSlot {
  color: grey;
}

@keyframes notification-active {
  from {
    box-shadow: 0 0 0 0 rgba(224, 188, 55, 1);
  }

  to {
    box-shadow: 0 0 30px 10px rgba(224, 188, 55, 0);
  }
}
.notification-active {
  animation: notification-active infinite 1s;
}


.live-notifications.show {
  left: 20px;
}
.live-notifications {
  cursor: pointer;
  position: fixed;
  z-index: 99;
  width: 250px;
  left: -260px;
  top: 20px;
  transition: left linear 200ms;

}

.live-notifications {
}
//
//
// .menu-main .v-toolbar-logo {
//   cursor: pointer;
//   display: flex;
//   align-content: center;
// }
//
// .menu-main .v-toolbar-logo img {
//   height: 16px;
// }
//
// .menu-main .v-list-item--active::before {
//   border-left: solid 5px rgba(224,188,55,1);
//   opacity: 1 !important;
//   background: -moz-linear-gradient(right,  rgba(224,188,55,0) 0%, rgba(224,188,55,0) 20%, rgba(224,188,55,0.2) 100%);
//   background: -webkit-linear-gradient(right,
//    rgba(224,188,55,0) 0%,rgba(224,188,55,0) 20%,rgba(224,188,55,0.2) 100%);
//   background: linear-gradient(to left,  rgba(224,188,55,0) 0%,rgba(224,188,55,0) 20%,rgba(224,188,55,0.2) 100%);
//   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e0bc37',
//    endColorstr='#33e0bc37',GradientType=0 );
// }

// .v-tabs .v-slide-group__wrapper {
//   border-top: solid 1px rgba(255,255,255,.12) !important;
// }
//
// .v-tab {
//   border-right: solid 1px rgba(255,255,255,.12);
// }
// .v-tab:last-child {
//   border-right: none;
// }
//
// .v-tab.v-tab--active {
//   height: calc(100% - 1px);
//   font-weight: bold;
//   background: -moz-linear-gradient(top,  rgba(224,188,55,0) 0%, rgba(224,188,55,0) 20%, rgba(224,188,55,0.2) 100%);
//   background: -webkit-linear-gradient(top,  rgba(224,188,55,0) 0%,rgba(224,188,55,0) 20%,rgba(224,188,55,0.2) 100%);
//   background: linear-gradient(to bottom,  rgba(224,188,55,0) 0%,rgba(224,188,55,0) 20%,rgba(224,188,55,0.2) 100%);
//   filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00e0bc37',
//    endColorstr='#33e0bc37',GradientType=0 );
// }

// .menu-main .active::after {
//   z-index: 9999;
//   content:"";
//   position: absolute;
//   bottom:-1px;
//   right: 0;
//   left: 0;
//   // width: calc(100% + 1px);
//   border-bottom: solid 3px #e0bc37;
// }
</style>
