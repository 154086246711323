// global
export const REQUEST = "REQUEST"
export const RESET_STATE = "RESET_STATE"
export const CLEAN_STATE = "CLEAN_STATE"
export const SOCKET = "SOCKET"

// * * * * * * * * *
// App
// * * * * * * * * *
export const APP_READY = "APP_READY"
export const APP_REFRESH = "APP_REFRESH"
export const APP_WILL_REFRESH = "APP_WILL_REFRESH"
export const APP_CONNECTED = "APP_CONNECTED"
export const APP_RESTORE_MUTATION = "APP_RESTORE_MUTATION"
export const APP_BASE_URL = "APP_BASE_URL"
export const APP_SETTINGS = "APP_SETTINGS"
export const APP_SCREENSIZE = "APP_SCREENSIZE"
export const APP_PAGE = "APP_PAGE"

// * * * * * * * * *
// Messenger
// * * * * * * * * *
export const MESSENGER_ADD = "MESSENGER_ADD"
export const MESSENGER_REMOVE = "COMMON.REMOVE"

// * * * * * * * * *
// User
// * * * * * * * * *
export const USER_SESSION = "USER_SESSION"
export const SET_TOKEN_PARSE = "SET_TOKEN_PARSE"

// * * * * * * * * *
// OFFICE
// * * * * * * * * *
export const SET_OFFICE = "SET_OFFICE"

// * * * * * * * * *
// CV
// * * * * * * * * *
export const ADD_CV = "ADD_CV"
export const ADD_CVS = "ADD_CVS"
export const ADD_CV_MATCH = "ADD_CV_MATCH"

// * * * * * * * * *
// JOB
// * * * * * * * * *
export const ADD_JOB = "ADD_JOB"
export const ADD_EXAMPLES_JOB = "ADD_EXAMPLES_JOB"
export const ADD_JOBS = "ADD_JOBS"
export const ADD_JOB_MATCH = "ADD_JOB_MATCH"
export const ADD_JOBS_PARSED = "ADD_JOBS_PARSED"
export const REMOVE_JOB_PARSED = "REMOVE_JOB_PARSED"
export const SHOW_JOB_PARSED = "SHOW_JOB_PARSED"
export const REMOVE_SHOWN_JOB = "REMOVE_SHOWN_JOB"

// * * * * * * * * *
// OFFICE
// * * * * * * * * *
