export default {
  TITLE: "Publication",
  TITLES: "Publications",
  TITLE_COUNT: "No publication | {n} publication | {n} publications",
  ICON: "mdi-newspaper-variant",
  ICONS: "mdi-newspaper-variant-multiple",

  STATUS_TYPE_ITEMS: [
    { text: "Show: errors", value: "error" },
    { text: "Show: infos", value: "info" },
    { text: "Show: published", value: "success" },
    { text: "Show: unpublished", value: "finished" },
  ],
  NO_PUBLICATION: "No publication",
  RELATED_JOB: "Access the offer",

  REDIRECTION: "Redirection",
  REDIRECTION_ICON: "mdi-arrow-right-top",

  URL: "Publication Url | Publication Url | Publication Urls",
  URL_SETTING: "Publication Url Settings",
  URL_SETTING_BTN: "Url settings",

  REFRESH: "Refresh publication",
  STATUS: "Show: all",
  METHOD: "Pricing model",
  TYPE: "Content",
  TYPE_LABEL: "Type of content",
  SETTINGS: "Params",

  CAMPAIGN_NOT_STARTED: "Ready",
  JOB_NOT_STARTED: "Ready",
  UNPUBLISHED_REMOVED_ICON: "mdi-stop-circle",
  UNPUBLISHED_REMOVED: "Removed",
  UNPUBLISHED_COMPANY_REMOVED_ICON: "mdi-stop-circle",
  UNPUBLISHED_COMPANY_REMOVED: "Removed company",
  UNPUBLISHED_OFFICE_REMOVED_ICON: "mdi-stop-circle",
  UNPUBLISHED_OFFICE_REMOVED: "Removed office",
  UNPUBLISHED_CHANNEL_SUBSCRIPTION_REMOVED_ICON: "mdi-stop-circle",
  UNPUBLISHED_CHANNEL_SUBSCRIPTION_REMOVED: "Removed subscription",
  UNPUBLISHED_CAMPAIGN_REMOVED_ICON: "mdi-stop-circle",
  UNPUBLISHED_CAMPAIGN_REMOVED: "Removed campaign",
  UNPUBLISHED_JOB_REMOVED_ICON: "mdi-stop-circle",
  UNPUBLISHED_JOB_REMOVED: "Removed job",
  UNPUBLISHED_CAMPAIGN_FINISHED_ICON: "mdi-stop-circle",
  UNPUBLISHED_CAMPAIGN_FINISHED: "Finished campaign",
  UNPUBLISHED_JOB_FINISHED_ICON: "mdi-stop-circle",
  UNPUBLISHED_JOB_FINISHED: "Finished job",
  NO_CONTRACT_ICON: "mdi-close-circle",
  NO_CONTRACT: "No contract",
  NOT_VALID_ICON: "mdi-close-circle",
  NOT_VALID: "Not complete",
  COMMITED_ICON: "mdi-arrow-up-bold-circle",
  COMMITED: "Commited",
  REPUBLISHED_ICON: "mdi-check-circle",
  REPUBLISHED: "Published",
  UPDATED_ICON: "mdi-check-circle",
  UPDATED: "Updated",
  AVOIDED_ICON: "mdi-arrow-down-drop-circle",
  AVOIDED: "Avoided",
  DISPATCH_FAILED_ICON: "mdi-stop-circle",
  DISPATCH_FAILED: "Error",
  DISPATCH_WARNING_ICON: "mdi-alert",
  DISPATCH_WARNING: "Warning",
  TTL_WARNING_ICON: "mdi-alert",
  TTL_WARNING: "Dépublication prévue dans ",
  PUBLISHED_ICON: "mdi-check-circle",
  PUBLISHED: "Published",
  UNPUBLISHED_ICON: "mdi-stop-circle",
  UNPUBLISHED: "Unpublished",
  REFRESH_WAIT_ICON: "mdi-refresh-circle",
  REFRESH_WAIT: "Waiting",

  TYPE_OFFER: "Job offer",
  TYPE_OFFER_ICON: "mdi-briefcase",
  TYPE_TEXT: "Text",
  TYPE_TEXT_ICON: "mdi-card-text",
  TYPE_IMAGE: "Image & Text",
  TYPE_IMAGE_ICON: "mdi-post",
  TYPE_CAROUSEL: "Carousel",
  TYPE_CAROUSEL_ICON: "mdi-view-carousel",
  TYPE_HTML: "Html Banner",
  TYPE_HTML_ICON: "mdi-image-area",
  TYPE_VIDEO: "Video",
  TYPE_VIDEO_ICON: "mdi-video",
  TYPE_AUDIO: "Audio",
  TYPE_AUDIO_ICON: "mdi-music",

  METHOD_CPA: "CPA",
  METHOD_CPC: "CPC",
  METHOD_CPCT: "CPCT",
  METHOD_CPM: "CPM",
  METHOD_CREDIT: "Credits",
  METHOD_ORGANIC: "Organic",

  FORM_TITLE_UPDATE: "Edit publication",
  FORM_TITLE_CREATE: "Create publication",
  FORM_TITLE_NEW: "Enter information for new publication",
  FIELD_PRICING_MODEL: "Pricing model",
  FIELD_METHOD: "Methods",
  FIELD_MODE: "Mode: Test",
  FIELD_PRICING_MODEL_ERROR: "You must select a pricing model.",

  METHOD_ITEMS: [
    { text: "CPA", value: "cpa" },
    { text: "CPC", value: "cpc" },
    { text: "CPC Imposé", value: "cpct" },
    { text: "CPM", value: "cpm" },
    { text: "Credits", value: "credit" },
    { text: "Organic", value: "organic" },
  ],

  TYPE_ITEMS: [
    { text: "Offer", value: "offer" },
    { text: "Image", value: "image" },
    { text: "Audio", value: "audio" },
    { text: "Video", value: "video" },
    { text: "Html banner", value: "html" },
    { text: "Text", value: "text" },
  ],
}
