export default {
  TITLE: "Notification",
  TITLES: "Notifications",
  TITLE_COUNT: "Aucune notification | 1 notification | {n} notifications",
  ICON: "mdi-bell-outline",

  SCOPE_FEED: 'Flux',
  SCOPE_JOB: 'Offre',
  SCOPE_CAMPAIGN: 'Campagne',
  SCOPE_CHANNEL_SUBSCRIPTION: 'Média',

  FEED_FIRST_REFRESH_SUCCESS: "Le flux {_1} a été initialisé avec succès.",
  FEED_REFRESH_SUCCESS: "Le flux {_1} a été mis à jour avec succès.",
  FEED_REFRESH_ERROR: "Le flux {_1} a rencontré un problème. {_2}",
  FEED_REFRESH_REPORT: "Mise à jour de vos offres depuis le flux {_1} réussie: {_2} créée(s), {_3} modifiée(s), {_4} supprimée(s), {_5} erreur(s).",

  CAMPAIGN_PUBLICATION_ERROR: "{_1} publications ont des erreurs.",
  CAMPAIGN_PUBLICATION_PUBLISHED: "{_1} publications sont publiées.",
  CAMPAIGN_PUBLISH_SUCCESS: "Mise à jour de la campagne {_1}: {_2} publications publiée(s), {_3} erreur(s).",

  VIEW_ALL: "Marquer comme vu",
  ALREADY_VIEW: "Précedentes notifications",
  NO_NEW_NOTIFICATION: "Pas de nouvelle notification",
  RECENTLY_VIEW: "Vu dernièrement",

  JOB_PARSED_SUCCESS: "Toutes les offres ont été analysé avec succès.",

  CAMPAIGN_FEED_REFRESH_SUCCESS: "Les offres de votre campagne ont été mises à jour: {_1} ajoutée(s), {_2} supprimée(s).",

  CAMPAIGN_PUBLICATION_CREATE_SUCCESS: "Les publications ont été crée automatiquement par les règles de campagne: {_1} créée(s)",
  CAMPAIGN_PUBLICATION_CREATE_WARNING: "Les publications ont été crée automatiquement par les règles de campagne, mais il y a des erreurs: {_1} créée(s), {_2} erreur(s)",
  CAMPAIGN_PUBLICATION_CREATE_ERROR: "Les publications n'ont pas été crée: {_2} erreur(s).",

  CHANNEL_SUBSCRIPTION_CONTRACT_ERROR: "Le contrat est arrivé à terme.",

  LARGE_FILE: "Le fichier est trop grand. Répétez s'il vous plaît un autre fichier.",
  FORMAT_NOT_SUPPORTED: "Format non pris en charge. Utilisez le format ci-dessus",
  UPLOAD_ERROR: "Désolé, le téléchargement a échoué. Réessayer !",
  NEED_SELECT: "Veuillez sélectionner un document !",
  MANY_FILES: "Le nombre de fichiers téléchargés est limité à 10. Essayez d'en télécharger moins."
}
