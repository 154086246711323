/**
 * Check if two arrays are strictly the same
 * @param {Array} a  the first array
 * @param {Array} b  the second array
 * @returns {Boolean} true if a is strictly the same as b, false otherwise
 */
const _arraysAreEquals = (a, b) => {
  if (a.length === b.length) {
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) {
        return false
      }
    }
    return true
  }
  return false
}

/**
 * check if two objects are strictly the same
 * @param {Object} a the first object
 * @param {Object} b the second object
 * @returns {Boolean} true if a is strictly the same as b, false otherwise
 */
const _objectsAreEquals = (a, b) => {
  // depp check on keys and values
  const aProps = Object.getOwnPropertyNames(a)
  const bProps = Object.getOwnPropertyNames(b)

  if (aProps.length !== bProps.length) {
    return false
  }

  let propName = null

  for (let i = 0; i < aProps.length; i++) {
    propName = aProps[i]

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false
    }
  }
  return true
}

/**
 * Check if two things are strictly equal
 * if the params are two arrays or two objects, the values of each param are checked
 * @param {Any} a the first thing to compare
 * @param {Any} b the second thing to compare
 * @returns {Boolean} true if a is strictly the same as b, false otherwise
 */
export default (a, b) => {
  // simple comparison
  if (a === b) {
    return true
  }

  // type comparison
  const aType = Object.prototype.toString.call(a)
  const bType = Object.prototype.toString.call(b)

  if (aType === bType) {
    // deep check on values
    if (Array.isArray(a)) {
      return _arraysAreEquals(a, b)
    } else if (aType === '[object Object]') {
      return _objectsAreEquals(a, b)
    }
  }
  return false
}
