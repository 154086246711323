export default {
  SHOW_PUBLIC: "Afficher au public (Landing page)",
  SHOW_PUBLIC_INFO:
    "Afficher public permet aux utilisateurs d'accéder à la page de destination et à l'API Multidiff",
  TO_LANDING: "Aller à la page d'accueil",
  SAMPLE: "échantillon",
  TOTAL: "Total",
  BASELINE: "L’innovation au service du recrutement & du capital humain",
  XTRAMILE: "Xtramile",
  ITEMS: "Aucun élement | {count} élement | {count} élements",
  SELECTED: "Aucune sélection | 1 sélection | {n} sélections",
  REMOVE: "Supprimer",
  SELECT_ALL_PAGES: "Selectionner tout",
  PUBLISH: "Publier",
  BACK: "Retour",
  EDIT: "Modifier",
  EDIT_COMPANY: "Modifier l'entreprise",
  NEW: "Nouveau",
  ADD: "Ajouter",
  ADD_COMPANY: "Ajouter une campagne",
  ADD_JOB: "Ajouter une offre",
  ADD_CVS: "Ajouter un cv",
  ADD_MEDIA: "Ajouter un média",
  ADD_FEED: "Ajouter un flux",
  CREATE: "Créer",
  CREATED: "Créé",
  UPDATED: "Mis à jour",
  TEST: "Tester",
  START: "Début",
  END: "Fin",
  NO_LIMIT_TIME: "Pas de limite de temps",
  FILTERS: "Filtres",
  FILTERS_COUNT: "Aucun filtre | 1 filtre | {n} filtres",
  SORT_BY: "Trier par",
  SORT_BY_ALPHABETICAL_ASCENDANT: "Alphabetique croissant",
  SORT_BY_ALPHABETICAL_DESCENDANT: "Alphabetique décroissant",
  SORT_BY_DATE_ASCENDANT: "Plus récent au plus ancien",
  SORT_BY_DATE_DESCENDANT: "Plus ancien au plus récent",
  SORT_BY_LAST_UPDATED_ASCENDANT: "Dernières modifications",
  SORT_BY_LAST_UPDATED_DESCENDANT: "Premières modifications",
  APPLY: "Appliquer",
  RESET: "Réinitialiser",
  SEE: "Voir",
  IMPORT: "Importer",
  IMPORT_ICON: "mdi-application-import",
  SEARCH: "Rechercher",
  CANCEL: "Annuler",
  ADMIN: "Administration",
  CONFIGURE: "Configurer",
  OPTIONNAL: "Optionnel",
  GENERAL: "General",
  PROFILE: "Profil",
  FIELDS_MAPPING: "my.field.name",
  SAMPLES: "no sample | sample | samples",
  RESULTS: "aucun résultat | résultat | resultats",
  TRUE: "Oui",
  FALSE: "Non",
  BUDGET: "budget",
  BUDGET_ALLOCATED: "budget alloué",
  FREE: "Gratuit",
  FIXED: "Fixé",
  ESTIMATION: "Estimation",
  DAY: "jour | jour | jours",
  LOGOUT: "Se déconnecter",
  PASS: "Passer",
  ARCHIVE: "Archiver",
  DISARCHIVE: "Réactiver",
  REQUIRED: "Obligatoire",
  PRICE: "Prix",
  DUPLICATE: "Dupliquer",
  DUPLICATE_ICON: "mdi-content-duplicate",
  TOTAL_DUPLICATION:
    "Aucun duplicat | Total: 1 duplicat | Total: {n} duplicats",
  NUMBER_DUPLICATION: "Nombre de duplicats",
  NUMBER_DUPLICATION_HOW: "Dupliquer les offres par un nombre.",
  CITIES_DUPLICATION: "Dupliquer par ville",
  CITIES_DUPLICATION_HOW:
    "Dupliquer les offres et remplacer la ville pour chaque copie.",
  BY_CITIES: "Par ville",
  BY_NUMBER: "Par nombre",
  EXPAND: "Cliquer pour déployer",
  ACTIONS: "actions",
  OR: "ou",
  SEE_ALL: "Voir tout",
  MODIFY: "Modifier",
  NO_MESSAGE: "Aucun message",
  NEXT_REFRESH: "Prochaine mise à jour: {date}",
  MORE_ACTIONS: "Plus d'actions...",
  REFRESH_LIST: "Recharger la liste",
  LAST_UPDATE: "Dernière modification",
  SWITCH_HTML_OFF: "Modifier le contenu",
  SWITCH_HTML_ON: "Afficher HTML",
  SELECT_ALL: "Tous/Aucun",
  RELOAD: "Recharger",
  PAUSE: "Pause",
  CREDITS: "crédits",
  LOCALE_CURRENCY_SIGN: "€",
  CONTINUE: "Continuer",
  NEXT: "Suivant",
  PREVIOUS: "Précédent",
  SAVE: "Enregistrer",
  CONFIRM_MULTIPLE_ITEMS:
    "Êtes-vous sûr de vouloir appliquer ces modifications sur {n} élements ?",
  CONFIRM: "Confirmer",
  ALL: "Global",
  HELP: "Aide",
  CLOSE: "Fermer",
  STATUS_ITEMS: [
    { value: 1, text: "Activé" },
    { value: 0, text: "Archivé" },
    { value: -1, text: "Supprimé" },
  ],
  SEPARATE_JOB_FEED: "Détacher l'offre",
  KEY: "Clé",
  VALUE: "Valeur",
  LIST_PRODUCTS: "Liste de produits",
  LIST_SESSIONS: "Historique des sessions",
  NOT_AVAILABLE: "Pas disponible pour le moment",
  MISSING_DATA: "Il manque des données actuelles à la tâche analysée",
  NO_DATA: "Pas de données disponibles",
  HR_TRADING_DESK: "RH Trading",
  MATCHING: "Correspondance",
  DOWNLOAD: "Télécharger",
  TITLES: {
    CHOOSE: "Choisissez",
    YOUR_CV: "votre CV",
    YOUR_JOB: "votre offre",
    OR: "ou",
    YOUR_CV_FILE: "votre fichier CV",
    YOUR_JOB_FILE: "votre fichier contenant l'offre",
  },
  LABELS: {
    TITLE: "Title",
    COMPANY: "Company",
    OFFICE: "Office",
    CREATED_AT: "Created at",
    SCORE: "Score",
    ACTIONS: "Actions",
    YEAR: "an",
    YEARS: "ans",
    MONTH: "mois",
    MONTHES: "mois",
    UPLOAD_TITLE_CV: "Choisissez un CV au format pdf",
    UPLOAD_TITLE_JOB: "Choisissez un Job au format pdf",
  },
  BUTTON: {
    BACK: "Retour",
    LIKE: "J'aime",
    DISLIKE: "Je n'aime pas",
    DETAILED_VIEW: "Vue détaillée",
  },
  TOOLTIP: {
    RATING: "Évaluation",
    DOWNLOAD: "Télécharger",
    MATCHING: "Correspondant à",
    DELETE: "Supprimer",
  },
  ANOTHER: {
    LANG: "fr",
  },
  OTHERS: "autre | autre | autres",
  RATE_PARSING_RESULT: "Évaluez le résultat de l'analyse:",
  NO_EXAMPLE: "pas d'exemple",
  NOT_FOUND: {
    LOCATION: "Emplacement introuvable",
  },
  DISABLE: "Désactiver",
  ENABLE: "Activer",
};
