// init script is kinda long so I put it out
import * as types from '@/store/mutations.types'
import services from '@/plugins/services'
import vueI18n from '@/plugins/vueI18n'
import moment from '@/plugins/moment'
import router from '@/router'

let screenSizeSingleton = false
let connectInstance = false
let timeoutRefresh = null
let initTimeout = null

// mutations lo listen to
const _mutations = new Set([types.APP_RESTORE_MUTATION, types.APP_CONNECTED])
// mutation namespace
const getMutationNamespace = (mutationType) =>
  mutationType.includes('/') ? mutationType.split('/')[1] : mutationType

export default {

  // destroy
  destroy({ commit }) {
    // save music time
    commit(types.APP_SETTINGS, {})
  },

  // init app
  init ({ commit, dispatch }) {

    let isRestored = false, isConnected = false

    // dynamic mutations
    const mutationsHandler = {
      // restore store states from localstorage
      [types.APP_RESTORE_MUTATION]: (/*payload*/) => {
        isRestored = true
      },
      // ensure the app is connected to internet
      [types.APP_CONNECTED]: (payload) => {
        isConnected = payload
      }
    }

    // init screenSize
    dispatch('screenSize')

    // load assets
    dispatch('assets')

    // start server connection check
    dispatch('connect')

    // when a mutation is called
    this.subscribe((mutation/*, state*/) => {

      // get the mutation without the namespace
      const type = getMutationNamespace(mutation.type)

      // if a type is triggered
      if (_mutations.has(type)) {

        // be sure we wont init twice
        clearTimeout(initTimeout)

        // do action attached to type
        mutationsHandler[type](mutation.payload)

        // and re-check if app is ready
        if (isRestored && isConnected) {

          // reload current page
          dispatch('refresh')
            .then(() => {
              console.info('App: ready, connected, store restored.')
              commit(types.APP_READY, true)
            })
            .catch((e) => {
              console.error('App: init has failed,', e)
              commit(types.APP_READY, false)
              // try to re-init 2s later
              initTimeout = setTimeout(() => {
                dispatch('refresh')
              }, 2000)
            })

        // or app is not ready yet
        } else {
          commit(types.APP_READY, false)
        }
      }

    })
  },

  // clear saved data in local storage
  clearStorage () {
    // get the localforage instances to clear
    const instances = Object.keys(this.storage.instances)
    this.storage.empty(instances)
  },

  // load assets
  assets ({commit}) {

  },

  // ensure app is connected to server
  connect ({commit, dispatch}) {

    // TODO: to change later when socket are on
    setTimeout(() => {
      commit(types.APP_CONNECTED, true)
    }, 100)

    // if (!connectInstance) {
    //   connectInstance = true
    //   api.set('baseURL', baseURL)
    //   commit(types.APP_BASE_URL, baseURL)
    //   api.socket.on('connect', () => {
    //     commit(types.APP_CONNECTED, true)
    //   })
    //   api.socket.on('disconnect', () => {
    //     commit(types.APP_CONNECTED, false)
    //   })
    //   api.socket.on('connect_error', (error) => {
    //     console.error('Socket: connect_error,', error)
    //   })
    //   api.socket.on('refresh', () => {
    //     commit(types.APP_WILL_REFRESH, true)
    //     // delay refresh randomly on 2min to avoid server bottleneck
    //     if (timeoutRefresh) clearTimeout(timeoutRefresh)
    //     timeoutRefresh = setTimeout(() => {
    //       dispatch('refresh')
    //       commit(types.APP_WILL_REFRESH, false)
    //     }, Math.round(Math.random() * 120000))
    //   })
    // }

  },


  // get and prepare all data
  refresh ({commit, dispatch, state}) {
    commit(types.APP_REFRESH, true)

    // apply settings
    dispatch('settings')



    return Promise.all([

      // check user session
      this.dispatch('user/check'),

    ])
      .then(() => {
        console.info('App: resfreshed.')
      })
      .finally(() => {
        // change locale in moment first
        moment.locale(vueI18n.locale)
        commit(types.APP_REFRESH, false)
      })

  },


  screenSize ({commit}) {
    if (!screenSizeSingleton) {
      screenSizeSingleton = true
      window.addEventListener('resize', () => {
        commit(types.APP_SCREENSIZE, { width: window.innerWidth, height: window.innerHeight })
      })
    }
    return commit(types.APP_SCREENSIZE, { width: window.innerWidth, height: window.innerHeight })
  },


  settings ({commit, state}, data) {
    if (data) commit(types.APP_SETTINGS, data)
  },


  page ({commit, state}, data) {
    if (state.page !== data) {
      return commit(types.APP_PAGE, data)
    }
  },


}
