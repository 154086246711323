<template>
  <v-overlay
    class="overlay-loading"
    absolute
    v-model="value"
    :color="color"
    opacity="0.5"
    :z-index="zIndex"
    full-width
    full-height
  >
    <div class="text-center text--accent">
      <v-progress-circular
        indeterminate
        size="32"
        width="4"
        color="accent"
      />
      <div v-if="title"><small>{{ title }}</small></div>
    </div>
  </v-overlay>
</template>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';

// .v-overlay--active.overlay-loading {
// }

.overlay-loading .v-overlay__scrim {
  border-radius: none;
}

// .overlay-loading + .overlay-loading,
// .overlay-loading + * .overlay-loading {
//   display: none;
//   backdrop-filter: none;
// }

</style>

<script>
export default {
  name: 'DialogOverlayLoading',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'white'
    },
    zIndex: {
      type: [String, Number],
      default: 5
    },
  }
}
</script>
