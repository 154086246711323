export default {
  TITLE: "Rôle",
  TITLES: "Rôles",
  TITLE_COUNT: "Aucun rôle | 1 rôle | {n} rôles",
  ICON: "mdi-medal",
  INFO: "Informations",
  FORM_TITLE_UPDATE: "Modifier un rôle",
  FORM_TITLE_NEW: "Nouveau rôle",
  FORM_TITLE_CREATE: "Créer un rôle",
  RESET: "Annuler les modifications",
  SAVE: "Enregistrer",
  REMOVE: "Supprimer",
  FIELD_TITLE: "Titre",
  FIELD_TITLE_PLACEHOLDER: "manager",
  FIELD_LEVEL: "Niveau",
  FIELD_LEVEL_PLACEHOLDER: "1",
}