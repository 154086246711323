export default {
  TITLE: "Permission",
  TITLES: "Permissions",
  TITLE_COUNT: "permission | 1 permission | {n} permissions",
  ICON: "mdi-key-chain-variant",
  DEFAULT_PERMISSION:
    "default permission | default permissions | default permissions",
  TIME_USAGE: "Usage time",
  LIMIT_USAGE: "Limitation of use",
  FORM_TITLE_UPDATE: "Modify permission",
  FORM_TITLE_NEW: "New permission",
  FORM_TITLE_CREATE: "Create permission",
}