/* * * * * * * * * * * * * * *
 *  Local storage
 *
 *  Usage:
 *    storage.get('USER.TITLES', key).then(...)
 *    storage.update('user', { key:value, key2:value2 }).then(...)
 *    storage.empty('user').then(...)
 *    storage.drop('user').then(...)
 *
 */

import localforage from 'localforage'
import isEqual from './isEqual'

// avoid storage overlaping
const uid = JSON.stringify(process.env || {a:1}).split('').map(v => v.charCodeAt()).reduce((a,b) => a+b)

localforage.config({
  driver: [localforage.INDEXEDDB, localforage.WEBSQL, localforage.LOCALSTORAGE],
  name: 'xtramile' + uid,
  version: 1.0,
  size: 5 * 1024 * 1024,
  storeName: 'xtramile',
  description: 'xtramile data store'
})

// instance
const storageInstances = {}

// default data
const defaultData = {}

// ready
const ready = type => {
  return storageInstances[type].ready()
  // .catch(e => {
  //   console.warn('localStorage.get() -', e)
  // })
}

// clear everything
const clear = () => {
  const promised = []
  Object.keys(storageInstances).forEach(type => {
    promised.push(
      storageInstances[type].clear()
      // .then(() => {
      //   console.warn('localStorage.clear() -', type, 'data has been cleared.')
      // })
    )
  })
  return Promise.all(promised)
}

// empty all states in <type> instance
const empty = (...types) => {
  const promised = []

  for (const type of types) {
    if (defaultData[type]) {
      Object.keys(defaultData[type]).forEach(key => {
        promised.push(storageInstances[type].setItem(key, defaultData[type][key]))
      })
    }
  }

  return Promise.all(promised)
  // .catch(e => {
  //   console.warn('localStorage.empty() -', e)
  // })
}

// get all in <type>
const getAll = type => {
  if (storageInstances[type]) {
    const promised = []
    const keys = Object.keys(storageInstances[type])
    keys.forEach(key => {
      promised.push(get(type, key))
    })

    return Promise.all(promised)
      .then(results => {
        const obj = {}
        keys.forEach((key, index) => {
          obj[key] = results[index]
        })
        return obj
      })
      // .catch(e => {
      //   console.warn('localStorage.getAll() -', e)
      // })
  }
}

// get <key> states in <type> instance
const get = (type, key) => {
  if (storageInstances[type]) {
    if (!key) {
      return getAll(type)
    } else if (Object.prototype.hasOwnProperty.call(defaultData[type], key)) {
      return storageInstances[type].getItem(key)
    }
    return Promise.reject(`${key  } is not defined as default data for ${  type  }.`)
  }
  return Promise.reject(`${type  } instance does not exist.`)
}

// set all
const setAll = (type, values) => {
  const promised = []
  Object.keys(values).forEach(keys => {
    keys.forEach(key => {
      promised.push(set(type, key, values[key]))
    })
  })
  return Promise.all(promised)
  // .catch(e => {
  //   console.warn('localStorage.setAll() -', e)
  // })
}

// set <key>:<value> states in <type> instance
const set = (type, key, value) => {
  if (storageInstances[type]) {
    return new Promise((resolve/*, reject*/) => {
      // get the item
      storageInstances[type].getItem(key).then(item => {
        if (!isEqual(value, item)) {
          resolve(storageInstances[type].setItem(key, value))
        }
        // else {
        //   reject(`${key} is set to the same as it's previous value`)
        // }
      })
    })
  }
  return Promise.reject(`${type  } instance does not exist.`)
}

// create <type> instance
const create = (type, data = {}) => {
  const promised = []
  // if (!storageInstances[type]) {

  promised.push(localforage.createInstance({ name: type }))
  // }
  // else {
  //   promised.push(storageInstances[type].ready())
  // }
  return Promise.all(promised)
    .then(([instance]) => {
      storageInstances[type] = instance
      defaultData[type] = data
      return instance
    })
    // .catch(e => {
    //   console.warn('localStorage.create() -', e)
    // })
}

// drop <type> instance
const drop = type => {
  if (storageInstances[type]) {
    return localforage
      .dropInstance({ name: type })
      .then(() => {
        delete defaultData[type]
        delete storageInstances[type]
        /*
        console.warn(
          'localStorage.drop() -',
          type + ' instance is droped and not available anymore.'
        )
        */
      })
      // .catch(e => {
      //   console.warn('localStorage.drop() -', e)
      // })
  }
  return Promise.reject(`${type  } instance does not exist.`)

}

export default {
  instances: storageInstances,
  get: get,
  set: set,
  setAll: setAll,
  create: create,
  ready: ready,
  empty: empty,
  drop: drop,
  clear: clear
}
