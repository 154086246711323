import * as types from '@/store/mutations.types'

export default {

  [types.MESSENGER_ADD] (state, message) {
    state.messages.push(message)
  },

  [types.MESSENGER_REMOVE] (state, id) {
    const messages = []
    for (let message of state.messages) {
      if (id !== message.id) {
        messages.push(message)
      }
    }
    state.messages = messages
  }

}
