export default {
  env: process.env.NODE_ENV || 'development',
  baseURL: null,
  connected: false,
  restored: false,
  ready: false,
  refresh: false,
  willRefresh: false,
  page: null,
  screenSize: {width:0, height:0},
  settings: {},
}
