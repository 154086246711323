import Vue from 'vue'

const clone = (value) => {

  let obj = value
  let newObj

  if (typeof value !== 'object' || value === null) {
    return value
  } else if (obj instanceof Array) {
    newObj = []
  } else if (typeof obj === 'object') {
    newObj = {}
  }

  for (let k in obj) {
    let v = obj[k]
    if (obj instanceof Array) {
      newObj.push(clone(v))
    } else if (typeof obj === 'object') {
      newObj[k] = clone(v)
    }
  }
  return newObj
}


const vm = {
  install: function (Vue) {
    Vue.prototype.$clone = clone
  }
}

Vue.use(vm)

export default clone
