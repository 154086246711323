export default {
  TITLE: "Produit",
  TITLES: "Produits",
  TITLE_COUNT: "Aucun produi | 1 produi | {n} produis",
  WILL_FINISHED: "Se termine {date}",
  CANCELED: "Annulé",
  LIMIT_USAGE: "Limite d'utilisation",
  TIME_USAGE: "Temps d'utilisation",
  FORM_TITLE_UPDATE: "Modifier un produit utilisateur",
  FORM_TITLE_NEW: "Nouveau produit utilisateur",
  FORM_TITLE_CREATE: "Créer un produit utilisateur",
}