<template>
  <v-dialog
    persistent
    :value="value"
    :overlay-color="overlayColor"
    :width="width"
  >
    <div :class="dialogRate ? 'dialog-rate' : 'dialog'">
      <Loading :value="loading" />
      <v-lazy :value="value">
        <v-card>
          <div class="form-compact-header">
            <v-system-bar dark color="primary pa-4 pl-2" v-if="subtitle">
              <v-icon class="mr-2" size="20">{{icon}}</v-icon>
              <strong class="text-large text-ellipsis">{{subtitle}}</strong>
              <v-spacer/>
              <v-icon v-if="closable" small class="clickable" @click="$emit('close')">mdi-close</v-icon>
            </v-system-bar>

            <template v-if="title">
              <v-toolbar
                color="transparent"
                flat
                :height="toolbarHeight"
                :class="toolbarClass"
              >
                <v-toolbar-title class="primary--text">
                  {{ title }}
                </v-toolbar-title>
                <v-btn
                  v-if="dialogRate"
                  icon
                  dark
                  @click="$emit('closeDialog')"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-spacer v-if="!dialogRate" />
              </v-toolbar>
              <v-divider v-if="dialogRate" class="dialog-rate__devider ml-4 mr-4"></v-divider>
              <div v-if="!dialogRate" class="toolbar-separator primary ml-4 mr-4"/>
            </template>
          </div>


          <!-- <div class="secondary">
            <v-toolbar
              flat
              color="transparent"
              class="dialog-header"
              v-if="$slots.header || title || icon"
              height="90px"
            >
              <v-avatar class="mr-4" v-if="icon">
                <v-icon size="24">{{ icon }}</v-icon>
              </v-avatar>
              <v-toolbar-title>
                <div v-if="subtitle" class="v-toolbar__subtitle">
                  <span>{{ subtitle }}</span>
                </div>
                <strong class="text-h5 text-capitalize">{{ title }}</strong>
                <div v-if="items.length > 1" class="v-toolbar__subtitle muted">
                  <span>+ {{ $tc("COMMON.ITEMS", items.length - 1) }}</span>
                </div>
              </v-toolbar-title>
              <v-spacer />
              <template v-if="$slots.header">
                <slot name="header"/>
              </template>
              <template v-if="closable">
                <v-btn
                  icon
                  @click="$emit('close')"
                  large
                  class="ml-3 mr-0"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </template>
            </v-toolbar>
            <v-divider />
          </div> -->
          <div v-if="$slots.default || component" class="dialog-content">
            <component
              @close="close"
              @update="update"
              @select="select"
              @create="create"
              :is="componentAsync"
              :props="props"
              :items="items"
              v-if="componentAsync"
              @loading="handleLoading"
            />
            <slot v-if="$slots.default"/>
           </div>
        </v-card>
      </v-lazy>
    </div>
  </v-dialog>
</template>

<style lang="scss">
.dialog {
  position: relative;
}

.dialog-content {
  max-height: calc(90vh - 48px - 64px - 100px);
  overflow-y: auto;
  overflow-x: hidden;
}

.dialog .v-toolbar .v-avatar {
  border: solid 1px rgba(0, 0, 0, 0.36) !important;
}

.dialog-content .list {
  height: calc(100vh - 103px);
}
.dialog-content .list.filter {
  height: calc(100vh - 153px);
}
.dialog .v-toolbar .v-avatar {
  border: solid 1px rgba(0, 0, 0, 0.36) !important;
}

.dialog .back-btn {
  background: rgba(255, 255, 255, 0.08);
}
.dialog .v-toolbar__title .v-icon {
  vertical-align: text-bottom;
}

.dialog .v-toolbar__subtitle {
  font-size: 12px !important;
  line-height: 16px;
  text-transform: uppercase;
}

.dialog-rate {
  .v-toolbar__content {
    display: flex;
    justify-content: space-between;
    padding-top: 16px;
    padding-bottom: 16px;
  }

  .v-toolbar__title {
    font-size: 18px;
    font-weight: 700;
  }

 .theme--dark.v-btn.v-btn--icon {
    color: #201f35;
  }
}
</style>

<script>
import Loading from "@/components/Loading";
const _componentsCache = new Map();

export default {
  name: "Dialog",

  components: {
    Loading,
  },

  data: () => ({
    componentAsync: null,
    loading: false,
  }),

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    width: {
      type: String,
      default: "680px",
    },
    closable: {
      type: Boolean,
      default: true,
    },
    icon: {
      type: [String, Boolean],
      default: false,
    },
    subtitle: {
      type: [String, Boolean],
      default: false,
    },
    title: {
      type: [String, Boolean],
      default: false,
    },
    component: {
      type: [String, Object, Boolean],
      default: false,
    },
    props: {
      type: [Object, Boolean],
      default: false,
    },
    items: {
      type: [Array, Boolean],
      default: false,
    },
    overlayColor: {
      type: String,
      default: "primary",
    },
    dialogRate: {
      type: Boolean,
      default: false,
    }
  },

  mounted() {
    if (this.component) {
      this.loadComponent();
    }
  },

  computed: {
    toolbarHeight() {
      return this.dialogRate ? 60 : 66
    },

    toolbarClass() {
      return this.dialogRate ? 'dialog-rate__toolbar' : ''
    },
  },

  methods: {
    loadComponent() {
      const component = this.component;
      if (component) {
        if (!_componentsCache.has(component)) {
          import(`../${component}.vue`).then((component) => {
            _componentsCache.set(component, component.default);
            this.componentAsync = component.default;
          });
        } else {
          this.componentAsync = _componentsCache.get(component) || null;
        }
      }
    },

    handleLoading(v) {
      this.loading = !!v;
    },

    close(data) {
      this.$emit("close", data);
    },

    update(data) {
      this.$emit("update", data);
    },

    select(data) {
      this.$emit("select", data);
    },

    create(data) {
      this.$emit("create", data);
    },
  },
};
</script>
