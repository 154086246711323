export default {
  TITLE: "Canal",
  TITLES: "Canaux",
  TITLE_COUNT: "Aucun canal | 1 canal | {n} canaux",
  ICON: "mdi-newspaper-variant",

  ALL_CHANNELS: "Tous les canaux",
  CHANNEL_NO_PROTOCOL: "Aucun protocole",
  PARAMS: "Paramètres",
  CONTRACT: "Contrat",
  PRICING_MODEL: "Modèle de tarification",
  ACCOUNT_FIELDS: "Champs de paramètres de compte",
  PARAMS_FIELDS: "Champs de paramètres du canal",
  FIELD_CATEGORY: "Catégorie",
  INFORMATIONS: "Informations du channel (visible)",
  COUNTRY: "Pays compatibles",

  FORM_TITLE_CREATE: "Créer un canal",
  FORM_TITLE_NEW: "Nouveau canal",
  FORM_TITLE_UPDATE: "Modifier le canal",
  FIELD_TITLE_PLACEHOLDER: "Title",

  CATEGORY: {
    credit: "Crédit",
    aggregator: "Agrégateur",
    fixed_aggregator: "Agrégateur (Fixe)",
    display: "Display",
  },

  CATEGORY_ITEMS: [
    { value: "credit", text: "Crédit" },
    { value: "aggregator", text: "Agrégateur" },
    { value: "fixed_aggregator", text: "Agrégateur (Fixe)" },
    { value: "display", text: "Display" },
  ],
}