import * as types from "@/store/mutations.types"
import dataProcess from "@/data/dataProcess"
import transformationData from "@/utils/dataProcess/transformationData"

const {
  offerInformationsKey,
  offerSkillsKeys,
  offerExperienceKeys,
  offerEducationKeys,
  offerEscoKeys,
} = dataProcess

export default {
  [types.ADD_JOB](state, file) {
    const { plaintext: company } = file.company.length
      ? file.company[0]
      : { plaintext: "" }
    const { description } = file
    const { plaintext: titleHeader } =
      file.title ||
      (file.description.length > 0 && file.description[0].occupations.length > 0
        ? file.description[0].occupations[0]
        : { plaintext: "" })
    const { skills: skillsArr } = file.profile.length
      ? file.profile[0]
      : { skills: [] }
    const { experience } = file.profile.length
      ? file.profile[0]
      : { experience: [] }
    const { education } = file.profile.length
      ? file.profile[0]
      : { education: [] }
    const { esco, content } = file
    const requirements = {}
    const requirementsInfo = []
    const descriptionInfo = []
    const missionInfo = []
    const skillsInfo = []

    if (company) description[0].company = company

    description.forEach((i) => {
      const obj = transformationData(offerInformationsKey, i)
      const objTransform = {}
      for (let key in obj) {
        if (obj[key]) objTransform[key] = obj[key]
      }

      if (Object.keys(objTransform).length !== 0)
        descriptionInfo.push(objTransform)
    })

    description.forEach((i) => {
      const obj = transformationData(["missions"], i)
      if (obj["missions"].length) missionInfo.push(obj["missions"])
    })

    const skills = {}
    skillsArr.forEach((i) => {
      const obj = transformationData(offerSkillsKeys, i)

      for (let key in obj) {
        if (obj[key]) {
          skills[key].push(obj[key])
        }
      }
    })

    if (Object.keys(skills).length !== 0) skillsInfo.push(skills)

    experience.forEach((i) => {
      const obj = transformationData(offerExperienceKeys, i)
      for (let key in obj) {
        if (obj[key]) {
          switch (key) {
            case "level":
              requirements["experience_level"] = obj[key]
              return
            default:
              requirements[key] = obj[key]
              return
          }
        }
      }
    })

    education.forEach((i) => {
      const obj = transformationData(offerEducationKeys, i)
      for (let key in obj) {
        if (obj[key]) {
          switch (key) {
            case "level":
              requirements["education_level"] = obj[key]
              return
            default:
              requirements[key] = obj[key]
              return
          }
        }
      }
    })

    ;[esco].forEach((i) => {
      const obj = transformationData(offerEscoKeys, i)
      for (let key in obj) {
        if (obj[key]) requirements[key] = obj[key]
      }
    })

    requirementsInfo.push(requirements)

    state.job = {
      titleHeader,
      descriptionInfo,
      missionInfo,
      skillsInfo,
      requirementsInfo,
      content,
    }
  },

  [types.ADD_EXAMPLES_JOB](state, file) {
    const examplesModify = file.map((item) => {
      const objItem = {}
      for (let key in item) {
        if (!Array.isArray(item[key])) {
          objItem[key] = item[key]
        } else {
          const elems = item[key][0]

          for (let el in elems) {
            objItem[el] =
              elems[el][0].replace(/[\s+{2,}]+/g, "") !== "" ? elems[el][0] : ""
          }
        }
      }

      return objItem
    })

    state.examplesJob = [...examplesModify]
  },

  [types.ADD_JOBS](state, files) {
    state.jobs = files
  },

  [types.ADD_JOB_MATCH](state, file) {
    state.jobMatch = file.length
      ? file.map(
          ({
            id,
            applicant_id,
            score,
            matchingCriterias,
            source: { source_name, created_at },
          }) => ({
            id,
            applicant_id,
            score,
            source_name,
            created_at,
            matchingCriterias,
          })
        )
      : []
  },

  [types.ADD_JOBS_PARSED](state, data) {
    if (data.length === 0) {
      state.jobsParsed = []
      return
    }

    if (state.jobsParsed.length < 10) {
      state.jobsParsed.push(data)
      return
    }

    state.jobsParsed.shift()
    state.jobsParsed.push(data)
  },

  [types.REMOVE_JOB_PARSED](state, data) {
    state.jobsParsed.forEach((item, index) => {
      if (item.id === data.id) return state.jobsParsed.splice(index, 1)
    })
  },

  [types.SHOW_JOB_PARSED](state, data) {
    if (!data) {
      state.jobParsed = {}
      state.activeTabContent = {}
      return
    }

    const biTabFiealds = [
      "global_occupation",
      "cities",
      "experience_level",
      "bi_info",
    ]
    const sourceArr = ["xtramile1", "xtramile2", "xtramile3"]
    const tabContentObj = {}
    const { activeTab, job } = data
    let occupations, cities, experience

    function getOccupations(field) {
      return job.results[field]["type"].map(({ source, value }) =>
        sourceArr.includes(source) ? { [source]: value } : {}
      )
    }

    switch (activeTab) {
      case "bi":
        biTabFiealds.forEach((field) => {
          switch (field) {
            case "bi_info":
              tabContentObj[field] = job.results[field]["by_audience"]
              break
            case "global_occupation":
              occupations = getOccupations(field)
              occupations.forEach((el) => {
                tabContentObj[field] = { ...tabContentObj[field], ...el }
              })
              break
            case "cities":
              cities = job.results[field].length ? job.results[field][0] : {}
              tabContentObj[field] =
                Object.keys(cities).length !== 0
                  ? {
                      ["address"]: cities.plaintext,
                      ["state"]: cities.ref.state,
                      ["city"]: cities.ref.value,
                      ["postal_code"]: cities.ref.postal_code,
                    }
                  : {}
              break
            case "experience_level":
              experience = job.results[field].length
                ? job.results[field][0]
                : {}
              tabContentObj[field] =
                Object.keys(experience).length !== 0
                  ? {
                      ["experience_requise"]: experience.plaintext,
                    }
                  : {}
              break
          }
        })
        break
      default:
        break
    }

    state.jobParsed = job ? job : {}
    state.activeTabContent = tabContentObj
  },

  [types.REMOVE_SHOWN_JOB](state, data) {
    state.jobParsed = data
  },
}
