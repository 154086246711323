import actions from './actions'
import mutations from './mutations'
import state from './state'
import storage from './storage'
import getters from './getters'

export default {
  namespaced: true,
  state: state,
  getters: getters,
  mutations: mutations,
  storage: storage,
  actions: actions
}
