<template>
  <v-list-group id="ximpact" class="ximpact white--text" v-if="productXBI">
    <template v-slot:activator>
      <v-list-item-title id="ximpact__item-title" class="ximpact__item-title d-flex accent--text">
        <strong>
          <h3 id="ximpact__title" class="ximpact__title menu-main-ximpact__main-title">
            X
            <span
              id="menu-main-ximpact__main-subtitle"
              class="menu-main-ximpact__main-subtitle white--text"
            >
              {{ $t('IMPACT.TITLES.MAIN') }}
            </span>
          </h3>
        </strong>
      </v-list-item-title>
    </template>

    <v-list-item id="ximpact_xparsing_jobs" class="ximpact_xparsing_jobs pl-6" to="/ximpact/xparsing/jobs">
      <v-list-item-icon id="ximpact_xparsing_jobs__iten-icon" class="ximpact_xparsing_jobs__iten-icon">
        <v-icon id="ximpact_xparsing_jobs__icon" class="ximpact_xparsing_jobs__icon" color="accent" size="20">
          {{ $t('IMPACT.ICONS.PARSING_JOBS') }} 
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content id="ximpact_xparsing_jobs__item-content" class="ximpact_xparsing_jobs__item-content">
        <v-list-item-title id="ximpact_xparsing_jobs__item-title" class="ximpact_xparsing_jobs__item-title">
          <strong>{{ $t('IMPACT.TITLES.PARSING_JOBS') }}</strong>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item id="ximpact_power-bi-page" class="ximpact_power-bi-page pl-6" to="/ximpact/power-bi-page">
      <v-list-item-icon id="ximpact_power-bi-page__item-icon" class="ximpact_power-bi-page__item-icon">
        <v-icon id="ximpact_power-bi-page__icon" class="ximpact_power-bi-page__icon" color="accent" size="20">
          {{ $t('IMPACT.ICONS.IFRAME') }}
        </v-icon>
      </v-list-item-icon>
      <v-list-item-content id="ximpact_power-bi-page__item-content" class="ximpact_power-bi-page__item-content">
        <v-list-item-title id="ximpact_power-bi-page__item-title" class="ximpact_power-bi-page__item-title">
          <strong>{{ $t('IMPACT.TITLES.POWER_BI') }}</strong>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-group>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "MenuMainXBI",
  data: (_this) => ({
    productXBI: false,
    language: "en",
  }),
  async created () {
    this.productXBI = !!(await this.$permission.check('product.xbi'))
  },
  computed: {
    opened () {
      return this.page.includes('/xbi')
    },
    ...mapState("app", {
      page: (state) => {
        return state.page
      },
    }),
  },
}
</script>

<style lang="scss">
.menu-main-ximpact__main-title {
  white-space: nowrap;
  flex-wrap: nowrap;
  display: flex;  
}

.menu-main-ximpact__main-subtitle {
  white-space: break-spaces;
}
</style>
