import * as types from '@/store/mutations.types'

export default {
  addJob ({commit}, data) {
    return commit(types.ADD_JOB, data)
  },

  addExamplesJob({commit}, data) {
    return commit(types.ADD_EXAMPLES_JOB, data)
  },

  addJobs({ commit }, data) {
    return commit(types.ADD_JOBS, data)
  },

  addJobMatch({ commit }, data) {
    return commit(types.ADD_JOB_MATCH, data)
  },

  addJobsParsed({ commit }, data) {
    return commit(types.ADD_JOBS_PARSED, data)
  },

  removeJobParsedResult({ commit }, data) {
    return commit(types.REMOVE_JOB_PARSED, data)
  },

  showJobParsedResult({ commit }, data) {
    return commit(types.SHOW_JOB_PARSED, data)
  },

  removeShownJob({ commit }, data) {
    return commit(types.REMOVE_SHOWN_JOB, data)
  },
}
