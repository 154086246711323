export default {
  DEFAULT_MESSAGE: "Unexpected error.",
  SERVICE_REQUEST_ERROR: "Request has failed.",
  SERVICE_REQUEST_TOO_LARGE: "File is too large.",
  GA4_IMPORT: "New application has been found on google !",

  USER_SESSION: {
    EXPIRED: "Session has expired.",
    CHECK_ERROR: "Session is not valid",
    LOGIN: "User is now connected",
    LOGIN_ERROR: "Email or password is not valid, or user does not exist.",
    SWITCH: "Account switching.",
  },
  JOB: {
    REFRESH: "Job parsing succeed.",
    REFRESH_ERROR: "Job parsing failed.",
    CREATE: "New offer has been created.",
    CREATE_ERROR: "Offer creation failed.",
    UPDATE: "Offer has been updated.",
    UPDATE_ERROR: "Offer update failed.",
    REMOVE: "Remove job.",
    REMOVE_ERROR: "Remove failed.",
  },
  CAMPAIGN: {
    REFRESH: "Refresh succeed.",
    REFRESH_ERROR: "Refresh failed.",
    CREATE: "New campaign has been created.",
    CREATE_ERROR: "Campaign creation failed.",
    UPDATE: "Campaign update succeed.",
    UPDATE_ERROR: "Campaign update failed.",
    REMOVE: "Remove campaign.",
    REMOVE_ERROR: "Remove failed.",
  },
  CAMPAIGN_JOB: {
    CREATE: "Offre importée dans la campagne.",
    CREATE_ERROR: "Echec de l'import.",
    REMOVE: "Offre supprimée de la campagne.",
    REMOVE_ERROR: "Echec de la suppression.",
  },
  CAMPAIGN_FEED: {
    CREATE: "Flux importé dans la campagne.",
    CREATE_ERROR: "Echec de l'import.",
    REMOVE: "Flux supprimé de la campagne.",
    REMOVE_ERROR: "Echec de la suppression.",
  },
  FEED: {
    UPLOAD: "File upload succeed.",
    UPLOAD_ERROR: "File upload failed.",
    REFRESH: "Data retrieval succeed.",
    REFRESH_ERROR: "Data retrieval failed.",
    REFRESH_FINISHED: "Feed refresh finished",
    CREATE: "New feed has been created.",
    CREATE_ERROR: "Feed creation failed.",
    UPDATE: "Feed update succeed.",
    UPDATE_ERROR: "Feed update succeed.",
    REMOVE: "Remove feed.",
    REMOVE_ERROR: "Remove failed.",
  },
  CHANNEL_SUBSCRIPTION: {
    CREATE: "New media has been created.",
    CREATE_ERROR: "Media creation failed.",
    UPDATE: "Media update succeed.",
    UPDATE_ERROR: "Media update failed.",
    REMOVE: "Remove media.",
    REMOVE_ERROR: "Remove failed.",
  },
  CHANNEL_SUBSCRIPTION_CONTRACT: {
    CREATE: "New contract has been created.",
    CREATE_ERROR: "Contract creation failed.",
    UPDATE: "Contract update succeed.",
    UPDATE_ERROR: "Contract update failed.",
    REMOVE: "Remove contract.",
    REMOVE_ERROR: "Remove failed.",
  },
  USER: {
    CREATE: "New user has been created.",
    CREATE_ERROR: "User creation failed.",
    UPDATE: "User update succeed.",
    UPDATE_ERROR: "User update failed.",
    REMOVE: "Remove user.",
    REMOVE_ERROR: "Remove failed.",
  },
  COMPANY: {
    CREATE: "New company has been created.",
    CREATE_ERROR: "Company creation failed.",
    UPDATE: "Company update succeed.",
    UPDATE_ERROR: "Company update failed.",
    REMOVE: "Remove company.",
    REMOVE_ERROR: "Remove failed.",
  },
  OFFICE: {
    CREATE: "New office has been created.",
    CREATE_ERROR: "Office creation failed.",
    UPDATE: "Office update succeed.",
    UPDATE_ERROR: "Office update failed.",
  },
  CHANNEL: {
    CREATE: "New channel has been created.",
    CREATE_ERROR: "Channel creation failed.",
    UPDATE: "Channel update succeed.",
    UPDATE_ERROR: "Channel update failed.",
    REMOVE: "Remove channel.",
    REMOVE_ERROR: "Remove failed.",
  },
  XRANKING: {
    ORGANIZATION_ERROR: "Request has failed for the organization",
    MISSING_OFFICE_ORG_MAPPING:
      "XRanking has not been configured for this office.",
    SERVER_ERROR: "Server Error",
    FILE_PARSE_ERROR: "Error parsing the uploaded file!",
    ADD_RATE_SUCCESSFULLY: "Rating added successfully",
    DELETE_CV_SUCCESSFULLY: "CV deleted successfully",
    ERROR_PARSING: "Error while parsing, ask a customer success for help",
    DELETE_JOB_SUCCESSFULLY: "Job deleted successfully",
    UNSUPPORTED_SIZE: "File size should be less than 30 MB!",
  },
  CREATIVE: {
    CREATE: "New creative has been created.",
    CREATE_ERROR: "Creative creation failed.",
    UPDATE: "Creative update succeed.",
    UPDATE_ERROR: "Creative update failed.",
    REMOVE: "Remove creative.",
    REMOVE_ERROR: "Remove failed.",
    UNSUPPORTED_FORMAT: "Format not supported, only pdf format is accepted",
    UNSUPPORTED_SIZE: "File size should be less than 30 MB!",
  },
  XPARSING: {
    PARSING_JOB_SUCCESSFULLY: "Job parsed successfully",
  },
};
