import ACCOUNT from './en/account'
import APPLICATION_EMAIL from './en/application_email'
import AUDIENCE from './en/audience'
import CAMPAIGN_FEED from './en/campaign_feed'
import CAMPAIGN_JOB from './en/campaign_job'
import CAMPAIGN_RULE from './en/campaign_rule'
import CAMPAIGN from './en/campaign'
import CANDIDATE from './en/candidate'
import CHANNEL_SUBSCRIPTION_CONTRACT from './en/channel_subscription_contract'
import CHANNEL_SUBSCRIPTION from './en/channel_subscription'
import CHANNEL from './en/channel'
import CHART from './en/chart'
import COMMON from './en/common'
import COMPANY from './en/company'
import COUNTRY from './en/country'
import CREATIVE from './en/creative'
import CURRENCY from './en/currency'
import CV from './en/cv'
import CV_PARSING from './en/cv_parsing'
import DETAILS from './en/details'
import DIALOG from './en/dialog'
import FEED from './en/feed'
import FORM from './en/form'
import HEADER from './en/header'
import HOME from './en/home'
import HOOKS from './en/hooks'
import IMPACT from './en/impact'
import INPUT from './en/input'
import INSIGHT from './en/insight'
import JOB from './en/job'
import LANG from './en/lang'
import LIMITATION from './en/limitation'
import LIST from './en/list'
import LOGIN from './en/login'
import LOGOUT from './en/logout'
import MESSAGE from './en/message'
import NOTIFICATION from './en/notification'
import OFFICE from './en/office'
import PRODUCT from './en/product'
import PUBLICATION_URL from './en/publication_url'
import PUBLICATION from './en/publication'
import RATIO from './en/ratio'
import ROLE from './en/role'
import RULE from './en/rule'
import SERVICE from './en/service'
import SETTINGS from './en/settings'
import STATUS_ITEMS from './en/status_items'
import SWITCH_ACCOUNT from './en/switch_account'
import UPLOAD from './en/upload'
import USER_PERMISSION from './en/user_permission'
import USER_PRODUCT from './en/user_product'
import USER from './en/user'
import LANDING_PAGE from './en/landing_page'

export default {
  // only in fallback language
  languages: {
    en: "English",
    fr: "French",
  },
  ACCOUNT,
  APPLICATION_EMAIL,
  AUDIENCE,
  CAMPAIGN_FEED,
  CAMPAIGN_JOB,
  CAMPAIGN_RULE,
  CAMPAIGN,
  CANDIDATE,
  CHANNEL_SUBSCRIPTION_CONTRACT,
  CHANNEL_SUBSCRIPTION,
  CHANNEL,
  CHART,
  COMMON,
  COMPANY,
  COUNTRY,
  CREATIVE,
  CURRENCY,
  CV,
  CV_PARSING,
  DETAILS,
  DIALOG,
  FEED,
  FORM,
  HEADER,
  HOME,
  HOOKS,
  IMPACT,
  INPUT,
  INSIGHT,
  JOB,
  LANG,
  LIMITATION,
  LIST,
  LOGIN,
  LOGOUT,
  MESSAGE,
  NOTIFICATION,
  OFFICE,
  PRODUCT,
  PUBLICATION_URL,
  PUBLICATION,
  RATIO,
  ROLE,
  RULE,
  SERVICE,
  SETTINGS,
  STATUS_ITEMS,
  SWITCH_ACCOUNT,
  UPLOAD,
  USER_PERMISSION,
  USER_PRODUCT,
  USER,
  LANDING_PAGE,
}
