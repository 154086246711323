export default {
  TITLE: "Publication",
  TITLES: "Publications",
  TITLE_COUNT: "Aucune publication | {n} publication | {n} publications",
  ICON: "mdi-newspaper-variant",
  ICONS: "mdi-newspaper-variant-multiple",

  STATUS: "Afficher: Tous",
  STATUS_TYPE_ITEMS: [
    { text: "Afficher: Erreurs", value: "error" },
    { text: "Afficher: Attentes", value: "info" },
    { text: "Afficher: Publiées", value: "success" },
    { text: "Afficher: Dépubliées", value: "finished" },
  ],
  NO_PUBLICATION: "Aucune publication",
  RELATED_JOB: "Accèder à l'offre",

  REDIRECTION: "Redirection",
  REDIRECTION_ICON: "mdi-arrow-right-top",

  URL: "Publication Url | Publication Url | Publication Urls",
  URL_SETTING: "Publication Url Settings",
  URL_SETTING_BTN: "Url settings",

  REFRESH: "Relancer la publication",
  METHOD: "Modèle de prix",
  TYPE: "Contenu",
  TYPE_LABEL: "Type de contenu",
  SETTINGS: "Paramètres",

  CAMPAIGN_NOT_STARTED: "Prêt",
  JOB_NOT_STARTED: "Prêt",
  UNPUBLISHED_REMOVED_ICON: "mdi-stop-circle",
  UNPUBLISHED_REMOVED: "Supprimé",
  UNPUBLISHED_COMPANY_REMOVED_ICON: "mdi-stop-circle",
  UNPUBLISHED_COMPANY_REMOVED: "Entreprise supprimée",
  UNPUBLISHED_OFFICE_REMOVED_ICON: "mdi-stop-circle",
  UNPUBLISHED_OFFICE_REMOVED: "Bureau supprimé",
  UNPUBLISHED_CHANNEL_SUBSCRIPTION_REMOVED_ICON: "mdi-stop-circle",
  UNPUBLISHED_CHANNEL_SUBSCRIPTION_REMOVED: "Média supprimé",
  UNPUBLISHED_CAMPAIGN_REMOVED_ICON: "mdi-stop-circle",
  UNPUBLISHED_CAMPAIGN_REMOVED: "Campagne supprimée",
  UNPUBLISHED_JOB_REMOVED_ICON: "mdi-stop-circle",
  UNPUBLISHED_JOB_REMOVED: "Offre supprimée",
  UNPUBLISHED_CAMPAIGN_FINISHED_ICON: "mdi-stop-circle",
  UNPUBLISHED_CAMPAIGN_FINISHED: "Campagne terminée",
  UNPUBLISHED_JOB_FINISHED_ICON: "mdi-stop-circle",
  UNPUBLISHED_JOB_FINISHED: "Offre depassée",
  NO_CONTRACT_ICON: "mdi-close-circle",
  NO_CONTRACT: "Aucun contrat",
  NOT_VALID_ICON: "mdi-close-circle",
  NOT_VALID: "Incomplet",
  COMMITED_ICON: "mdi-arrow-up-bold-circle",
  COMMITED: "Envoyé",
  REPUBLISHED_ICON: "mdi-check-circle",
  REPUBLISHED: "Republié",
  UPDATED_ICON: "mdi-check-circle",
  UPDATED: "Mis à jour",
  AVOIDED_ICON: "mdi-arrow-down-drop-circle",
  AVOIDED: "Non supporté",
  DISPATCH_FAILED_ICON: "mdi-stop-circle",
  DISPATCH_FAILED: "Erreur",
  DISPATCH_WARNING_ICON: "mdi-alert",
  DISPATCH_WARNING: "Avertissement",
  TTL_WARNING_ICON: "mdi-alert",
  TTL_WARNING: "Dépublication prévue dans ",
  DISPATCH_RUNNING: "Envoi en cours",
  DISPATCH_RUNNING_ICON: "mdi-refresh-circle",
  PUBLISHED_ICON: "mdi-check-circle",
  PUBLISHED: "Publié",
  UNPUBLISHED_ICON: "mdi-stop-circle",
  UNPUBLISHED: "Dépublié",
  REFRESH_WAIT_ICON: "mdi-refresh-circle",
  REFRESH_WAIT: "En attente",

  TYPE_OFFER: "Offre d'emploi",
  TYPE_OFFER_ICON: "mdi-briefcase",
  TYPE_TEXT: "Texte",
  TYPE_TEXT_ICON: "mdi-card-text",
  TYPE_IMAGE: "Image & Texte",
  TYPE_IMAGE_ICON: "mdi-post",
  TYPE_CAROUSEL: "Carrousel",
  TYPE_CAROUSEL_ICON: "mdi-view-carousel",
  TYPE_HTML: "Banière Html",
  TYPE_HTML_ICON: "mdi-image-area",
  TYPE_VIDEO: "Vidéo",
  TYPE_VIDEO_ICON: "mdi-video",
  TYPE_AUDIO: "Audio",
  TYPE_AUDIO_ICON: "mdi-music",

  METHOD_CPA: "CPA",
  METHOD_CPC: "CPC",
  METHOD_CPCT: "CPCT",
  METHOD_CPM: "CPM",
  METHOD_CREDIT: "Crédits",
  METHOD_ORGANIC: "Organique",

  FORM_TITLE_UPDATE: "Modifier une publication",
  FORM_TITLE_CREATE: "Créer une publication",
  FORM_TITLE_NEW: "Saisissez les informations de la nouvelle publication",
  FIELD_PRICING_MODEL: "Modèle de prix",
  FIELD_METHOD: "Méthodes",
  FIELD_PRICE: "Prix",
  FIELD_MODE: "Mode: Test",
  FIELD_PRICING_MODEL_ERROR: "Vous devez sélectionner un modèle de prix.",

  METHOD_ITEMS: [
    { text: "CPA", value: "cpa" },
    { text: "CPC", value: "cpc" },
    { text: "CPC Imposé", value: "cpct" },
    { text: "CPM", value: "cpm" },
    { text: "Crédits", value: "credit" },
    { text: "Organic", value: "organic" },
  ],

  TYPE_ITEMS: [
    { text: "Offre", value: "offer" },
    { text: "Image", value: "image" },
    { text: "Audio", value: "audio" },
    { text: "Video", value: "video" },
    { text: "Bannière html", value: "html" },
    { text: "Texte", value: "text" },
  ],
}
