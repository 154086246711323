export default {
  BUDGET_ALLOCATED: "budget alloué",
  BUDGET_DEPOSITS: "dépôt(s)",
  CREATED: "Créé par",
  UPDATED: "Mis à jour",
  CREATED_AT: "Date de création",
  REF: "Référence",
  EMAIL: "Email",
  PASSWORD: "Mot de passe",
  STATUS: "Statut",
  STATUS__1: "Supprimé",
  STATUS_0: "Pas publié",
  STATUS_1: "Publié",
  ID: "ID",
  TITLE: "Titre",
  TITLES: "Titre",
  SALARY: "Salaire",
  LEVEL: "Niveau",
  DESCRIPTION: "Description",
  FULL_DESCRIPTION: "Description entière",
  UPLOAD_IMAGE: "Télécharger une image",
  DELETE_IMAGE: "Supprimer une image",
  COUNTRY: "Pays",
  ADMIN: "Administration",
  CHANNEL: "Canal",
  BUDGET: "Budget",
  TOTAL_BUDGET: "Budget total",
  COMMISSION: "Commission",
  TOTAL: "Total",
  GROUP_BY: "Grouper par",
  IMAGE: "Image",
  INFORMATIONS: "Informations",
  CHANNEL_TYPES: "Type de canal",
  CONTRACT_TYPES: "Type de contrat",
  CONTRACT_START_DATE: "Début du contrat",
  CONTRACT_END_DATE: "Fin du contrat",
  CONTROLLER_NAME: "Nom du controller",
  CREDIT: "Crédits",

  TEST_MODE: "Mode: Test",
  USE_XTRAMILE_ACCOUNT: "Utiliser le compte Xtramile",

  CAMPAIGN_TYPES: "Types de campagne :",
  CAMPAIGN_METHODS: "Méthodes de campagne:",
  CAMPAIGN_MODE: "Mode de campagne:",
  MODE_JOBBOARD: "jobboard",
  MODE_AGGREGATOR: "agrégateur",
  MODE_DISPLAY: "display",
  MODE_PERFORMANCE: "performance",
  MODE_PERFORMANCE_ADVANCED: "avancé",

  SHOW_PUBLIC: "Afficher au public (Landing page)",

  INTRO: "Intro",

  MIN: "Min",
  MAX: "Max",
  PERIOD: "Période",
  DIPLOMAS: "Diplômes",

  PROFILE: "Profil",

  AUTOREFRESH: "Mettre à jour automatiquement tous les jours",
  NEED_REFRESH: "Besoin de rafraichir ?",
  NEED_DATA: "Données de requête supplémentaires",
  NEED_AUTH: "Authentification",

  CREATE_LANDING_PAGES: "Créer landing pages sur landing.xtramile.io",

  HASH: "Hash",
  NAME: "Name",
  URL: "Url",
  ACTION: "Action",
  SCOPES: "Scopes",
  LASTNAME: "Nom de famille",
  FIRSTNAME: "Prénom",
  PHONE: "Téléphone",
  COMPANY: "Entreprise",
  OFFICE: "Bureau",
  ROLE: "Rôle",
  USER: "Utilisateur",
  LIMIT: "Limite",
  DATE: "Date",
  DATE_START: "Date de début",
  DATE_END: "Date de fin",

  PRICE: "Prix",
  COUNTRY_CODE: "Code (ISO 3166-1 alpha3)",
  LANGUAGE: "Langues",
  DOMAINS: "Domaines",
  DOMAINS_SALE: "Commercial",
  FEE_RATE: "Taux de frais",
  TAX_RATE: "Taux d'imposition",
  TAX_AMOUNT: "Montant de la taxe",

  CURRENCY: "Devise",
  CURRENCY_EUR: "Euro",
  CURRENCY_USD: "US Dollar",

  LOCATION: "Localisation",
  LOCATION_COUNTRY: "Pays",
  LOCATION_COUNTRY_CODE: "Code du pays",
  LOCATION_REGION: "Région",
  LOCATION_REGION_CODE: "Code de région",
  LOCATION_DEPARTMENT: "Département",
  LOCATION_DEPARTMENT_CODE: "Code départemental",
  LOCATION_CITY: "Ville",
  LOCATION_CITY_CODE: "Code postal",
  LOCATION_LAT: "Latitude",
  LOCATION_LON: "Longitude",

  AGE: "Age",
  AGE_MIN: "Age min.",
  AGE_MAX: "Age max.",

  DURATION_CAMPAIGN: "Durée de la campagne",
  DURATION: "Durée",
  DURATION_MIN: "Durée min.",
  DURATION_MAX: "Durée max.",
  DURATION_PERIOD: "Période",
  START_DATE: "Date de début",
  END_DATE: "Date de fin",

  EDUCATION: "Education",
  EDUCATION_LEVEL: "Niveau de formation",
  EDUCATION_VOCATIONAL: "Professionnel",
  EDUCATION_HIGHSCHOOL: "Lycée",
  EDUCATION_ASSOCIATE: "Associate",
  EDUCATION_BACHELOR: "Bachelier",
  EDUCATION_EXECUTIVE_BACHELOR: "Bachelier <+",
  EDUCATION_MASTER: "Master",
  EDUCATION_EXECUTIVE_MASTER: "Master <+",
  EDUCATION_DOCTORANT: "Doctorat",
  EDUCATION_EXECUTIVE_DOCTORANT: "Doctorat <+",

  EXPERIENCE: "Expérience",
  EXPERIENCE_LEVEL: "Niveau d'experience",
  EXPERIENCE_NONE: "Aucune",
  EXPERIENCE_BEGINNER: "Débutante (0-1)",
  EXPERIENCE_JUNIOR: "Junior (2-4)",
  EXPERIENCE_EXPERIENCED: "Experimenté (5-9)",
  EXPERIENCE_SENIOR: "Senior (10+)",

  FORCE: "Forcer",

  CATEGORY: "Catégorie",
  CONVERSION_RATE: "Taux de conversion",
  ZERO_DECIMAL: "Zero decimal",

  METHODS: "Méthodes",
  METHOD: "Méthode",
  TYPES: "Types",
  CONTRACT_REMOTE: "Accepter le télétravail",
  CONTRACT: "Contrat",
  CONTRACT_TYPE: "Type",
  CONTRACT_TYPE_PERMANENT: "Permanent",
  CONTRACT_TYPE_FIXED: "Fixé",
  CONTRACT_TYPE_TEMPORARY: "Temporaire",
  CONTRACT_TYPE_INTERIM: "Interim",
  CONTRACT_TYPE_INTERNSHIP: "Stage",
  CONTRACT_TYPE_WORK_STUDY: "Étude du travail",
  CONTRACT_TYPE_INTERMITTENT: "Intermittent",
  CONTRACT_TYPE_OFFICIAL: "Officiel",
  CONTRACT_TYPE_FREELANCE: "Freelance",
  CONTRACT_TYPE_SEASONAL: "Saisonnier",
  CONTRACT_TYPE_APPRENTICESHIP: "Apprenti",
  CONTRACT_TYPE_VOLUNTEER: "Bénévolat",

  LANGUAGE_FR: "Francais",
  LANGUAGE_EN: "Anglais",
  LANGUAGE_DE: "Allemande",
  LANGUAGE_NL: "Néerlandais",
  LANGUAGE_IT: "Italien",
  LANGUAGE_ES: "Espagnol",
  LANGUAGE_RU: "Russe",
  LANGUAGE_CN: "Chinois",

  CONTRACT_SCHEDULE: "Horaire",
  CONTRACT_SCHEDULE_FULL_TIME: "Temps plein",
  CONTRACT_SCHEDULE_PART_TIME: "Temps partiel",
  CONTRACT_SCHEDULE_FULL_PART_TIME: "Temps partiel et temps plein",
  CONTRACT_SCHEDULE_ONE_SHOT: "Intérim",

  PROTOCOL: "Protocole",
  PROTOCOL_PARAMS: "Paramètres du protocole",
  PROTOCOL_HTTP: "HTTP",
  PROTOCOL_FTP: "FTP",
  PROTOCOL_STORAGE: "STORAGE",
  PROTOCOL_METHOD: "Methode de requête",
  PROTOCOL_METHOD_GET: "GET",
  PROTOCOL_METHOD_POST: "POST",
  PROTOCOL_METHOD_PUT: "PUT",
  PROTOCOL_METHOD_PATCH: "PATCH",
  PROTOCOL_METHOD_DELETE: "DELETE",
  FILEFORMAT: "Format du fichier",
  FILEFORMAT_CSV: "CSV",
  FILEFORMAT_JSON: "JSON",
  FILEFORMAT_XML: "XML",
  AUTH_TOKEN: "Jeton d'autorisation",
  QUERY_DATA: "Données de requête (JSON)",
  HOST: "Hôte",
  PORT: "Port",
  SECURE: "Mode: sécurité (ssl)",
  SECURE_OPTION: "Options de sécurité",
  FILE_PATH: "Chemin du fichier",
  FILEFORMAT_FIELDS: "Structure des champs de données",
  FILEFORMAT_HOOKS: "Automatisation des tâches",
  FILEFORMAT_LIST: "Liste des offres",

  CONTACT: "Contact",
  QUANTITY: "Quantité",

  IMAGE_URL: "Image (URL)",
  TAGS: "Tags",
  PERIOD_YEAR: "année",
  PERIOD_MONTH: "mois",
  PERIOD_DAY: "jour",
  PERIOD_HOUR: "heure",

  FIELDS: "Champs",
  VALUE: "Valeur",
  VALUES: "Valeurs",
  DEFAULT_VALUE: "Valeurs par défaut",
  MULTIPLE_VALUE: "Choisissez une ou plusieurs valeurs",

  URL_TEMPLATE: "URL template",
  MACRO: "Nom de la macro",
  MACRO_TYPE: "Type",
  MACRO_TYPE_REGEX: "RegExp",
  MACRO_TYPE_DATA: "Donnée",
  MACRO_ON: "Pour",
  MACRO_TRIGGER: "Déclencheur",
  MACRO_VALUE: "Valeur",
  MACRO_DEFAULT_VALUE: "Value par défaut",
  MACROS: "Macros",

  FEED_CREATE_JOB: "Ajouter les offres automatiquement",
  FEED_REMOVE_JOB: "Supprimer les offres automatiquement",

  FILE_UPLOAD_NO_IMAGE: "Aucune image",

  DUPLICATE_BY_CITIES_IN_COUNTRY: "Dupliquer les villes par pays",
  DUPLICATE_BY_CITIES_IN_DEPARTMENT:
    "Dupliquer les villes par département(s)",
  DUPLICATE_BY_CITIES_IN_REGION: "Dupliquer les villes par région(s)",
  DUPLICATE_BY_CITIES_IN_DESCRIPTION: "Dupliquer les villes par champs",

  CONTACT_NAME: "Nom du contact",
  CONTACT_POSITION: "Position",
  CONTACT_EMAIL: "E-mail du contact",
  CONTACT_PHONE: "Numéro de téléphone",
  COMPANY_TITLE: "Nom de l'entreprise",
  COMPANY_IMAGE_URL: "Image (URL)",
  COMPANY_URL: "Site internet",
  COMPANY_DESCRIPTION: "Description de l'entreprise",
  TYPE: "Type",
  NO_LIMIT_VALUE: "Ne pas limiter le budget",

  GA4_PARAMS_TITLE: "Configurer l'intégration avec Google Analytics",
  GA4_PARAMS_PROPERTY_ID: "Numéro d'identification de la propriété",
  GA4_PARAMS_PROPERTY_ID_PLACEHOLDER: "292480871",
  GA4_PARAMS_CONVERSION_EVENT_NAME: "Événement de conversion",
  GA4_PARAMS_CONVERSION_EVENT_NAME_PLACEHOLDER: "apply",

  OCCUPATIONS: "Occupations",
  SECTOR: "Sector",
}
