export default {
  TITLE: "Utilisateur",
  TITLES: "Utilisateurs",
  TITLE_COUNT: "Aucun utilisateur | 1 utilisateur | {n} utilisateurs",
  ICON: "mdi-account-group",
  RESET: "Annuler les modifications",
  SAVE: "Enregistrer",
  REMOVE: "Supprimer",
  ACCOUNT: "Compte",
  ROLE: "Droits",
  WORKING_GROUP: "Groupe de travail",
  RESET_PASSWORD_EMAIL: "Réinitialiser Mot de passe",
  FIELD_TITLE: "Titre",
  FIELD_ROLE: "Rôle",
  FIELD_ROLE_PLACEHOLDER: "Choississez un rôle",
  FIELD_LEVEL: "Niveau",
  FIELD_LEVEL_PLACEHOLDER: "1",
  FIELD_ADMIN: "Super admin",
  FIELD_LASTNAME: "Nom",
  FIELD_LASTNAME_PLACEHOLDER: "Dupond",
  FIELD_FIRSTNAME: "Prénom",
  FIELD_FIRSTNAME_PLACEHOLDER: "Pierre",
  FIELD_EMAIL: "E-mail",
  FIELD_EMAIL_PLACEHOLDER: "pierre.dupont@monentreprise.fr",
  FIELD_PHONE: "Téléphone",
  FIELD_PHONE_PLACEHOLDER: "+33",
  FIELD_COMPANY: "Entreprise",
  FIELD_COMPANY_PLACEHOLDER: "Choississez une entreprise",
  FIELD_OFFICE: "Bureau",
  FIELD_OFFICE_PLACEHOLDER: "Choisissez un bureau",
  PRODUCT_AFTER_CREATION:
    "Vous pourrez ajouter des produits à l'utilisateur une fois que celui-ci a été créé.",
  FORM_TITLE_UPDATE: "Modifier un utilisateur",
  FORM_TITLE_NEW: "Nouveau utilisateur",
  FORM_TITLE_CREATE: "Créer un utilisateur",
}