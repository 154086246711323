export default {
  TITLE: 'Editeur de créations',
  TITLE_COUNT: "bannière | 1 bannière | {n} bannières",
  ICON: "mdi-image-area",
  HEIGHT: 'Hauteur',
  WIDTH: 'Largeur',
  PARAMS: 'Paramètres',
  ADVANCED: 'Avancés',
  NO_ADVANCED: 'Aucun paramètre avancé',
  BANNER_EXAMPLE: 'Aspect final approximatif (réduit de 33%)',
  CONTENT_PARAMS: 'Contenu',
  MACROS_IN_HTML: 'Macros',
  NO_MACRO: 'Aucune macro n\'est detectée dans votre contenu HTML',
  MACRO_RELATION: 'Faîtes la liaison entres les macros et les données Xtramile',
  CHANGE_WARNING: 'Êtes-vous sur de vouloir écraser les informations remplies ?',
  NO_VALUE: 'Aucune valeur attribuée',
  EXAMPLE_DATA: '+ Données d\'exemple',
  IMPORT_FROM_ZIP: 'Importer depuis un fichier Zip (2022)',
  SIZE: 'Dimensions',
}
