export default {
  TITLE: "Channel",
  TITLES: "Channels",
  TITLE_COUNT: "channel | 1 channel | {n} channels",
  ICON: "mdi-newspaper-variant",

  ALL_CHANNELS: "All channels",
  CHANNEL_NO_PROTOCOL: "No protocol",
  PARAMS: "Settings",
  CONTRACT: "Contract",
  PRICING_MODEL: "Pricing model",
  ACCOUNT_FIELDS: "Account fields",
  PARAMS_FIELDS: "Channel settings fields",
  FIELD_CATEGORY: "Category",
  INFORMATIONS: "Channel information (visible)",
  COUNTRY: "Compatible countries",

  FORM_TITLE_CREATE: "Create a channel",
  FORM_TITLE_NEW: "New channel",
  FORM_TITLE_UPDATE: "Change channel",
  FIELD_TITLE_PLACEHOLDER: "Title",

  CATEGORY: {
    credit: "Credit",
    aggregator: "Aggregator",
    fixed_aggregator: "Aggregator (Fixed)",
    display: "Display",
  },

  CATEGORY_ITEMS: [
    { value: "credit", text: "Credit" },
    { value: "aggregator", text: "Aggregator" },
    { value: "fixed_aggregator", text: "Aggregator (Fixed)" },
    { value: "display", text: "Display" },
  ],
}