export default {
  TITLES: {
    MAIN: "Impact HR Analytics",
    HEADER_POWER_BI: "Power Bi",
    POWER_BI: "XImpact HR Macro",
    PARSING_JOBS: "XImpact HR Micro",
    ENTER_JOB: "Enter your job content"
  },
  ICONS: {
    IFRAME: "mdi-application-brackets",
    PARSING_JOBS: "mdi-briefcase-account",
  },
  BUTTONS: {
    PARSE_BUTTON: "Parse Jobs",
    PARSE_BUTTON_JOB: "Parse Job",
    CANCEL_BUTTON: "Cancel",
    VALIDATE: "Validate",
  },
  LABELS: {
    ENTER_JOB_NAME: "Enter your job name",
  }
}