export default {
  UPLOAD_FILE: "Télécharger un fichier",
  UPLOAD_FORMAT: "Aucun format | 1 format | {n} formats",
  UPLOAD_FILE_LIST: "Fichiers",

  DRAGNDROP: "Glisser et déposer",
  OR: "ou",
  CLICK: "cliquer ici pour déposer un fichier",
  TO_LOAD_CV: "un CV",
  TO_LOAD_JOB: "un Job",
  IMPACT_BUTTONS_TITLE: "Cliquez ici pour télécharger l'offre",
  IMPACT_BUTTONS: {
    FROM_FILE: "Depuis un fichier",
    FROM_TEXT: "Depuis un texte",
  },
}
