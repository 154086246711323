<template>

  <div class="form-compact form-compact-login">
    <v-form
      ref="form"
      class="pa-6"
      v-model="valid"
    >
      <Loading :value="loading" />
      <div class="pa-0">
        <v-text-field
          ref="email"
          v-model="email"
          :label="$t('INPUT.EMAIL')"
          :rules="[emailRule]"
          placeholder=" "
          autocomplete="username"
          autofocus
          outlined
          required
        />
      </div>

      <div class="pa-0">
        <v-text-field
          ref="password"
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :type="showPassword ? 'text' : 'password'"
          @click:append="showPassword = !showPassword"
          @keydown.enter="validate"
          :label="$t('INPUT.PASSWORD')"
          :rules="[passwordRule]"
          placeholder=" "
          autocomplete="current-password"
          outlined
          required
        />
      </div>
    </v-form>

    <v-divider />

    <div class="text-center pa-4">
      <v-btn
        color="accent"
        :disabled="!valid && !loading"
        x-large
        @click="validate"
      >
        <span>{{$t('FORM.LOGIN_BTN')}}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import Loading from '@/components/Loading'
import { mapActions } from 'vuex'

export default {
  name: 'FormLogin',
  components: {
    Loading
  },
  data: () => ({
    loading: false,
    valid: false,
    email: '',
    emailRule: () => true,
    password: '',
    passwordRule: () => true,
    showPassword: false,
    autofilled: false
  }),

  watch: {
    loading (n) {
      this.$emit('loading', n)
    },
    email(v) {
      if (v) {
        this.emailRule = () => true
        return
      }

      this.emailRule = () => this.$t('LOGIN.EMPTY_FIELD')
    },
    password(v) {
      if (v) {
        this.passwordRule = () => true
        return
      }

      this.passwordRule = () => this.$t('LOGIN.EMPTY_FIELD')
    }
  },
  mounted () {
    // avoid autofill bug with chrome
    setTimeout(() => {
      if (this.$refs.password && this.$refs.password.$el) this.$refs.password.$el.getElementsByTagName('input')[0].value = ""
      this.password = ''
      setTimeout(() => {
        this.emailRule = () => this.$t('LOGIN.EMPTY_FIELD')
        this.passwordRule = () => this.$t('LOGIN.EMPTY_FIELD')
      }, 50)
    }, 50)
  },
  methods: {
    ...mapActions({
      login: 'user/login',
      setTokenParse: 'user/setTokenParse',
    }),

    async validate () {
      if (this.$refs.form.validate()) {
        this.loading = true
        const formData = new FormData()
        formData.append('grant_type', 'password')
        formData.append('username', this.email)
        formData.append('password', this.password)

        try {
          const { data: { access_token } } = await this.$services.xbi_pr.job.token(formData)
          if (access_token) this.setTokenParse(access_token)
        } catch(error) {
          this.loading = false
          console.log(error)
        }

        try {
          await this.login({ email: this.email, password: this.password })
          this.loading = false
        } catch(error) {
          this.loading = false
          console.log(error)
        }
      } else {
        this.$rules.selectErrorField()
      }
    },

    close () {
      this.$emit('close')
    },
  }
}
</script>
