export default {
  TITLE: "Audience",
  TITLES: "Audiences",
  TITLE_COUNT: "audience | 1 audience | {n} audiences",
  ICON: "mdi-human-queue",

  STATISTIC: "statistics | 1 statistic | {n} statistics",
  STATISTIC_ICON: "mdi-chart-line",

  SELECT_CRITERIA: "Select your criteria",
  SECTORS: "Sectors",
  OCCUPATIONS: "Occupations",
  EXPERIENCE: "Experience",
  COUNTRYS: "Countries",
  CITIES: "Cities",
}