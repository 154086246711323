export default {
  START_DATE: "Start",
  END_DATE: "End",

  // CAMPAIGN
  TITLE: "Campaign",
  TITLES: "Campaigns",
  TITLE_COUNT: "no campaign | 1 campaign | {n} campaigns",
  ICON: "mdi-hexagon-multiple",
  SEE_PUBLICATION: "View publications",
  PERIOD: "Campaign period",
  IMPORTS: "Imports",

  IMPORT_ICON: "mdi-swap-vertical-bold",
  IMPORT: "Imports",

  FORM_TITLE_FEED_IMPORT: "Import feeds",
  FORM_TITLE_JOB_IMPORT: "Import jobs",
  FORM_TITLE_UPDATE: "Edit a campaign",
  FORM_TITLE_CREATE: "Create a campaign",
  FORM_TITLE_NEW: "Create a campaign",

  FIELD_GA4_PARAMS_TITLE: "Configure integration with Google Analytics",
  FIELD_GA4_PARAMS_PROPERTY_ID: "Property identification number",
  FIELD_GA4_PARAMS_PROPERTY_ID_PLACEHOLDER: "292480871",
  FIELD_GA4_PARAMS_CONVERSION_EVENT_NAME: "Conversion event",
  FIELD_GA4_PARAMS_CONVERSION_EVENT_NAME_PLACEHOLDER: "apply",

  TOTAL: "Publications",
  PUBLISHED: "Published",
  UNPUBLISHED: "Unpublished",
  WAITING: "Waiting",
  ERRORS: "Error",

  // CAMPAIGN FEED
  // TO REPLACE
  FEED_HOW:
    "How jobs in campaign could be updated when its feed is refreshing ?",
  FEED: "campaign feed | campaign feed | campaign feeds",
  FEED_ICON: "mdi-rss",

  // CAMPAIGN JOB
  CAMPAIGN_JOB: "campaign job | campaign job | campaign jobs",
  CAMPAIGN_JOB_ICON: "mdi-briefcase",

  NEED_REFRESH: "Waiting",
  NEED_REFRESH_ICON: "mdi-refresh-circle",

  PLANNED_ICON: "mdi-clock-time-four",
  PLANNED: "Planned",

  RUNNING_ICON: "mdi-play-circle",
  RUNNING: "Running",

  FINISHED_ICON: "mdi-stop-circle",
  FINISHED: "Finished",

  REFRESH_FAILED_ICON: "mdi-alert-circle",
  REFRESH_FAILED: "Refresh has failed",

  REFRESH_RUNNING_ICON: "mdi-refresh-circle",
  REFRESH_RUNNING: "Refresh is running",

  STEP_INTRO_TITLE: "Introduction",
  STEP_INTRO_DESCRIPTION:
    "Please enter the title and period of your campaign",

  STEP_DATES_TITLE: "Publication period",
  STEP_DATES_DESCRIPTION:
    "Indicate the active period of your campaign publications.",

  STEP_MODE: "Mode",
  STEP_MODE_DESCRIPTION:
    "Please choose a campaign type that will affect the choice of delivery channels",

  STEP_STATS_TITLE: "Statistics",
  STEP_STATS_DESCRIPTION:
  "Connect your campaign to your external statistics systems.",

  STEP_CONFIRM_TITLE:
   "Confirmation",
  STEP_CONFIRM_DESCRIPTION: "Check and confirm your campaign information",
  
  STATS_TITLE: "Connect your external statistics systems",

  INTRODUCTION_TITLE: "Name your campaign",
  MODES_TITLE: "Category channel",
  MODES_TEXT: "Choose the channel categories available in your campaign.",

  DATES_TITLE: "Dates",
  DATES_TEXT: "Select the date range when your posts will be visible.",

  REFRESH: "Relaunch the campaign",

  FIELD_CHANNEL_CATEGORY: "Category channel",
  FIELD_TITLE: "Category title",
  FIELD_TITLE_PLACEHOLDER: "Campaign 01",
  FIELD_DATES: "Dates",
  FIELD_START_DATE: "Start date",
  FIELD_END_DATE: "End date",

  SELECT_ALL_CATEGORY: "Select all",
  CHANNEL_CATEGORY_ITEMS: [
    {
      channel_categories: ["credit"],
      title: "Credit",
      description: "Job site with credits or slots.",
    },
    {
      channel_categories: ["aggregator"],
      title: "Aggregator",
      description: "Site of aggregated job offers.",
    },
    {
      channel_categories: ["display"],
      title: "Display",
      description: "Interactive media: Ads, Images, Videos and Audios.",
    },
    {
      channel_categories: ["credit", "aggregator", "display"],
      title: "Performance",
      description: "All channels.",
    },
  ],
}