export default {
  TITLE: "Product",
  TITLES: "Products",
  TITLE_COUNT: "product | 1 product | {n} products",
  ICON: "mdi-cube-outline",
  RESET: "Discard changes",
  SAVE: "Save",
  REMOVE: "Remove",
  INFO: "Informations",
  FIELD_TITLE: "Title",
  FIELD_TITLE_PLACEHOLDER: "XProduct",
  FIELD_COUNTRY: "Country",
  FIELD_COUNTRY_PLACEHOLDER: "Choose a country",
  FIELD_DESCRIPTION: "Description",
  FIELD_DESCRIPTION_PLACEHOLDER: "XProduct allows to ...",
  FIELD_IMAGE_URL: "Image URL",
  FIELD_IMAGE_URL_PLACEHOLDER: "https://www.monimage.fr/...",
  BILL: "Billing",
  FIELD_PRICE: "Price (€)",
  FIELD_PRICE_PLACEHOLDER: "1000",
  FIELD_DISCOUNT: "Reduction (%)",
  FIELD_DISCOUNT_PLACEHOLDER: "0",
  FORM_TITLE_UPDATE: "Update product",
  FORM_TITLE_CREATE: "Create product",
  FORM_TITLE_NEW: "Enter new product information",
}