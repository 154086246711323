export default {
  TITLE: "Audience",
  TITLES: "Audiences",
  TITLE_COUNT: "audience | 1 audience | {n} audiences",
  ICON: "mdi-human-queue",

  STATISTIC: "statistique | 1 statistique | {n} statistiques",
  STATISTIC_ICON: "mdi-chart-line",

  SELECT_CRITERIA: "Séléctionnez vos critères",
  SECTORS: "Secteurs",
  OCCUPATIONS: "Occupations",
  EXPERIENCE: "Vivre",
  COUNTRYS: "Pays",
  CITIES: "Villes",
}