export default {
  TITLE: "User",
  TITLES: "Users",
  TITLE_COUNT: "user | 1 user | {n} users",
  ICON: "mdi-account-group",
  RESET: "Discard change",
  SAVE: "Save",
  REMOVE: "Remove",
  ACCOUNT: "Account",
  ROLE: "Rights",
  WORKING_GROUP: "Working group",
  RESET_PASSWORD_EMAIL: "Reset password",
  FIELD_TITLE: "Title",
  FIELD_ROLE: "Role",
  FIELD_ROLE_PLACEHOLDER: "Choose a role",
  FIELD_LEVEL: "Level",
  FIELD_LEVEL_PLACEHOLDER: "1",
  FIELD_ADMIN: "Super admin",
  FIELD_LASTNAME: "Name",
  FIELD_LASTNAME_PLACEHOLDER: "Dupond",
  FIELD_FIRSTNAME: "First name",
  FIELD_FIRSTNAME_PLACEHOLDER: "Pierre",
  FIELD_EMAIL: "Email",
  FIELD_EMAIL_PLACEHOLDER: "pierre.dupont@monentreprise.fr",
  FIELD_PHONE: "Telephone",
  FIELD_PHONE_PLACEHOLDER: "+33",
  FIELD_COMPANY: "Company",
  FIELD_COMPANY_PLACEHOLDER: "Choose a company",
  FIELD_OFFICE: "Office",
  FIELD_OFFICE_PLACEHOLDER: "Choose a office",
  PRODUCT_AFTER_CREATION:
    "You will be able to add products to the user once the user has been created.",
  FORM_TITLE_UPDATE: "Update user",
  FORM_TITLE_NEW: "New user",
  FORM_TITLE_CREATE: "Create user",
}