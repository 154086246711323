export default {
  TITLE: "Role",
  TITLES: "Roles",
  TITLE_COUNT: "role | 1 role | {n} roles",
  ICON: "mdi-medal",
  INFO: "Informations",
  FORM_TITLE_UPDATE: "Update role",
  FORM_TITLE_NEW: "New role",
  FORM_TITLE_CREATE: "Create role",
  RESET: "Discard changes",
  SAVE: "Save",
  REMOVE: "Remove",
  FIELD_TITLE: "Title",
  FIELD_TITLE_PLACEHOLDER: "manager",
  FIELD_LEVEL: "Level",
  FIELD_LEVEL_PLACEHOLDER: "1",
}