<template>
  <div :class="['messages', messages.length === 0 ? 'hidden' : '']">
    <transition-group name="slide-message" tag="div">
      <div
        v-for="message in messages"
        class="message"
        @click="close(message.id)"
        :key="message.id"
      >
        <v-alert
          :type="message.type"
          class="ma-2 rounded"
          tile
        >
          <div v-if="message.code" class="ma-0">
            <strong>{{ $t('MESSAGE.' + message.code, message.options || {}) }}</strong>
          </div>
          <div v-if="admin"><small>{{ message.code }} : {{ message.message }}</small></div>
          <div class="message-info">
            <v-tooltip top z-index="9999">
              <template v-slot:activator="{ on }">
                <v-icon
                  dark
                  size="26"
                  v-on="on"
                >
                  mdi-information
                </v-icon>
              </template>
              <span>{{ message.code }} : {{ message.message }}</span>
            </v-tooltip>
          </div>
          <div class="message-time" :key="render">
            <v-progress-circular
              size="24"
              width="2"
              :value="time(message)"
              color="rgba(255,255,255,1)"
            />
          </div>
          <div class="message-close">
            <v-icon
              @click="close(message.id)"
              dark
              size="18"
              v-on="on"
            >
              mdi-close
            </v-icon>
          </div>
        </v-alert>
      </div>
    </transition-group>
  </div>
</template>

<style scoped>

  .v-alert {
    user-select: none;
  }

  .messages {
    position: fixed;
    bottom:0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 500px;
    transition: all 300ms;
    z-index: 9998;
    box-shadow: 0 10px 20px -10px rgba(0,0,0,.3);
  }
  .messages.hidden {
    opacity: 0;
  }

  .message::before {
    content: "";
    position: absolute;
    bottom:0;
    top:0;
    z-index: 9;
    width:100%;
    transition: all 80ms;
  }

  .message:hover::before {}

  .message {
    position: relative;
    transition: all 200ms;
    cursor: pointer;
    z-index: 9998;
  }

  .message p {
    white-space: nowrap;
    max-width: calc(100% - 80px);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .message:hover {
    z-index: 9;
  }

  .message .v-icon {
    line-height: 1;
  }

  /* .message:hover .v-alert {
    padding-left: 30px;
  } */

  .message-info {
    position: absolute;
    right: 55px;
    top:14px;
    z-index: 99;
  }

  .message-time {
    position: absolute;
    right: 20px;
    top:14px;
    z-index: 99;
  }

  .message-close {
    position: absolute;
    right: 23px;
    top:14px;
    z-index: 99;
  }

</style>

<script>

import { mapState } from 'vuex'

export default {
  name: 'Messenger',
  data: () => ({
    on: null,
    render: false,
    interval: null
  }),

  updated () {
    if (this.messages.length > 0) {
      if (!this.interval) this.interval = setInterval(() => {
        this.render = !this.render
      }, 100)
    } else {
      clearInterval(this.interval)
      this.interval = null
    }
  },

  computed: {
    ...mapState('user', {
      admin: state => (state.user && state.user.admin) || false
    }),
    messages () {
      return this.$store.state.messenger.messages
    },
  },

  methods: {
    time (message) {
      return Math.ceil(100 * ((message.timeEnd -  Date.now()) / message.time)) + 2
    },
    close (id) {
      this.$store.dispatch('messenger/remove', id)
    },
  }
}
</script>
