<template>
  <v-list-group :value="true" class="white--text" v-if="0 && productXparsing">
    <template v-slot:activator>
      <v-list-item-title class="accent--text">
        <strong><h3>X<span class="white--text">Parsing</span></h3></strong>
      </v-list-item-title>
    </template>
  </v-list-group>
</template>

<script>
import { mapState } from "vuex"

export default {
  name: "MenuMainXparsing",
  components: {
  },
  data: (_this) => ({
    productXparsing: false,
    language: "en",
  }),
  async created () {
    this.productXparsing = !!(await this.$permission.check('product.xparsing'))
  },
  computed: {
    opened () {
      return this.page.includes('/xparsing')
    },
    ...mapState("app", {
      page: (state) => {
        return state.page
      },
    }),
    ...mapState("user", {
      username: (state) =>
        state.user ? `${state.user.firstname[0]}. ${state.user.lastname}` : "-",
      fullname: (state) =>
        state.user ? `${state.user.firstname} ${state.user.lastname}` : "-",
      role: (state) =>
        state.user && state.user.role ? state.user.role.title : "-",
      company: (state) =>
        state.user && state.user.company ? state.user.company.title : "-",
      office: (state) =>
        state.user && state.user.office ? state.user.office.title : "-",
      userIsXtramileAdmin: (state) =>
        state.user && state.user.role && state.user.role.title[0] === "x",
      userIsAdmin: (state) =>
        state.user &&
        state.user.role &&
        state.user.role.title.includes("admin"),
    }),
  },

  methods: {
  },
}
</script>

<style lang="scss">

</style>
