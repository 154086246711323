import app from "./app"
import user from "./user"
import messenger from "./messenger"
import cv from "./cv"
import job from "./job"
import office from "./office"

export default {
  app,
  user,
  messenger,
  cv,
  job,
  office,
}
