<template>

  <v-list-group :value="opened" class="white--text" v-if="productXprogrammatic">
    <template v-slot:activator>
      <v-list-item-title class="accent--text submenu--title">
        <strong><h3>X<span class="white--text">Trading-</span><span class="accent--text">X</span><span class="white--text">tramile</span></h3></strong>
      </v-list-item-title>
    </template>

    <v-list-item class="pl-6" to="/xprogrammatic/channel_subscriptions">
      <v-list-item-icon>
        <v-icon
          color="accent"
          v-text="$t('CHANNEL_SUBSCRIPTION.ICON')"
          size="20"
        />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="">
          <strong
            v-text="$t('CHANNEL_SUBSCRIPTION.TITLES')"
          />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item class="pl-6" to="/xprogrammatic/feeds">
      <v-list-item-icon>
        <v-icon
          color="accent"
          v-text="$t('FEED.ICON')"
          size="20"
        />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="">
          <strong v-text="$t('FEED.TITLES')" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item class="pl-6" to="/xprogrammatic/jobs">
      <v-list-item-icon>
        <v-icon
          color="accent"
          v-text="$t('JOB.ICON')"
          size="20"
        />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="">
          <strong v-text="$t('JOB.TITLES')" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item class="pl-6" to="/xprogrammatic/creatives">
      <v-list-item-icon>
        <v-icon
          color="accent"
          v-text="$t('CREATIVE.ICON')"
          size="20"
        />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="">
          <strong v-text="$t('CREATIVE.TITLES')" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item class="pl-6" to="/xprogrammatic/campaigns">
      <v-list-item-icon>
        <v-icon
          color="accent"
          v-text="$t('CAMPAIGN.ICON')"
          size="20"
        />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="">
          <strong v-text="$t('CAMPAIGN.TITLES')" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item class="pl-6" to="/xprogrammatic/ratios">
      <v-list-item-icon>
        <v-icon
          color="accent"
          v-text="$t('RATIO.ICON')"
          size="20"
        />
      </v-list-item-icon>
      <v-list-item-content>
        <v-list-item-title class="">
          <strong v-text="$t('RATIO.TITLES')" />
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>

  </v-list-group>


</template>

<script>
import { mapState } from "vuex"

export default {
  name: "MenuMainXprogrammatic",
  components: {
  },
  data: (_this) => ({
    productXprogrammatic: false,
    language: "en",
  }),
  async created () {
    this.productXprogrammatic = !!(await this.$permission.check('product.xprogrammatic'))
  },
  computed: {
    opened () {
      return this.page.includes('/xprogrammatic')
    },
    ...mapState("app", {
      page: (state) => {
        return state.page
      },
    }),
    ...mapState("user", {
      username: (state) =>
        state.user ? `${state.user.firstname[0]}. ${state.user.lastname}` : "-",
      fullname: (state) =>
        state.user ? `${state.user.firstname} ${state.user.lastname}` : "-",
      role: (state) =>
        state.user && state.user.role ? state.user.role.title : "-",
      company: (state) =>
        state.user && state.user.company ? state.user.company.title : "-",
      office: (state) =>
        state.user && state.user.office ? state.user.office.title : "-",
      userIsXtramileAdmin: (state) =>
        state.user && state.user.role && state.user.role.title[0] === "x",
      userIsAdmin: (state) =>
        state.user &&
        state.user.role &&
        state.user.role.title.includes("admin"),
    }),
  },

  methods: {
  },
}
</script>

<style lang="scss">
.disabled {
  color: grey;
}

.submenu--title {
  overflow: visible !important;
}
</style>
