export default {
  ICON: "mdi-file-account",
  ICON_ACCOUNT: "mdi-account",
  TITLE: "CV",
  TITLES: "CVs",
  TITLE_COUNT: "CV | 1 CV | {n} CVs",
  TITLES_FIELDS: {
    Personal: "Personal information",
    Occupation: "Occupation",
    firstName: "First name",
    lastName: "Last name",
    unknown: "Undefined identity",
    address: "Address",
    postal_code: "Postal Code",
    city: "City",
    country: "Country",
    email: "Email",
    phone_number: "Phone number",
    birthday: "Birthday",
    nationality: "Nationality",
    driver_licence: "Driver's Licence",
    Experience: "Professional experience",
    organisation_name: "Organisation Name",
    contract_type: "Contract type",
    duration: "Duration",
    occupations: "Occupations",
    occupation: "Occupation",
    Education: "Education",
    school_name: "School Name",
    specialisation: "Specialization",
    level: "Level",
    Skills: "Skills",
    hard_skill: "Hard skills",
    soft_skill: "Soft skills",
    language: "Languages",
    interests: "Interests",
  },
}
