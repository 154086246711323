export default {
  TITLE: "Offer",
  TITLES: "Offers",
  TITLE_COUNT: "no offer | {n} offer | {n} offers",
  ICON: "mdi-briefcase-account",
  PARSING_TITLES: "Parsing Jobs",
  PARSING_CONTENT_LABELS: {
    global_occupation: "occupations xtramile",
    cities: "geography",
    experience_level: "experiense",
    bi_info: "cpa",
    xtramile1: "xtramile I",
    xtramile2: "xtramile II",
    xtramile3: "xtramile III",
    address: "address",
    state: "state",
    city: "city",
    postal_code: "postal code",
    experience_requise: "experience requise",
    cpa_min: "cpa min",
    cpa_mean: "cpa mean",
    cpa_max: "cpa max",
    cpa_qualified: "cpa qualified",
    cpa_hired: "cpa hired",
    cpa_currency: "cpa currency",
  },

  STATUS_ERROR_COUNT: " | {n} error to review | {n} errors to review",
  REMOVE: "Remove",
  RESET: "Discard changes",
  REFRESH: "Parse offer",
  DUPLICATED: "Duplicate",
  SAVE: "Save",
  PREVIOUS: "Previous",
  NEXT: "Next",
  CHECK: "Check job",
  ARCHIVED: "Archived",
  ARCHIVED_TEXT: "Archived Offer, it will not be used in publications.",

  PARENT: "Hide duplicate offers",

  JOB_DRAFTS: "Drafts",
  JOB_ARCHIVED: "Archived",
  JOB_REMOVED: "Deleted",

  FORM_TITLE_PUBLISH_COUNT:
    "No job selected | 1 selected job | {n} selected job",
  FORM_TITLE_PUBLISH: "Publish job",
  FORM_TITLE_UPDATE: "Edit job",
  FORM_TITLE_CREATE: "Create job",
  FORM_TITLE_NEW: "Enter the information for the new job posting",

  FIELD_OCCUPATION_PLACEHOLDER: "Enter a profession",
  FIELD_CAMPAIGN_TITLE: "Title",
  FIELD_CAMPAIGN_START_DATE: "Start",
  FIELD_CAMPAIGN_END_DATE: "End",

  FORM_CAMPAIGN_CHOOSE_TITLE: "Choose a campaign",
  FORM_CHANNEL_SUBSCRIPTION_CHOOSE_TITLE: "Choose one or more media",
  FORM_CHANNEL_SUBSCRIPTION_CHOOSE_TEXT:
    "Publications will be created on the selected media for jobs that are not already published.",

  FORM_CHANNEL_SUBSCRIPTION_PARAMS_TITLE: "",
  FORM_CHANNEL_SUBSCRIPTION_PARAMS_CHOOSE_PRICING_MODEL: "Pricing model",
  FIELD_CAMPAIGN_PLACEHOLDER: "Campaigns",

  HAS_PARENT:
    "This job comes from a duplicate job and will be modified at the same time as its parent.",
  HAS_PARENT_BTN: "Access the parent job",

  CONTRACT_DATE: "Contract dates",
  PUBLICATION_DATES: "Publication dates",
  CONTRACT_START_DATE: "Contract start date",
  FIELD_CONTRACT_START_DATE: "Contract start date",
  FIELD_CONTRACT_START_DATE_PLACEHOLDER: "",
  CONTRACT_END_DATE: "Contract end date",
  FIELD_CONTRACT_END_DATE: "Contract end date",
  FIELD_CONTRACT_END_DATE_PLACEHOLDER: "",

  FILTER_TITLE: "Select your filters",
  FIELD_TITLE: "Title",
  FIELD_TITLE_PLACEHOLDER: "Enter the title ...",
  FIELD_DATE: "Release date",
  FIELD_DATE_PLACEHOLDER: "Date",
  CLASSIFICATION: "Classification",
  FIELD_OFFICE: "Office",
  FIELD_OFFICE_PLACEHOLDER: "Office",
  FIELD_SECTOR: "Sector",
  FIELD_SECTOR_PLACEHOLDER: "",
  FIELD_CATEGORY: "Category",
  FIELD_CATEGORY_PLACEHOLDER: "",
  FIELD_URL: "URL",
  FIELD_URL_PLACEHOLDER: "https://www.monsite.fr/mon-offre",
  CONTRACT: "Contract",
  CONTRACT_STATUS: "Status",
  FIELD_CONTRACT_STATUS: "Status",
  FIELD_CONTRACT_STATUS_PLACEHOLDER: "",
  CONTRACT_TYPE: "Contract type",
  FIELD_CONTRACT_TYPE: "Contract",
  FIELD_CONTRACT_TYPE_PLACEHOLDER: "",
  CONTRACT_SCHEDULE: "Schedule",
  FIELD_CONTRACT_SCHEDULE: "Time intervals",
  FIELD_CONTRACT_SCHEDULE_PLACEHOLDER: "",
  FIELD_CONTRACT_REMOTE: "Telework accepted",
  FIELD_LOCATION: "Location",
  FIELD_LOCATION_PLACEHOLDER: "Start typing the city name ...",
  FIELD_DESCRIPTION: "Mission",
  FIELD_DESCRIPTION_PLACEHOLDER: "Enter job description ...",
  FIELD_PROFILE: "Profile",
  FIELD_PROFILE_PLACEHOLDER:
    "Persevering and sympathetic people who have experience with people ...",
  FIELD_TAGS: "Keywords",
  FIELD_TAGS_PLACEHOLDER: "Enter a value and press Enter.",

  ADVANCED_PARAMS: "Advanced settings",
  SALARY: "Salary",
  SALARY_MIN: "Minimal salary",
  FIELD_SALARY_MIN: "Min",
  FIELD_SALARY_MIN_PLACEHOLDER: "Min",
  SALARY_MAX: "Maximum salary",
  FIELD_SALARY_MAX: "Max",
  FIELD_SALARY_MAX_PLACEHOLDER: "Max",
  SALARY_PERIOD: "Salary period",
  FIELD_SALARY_PERIOD: "Period",
  FIELD_SALARY_PERIOD_PLACEHOLDER: "",
  SALARY_CURRENCY: "Salary currency",
  FIELD_SALARY_CURRENCY: "Currency",
  FIELD_SALARY_CURRENCY_PLACEHOLDER: "",
  DURATION: "Contract duration",
  DURATION_MIN: "Minimum duration",
  FIELD_DURATION_MIN: "Min",
  FIELD_DURATION_MIN_PLACEHOLDER: "Min",
  DURATION_MAX: "Maximum duration",
  FIELD_DURATION_MAX: "Max",
  FIELD_DURATION_MAX_PLACEHOLDER: "Max",
  DURATION_PERIOD: "Duration period",
  FIELD_DURATION_PERIOD: "Period",
  FIELD_DURATION_PERIOD_PLACEHOLDER: "",
  START_DATE: "Start",
  FIELD_START_DATE: "Start date",
  FIELD_START_DATE_PLACEHOLDER: "",
  END_DATE: "End",
  FIELD_END_DATE: "End date",
  FIELD_END_DATE_PLACEHOLDER: "",
  AGES: "Age group",
  AGE_MIN: "Minimum age",
  FIELD_AGE_MIN: "Min",
  FIELD_AGE_MIN_PLACEHOLDER: "Min",
  AGE_MAX: "Maximum age",
  FIELD_AGE_MAX: "Max",
  FIELD_AGE_MAX_PLACEHOLDER: "Max",
  EXPERIENCE: "Experience",
  EXPERIENCE_LEVEL: "Experience",
  FIELD_EXPERIENCE_LEVEL: "Experience level",
  FIELD_EXPERIENCE_LEVEL_PLACEHOLDER: "",
  EDUCATION: "Education",
  EDUCATION_LEVEL: "Education",
  FIELD_EDUCATION_LEVEL: "School level",
  FIELD_EDUCATION_LEVEL_PLACEHOLDER: "",
  DIPLOMA: "Diploma",
  FIELD_DIPLOMAS: "Diplomas",
  FIELD_DIPLOMAS_PLACEHOLDER: "Enter a value and press Enter",
  QUANTITY: "Available seats",
  FIELD_QUANTITY: "",
  FIELD_QUANTITY_PLACEHOLDER: "1",

  PROFILE_VALIDATION: "",

  RECRUITER: "Information about the company",
  COMPANY: "Company",
  COMPANY_URL: "URL address",
  COMPANY_IMAGE_URL: "Image (URL)",
  FIELD_COMPANY_TITLE: "Title",
  FIELD_COMPANY_TITLE_PLACEHOLDER: "My company",
  FIELD_COMPANY_DESCRIPTION: "Description",
  FIELD_COMPANY_DESCRIPTION_PLACEHOLDER:
    "My company is based in Paris, we are sales specialists, we have over 200 employees all over France ...",
  FIELD_COMPANY_URL: "URL address",
  FIELD_COMPANY_URL_PLACEHOLDER: "https://www.monentreprise.fr",
  FIELD_COMPANY_IMAGE_URL: "Image (URL)",
  FIELD_COMPANY_IMAGE_URL_PLACEHOLDER: "",
  CONTACT: "Contact",
  CONTACT_NAME: "Contact name",
  CONTACT_EMAIL: "E-mail adress",
  FIELD_OCCUPATION: "Trades",
  FIELD_CONTACT_NAME: "Contact name",
  FIELD_CONTACT_NAME_PLACEHOLDER: "Mr. Dupont",
  FIELD_CONTACT_EMAIL: "E-mail adress",
  FIELD_CONTACT_EMAIL_PLACEHOLDER: "dupond@monentreprise.fr",

  STEP_INTRO: "Introduction",
  STEP_INTRO_TITLE: "Introduction",
  STEP_INTRO_DESCRIPTION: "Please fill in the necessary fields of the job",

  STEP_CONTRACT: "Contract",
  STEP_CONTRACT_TITLE: "Contract specs",
  STEP_CONTRACT_DESCRIPTION:
    "Please fill in the contractual information related to the job",

  STEP_COMPANY_SPECIFICATION: "Company",
  STEP_COMPANY_SPECIFICATION_TITLE: "Company specification",
  STEP_COMPANY_SPECIFICATION_DESCRIPTION: "",

  STEP_CONFIRM: "Confirm",
  STEP_CONFIRM_TITLE: "Confirm job creation",
  STEP_CONFIRM_DESCRIPTION:
    "Please check the accuracy of the information provided",

  SUBTITLE_JOB: "Job",
  SUBTITLE_CONTRACT_SECTOR: "Sector",
  SUBTITLE_CONTRACT_CONDITIONS: "Conditions",
  SUBTITLE_CONTRACT_SALARY: "Salary",
  SUBTITLE_CONTRACT_DURATION: "Duration",
  SUBTITLE_CONTRACT_EXPERIENCE: "Experience",

  CATEGORY_ICON: "mdi-billiards-rack",

  PARSE_SUCCESS_ICON: "mdi-check-circle",
  PARSE_SUCCESS: "Parsed",

  PARSE_WAIT_ICON: "mdi-refresh-circle",
  PARSE_WAIT: "Waiting",

  PARSE_FAILED_ICON: "mdi-alert-circle",
  PARSE_FAILED: "Parsing has failed",

  PARSE_FAILED_FINAL_ICON: "mdi-alert-circle",
  PARSE_FAILED_FINAL: "Parsing has failed (no more retries)",

  DUPLICATION_FAILED_ICON: "mdi-alert-circle",
  DUPLICATION_FAILED: "Duplication has failed",

  PARSE_RUNNING_ICON: "mdi-refresh-circle",
  PARSE_RUNNING: "Parsing is running",

  // XTRAMILE_SECTOR: "XSector",
  // XTRAMILE_CATEGORY: "XCategory",
  // ESCO4_CODE: "Esco4 code",
  // ROME_CODE: "Rome code",
  // LOCATION: "Location",
  // GEO: "Geoloc",
  COMPANY_TITLE: "Company title",
  // COMPANY_TITLE: "Company title",
  // COMPANY_IMAGE: "Company image",
  // COMPANY_URL: "Company url",
  // CONTACT_NAME: "Contact name",
  // CONTACT_PHONE: "Contact phone",
  // CONTACT_EMAIL: "Contact email",
  // SALARY: "Salary",
  // MIN: "Min.",
  // MAX: "Max.",
  // AGE: "Age",
  // CONTRACT_TYPE: "Contract type",
  // CONTRACT_SCHEDULE: "Contract schedule",
  // CONTRACT_DETAILS: "Contract details",
  // CONTRACT_REMOTE: "No remote | Remote | Remote (partially)",
  // EXPERIENCE_LEVEL: "Experience level",
  // EXPERIENCE_DOMAINS: "Experience domains",
  // EDUCATION_LEVEL: "Education level",
  // EDUCATION_DOMAINS: "Education domains",
  // DIPLOMAS: "Diplomas",
  // EDUCATION_LANGUAGES: "Languages",

  EXACT_TEXT_MATCH: "Exact text match",
  MANDATORY: "Mandatory",

  DRAFT: "Draft",

  STATUS: "Show: All",
  STATUS_TYPE_ITEMS: [
    { text: "Show: Errors", value: "error" },
    { text: "Show: Waiting", value: "info" },
    { text: "Show: Parsing", value: "warning" },
  ],

  CONTRACT_SCHEDULES_ITEMS: [
    { value: "fulltime", text: "Full-time" },
    { value: "parttime", text: "Part-time" },
    { value: "fullpartime", text: "Full or part time" },
  ],

  CONTRACT_STATUS_ITEMS: [
    { value: "employee", text: "Employee" },
    { value: "frame", text: "Framework" },
  ],

  CONTRACT_TYPES_ITEMS: [
    { value: "permanent", text: "CDI" },
    { value: "fixed", text: "CDD" },
    { value: "interim", text: "Interim" },
    { value: "intermittent", text: "Intermittent" },
    { value: "internship", text: "Intern/Internship" },
    { value: "work_study", text: "Alternation" },
    { value: "apprenticeship", text: "Learning" },
    { value: "seasonal", text: "Seasonal" },
    { value: "volunteering", text: "Volunteering" },
    { value: "freelance", text: "Independent" },
  ],

  EXPERIENCE_LEVEL_ITEMS: [
    { value: "beginner", text: "Beginner (<2years)" },
    { value: "junior", text: "Junior (2-4 years)" },
    { value: "experienced", text: "Experienced (5-9 years old)" },
    { value: "senior", text: "Senior (> 10 years old)" },
  ],

  EDUCATION_LEVEL_ITEMS: [
    { value: "vocationnal", text: "CAP/BEP" },
    { value: "highschool", text: "Bac" },
    { value: "associate", text: "Bac+2" },
    { value: "bachelor", text: "Licence" },
    { value: "executive_bachelor", text: "Bac+4" },
    { value: "master", text: "Mastery" },
    { value: "executive_master", text: "Bac+6" },
    { value: "doctorant", text: "Doctorate" },
    { value: "executive_doctorant", text: "Bac+10" },
  ],

  PERIOD_ITEMS: [
    { value: "day", text: "Day" },
    { value: "week", text: "Week" },
    { value: "month", text: "Month" },
    { value: "year", text: "Year" },
  ],

  CURRENCY_ITEMS: [
    { value: "EUR", text: "Euro" },
    { value: "USD", text: "US dollar" },
    { value: "GBP", text: "Pound" },
  ],

  SECTOR: {
    1: "Agriculture, forestry, fishing",
    2: "Banking, Finance",
    3: "Building public works",
    4: "Distribution, Trade",
    5: "Communication, Advertising, Publishing",
    6: "Culture, Arts, Shows",
    7: "Teaching, Training",
    8: "Energy",
    9: "Hotel and catering",
    10: "IT, Technologies",
    11: "Health",
    12: "Defense, Security",
    13: "Particular services",
    14: "Logistics, Transport of goods",
    15: "Industry",
    16: "Pharmaceuticals, Biotechnologies",
    17: "Agri-food",
    18: "Automobile",
    19: "Aeronautics, Naval and Defense",
    20: "Electronics, Equipment",
    21: "Insurance, Mutual",
    22: "Audiovisual, Media",
    23: "Business services",
    24: "Business advice",
    25: "Wholesale, import / export",
    26: "Environment, Quality control",
    27: "Public service",
    28: "Real estate, Land",
    29: "Chemistry, Plastic",
    30: "Engineering",
    31: "E-commerce, Online services",
    32: "Human Resources, Recruitment",
    33: "Associative",
    34: "Audit, Accounting",
    35: "Sports",
    36: "Telecommunications",
    37: "Tourism, Leisure",
    38: "Landscaping, Green spaces",
  },

  XTRAMILE_CATEGORIES_ITEMS: [{ value: undefined, text: "coming soon" }],

  XTRAMILE_SECTORS_ITEMS: [
    { value: 1, text: "Agriculture, forestry, fishing" },
    { value: 2, text: "Banking, Finance" },
    { value: 3, text: "Building public works" },
    { value: 4, text: "Distribution, Trade" },
    { value: 5, text: "Communication, Advertising, Publishing" },
    { value: 6, text: "Culture, Arts, Shows" },
    { value: 7, text: "Teaching, Training" },
    { value: 8, text: "Energy" },
    { value: 9, text: "Hotel and catering" },
    { value: 10, text: "IT, Technologies" },
    { value: 11, text: "Health" },
    { value: 12, text: "Defense, Security" },
    { value: 13, text: "Particular services" },
    { value: 14, text: "Logistics, Transport of goods" },
    { value: 15, text: "Industry" },
    { value: 16, text: "Pharmaceuticals, Biotechnologies" },
    { value: 17, text: "Agri-food" },
    { value: 18, text: "Automobile" },
    { value: 19, text: "Aeronautics, Naval and Defense" },
    { value: 20, text: "Electronics, Equipment" },
    { value: 21, text: "Insurance, Mutual" },
    { value: 22, text: "Audiovisual, Media" },
    { value: 23, text: "Business services" },
    { value: 24, text: "Business advice" },
    { value: 25, text: "Wholesale, import / export" },
    { value: 26, text: "Environment, Quality control" },
    { value: 27, text: "Public service" },
    { value: 28, text: "Real estate, Land" },
    { value: 29, text: "Chemistry, Plastic" },
    { value: 30, text: "Engineering" },
    { value: 31, text: "E-commerce, Online services" },
    { value: 32, text: "Human Resources, Recruitment" },
    { value: 33, text: "Associative" },
    { value: 34, text: "Audit, Accounting" },
    { value: 35, text: "Sports" },
    { value: 36, text: "Telecommunications" },
    { value: 37, text: "Tourism, Leisure" },
    { value: 38, text: "Landscaping, Green spaces" },
  ],
  TITLES_FIELDS: {
    offer_information: "Offer Information",
    firstName: "First name",
    lastName: "Last name",
    address: "Address",
    postal_code: "Postal Code",
    city: "City",
    country: "Country",
    email: "Email",
    phone_number: "Phone number",
    birthday: "Birthday",
    nationality: "Nationality",
    driver_licence: "Driver's Licence",
    organisation_name: "Organisation Name",
    contract_type: "Contract type",
    duration: "Duration",
    occupations: "Occupations",
    occupation: "Occupation",
    school_name: "School Name",
    specialisation: "Specialization",
    level: "Level",
    company: "Company",
    mission: "Mission",
    hard_skills: "Hard skills",
    soft_skills: "Soft skills",
    languages: "Languages",
    skills: "Skills",
    date_start: "Start Date",
    date_end: "End Date",
    requirements: "Requirements",
    domain: "Domain",
    specialization: "Specialization",
    education_level: "Education level",
    experience_level: "Experience level",
    state: "State",
    schedule: "Schedule",
  },
  TOOLTIP: {
    UPLOAD_JOB: "Upload job",
    MATCHING_CRITERIA: "Matching criteria",
  },
}
