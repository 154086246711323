import Vue from 'vue'

// get image in db images object
const getImgSrc = (images, key, defaultImg) => {
  if (images) {
    const keys = Object.keys(images)
    if (keys.length > 0) {
      if (!key) key = 0
      if (typeof key === 'number') {
        const path = images[keys[key]]
        if (path) return path.startsWith('data:') ? path : process.env.VUE_APP_FILESHARE_PUBLIC + '/' + path
      } else {
        for (const k of keys) {
          if (k === key || k.includes(key)) {
            const path = images[key]
            if (path) return path.startsWith('data:') ? path : process.env.VUE_APP_FILESHARE_PUBLIC + '/' + path
          }
        }
      }
    }
  }
  return defaultImg
}

const vm = {
  install: function (Vue) {
    Vue.prototype.$getImgSrc = getImgSrc
  }
}
Vue.use(vm)

export default getImgSrc
