export default {
  TITLE: "Gestion des balises URL",
  TITLES: "Gestion des balises URL",
  ICON: "mdi-routes",
  ADD_MACRO: "Ajouter une macro",
  DELETE_MACRO: "Supprimer la macro",
  SAVE: "Sauvegarder",
  MACRO_NAME: "Nom de la macro",
  ADD_CONFIG: "Ajouter une configuration",
  REMOVE_CONFIG: "Supprimer la configuration",
}