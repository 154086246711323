import * as types from '@/store/mutations.types'
import concatSkillsArrays from '@/utils/dataProcess/concatSkillsArrays'
import getInterests from '@/utils/dataProcess/getInterests'
import transformationData from '@/utils/dataProcess/transformationData'
import dataProcess from '@/data/dataProcess'

const transformObject = (obj) => {
  const transfObj = {}
  for (let key in obj) {
    if(obj[key]) transfObj[key] = obj[key]
  }

  return transfObj
}

const {
  personalInfoKeys,
  educationKeys,
  experienceKeys,
  skillsKeys,
} = dataProcess

export default {
  [types.ADD_CV] (state, file) {
    const { id } = file
    const { id: source } = file.source
    const { data: identities } = file.identities
    const { infos: introductions } = file.introductions
    const { infos: titles } = file.titles
    const { data: educations } = file.educations
    const { data: experiences } = file.experiences
    const { occupations } = file.titles.infos
    const { image_url } = file.export
    const { occupations: positions } = file.position
    const { name: source_name } = file.source
    const { parsing_steps } = file.explanations
    const city = identities.city.plaintext
    const country = identities.country.plaintext
    const skills = concatSkillsArrays(file)
    const interests = getInterests(file)
    const firstName = identities.firstname || introductions.firstname || titles.firstname
    const lastName = identities.lastname || introductions.lastname || titles.lastname
    const fullName = `${firstName} ${lastName}`
    let occupation = ''
    let position = positions[0].plaintext || positions[0].ref.value
    const personalInfo = []
    const educationInfo = []
    const experienceInfo = []
    const skillsInfo = []

    if (occupations && occupations.length > 0) {
      occupations.forEach(item => {
        occupation = item.plaintext ? item.plaintext : occupation
      })
    }

    const personalObj = transformationData(personalInfoKeys, identities)
    const personalObjTransf = transformObject(personalObj)

    if (Object.keys(personalObjTransf).length !== 0)
      personalInfo.push(personalObjTransf)

    educations.forEach((i) => {
      const obj = transformationData(educationKeys, i)
      const objTransform = transformObject(obj)
      if (Object.keys(objTransform).length !== 0)
        educationInfo.push(objTransform)
    })

    experiences.forEach((i) => {
      const obj = transformationData(experienceKeys, i)
      const objTransform = transformObject(obj)
      if (Object.keys(objTransform).length !== 0)
        experienceInfo.push(objTransform)
    })

    skills.forEach((i) => {
      const obj = transformationData(skillsKeys, i)
      const objTransform = transformObject(obj)
      if (interests) objTransform.interests = interests
      if (Object.keys(objTransform).length !== 0)
        skillsInfo.push({ ...objTransform })
    })

    state.cv = {
      id,
      source,
      source_name,
      firstName,
      lastName,
      fullName,
      occupation,
      personalInfo,
      educationInfo,
      experienceInfo,
      skillsInfo,
      image_url,
      position,
      experiences,
      educations,
      city,
      country,
      parsing_steps,
    }
  },

  [types.ADD_CVS] (state, files) {
    state.cvs = files
  },

  [types.ADD_CV_MATCH] (state, file) {
    state.cvMatch = file.length ? file.map(({ id, result, scores: { score } }) => ({ id, ...result, score })) : []
  },
}
