import Vue from 'vue'

const _isEmpty = (v) => {
  if (v instanceof Array) return v.length < 1
  if (v instanceof Object) return Object.keys(v).length < 1
  if (v === 0) return false
  return !v
}

const _filters = new Map()

const _getValue = (element) => {
  if (element.value) return element.value
  if (element.$data && element.$data.internalLazyValue) return element.$data.internalLazyValue
  if (element.$data && element.$data.lazyValue) return element.$data.lazyValue
  return undefined
}

function queryFilters () {

  const refs = this.$refs || {}

  const get = (name) => {
    const tag = name || this.$vnode.tag
    if (_filters.has(tag)) {
      const filters = _filters.get(tag)
      Object.keys(filters).forEach((ref) => {
        if (filters[ref] && refs[ref]) {
          if (Object.hasOwnProperty.call(refs[ref], 'setValue')) {
            refs[ref].setValue.toString()
            refs[ref].setValue(filters[ref])
          }
          if (Object.hasOwnProperty.call(refs[ref].$data, 'lazyValue')) refs[ref].$data.lazyValue = filters[ref]
          if (Object.hasOwnProperty.call(refs[ref].$data, 'internalLazyValue')) refs[ref].$data.internalLazyValue = filters[ref]
        }
      })
    }
  }

  const save = (name) => {
    const tag = name || this.$vnode.tag
    const saved = {}
    // console.log('SAVE FILTERS:')
    // console.log('ORIGINAL:', refs)
    Object.keys(refs).forEach((ref) => {
      if (ref.includes('where.') || ref.includes('options.')) {
        const value = _getValue(refs[ref])
        // console.log(ref, "=", value)
        if (!_isEmpty(value)) {
          saved[ref] = value
        }
      }
    })
    // console.log('')
    _filters.set(tag, saved)
  }

  const clear = (name) => {
    const tag = name || this.$vnode.tag
    if (_filters.has(tag)) {
      const filters = _filters.get(tag)
      Object.keys(filters).forEach((ref) => {
        if (filters[ref] && refs[ref]) {
          if (Object.hasOwnProperty.call(refs[ref], 'setValue')) {
            refs[ref].setValue.toString()
            refs[ref].setValue(undefined)
          }
          if (Object.hasOwnProperty.call(refs[ref].$data, 'lazyValue')) refs[ref].$data.lazyValue = undefined
          if (Object.hasOwnProperty.call(refs[ref].$data, 'internalLazyValue')) refs[ref].$data.internalLazyValue = undefined
        }
      })
      _filters.delete(tag)
    }
  }

  const count = (name) => {
    const tag = name || this.$vnode.tag
    if (_filters.has(tag)) {
      const filters = _filters.get(tag)
      return Object.keys(filters).length
    }
    return 0
  }

  return {
    get,
    save,
    clear,
    count
  }
}


const vm = {
  install: function (Vue) {
    Vue.prototype.$queryFilters = queryFilters
  }
}

Vue.use(vm)


export default queryFilters
