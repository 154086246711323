export default {
  TITLE: "Création",
  TITLES: "Créations",
  TITLE_COUNT: "création | 1 création | {n} créations",
  ICON: "mdi-image-area",
  TEMPLATE: "modèle",
  CONTENT: "Contenu",
  PARAMS: "Paramètres avancés",

  CHOOSE_CREATIVE: "Choisissez une création",

  FORM_TITLE_UPDATE: "Modifier une création",
  FORM_TITLE_NEW: "Nouvelle création",
  FORM_TITLE_CREATE: "Ajouter une création",
  FIELD_TITLE: "Titre",
  FIELD_DESCRIPTION: "Description",

  TYPE_ITEMS: [
    { value: 'html', text: "Bannière: HTML" },
    { value: 'image', text: "Bannière: Image" },
    { value: 'carousel', text: "Carousel: +3 images" },
    //
  ],
}
