export default {
  personalInfoKeys: [
    "address",
    "postal_code",
    "city",
    "country",
    "email",
    "phone_number",
    "birthday",
    "nationality",
    "driver_licence",
  ],
  educationKeys: ["school_name", "specialisation", "level"],
  experienceKeys: [
    "organisation_name",
    "contract_type",
    "duration",
    "occupations",
  ],
  skillsKeys: ["hard_skill", "soft_skill", "language"],
  offerInformationsKey: [
    "company",
    "postal_code",
    "city",
    "state",
    "country",
    "contract_type",
    "contract_duration",
    "occupations",
    "schedule",
    "date_start",
    "date_end",
    "driving_licence",
    "education_level",
    "experience_level",
  ],
  offerSkillsKeys: ["hard_skills", "soft_skills", "languages"],
  offerExperienceKeys: ["occupations", "duration", "domain"],
  offerEducationKeys: ["level", "specialization"],
  offerEscoKeys: [
    "basic_competencies",
    "basic_knowledge",
    "advanced_competencies",
    "advanced_knowledge",
  ],
}
