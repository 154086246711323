<template>

  <div class="page page-login primary">
    <div class="page-content">

      <Dialog
        :value="showDialogRecovery"
        icon="mdi-restore"
        :subtitle="this.$i18n.t('LOGIN.PAGE_LOGIN_FORGOT_PASSWORD_TITLE')"
        width="450px"
        component="forms/login/compact/FormLoginRecoveryCompact"
        @close="showDialogRecovery = false"
      />
      <v-card flat class="pa-0 pt-6 elevation-5 white">
        <div class="text-center">
          <img src="/img/xtramile-logo-dark.svg" class="logo mb-6 mt-6" >
          <!-- <img src="/img/xtramile-logo-animated.gif" /> -->
        </div>

        <FormLogin />

        <v-divider />
        <div class="text-center pa-4 secondary" @click="showDialogRecovery = true">
          <p class="mb-0"><strong>{{ $t('LOGIN.PAGE_LOGIN_FORGOT_PASSWORD') }}</strong></p>
          <small><a>{{ $t('LOGIN.PAGE_LOGIN_FORGOT_PASSWORD_BTN') }}</a></small>
        </div>

        <v-divider />

      </v-card>
    </div>

    <div class="page-login-background">&nbsp;</div>
  </div>

</template>


<script>
import Dialog from '@/components/Dialog'
import FormLogin from '@/forms/login/compact/FormLoginCompact'

export default {
  name: 'PageLoginAll',
  components: {
    Dialog,
    FormLogin,
  },
  data: () => ({
    showDialogRecovery: false,
  }),
  methods: {},
}
</script>


<style lang="scss">
.page-login {
  height: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}

.page-login::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: inherit;
  background-image: url("/img/bg/people-working.jpg");
  background-position: center center;
  background-size: cover;
  background-blend-mode: overlay;
  opacity: 0.3;
}

.st0 {
  fill: #FFFFFF;
}
.st1 {
  fill: #E0BC37;
}

.page-login-background {
  content: "";
  position: absolute;
  right: -50vh;
  bottom: -55vh;
  height: 200vh;
  width: 200vh;
  opacity: 1;
  display: none;
}

.page-login .page-content {
  z-index: 2;
  width: 400px;
  max-width: 100%;
}

.page-login .page-content img {
  width: 250px;
}
</style>
