function concatSkillsArrays(cv) {
  const { data } = cv.skills;
  const { hard_skills, soft_skills } = cv.introductions.infos;
  const { languages: langs } = cv.languages.infos;
  const hardSkills = [];
  const softSkills = [];
  const languages = [];

  if (data && data.length > 0) {
    data.map(({ hard_skill }) => {
      if (hard_skill && hard_skill.length > 0) {
        hard_skill.map(skillsItem => skillsItem.ref.value !== '' && hardSkills.push(skillsItem.ref.value));
      }
      return null;
    });
    
    data.map(({ soft_skill }) => {
      if (soft_skill && soft_skill.length > 0) {
        soft_skill.map(skillsItem => skillsItem.ref.value !== '' && softSkills.push(skillsItem.ref.value));
      }
      return null;
    });
    
    data.map(({ language }) => {
      if (language && language.length > 0) {
        language.map(skillsItem => skillsItem.ref.value !== '' && languages.push(skillsItem.ref.value));
      }
      return null;
    });
  }

  if (hard_skills && hard_skills.length > 0) {
    hard_skills.map(skillsItem => skillsItem?.ref?.value && hardSkills.push(skillsItem.ref.value));
  }

  if (soft_skills && soft_skills.length > 0) {
    soft_skills.map(skillsItem => skillsItem?.ref?.value && softSkills.push(skillsItem.ref.value));
  }

  if (langs && langs.length > 0) {
    langs.map(langItem => langItem?.ref?.value && languages.push(langItem.ref.value));
  }

  return [{
    hard_skill: hardSkills.join(', '),
    soft_skill: softSkills.join(', '),
    language: languages.join(', '),
  }];
}

export default concatSkillsArrays;
