import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../pages/login/all/page.login.all.vue";
import store from "../store";
import permission from "../plugins/permission";
import services from "@/plugins/services";

const checkPermission = (name, related_id, next) => {
  // async user permission checking,
  // waiting the app is ready and store restored
  return permission
    .check(name, related_id)
    .then((check) => {
      if (check) return next();
      return next("/not-found");
    })
    .catch(() => {
      return next("/not-found");
    });
};

const checkOfficeAndUserPermission = (
  name,
  related_id,
  next,
  needOptionsToAccess = true
) => {
  const options = store.state.office.office.options;
  const hasOptionsToAccess =
    options?.multidiff !== undefined
      ? options?.multidiff === needOptionsToAccess
      : needOptionsToAccess === true;

  if (!hasOptionsToAccess) return next("/not-found");
  return permission
    .check(name, related_id)
    .then((check) => {
      if (check) return next();
      return next("/not-found");
    })
    .catch(() => {
      return next("/not-found");
    });
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },

  /* Home */

  {
    path: "/",
    name: "Home",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../pages/home/all/page.home.all.vue"
      ),
  },

  {
    path: "/notifications",
    name: "Notifications",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "home" */ "../pages/notifications/all/page.notifications.all.vue"
      ),
  },

  /* Settings */

  {
    path: "/settings",
    name: "SettingsAll",
    component: () =>
      import(
        /* webpackChunkName: "settings" */ "../pages/settings/all/page.settings.all.vue"
      ),
    beforeEnter(to, from, next) {
      next();
    },
  },

  /* Settings account (user) */
  //ACCOUNT
  {
    path: "/settings/account",
    name: "Account",
    component: () =>
      import(
        /* webpackChunkName: "admin-products" */ "../pages/settings/all/page.settings.all.account.vue"
      ),
    beforeEnter(to, from, next) {
      next();
    },
  },

  /* Settings manager */
  {
    path: "/settings/manager/office",
    name: "SettingsManagerOffice",
    component: () =>
      import(
        /* webpackChunkName: "settings-manager-office" */ "../pages/settings/manager/page.settings.manager.office.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.manager", undefined, next);
    },
  },
  {
    path: "/settings/manager/offices",
    name: "SettingsManagerOffices",
    component: () =>
      import(
        /* webpackChunkName: "settings-admin-offices" */ "../pages/settings/manager/page.settings.manager.offices.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.manager", undefined, next);
    },
  },
  {
    path: "/settings/manager/users",
    name: "SettingsManagerUser",
    component: () =>
      import(
        /* webpackChunkName: "settings-manager-users" */ "../pages/settings/manager/page.settings.manager.users.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.manager", undefined, next);
    },
  },
  {
    path: "/settings/manager/url_tag_manager",
    name: "SettingsAdminPublicationUrl",
    component: () =>
      import(
        /* webpackChunkName: "settings-manager-publication_url" */ "../pages/settings/all/page.settings.all.publication_url.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.manager", undefined, next);
    },
  },
  {
    path: "/settings/manager/application_email_manager",
    name: "SettingsAdminApplicationEmail",
    component: () =>
      import(
        /* webpackChunkName: "settings-manager-application_email" */ "../pages/settings/all/page.settings.all.application_email.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.manager", undefined, next);
    },
  },

  /* Settings admin */
  {
    path: "/settings/admin/users",
    name: "SettingsAdminUsers",
    component: () =>
      import(
        /* webpackChunkName: "settings-admin-users" */ "../pages/settings/admin/page.settings.admin.users.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.admin", undefined, next);
    },
  },
  {
    path: "/settings/admin/company",
    name: "SettingsAdminCompany",
    component: () =>
      import(
        /* webpackChunkName: "settings-admin-company" */ "../pages/settings/admin/page.settings.admin.company.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.admin", undefined, next);
    },
  },
  {
    path: "/settings/admin/offices",
    name: "SettingsAdminOffices",
    component: () =>
      import(
        /* webpackChunkName: "settings-admin-offices" */ "../pages/settings/admin/page.settings.admin.offices.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.admin", undefined, next);
    },
  },
  {
    path: "/settings/admin/url_tag_manager",
    name: "SettingsAdminPublicationUrl",
    component: () =>
      import(
        /* webpackChunkName: "settings-admin-publication_url" */ "../pages/settings/all/page.settings.all.publication_url.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.admin", undefined, next);
    },
  },
  {
    path: "/settings/admin/application_email_manager",
    name: "SettingsAdminApplicationEmail",
    component: () =>
      import(
        /* webpackChunkName: "settings-manager-application_email" */ "../pages/settings/all/page.settings.all.application_email.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.admin", undefined, next);
    },
  },

  /* Settings Xadmin */
  {
    path: "/settings/xadmin/company",
    name: "SettingsXadminCompany",
    component: () =>
      import(
        /* webpackChunkName: "settings-xadmin-company" */ "../pages/settings/xadmin/page.settings.xadmin.company.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.xadmin", undefined, next);
    },
  },
  {
    path: "/settings/xadmin/office",
    name: "SettingsXadminOffice",
    component: () =>
      import(
        /* webpackChunkName: "settings-xadmin-office" */ "../pages/settings/xadmin/page.settings.xadmin.office.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.xadmin", undefined, next);
    },
  },
  {
    path: "/settings/xadmin/companies",
    name: "SettingsXadminCompanies",
    component: () =>
      import(
        /* webpackChunkName: "settings-xadmin-companies" */ "../pages/settings/xadmin/page.settings.xadmin.companies.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.xadmin", undefined, next);
    },
  },
  {
    path: "/settings/xadmin/offices",
    name: "SettingsXadminOffices",
    component: () =>
      import(
        /* webpackChunkName: "settings-xadmin-offices" */ "../pages/settings/xadmin/page.settings.xadmin.offices.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.xadmin", undefined, next);
    },
  },
  {
    path: "/settings/xadmin/users",
    name: "SettingsXadminUsers",
    component: () =>
      import(
        /* webpackChunkName: "settings-xadmin-users" */ "../pages/settings/xadmin/page.settings.xadmin.users.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.xadmin", undefined, next);
    },
  },
  {
    path: "/settings/xadmin/channels",
    name: "SettingsXadminChannels",
    component: () =>
      import(
        /* webpackChunkName: "settings-xadmin-channels" */ "../pages/settings/xadmin/page.settings.xadmin.channels.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.xadmin", undefined, next);
    },
  },
  {
    path: "/settings/xadmin/roles",
    name: "SettingsXadminRoles",
    component: () =>
      import(
        /* webpackChunkName: "settings-xadmin-roles" */ "../pages/settings/xadmin/page.settings.xadmin.roles.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.xadmin", undefined, next);
    },
  },
  {
    path: "/settings/xadmin/products",
    name: "SettingsXadminProducts",
    component: () =>
      import(
        /* webpackChunkName: "settings-xadmin-products" */ "../pages/settings/xadmin/page.settings.xadmin.products.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.xadmin", undefined, next);
    },
  },
  {
    path: "/settings/xadmin/currencies",
    name: "SettingsXadminCurrencies",
    component: () =>
      import(
        /* webpackChunkName: "settings-xadmin-currencies" */ "../pages/settings/xadmin/page.settings.xadmin.currencies.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.xadmin", undefined, next);
    },
  },
  {
    path: "/settings/xadmin/countries",
    name: "SettingsXadminCountries",
    component: () =>
      import(
        /* webpackChunkName: "settings-xadmin-countries" */ "../pages/settings/xadmin/page.settings.xadmin.countries.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.xadmin", undefined, next);
    },
  },
  {
    path: "/settings/xadmin/services",
    name: "SettingsXadminServices",
    component: () =>
      import(
        /* webpackChunkName: "settings-xadmin-services" */ "../pages/settings/xadmin/page.settings.xadmin.services.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.xadmin", undefined, next);
    },
  },
  {
    path: "/settings/xadmin/url_tag_manager",
    name: "SettingsXadminPublicationUrl",
    component: () =>
      import(
        /* webpackChunkName: "settings-xadmin-publication_url" */ "../pages/settings/all/page.settings.all.publication_url.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.xadmin", undefined, next);
    },
  },
  {
    path: "/settings/xadmin/application_email_manager",
    name: "SettingsXadminApplicationEmail",
    component: () =>
      import(
        /* webpackChunkName: "settings-manager-application_email" */ "../pages/settings/all/page.settings.all.application_email.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("role.xadmin", undefined, next);
    },
  },

  /* Xdifusion */

  {
    path: "/xdiffusion/jobs",
    name: "XdiffusionAllJobs",
    component: () =>
      import(
        /* webpackChunkName: "xdiffusion-all-jobs" */ "../pages/xdiffusion/all/page.xdiffusion.all.jobs.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("product.xdiffusion", undefined, next);
    },
  },
  {
    path: "/xdiffusion/publications",
    name: "XdiffusionAllPublication",
    component: () =>
      import(
        /* webpackChunkName: "xdiffusion-all-publications" */ "../pages/xdiffusion/all/page.xdiffusion.all.publications.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("product.xdiffusion", undefined, next);
    },
  },
  {
    path: "/xdiffusion/channel_subscriptions",
    name: "XdiffusionAllChannelSubscriptions",
    component: () =>
      import(
        /* webpackChunkName: "xdiffusion-all-channel_subscriptions" */ "../pages/xdiffusion/all/page.xdiffusion.all.channel_subscriptions.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("product.xdiffusion", undefined, next);
    },
  },
  {
    path: "/xdiffusion/ratios",
    name: "XdiffusionAllRatios",
    component: () =>
      import(
        /* webpackChunkName: "xdiffusion-all-ratios" */ "../pages/xdiffusion/all/page.xdiffusion.all.ratios.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("product.xdiffusion", undefined, next);
    },
  },

  /* Xtrading */

  {
    path: "/xtrading/jobs",
    name: "XtradingAllJobs",
    component: () =>
      import(
        /* webpackChunkName: "xtrading-all-jobs" */ "../pages/xtrading/all/page.xtrading.all.jobs.vue"
      ),
    beforeEnter(to, from, next) {
      // checkPermission("product.xtrading", undefined, next)
      checkPermission("product.xtrading", undefined, next);
    },
  },
  {
    path: "/xtrading/publications",
    name: "XtradingAllPublication",
    component: () =>
      import(
        /* webpackChunkName: "xdiffusion-all-publications" */ "../pages/xtrading/all/page.xtrading.all.publications.vue"
      ),
    beforeEnter(to, from, next) {
      // checkPermission("product.xtrading", undefined, next)
      checkOfficeAndUserPermission("product.xtrading", undefined, next, false);
    },
  },
  {
    path: "/xtrading/feeds",
    name: "XtradingAllFeeds",
    component: () =>
      import(
        /* webpackChunkName: "xtrading-all-feeds" */ "../pages/xtrading/all/page.xtrading.all.feeds.vue"
      ),
    beforeEnter(to, from, next) {
      checkOfficeAndUserPermission("product.xtrading", undefined, next, true);
    },
  },
  {
    path: "/xtrading/campaigns",
    name: "XtradingAllCampaigns",
    component: () =>
      import(
        /* webpackChunkName: "xtrading-all-campaigns" */ "../pages/xtrading/all/page.xtrading.all.campaigns.vue"
      ),
    beforeEnter(to, from, next) {
      checkOfficeAndUserPermission("product.xtrading", undefined, next, true);
    },
  },
  {
    path: "/xtrading/campaign/:id",
    // name: "XtradingAllCampaign",
    component: () =>
      import(
        /* webpackChunkName: "xtrading-all-campaign" */ "../pages/xtrading/all/page.xtrading.all.campaign.vue"
      ),
    beforeEnter(to, from, next) {
      checkOfficeAndUserPermission("product.xtrading", undefined, next, true);
    },
    children: [
      {
        path: "",
        name: "XtradingAllCampaignOverview",
        component: () =>
          import(
            /* webpackChunkName: "xtrading-all-campaign-overview" */ "../pages/xtrading/all/page.xtrading.all.campaign.overview.vue"
          ),
        beforeEnter(to, from, next) {
          checkPermission("product.xtrading", undefined, next);
        },
      },
      {
        path: "publications",
        name: "XtradingAllCampaignPublications",
        component: () =>
          import(
            /* webpackChunkName: "xtrading-all-campaign-publications" */ "../pages/xtrading/all/page.xtrading.all.campaign.publications.vue"
          ),
        beforeEnter(to, from, next) {
          checkOfficeAndUserPermission(
            "product.xtrading",
            undefined,
            next,
            true
          );
        },
      },
      {
        path: "jobs",
        name: "XtradingAllCampaignJobs",
        component: () =>
          import(
            /* webpackChunkName: "xtrading-all-campaign-jobs" */ "../pages/xtrading/all/page.xtrading.all.campaign.jobs.vue"
          ),
        beforeEnter(to, from, next) {
          checkOfficeAndUserPermission(
            "product.xtrading",
            undefined,
            next,
            true
          );
        },
      },
      {
        path: "rules",
        name: "XtradingAllCampaignRules",
        component: () =>
          import(
            /* webpackChunkName: "xtrading-all-campaign-rules" */ "../pages/xtrading/all/page.xtrading.all.campaign.rules.vue"
          ),
        beforeEnter(to, from, next) {
          checkOfficeAndUserPermission(
            "product.xtrading",
            undefined,
            next,
            true
          );
        },
      },
      {
        path: "imports",
        name: "XtradingAllCampaignImports",
        component: () =>
          import(
            /* webpackChunkName: "xtrading-all-campaign-imports" */ "../pages/xtrading/all/page.xtrading.all.campaign.imports.vue"
          ),
        beforeEnter(to, from, next) {
          checkOfficeAndUserPermission(
            "product.xtrading",
            undefined,
            next,
            true
          );
        },
      },
    ],
  },
  {
    path: "/xtrading/channel_subscriptions",
    name: "XtradingAllChannelSubscriptions",
    component: () =>
      import(
        /* webpackChunkName: "xtrading-all-channel_subscriptions" */ "../pages/xtrading/all/page.xtrading.all.channel_subscriptions.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("product.xtrading", undefined, next);
    },
  },
  {
    path: "/xtrading/ratios",
    name: "XtradingAllRatios",
    component: () =>
      import(
        /* webpackChunkName: "xtrading-all-ratios" */ "../pages/xtrading/all/page.xtrading.all.ratios.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("product.xtrading", undefined, next);
    },
  },

  /* Xprogrammatic */

  {
    path: "/xprogrammatic/jobs",
    name: "XprogrammaticAllJobs",
    component: () =>
      import(
        /* webpackChunkName: "xprogrammatic-all-jobs" */ "../pages/xprogrammatic/all/page.xprogrammatic.all.jobs.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("product.xprogrammatic", undefined, next);
    },
  },
  {
    path: "/xprogrammatic/campaigns",
    name: "XprogrammatAllCampaigns",
    component: () =>
      import(
        /* webpackChunkName: "xprogrammatic-all-campaigns" */ "../pages/xprogrammatic/all/page.xprogrammatic.all.campaigns.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("product.xprogrammatic", undefined, next);
    },
  },
  {
    path: "/xprogrammatic/campaign/:id",
    // name: "XprogrammAllCampaign",
    component: () =>
      import(
        /* webpackChunkName: "xprogrammatic-all-campaign" */ "../pages/xprogrammatic/all/page.xprogrammatic.all.campaign.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("product.xprogrammatic", undefined, next);
    },
    children: [
      {
        path: "",
        name: "XprogrammaticAllCampaignOverview",
        component: () =>
          import(
            /* webpackChunkName: "xprogrammatic-all-campaign-overview" */ "../pages/xprogrammatic/all/page.xprogrammatic.all.campaign.overview.vue"
          ),
        beforeEnter(to, from, next) {
          checkPermission("product.xprogrammatic", undefined, next);
        },
      },
      {
        path: "publications",
        name: "XprogrammaticAllCampaignPublications",
        component: () =>
          import(
            /* webpackChunkName: "xprogrammatic-all-campaign-publications" */ "../pages/xprogrammatic/all/page.xprogrammatic.all.campaign.publications.vue"
          ),
        beforeEnter(to, from, next) {
          checkPermission("product.xprogrammatic", undefined, next);
        },
      },
      {
        path: "jobs",
        name: "XprogrammaticAllCampaignJobs",
        component: () =>
          import(
            /* webpackChunkName: "xprogrammatic-all-campaign-jobs" */ "../pages/xprogrammatic/all/page.xprogrammatic.all.campaign.jobs.vue"
          ),
        beforeEnter(to, from, next) {
          checkPermission("product.xprogrammatic", undefined, next);
        },
      },
      {
        path: "rules",
        name: "XprogrammaticAllCampaignRules",
        component: () =>
          import(
            /* webpackChunkName: "xprogrammatic-all-campaign-rules" */ "../pages/xprogrammatic/all/page.xprogrammatic.all.campaign.rules.vue"
          ),
        beforeEnter(to, from, next) {
          checkPermission("product.xprogrammatic", undefined, next);
        },
      },
      {
        path: "imports",
        name: "XprogrammaticAllCampaignImports",
        component: () =>
          import(
            /* webpackChunkName: "xprogrammatic-all-campaign-imports" */ "../pages/xprogrammatic/all/page.xprogrammatic.all.campaign.imports.vue"
          ),
        beforeEnter(to, from, next) {
          checkPermission("product.xprogrammatic", undefined, next);
        },
      },
    ],
  },
  {
    path: "/xprogrammatic/feeds",
    name: "XprogrammaticAllFeeds",
    component: () =>
      import(
        /* webpackChunkName: "xprogrammatic-all-feeds" */ "../pages/xprogrammatic/all/page.xprogrammatic.all.feeds.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("product.xprogrammatic", undefined, next);
    },
  },
  {
    path: "/xprogrammatic/creatives",
    name: "XprogrammaticAllCreativess",
    component: () =>
      import(
        /* webpackChunkName: "xprogrammatic-all-creatives" */ "../pages/xprogrammatic/all/page.xprogrammatic.all.creatives.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("product.xprogrammatic", undefined, next);
    },
  },
  {
    path: "/xprogrammatic/channel_subscriptions",
    name: "XprogrammaticAllChannelSubscriptions",
    component: () =>
      import(
        /* webpackChunkName: "xprogrammatic-all-channel_subscriptions" */ "../pages/xprogrammatic/all/page.xprogrammatic.all.channel_subscriptions.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("product.xprogrammatic", undefined, next);
    },
  },
  {
    path: "/xprogrammatic/ratios",
    name: "XprogrammaticAllRatios",
    component: () =>
      import(
        /* webpackChunkName: "xprogrammatic-all-ratios" */ "../pages/xprogrammatic/all/page.xprogrammatic.all.ratios.vue"
      ),
    beforeEnter(to, from, next) {
      checkPermission("product.xprogrammatic", undefined, next);
    },
  },

  {
    path: "/xranking/offers",
    name: "XrankingAllOffers",
    component: () =>
      import(
        /* webpackChunkName: "xranking-all-cvs" */ "../pages/xranking/all/page.xranking.all.offers"
      ),
    beforeEnter(to, from, next) {
      checkPermission("product.xranking", undefined, next);
    },
  },

  {
    path: "/xranking/offers/:id",
    name: "OfferParsing",
    component: () =>
      import(
        /* webpackChunkName: "cv_parsing" */ "../pages/xranking/all/page.xranking.all.offer"
      ),
  },

  {
    path: "/xranking/matching/offers/:id",
    name: "OfferMatching",
    component: () =>
      import(
        /* webpackChunkName: "cv_parsing" */ "../pages/xranking/all/page.xranking.all.matching.offers"
      ),
  },

  {
    path: "/xranking/cvs",
    name: "XrankingAllCvs",
    component: () =>
      import(
        /* webpackChunkName: "xranking-all-cvs" */ "../pages/xranking/all/page.xranking.all.cvs"
      ),
    beforeEnter(to, from, next) {
      checkPermission("product.xranking", undefined, next);
    },
  },

  {
    path: "/xranking/cvs/:id",
    name: "XrankingAllCv",
    component: () =>
      import(
        /* webpackChunkName: "xranking-all-cv" */ "../pages/xranking/all/page.xranking.all.cv.vue"
      ),
  },

  {
    path: "/xranking/matching/cvs/:id",
    name: "CVMatching",
    component: () =>
      import(
        /* webpackChunkName: "offer_parsing" */ "../pages/xranking/all/page.xranking.all.matching.cvs"
      ),
  },

  {
    path: "/xranking/upload/offer",
    name: "Upload",
    component: () =>
      import(
        /* webpackChunkName: "offer_parsing" */ "../pages/xranking/all/page.xranking.all.upload"
      ),
  },

  {
    path: "/xranking/upload/cv",
    name: "Upload",
    component: () =>
      import(
        /* webpackChunkName: "offer_parsing" */ "../pages/xranking/all/page.xranking.all.upload"
      ),
  },

  {
    path: "/xranking/transformation",
    name: "Transformation",
    component: () =>
      import(
        /* webpackChunkName: "offer_parsing" */ "../pages/xranking/all/page.xranking.all.transformation"
      ),
  },

  {
    path: "/ximpact/xparsing/jobs",
    name: "XparsingJobs",
    component: () =>
      import(
        /* webpackChunkName: "xranking-all-cvs" */ "../pages/ximpact/all/page.ximpact.all.parsingjobs"
      ),
    beforeEnter(to, from, next) {
      checkPermission("product.xbi", undefined, next);
    },
  },

  {
    path: "/ximpact/power-bi-page",
    name: "PowerBiPage",
    component: () =>
      import(
        /* webpackChunkName: "xranking-all-cvs" */ "../pages/ximpact/all/page.ximpact.all.powerbi"
      ),
  },
  //
  // // CV parsing OVERVIEW
  // {
  //   path: "/cv_parsing/preview",
  //   name: "CVParsingPreview",
  //   component: () => import(/* webpackChunkName: "cv_parsing" */ "../pages/PageCVParsing/PageCVParsingPreview.vue"),
  // },
  //
  // // CHANNEL SUBSCRIPTION
  // {
  //   path: "/medias",
  //   name: "ChannelSubscriptions",
  //   component: () => import(/* webpackChunkName: "medias" */ "../pages/PageChannelSubscriptions/PageChannelSubscriptions.vue"),
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "medias" */ "../pages/PageChannelSubscriptions/PageChannelSubscriptionsList.vue"),
  //     }
  //   ]
  // },
  // {
  //   path: "/media/:id",
  //   name: "ChannelSubscription",
  //   component: () => import(/* webpackChunkName: "media" */ "../pages/PageChannelSubscription/PageChannelSubscription.vue"),
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "media" */ "../pages/PageChannelSubscription/PageChannelSubscriptionOverview.vue"),
  //     }
  //   ]
  // },
  //
  // // URL
  // {
  //   path: "/publications",
  //   name: "Publications",
  //   component: () => import(/* webpackChunkName: "ratio" */ "../pages/PagePublications/PagePublications.vue"),
  //   children: [
  //     // {
  //     //   path: '',
  //     //   component: () => import(/* webpackChunkName: "campaigns" */ "../pages/PagePublication/PagePublicationsList.vue"),
  //     // },
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "campaigns" */ "../pages/PagePublications/PagePublicationsOverview.vue"),
  //     }
  //   ]
  // },
  //
  // // RATIOS
  // {
  //   path: "/ratios",
  //   name: "Ratios",
  //   component: () => import(/* webpackChunkName: "ratios" */ "../pages/PageRatios/PageRatios.vue"),
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "ratios_overview" */ "../pages/PageRatios/PageRatiosOverview.vue"),
  //     },
  //     {
  //       path: 'channels',
  //       component: () => import(/* webpackChunkName: "ratios_channels" */ "../pages/PageRatios/PageRatiosChannels.vue"),
  //     }
  //   ]
  // },
  //
  // // CAMPAIGNS
  // {
  //   path: "/campaigns",
  //   name: "Campaigns",
  //   component: () => import(/* webpackChunkName: "campaigns" */ "../pages/PageCampaigns/PageCampaigns.vue"),
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "campaigns" */ "../pages/PageCampaigns/PageCampaignsList.vue"),
  //     }
  //   ]
  // },
  //
  // // CAMPAIGN
  // {
  //   path: "/campaign/:id",
  //   name: "Campaign",
  //   component: () => import(/* webpackChunkName: "campaign" */ "../pages/PageCampaign/PageCampaign.vue"),
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "campaign" */ "../pages/PageCampaign/PageCampaignOverview.vue"),
  //     },
  //     {
  //       path: 'jobs',
  //       component: () => import(/* webpackChunkName: "campaign" */ "../pages/PageCampaign/PageCampaignJobs.vue"),
  //     },
  //     {
  //       path: 'feeds',
  //       component: () => import(/* webpackChunkName: "campaign" */ "../pages/PageCampaign/PageCampaignFeeds.vue"),
  //     }
  //   ]
  // },
  //
  //
  // // JOBS
  // {
  //   path: "/jobs",
  //   name: "Jobs",
  //   component: () => import(/* webpackChunkName: "jobs" */ "../pages/PageJobs/PageJobs.vue"),
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "jobs" */ "../pages/PageJobs/PageJobsList.vue"),
  //     }
  //   ]
  // },
  //
  // {
  //   path: "/job/:id",
  //   name: "Job",
  //   component: () =>
  //     import(/* webpackChunkName: "jobs" */ "../pages/PageJob/PageJob.vue"),
  //   children: [
  //     {
  //       path: "",
  //       component: () =>
  //         import(
  //           /* webpackChunkName: "jobs" */ "../pages/PageJob/PageJobOverview.vue"
  //         ),
  //     },
  //   ],
  // },
  //
  //
  // // FEEDS
  // {
  //   path: "/feeds",
  //   name: "Feeds",
  //   component: () => import(/* webpackChunkName: "feeds" */ "../pages/PageFeeds/PageFeeds.vue"),
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "feeds" */ "../pages/PageFeeds/PageFeedsList.vue"),
  //     }
  //   ]
  // },
  //
  // // FEED OVERVIEW
  // {
  //   path: "/feed/:id",
  //   name: "Feed",
  //   component: () =>
  //     import(/* webpackChunkName: "jobs" */ "../pages/PageFeed/PageFeed.vue"),
  //   children: [
  //     {
  //       path: "",
  //       component: () => import(/* webpackChunkName: "jobs" */ "../pages/PageFeed/PageFeedOverview.vue"),
  //     },
  //   ],
  // },
  //
  //
  // // CREATIVES
  // {
  //   path: "/creatives",
  //   name: "Creatives",
  //   component: () => import(/* webpackChunkName: "creatives" */ "../pages/PageCreatives/PageCreatives.vue"),
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "creatives" */ "../pages/PageCreatives/PageCreativesList.vue"),
  //     }
  //   ]
  // },
  //
  // //CREATIVES OVERVIEW
  // {
  //   path: "/creative/:id",
  //   name: "Creative",
  //   component: () => import(/* webpackChunkName: "creative" */ "../pages/PageCreative/PageCreative.vue"),
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "creative" */ "../pages/PageCreative/PageCreativeOverview.vue"),
  //     }
  //   ]
  // },
  //
  //
  // // ADMIN CHANNELS
  // {
  //   path: "/admin/channels",
  //   name: "Channels",
  //   component: () => import(/* webpackChunkName: "channels" */ "../pages/PageAdminChannels/PageAdminChannels.vue"),
  //   beforeEnter (to, from, next) {
  //     _checkXtramileUser(next)
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "channels" */ "../pages/PageAdminChannels/PageAdminChannelsList.vue"),
  //     }
  //   ]
  // },
  //
  //
  // // ADMIN COMPANIES
  // {
  //   path: "/admin/companies",
  //   name: "Companies",
  //   component: () => import(/* webpackChunkName: "admin-companies" */ "../pages/PageAdminCompanies/PageAdminCompanies.vue"),
  //   beforeEnter (to, from, next) {
  //     _checkXtramileUser(next)
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "admin-companies" */ "../pages/PageAdminCompanies/PageAdminCompaniesList.vue"),
  //     }
  //   ]
  // },
  //
  //
  // // ADMIN COUNTRIES
  // {
  //   path: "/admin/countries",
  //   name: "Countries",
  //   component: () => import(/* webpackChunkName: "admin-countries" */ "../pages/PageAdminCountries/PageAdminCountries.vue"),
  //   beforeEnter (to, from, next) {
  //     _checkXtramileUser(next)
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "admin-countries" */ "../pages/PageAdminCountries/PageAdminCountriesList.vue"),
  //     }
  //   ]
  // },
  //
  //
  // // ADMIN CURRENCIES
  // {
  //   path: "/admin/currencies",
  //   name: "Currencies",
  //   component: () => import(/* webpackChunkName: "admin-currencies" */ "../pages/PageAdminCurrencies/PageAdminCurrencies.vue"),
  //   beforeEnter (to, from, next) {
  //     _checkXtramileUser(next)
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "admin-currencies" */ "../pages/PageAdminCurrencies/PageAdminCurrenciesList.vue"),
  //     }
  //   ]
  // },
  //
  //
  // // ADMIN OFFICES
  // {
  //   path: "/admin/offices",
  //   name: "Offices",
  //   component: () => import(/* webpackChunkName: "admin-offices" */ "../pages/PageAdminOffices/PageAdminOffices.vue"),
  //   beforeEnter (to, from, next) {
  //     _checkXtramileUser(next)
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "admin-offices" */ "../pages/PageAdminOffices/PageAdminOfficesList.vue"),
  //     }
  //   ]
  // },
  //
  //
  // // ADMIN ROLES
  // {
  //   path: "/admin/roles",
  //   name: "Roles",
  //   component: () => import(/* webpackChunkName: "admin-roles" */ "../pages/PageAdminRoles/PageAdminRoles.vue"),
  //   beforeEnter (to, from, next) {
  //     _checkXtramileUser(next)
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "admin-roles" */ "../pages/PageAdminRoles/PageAdminRolesList.vue"),
  //     }
  //   ]
  // },
  //
  //
  // // ADMIN USERS
  // {
  //   path: "/admin/users",
  //   name: "Users",
  //   component: () => import(/* webpackChunkName: "admin-users" */ "../pages/PageAdminUsers/PageAdminUsers.vue"),
  //   beforeEnter (to, from, next) {
  //     _checkXtramileUser(next)
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "admin-users" */ "../pages/PageAdminUsers/PageAdminUsersList.vue"),
  //     }
  //   ]
  // },
  //
  //
  // // ADMIN PRODUCTS
  // {
  //   path: "/admin/products",
  //   name: "Products",
  //   component: () => import(/* webpackChunkName: "admin-products" */ "../pages/PageAdminProducts/PageAdminProducts.vue"),
  //   beforeEnter (to, from, next) {
  //     _checkXtramileUser(next)
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "admin-products" */ "../pages/PageAdminProducts/PageAdminProductsList.vue"),
  //     }
  //   ]
  // },
  //
  // //SETTINGS
  // {
  //   path: "/settings",
  //   name: "Settings",
  //   component: () => import(/* webpackChunkName: "admin-products" */ "../pages/PageSettings/PageSettings.vue"),
  //   beforeEnter (to, from, next) {
  //     _checkXtramileUser(next)
  //   },
  //   children: [
  //     {
  //       path: '',
  //       component: () => import(/* webpackChunkName: "admin-products" */ "../pages/PageSettings/PagePublicationUrlSettings.vue"),
  //     }
  //   ]
  // },

  // NOT FOUND PAGE
  {
    path: "*",
    beforeEnter(to, from, next) {
      window.location.replace("/");
    },
    component: () => 1, //import( /* webpackChunkName: "not-found" */ "../pages/notfound/all/page.notfound.all.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const onError = (e) => {
  if (e.name !== "NavigationDuplicated") throw e;
};

// avoid NavigationDuplicated
const _push = router.__proto__.push;
router.__proto__.push = function push(...args) {
  try {
    const op = _push.call(this, ...args);
    if (op instanceof Promise) op.catch(onError);
    return op;
  } catch (e) {
    onError(e);
  }
};

export default router;
