export default {
  TITLE: "Office",
  TITLES: "Offices",
  TITLE_COUNT: "office | 1 office | {n} offices",
  ICON: "mdi-office-building",
  INFO: "Informations",
  FIELD_TITLE: "Title (must finish in '[Mdif/A]', '[Mdif/I]', '[Prog/A]' or '[Prog/I]')",
  FIELD_IMAGE: "Image",
  FIELD_TITLE_PLACEHOLDER: "My company",
  FIELD_DESCRIPTION: "Description",
  FIELD_DESCRIPTION_PLACEHOLDER: "With 150 employees ...",
  CONTACT: "Contact",
  FIELD_NAME: "Name",
  FIELD_NAME_PLACEHOLDER: "Eric Dupond",
  FIELD_EMAIL: "Email",
  FIELD_COMPANY: "Company",
  FIELD_COMPANY_PLACEHOLDER: "Choose from the list",
  FIELD_EMAIL_PLACEHOLDER: "eric.dupond@monentreprise.fr",
  FIELD_ADDRESS: "Address",
  FIELD_ADDRESS_PLACEHOLDER:
    "6 rue du géneral de Gaulle, 75001 Paris, France",
  FORM_TITLE_UPDATE: "Update office",
  FORM_TITLE_NEW: "New office",
  FORM_TITLE_CREATE: "Create office",
}