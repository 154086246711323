export default {
  ICON: "mdi-file-account",
  ICON_ACCOUNT: "mdi-account",
  TITLE: "CV",
  TITLES: "CVs",
  TITLE_COUNT: "CV | 1 CV | {n} CVs",
  TITLES_FIELDS: {
    Personal: "Renseignements personnels",
    Occupation: "Occupation",
    firstName: "Prénom",
    lastName: "Nom de famille",
    unknown: "Identité non reconnue",
    address: "Adresse",
    postal_code: "Code postal",
    city: "Ville",
    country: "Pays",
    email: "Email",
    phone_number: "Numéro de téléphone",
    birthday: "Anniversaire",
    nationality: "Nationalité",
    driver_licence: "Permis de conduire",
    Experience: "Expérience professionnelle",
    organisation_name: "Nom de l'organisation",
    contract_type: "Contrat",
    duration: "Durée",
    occupations: "Occupations",
    occupation: "Occupation",
    Education: "Formation",
    school_name: "Nom de l'école",
    specialisation: "Spécialisation",
    level: "Niveau",
    Skills: "Compétences",
    hard_skill: "Compétences techniques",
    soft_skill: "Compétences générales",
    language: "Langues",
    interests: "Intérêts",
  },
}
