export default {
  CV_PARSING: "CV parsing",
  UPLOAD_YOUR_CV: "Upload your CV",
  AVAILABLE_EXTENSIONS: "Available extensions",
  CLICK_FOR_UPLOAD_FILE: "Click or drop the file to upload",
  UPLOAD_CV_ICON: "mdi-file-upload",
  UPLOAD: "Upload",
  CLEAR: "Clear",
  FILE_SIZE_RULE: "File size should be less than 2 MB!",
  FILE_REQUIRED_RULE: "No file selected",
  IDENTITIES: "Identities",
  TITLES: "Titles",
  INTRODUCTION: "Introduction",
  EDUCATION: "Education",
  WORK_EXPERIENCE: "Work Experience",
  SKILLS: "Skills",
  OTHERS: "Others",
  CITY: "City",
  COUNTRY: "Country",
  SCHOOL_NAME: "School name",
  START_DATE: "Start date",
  END_DATE: "End date",
  LEVEL: "Level",
  SPECIALISATION: "Specialisation",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  ADDRESS: "Address",
  POSTAL_CODE: "Postal code",
  STATE: "State",
  EMAIL: "Email",
  PHONE_NUMBER: "Phone number",
  BIRTHDAY: "Date of birth",
  CITIZENSHIP: "Citizenship",
  DRIVING_LICENCE: "Driving licence",
  OCCUPATION: "Occupation",
  OCCUPATION_LINK: "Occupation link",
  CONTRACT_TYPE: "Contract type",
  DURATION: "Duration",
  HARD_SKILLS: "Hard skills",
  SOFT_SKILLS: "Soft skills",
  OTHER_OCCUPATIONS: "Other occupations",
  OTHER_HARD_SKILLS: "Other hard skills",
  OTHER_SOFT_SKILLS: "Other soft skills",
  LANGUAGES: "Languages",
  INTERESTS: "Interests",
  ORGANISATION_NAME: "Organisation name",
  TASKS: "Tasks",
  WEIGHT: "Weight",
}
