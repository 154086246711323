<template>
  <v-app>
    <Messenger />
    <template v-if="ready">
      <div class="app">
        <header class="app-header" v-if="showMenuMain">
          <MenuMain/>
        </header>
        <main class="app-main">
          <transition name="page-fade" mode="in-out">
            <router-view/>
          </transition>
        </main>
      </div>
    </template>
  </v-app>
</template>

<script>
import Messenger from '@/components/Messenger.vue'
import MenuMain from '@/components/menu/MenuMain.vue'

import { mapState } from 'vuex'

export default {
  name: 'App',
  components: {
    Messenger,
    MenuMain,
  },
  data() {
    return {
      expiration_date: null,
    }
  },

  created () {
    this.$store.dispatch('app/init')
  },

  beforeDestroy () {
    this.$store.dispatch('app/destroy')
  },

  computed: {
    ...mapState('app', {
      ready: state => state.ready,
      page: state => state.page,
      showMenuMain: state => state.page && state.page !== '/login'
    }),
  },

  watch: {
    $route (to, from){
      this.$store.dispatch('app/page', to.path)
    },

    '$store.state.user': {
      handler({ user }) {
        if (!user) return

        const { expiration_date } = user.session
        this.expiration_date = expiration_date
        const delay = +new Date(this.expiration_date) - (+new Date())
        setTimeout(() => this.redirectToLoginPage(), delay)
      },
      deep: true,
    },
  },
  methods: {
    redirectToLoginPage() {
      const expiration_date = +new Date(this.expiration_date)
      const current_date = +new Date()
      
      if (current_date > expiration_date) {
        this.$store.dispatch('user/logout', true)
      }
    },
  },
}
</script>

<style lang="scss">
  @import '~vuetify/src/styles/styles.sass';

  .app {
    width: 100vw;
    height: 100vh;
    display: flex;
    background-color: #F0F0F0;
  }

  .app-header {
    width: 240px;
    height: 100%;
  }
  .app-main {
    flex-grow: 1;
    height: 100%;
  }

  .page {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .page-header {}
  .page-content {}
  .page-main {

  }
  .page-aside {}


  .form {
    display: flex;
    flex-direction: column;
  }

  .form-compact { display: flex; flex-direction: column; height: 100%; }
  .form-compact-content { flex-grow: 1; position: relative; overflow-x: hidden; overflow-y: scroll; }
  .form-compact-content.blocked { flex-grow: 1; position: relative; overflow-x: hidden; overflow-y: hidden; }

  // - - - - - - -
  // Native Chrome
  // - - - - - - -

  *:-internal-autofill-selected,
  *:-webkit-autofill,
  *:-webkit-autofill:hover,
  *:-webkit-autofill:focus,
  *:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    -webkit-text-fill-color: inherit !important;
    -webkit-transition-delay: 9999999s;
    -webkit-transition: color 9999999s ease-out, background-color 9999999s ease-out;
  }

  .v-menu__content { background: #fff; }

  ::-webkit-scrollbar {
    // position: fixed;
    z-index: 99;
    width: 8px;
    margin: 5px;
  }
  ::-webkit-scrollbar-track {
    background: inherit;
    // padding: 2px;
  }
  ::-webkit-scrollbar-thumb {
    z-index: 99;
    border-radius: 4px;

    background: rgba(133,133,133,0.3);
  }
  // ::-webkit-scrollbar-thumb:hover {
  //   background: rgba(133,133,133,0.9);
  //   // background: rgba(0,0,0,0.12);
  // }

  .small .v-input__control > .v-input__slot {
    min-height: 32px !important;
    font-size: 14px;
  }

  // - - - - - - -
  // form compact
  // - - - - - - -
  .form-compact {
    display: flex;
    flex-direction:
    column;
    height: 100%;
  }

  .form-compact-content {
    flex-grow: 1;
    position: relative;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .form-compact-content.blocked {
    flex-grow: 1;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
  }


  // - - - - - - -
  // utils
  // - - - - - - -

  .flex-grow {
    flex-grow: 1;
  }
  .flex-shrink {
    flex-grow: 0;
  }

  .white-alpha {
    background-color: rgba(255,255,255,.12);
  }

  .black-alpha {
    background-color: rgba(0,0,0,.12);
  }

  .clickable:hover {
    background-color: rgba(0,0,0,.08);
  }
  .clickable {
    cursor: pointer;
  }
  .cursor-pointer {
    cursor: pointer;
    user-select: none;
  }

  .none {
    width: 0 !important;
    max-width: 0 !important;
    max-height: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    opacity: 0 !important;
    margin: 0 !important;
    padding: 0 !important;
  }

  .muted {
    opacity: .45;
  }

  .muted img {
    filter: grayscale(100%);
  }

  .blurred {
    backdrop-filter: blur(10px);
    background: rgba(0,0,0,.1);
  }


  .scroll {
    overflow-y: auto;
    overflow-x: hidden;
  }

  .float-right { float: right; }
  .float-left { float: left; }
  .float-none { float: none; }

  .height-10vh { height: 10vh; }
  .height-20vh { height: 20vh; }
  .height-30vh { height: 30vh; }
  .height-40vh { height: 40vh; }
  .height-50vh { height: 50vh; }
  .height-60vh { height: 60vh; }
  .height-70vh { height: 70vh; }
  .height-80vh { height: 80vh; }
  .height-90vh { height: 90vh; }
  .height-100vh { height: 100vh; }

  .height-min-10vh { min-height: 10vh; }
  .height-min-20vh { min-height: 20vh; }
  .height-min-30vh { min-height: 30vh; }
  .height-min-40vh { min-height: 40vh; }
  .height-min-50vh { min-height: 50vh; }
  .height-min-60vh { min-height: 60vh; }
  .height-min-70vh { min-height: 70vh; }
  .height-min-80vh { min-height: 80vh; }
  .height-min-90vh { min-height: 90vh; }
  .height-min-100vh { min-height: 100vh; }

  .height-max-10vh { max-height: 10vh; }
  .height-max-20vh { max-height: 20vh; }
  .height-max-30vh { max-height: 30vh; }
  .height-max-40vh { max-height: 40vh; }
  .height-max-50vh { max-height: 50vh; }
  .height-max-60vh { max-height: 60vh; }
  .height-max-70vh { max-height: 70vh; }
  .height-max-80vh { max-height: 80vh; }
  .height-max-90vh { max-height: 90vh; }
  .height-max-100vh { max-height: 100vh; }

  .height-10 { height: 10%; }
  .height-20 { height: 20%; }
  .height-30 { height: 30%; }
  .height-40 { height: 40%; }
  .height-50 { height: 50%; }
  .height-60 { height: 60%; }
  .height-70 { height: 70%; }
  .height-80 { height: 80%; }
  .height-90 { height: 90%; }
  .height-100 { height: 100%; }

  .height-min-10 { min-height: 10%; }
  .height-min-20 { min-height: 20%; }
  .height-min-30 { min-height: 30%; }
  .height-min-40 { min-height: 40%; }
  .height-min-50 { min-height: 50%; }
  .height-min-60 { min-height: 60%; }
  .height-min-70 { min-height: 70%; }
  .height-min-80 { min-height: 80%; }
  .height-min-90 { min-height: 90%; }
  .height-min-100 { min-height: 100%; }

  .height-max-10 { max-height: 10%; }
  .height-max-20 { max-height: 20%; }
  .height-max-30 { max-height: 30%; }
  .height-max-40 { max-height: 40%; }
  .height-max-50 { max-height: 50%; }
  .height-max-60 { max-height: 60%; }
  .height-max-70 { max-height: 70%; }
  .height-max-80 { max-height: 80%; }
  .height-max-90 { max-height: 90%; }
  .height-max-100 { max-height: 100%; }

  .width-10 { width: 10%; }
  .width-20 { width: 20%; }
  .width-30 { width: 30%; }
  .width-40 { width: 40%; }
  .width-50 { width: 50%; }
  .width-60 { width: 60%; }
  .width-70 { width: 70%; }
  .width-80 { width: 80%; }
  .width-90 { width: 90%; }
  .width-100 { width: 100%; }

  .text-prewrap { white-space: pre-wrap; }
  .text-nowrap { white-space: nowrap; }
  .text-wrap { white-space: normal; }

  .text-nowrap .v-select__selections {flex-wrap: nowrap;}

  .text-xsmall { font-size: 10px; line-height: 12px; }
  .text-small { font-size: 12px; line-height: 14px; }
  .text-medium { font-size: 14px; line-height: 16px; }
  .text-large { font-size: 16px; line-height: 20px; }
  .text-xlarge { font-size: 24px; line-height: 28px; }
  .text-ellipsis { white-space: nowrap; overflow: hidden; text-overflow: ellipsis; max-width: 100%; }

  table.x-small {
    font-size: 10px;
    line-height: 12px;
    border-radius: 15px;
    border-spacing: 0;
    border-collapse: separate;
    width: 100%;
    border: 0;
  }
  table.x-small tr > * {
    padding: 2px 5px;
    font-size: 10px;
    line-height: 12px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.38);
  }
  table.x-small th {
    font-weight: lighter;
    text-align: left;
    font-size: 9px;
    background-color: #e6e6e6;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 90px;
    width: 90px;
  }
  table.x-small tr:first-child > * { border-top: solid 1px rgba(0, 0, 0, 0.38);}
  table.x-small tr > *:first-child {
    border-left: solid 1px rgba(0, 0, 0, 0.38);
    border-right: solid 1px rgba(0, 0, 0, 0.38);
  }
  table.x-small tr > *:last-child { border-right: solid 1px rgba(0, 0, 0, 0.38);}
  table.x-small tr:first-child > *:first-child { border-radius: 5px 0 0 0; }
  table.x-small tr:first-child > *:last-child { border-radius: 0 5px 0 0; }
  table.x-small tr:last-child > *:first-child { border-radius: 0 0 0 5px; }
  table.x-small tr:last-child > *:last-child { border-radius: 0 0 5px 0; }


  .selection-box {
    border: solid 1px rgba(0, 0, 0, 0.38);
    min-height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    flex-direction: column;
    transition: all 150ms;
    cursor: pointer;
  }
  .selection-box span {
    font-size: 12px;
  }
  .selection-box:hover {
    background: rgba(0, 0, 0, 0.12);
  }

  .selection-box .v-icon {
    color: inherit;
  }

  .row + .row.checkbox-selection {
    margin-top: -18px !important;
  }


  .toolbar-separator {
    height: 2px;
    border-bottom: solid 2px;
  }

  .v-divider {
    z-index: 1;
  }



  // - - - - - - -
  // Color
  // - - - - - - -

  .v-btn.accent {
    font-weight: bold;
    text-shadow: 0px 0px 5px rgba(0,0,0,.3);
  }

  .list-field {
    border: solid 1px rgba(0, 0, 0, 0.38);
    border-radius: 4px;
    overflow: hidden;
  }

  // - - - - - - -
  // Tag
  // - - - - - - -

  html, body {
    font-family: $body-font-family;
    overflow: hidden;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }

  .number, .numeric {
    font-family: sans-serif;
    // font-size: 94%;
  }

  .position-relative {
    position: relative;
  }


  // - - - - - - -
  // General
  // - - - - - - -

  .selection-box .corner-icon {
    position: absolute !important;
    right:-10px;
    top: -10px;
    background: #fff;
    border-radius: 100%;
  }

  .selection-box {
    min-height: 80px;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    cursor: pointer;
  }

  .v-list.list-small .v-list-item {
    min-height: 32px !important;
  }
  .v-list.list-small .v-list-item__content {
    padding: 6px 0px;
  }

  .v-application .v-btn.custom,
  .custom {
    position: relative;
    border-color: #fff;
    // box-shadow: 0 6px 10px -6px rgba(0,0,0,.6);
    box-shadow: none;
    overflow: hidden;
    // border-radius: 8px;
  }
  .v-application .v-btn.custom::after,
  .custom::after {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: .5;
    border-radius: inherit;
    // border-color: #fff !important;
    border: solid 1px rgba(0,0,0,.5) !important;
    border-bottom: solid 1px rgba(0,0,0,.5) !important;
    border-width: 1px 1px 1px 1px !important;
    // border-width: 0px 3px 0px 3px !important;
  }


  .v-application .theme-dark .v-btn.custom::after,
  .theme-dark .custom::after {
    border: solid 1px rgba(255,255,255,.5) !important;
    border-bottom: solid 1px rgba(255,255,255,.5) !important;
    border-width: 1px 1px 1px 1px !important;
  }

  .v-avatar img, .v-avatar svg {
    width: auto !important;
  }

  .bordered {
    border: solid 1px #bebebe !important;
  }

  .bordered-selected {
    border: solid 1px #201f35 !important;
  }

  .title-separator {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.76);
    height: 20px;
    border-bottom: solid 1px rgba(0, 0, 0, 0.38);
  }

  a {
    text-decoration: none;
  }

  .expandable.expandable-active .expandable-info {
    display: none;
  }
  .expandable .expandable-info {
    cursor: pointer;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 50%;
    font-size: 12px;
    display: flex;
    align-items: flex-end;
    background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 50%);
    background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%);
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 50%);
    filter: progid:DXImageTransform.Microsoft.gradient(
      startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0
    );
  }
  .expandable {
    cursor: pointer;
    position: relative;
    max-height: 100px;
    overflow: hidden;
  }
  .expandable > * {
    pointer-events: none;
  }
  .expandable.expandable-active {
    height: auto;
    max-height: none;
    cursor: inherit;
  }

  // - - - - - - -
  // Issues
  // - - - - - - -

  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }

  // [Bug Report] Autocomplete placeholder only displays on focus when using chips
  // https://github.com/vuetifyjs/vuetify/issues/11553
  .v-input:not(.v-input--is-focused) input { max-height: 25px !important; }

  .v-text-field--outlined fieldset {
    border-width: 1px !important;
  }

  .v-tabs-bar.primary .v-tab,
  .v-tabs-bar.primary .v-tabs-slider,
  .v-tabs-bar.secondary .v-tab,
  .v-tabs-bar.secondary .v-tabs-slider,
  .v-tabs-bar.accent .v-tab,
  .v-tabs-bar.accent .v-tabs-slider,
  .v-tabs-bar.success .v-tab,
  .v-tabs-bar.success .v-tabs-slider,
  .v-tabs-bar.error .v-tab,
  .v-tabs-bar.error .v-tabs-slider,
  .v-tabs-bar.warning .v-tab,
  .v-tabs-bar.warning .v-tabs-slider,
  .v-tabs-bar.info .v-tab,
  .v-tabs-bar.info .v-tabs-slider {
    color: inherit !important;
  }

  .v-window {
    overflow: visible !important;
  }
  .v-window-item {
    height: 100%;
  }

  .v-select.v-input--dense .v-chip {
    margin: 2px 4px 2px 4px !important;
  }

  .v-chip.v-size--x-small {
    font-size: 12px;
  }

  // - - - - - - -
  // Animation
  // - - - - - - -
  @keyframes highlight {
    0% {
      box-shadow: 0 0 0 rgba(32,31,53,1);
    }

    100% {
      box-shadow: 0 0 50px rgba(32,31,53,0);
    }
  }

  .highlight {
    position: relative;
  }
  .highlight::after {
    content: "";
    animation: highlight 2s;
    border-radius: inherit;
    position: absolute;
    top:0;
    left:0;
    right: 0;
    bottom: 0;
    pointer-events: none;
  }

  .page-fade-enter-active,
  .page-fade-leave-active {
    // transition-duration: 80ms;
    // transition-property: none;
    // transition-timing-function: linear;
    overflow: hidden;
  }
  .page-fade-enter,
  .page-fade-leave-active {
    // opacity: 0.5
  }

  .fade-enter-active,
  .fade-leave-active {
    transition-duration: 180ms;
    transition-property: height, opacity;
    transition-timing-function: ease;
    overflow: hidden;
  }
  .fade-enter,
  .fade-leave-active {
    opacity: 0
  }

  .slide-up-enter-active,
  .slide-up-leave-active {
    transition-duration: 0.3s;
    transition-property: height, transform, opacity;
    transition-timing-function: ease;
    overflow: hidden;
  }
  .slide-up-enter,
  .slide-up-leave-active {
    transform: translateY(-150px);
    opacity: 0;
  }

  .slide-right-enter-active,
  .slide-right-leave-active {
    transition-duration: 0.3s;
    transition-property: height, transform, opacity;
    transition-timing-function: ease;
    overflow: hidden;
  }
  .slide-right-enter,
  .slide-right-leave-active {
    transform: translateX(50px);
    opacity: 0;
  }

  textarea {
    resize: none;
  }
</style>
