import './registerServiceWorker'
import router from '../router'
import store from '../store'
import clone from './clone'
import compare from './compare'
import moment from './moment'
import queryFilters from './queryFilters'
import queryFormat from './queryFormat'
import rules from './rules'
import services from './services'
import i18n from './vueI18n'
import vuetify from './vuetify'
import showdown from './showdown'
import getImgSrc from './getImgSrc'
import nestedData from './nestedData'
import permission from './permission'

export default {
  router,
  store,
  clone,
  compare,
  moment,
  queryFilters,
  queryFormat,
  rules,
  services,
  i18n,
  vuetify,
  nestedData,
  getImgSrc,
  permission,
}
