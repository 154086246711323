import ACCOUNT from './fr/account'
import APPLICATION_EMAIL from './fr/application_email'
import AUDIENCE from './fr/audience'
import BANNER_EDITOR from './fr/banner_editor'
import CAMPAIGN_FEED from './fr/campaign_feed'
import CAMPAIGN_JOB from './fr/campaign_job'
import CAMPAIGN_RULE from './fr/campaign_rule'
import CAMPAIGN from './fr/campaign'
import CANDIDATE from './fr/candidate'
import CHANNEL_SUBSCRIPTION_CONTRACT from './fr/channel_subscription_contract'
import CHANNEL_SUBSCRIPTION from './fr/channel_subscription'
import CHANNEL from './fr/channel'
import CHART from './fr/chart'
import COMMON from './fr/common'
import COMPANY from './fr/company'
import COUNTRY from './fr/country'
import CREATIVE from './fr/creative'
import CURRENCY from './fr/currency'
import CV from './fr/cv'
import CV_PARSING from './fr/cv_parsing'
import DETAILS from './fr/details'
import DIALOG from './fr/dialog'
import FEED from './fr/feed'
import FORM from './fr/form'
import HEADER from './fr/header'
import HOME from './fr/home'
import HOOKS from './fr/hooks'
import IMPACT from './fr/impact'
import INPUT from './fr/input'
import INSIGHT from './fr/insight'
import JOB from './fr/job'
import LANG from './fr/lang'
import LIMITATION from './fr/limitation'
import LIST from './fr/list'
import LOGIN from './fr/login'
import LOGOUT from './fr/logout'
import MESSAGE from './fr/message'
import NOTIFICATION from './fr/notification'
import OFFICE from './fr/office'
import PRODUCT from './fr/product'
import PUBLICATION_URL from './fr/publication_url'
import PUBLICATION from './fr/publication'
import RATIO from './fr/ratio'
import ROLE from './fr/role'
import RULE from './fr/rule'
import SERVICE from './fr/service'
import SETTINGS from './fr/settings'
import STATUS_ITEMS from './fr/status_items'
import SWITCH_ACCOUNT from './fr/switch_account'
import UPLOAD from './fr/upload'
import USER_PERMISSION from './fr/user_permission'
import USER_PRODUCT from './fr/user_product'
import USER from './fr/user'
import LANDING_PAGE from './en/landing_page'

export default {
  ACCOUNT,
  APPLICATION_EMAIL,
  AUDIENCE,
  BANNER_EDITOR,
  CAMPAIGN_FEED,
  CAMPAIGN_JOB,
  CAMPAIGN_RULE,
  CAMPAIGN,
  CANDIDATE,
  CHANNEL_SUBSCRIPTION_CONTRACT,
  CHANNEL_SUBSCRIPTION,
  CHANNEL,
  CHART,
  COMMON,
  COMPANY,
  COUNTRY,
  CREATIVE,
  CURRENCY,
  CV,
  CV_PARSING,
  DETAILS,
  DIALOG,
  FEED,
  FORM,
  HEADER,
  HOME,
  HOOKS,
  IMPACT,
  INPUT,
  INSIGHT,
  JOB,
  LANG,
  LIMITATION,
  LIST,
  LOGIN,
  LOGOUT,
  MESSAGE,
  NOTIFICATION,
  OFFICE,
  PRODUCT,
  PUBLICATION_URL,
  PUBLICATION,
  RATIO,
  ROLE,
  RULE,
  SERVICE,
  SETTINGS,
  STATUS_ITEMS,
  SWITCH_ACCOUNT,
  UPLOAD,
  USER_PERMISSION,
  USER_PRODUCT,
  USER,
  LANDING_PAGE,
}
