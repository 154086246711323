export default {
  TITLE: "Ratio",
  TITLES: "Ratios",
  TITLE_COUNT: "No ratio | 1 ratio | {n} ratios",
  ICON: "mdi-chart-line",

  FILTERS: "Filters",
  FEED: "Feed",
  CAMPAIGN: "Campaign",
  CATEGORY: "Category",
  CATEGORY_ICON: "",
  EXPERIENCE: "Experience",
  EXPERIENCE_ICON: "",
  LOCATION: "Location",
  LOCATION_ICON: "mdi-map-marker",

  CHANNEL_RANKING: "Ranking by channel",

  CLICKS: "Clicks",
  APPLICATIONS: "Applications",
  CREDITS: "Credits",
  CREDITS_PRICE: "Credits price",
  COST: "Cost",
  AUDIENCES: "Audiences",
  JOBS: "Jobs",
  BUDGET: "Budget",
  APPLY_COUNT: "application | 1 application | {n} applications",
  CLICK_COUNT: "click | 1 click | {n} clicks",
  VIEW_COUNT: "view | 1 view | {n} views",
  CREDIT_COUNT:
    "publication (credits) | 1 publication (credits) | {n} publications (credits)",
  BEST_CHANNEL: "Best channel",
  DISTRIBUTION: "Distribution",
  CHANNELS_STATS: "Statistics by channel",
  CHANNEL: "Channel",
  PUBLICATIONS: "Publications",
  VIEWS: "Views",

  UPLOAD_STATISTICS: "Upload statistics",
  DOWNLOAD_TEMPLATE: "Download statistics",

  CREDITS_TITLE: "Cost per slot",
  CPA_TITLE: "Cost per acquisition",
  CPA: "CPA",
  CPM_TITLE: "Cost per 1000 views",
  CPM: "CPM",
  CPC_TITLE: "Cost per click",
  CPC: "CPC",
  BUDGET_USED_TITLE: "Expenses",

  CHANNEL_SUBSCRIPTION: "Channels",
  START_DATE: "Start date",
  END_DATE: "End date",

  BUDGET_USED: "Budget used",
  BUDGET_TOTAL: "Total budget",

  EXPORT_EXCEL: "Export as EXCEL",
  EXPORT_PDF: "Export as PDF",
}