export default {
  UPLOAD_FILE: "Upload a file",
  UPLOAD_FORMAT: "format | 1 format | {n} formats",
  UPLOAD_FILE_LIST: "Files",

  DRAGNDROP: "Drag and drop",
  OR: "or",
  CLICK: "click here to drop a file",
  TO_LOAD_CV: "a CV",
  TO_LOAD_JOB: "a Job",
  IMPACT_BUTTONS_TITLE: "Click here to download the offer",
  IMPACT_BUTTONS: {
    FROM_FILE: "From file",
    FROM_TEXT: "From text",
  },
}
