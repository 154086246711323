export default {
  ICON: "mdi-page-next-outline",
  TITLE: "Transformation de CVs",
  LIBRARY: "Accéder à la bibliothèque",
  FORMAT: "Transformer un CV",

  PARSE_BUTTON: "Parser les CVs",
  PARSE_BUTTON_CV: "Parser les CV",
  NEW_PARSING: "Nouveau parsing",
  PARSING_PROGRESS: "Parsing en cours",
}
