export default {
  getCV({ cv }) {
    return cv
  },

  getPersonalData({ cv }) {
    const { firstName, lastName, occupation, personalInfo } = cv
    const personal = {}

    if(personalInfo) {
      for (let key in personalInfo[0]) {
        personal[key] = personalInfo[0][key]
      }
    }

    return [{
      firstName,
      lastName,
      occupation,
      ...personal,
    }]
  },

  getEducationData({ cv }) {
    return cv.educationInfo
  },

  getExperienceData({ cv }) {
    return cv.experienceInfo
  },

  getSkillsData({ cv }) {
    return cv.skillsInfo
  },

  getUrlPicture({ cv }) {
    const { image_url } = cv
    return `${process.env.VUE_APP_FILESHARE_PUBLIC}/${image_url}`
  },

  getFullName({ cv: { fullName } }) {
    return fullName
  },

  getCVMatch({ cvMatch }) {
    return cvMatch
  },

  getPersonalInfoOnMatch({ cv: { position, source_name, experiences, educations, city, country } }) {
    return {
      position,
      source_name,
      experiences,
      educations,
      city,
      country,
    }
  },

  getParsingSteps({ cv: { parsing_steps, id }}) {
    return { parsing_steps, id }
  },

  getCVS({ cvs }) {
    return cvs
  },
}
