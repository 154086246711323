export default {
  TITLE: "Rule",
  TITLES: "Rules",
  ICON: "mdi-arrow-decision",
  TITLE_COUNT: "no rule | 1 rule | {n} rules",
  FULL_TITLE: "Automated rule",
  FULL_TITLES: "Automated rules",

  EVENT_COUNT: "no event | 1 event | {n} events",
  ACTION_COUNT: "no action | 1 action | {n} actions",

  ADD_RULE: "Add a rule",

  FIELD_STATUS: "Status",
  FORM_RULE_CREATE: "Add a rule",
  FORM_RULE_UPDATE: "Modify a rule",
  FIELD_RULE: "Rule",
  FIELD_LIMIT: "Limit",
  FIELD_NAME: "Rule name",
  FIELD_COUNT: "",

  FORM_EVENT_CREATE: "Add event",
  FORM_EVENT_UPDATE: "Edit an event",
  FIELD_EVENT: "Events",
  FIELD_EVENT_NAME: "Event type",
  FIELD_EVENT_FILTERS: "Filters",
  FORM_ACTION_CREATE: "Add action",
  FORM_ACTION_UPDATE: "Modify an action",
  FIELD_ACTION: "Actions",
  FIELD_ACTION_NAME: "Type of action",
  FIELD_ACTION_VALUES: "Settings",

  EVENT_NAMES: {
    CAMPAIGN_JOB_CREATE: "Addition of one / more offers",
    CAMPAIGN_JOB_CREATE_ICON: "mdi-briefcase-download",
    CAMPAIGN_JOB_UPDATE: "Modification of one or more offers",
  },
  EVENT_NAME_ITEMS: [
    {text:'Addition of one / more offers', value: 'campaign_job_create'},
    {text:'Modification of one / more offers', value: 'campaign_job_update'},
  ],
  ACTION_NAMES: {
    PUBLICATION_CREATE: "Create one / more publications",
    PUBLICATION_CREATE_ICON: "mdi-plus-box-multiple",
    PUBLICATION_UPDATE: "Modify one / more publications",
    CAMPAIGN_UPDATE: "Modify the current campaign",
    CAMPAIGN_STOP: "Stop the current campaign",
  },
  ACTION_NAME_ITEMS: [
    {text:'Create one / more publications', value: 'publication_create'},
    {text:'Modify one / more publications', value: 'publication_update'},
    {text:'Modify the current campaign', value: 'campaign_update'},
    {text:'Stop the current campaign', value: 'campaign_stop'},
  ],
  STATUS_ITEMS: [
    {text:'Active', value: 1},
    {text:'Inactive', value: 0},
  ]
}