import * as types from '@/store/mutations.types'

export default {

  [types.USER_SESSION] (state, data = {}) {
    state.token = data?.info?.token ? data.info.token : null
    state.token_ra = data?.info?.token_ra ? data?.info?.token_ra : null
    state.token_ra_refresh = data?.info?.token_ra_refresh ? data?.info?.token_ra_refresh : null
    state.token_parse = data?.token_parse || localStorage.getItem('token_parse')
      ? data?.token_parse || localStorage.getItem('token_parse')
      : null
    state.user = data?.info?.user ? data?.info?.user : null
    state.connected = state.user && state.token
  },

  [types.SET_TOKEN_PARSE] (state, data) {
    localStorage.setItem('token_parse', data)
    state.token_parse = data
  },
}
