export default {
  ICON: "mdi-page-next-outline",
  TITLE: "Format CVs",
  LIBRARY: "Go to library",
  FORMAT: "Format CVs",

  PARSE_BUTTON: "Parse CVs",
  PARSE_BUTTON_CV: "Parse CV",
  NEW_PARSING: "New parsing",
  PARSING_PROGRESS: "Parsing in progress",
}
