export default {
  SHOW_PUBLIC: "Show as public page (Landing page)",
  SHOW_PUBLIC_INFO:
    "Show public allows ussers to acces the landing page and API Multidiff",
  TO_LANDING: "Go to the landing page",
  SAMPLE: "Sample",
  TOTAL: "Total",
  BASELINE: "Innovation at the service of recruitment & human capital",
  XTRAMILE: "Xtramile",
  ITEMS: "no item | {count} item | {count} items",
  SELECTED: "no selection| selection | {n} selections",
  REMOVE: "Remove",
  SELECT_ALL_PAGES: "Select all",
  PUBLISH: "Publish",
  BACK: "Back",
  EDIT: "Edit",
  EDIT_COMPANY: "Edit campaign",
  NEW: "New",
  ADD: "Add",
  ADD_COMPANY: "Add campaign",
  ADD_JOB: "Add job",
  ADD_CVS: "Add cv",
  ADD_MEDIA: "Add media",
  ADD_FEED: "Add feed",
  CREATE: "Create",
  CREATED: "Created",
  UPDATED: "Updated",
  TEST: "Test",
  START: "Start",
  END: "End",
  NO_LIMIT_TIME: "No time limit",
  FILTERS: "Filters",
  FILTERS_COUNT: "No filter | 1 filter | {n} filters",
  SORT_BY: "Sort by",
  SORT_BY_ALPHABETICAL_ASCENDANT: "Alphabetical descending",
  SORT_BY_ALPHABETICAL_DESCENDANT: "Alphabetical ascending",
  SORT_BY_DATE_ASCENDANT: "Newest to oldest",
  SORT_BY_DATE_DESCENDANT: "Oldest to newest",
  SORT_BY_LAST_UPDATED_ASCENDANT: "Last modifications",
  SORT_BY_LAST_UPDATED_DESCENDANT: "First modifications",
  APPLY: "Apply",
  RESET: "Reset",
  SEE: "See",
  IMPORT: "Import",
  IMPORT_ICON: "mdi-application-import",
  SEARCH: "Search",
  CANCEL: "Cancel",
  ADMIN: "Admin",
  CONFIGURE: "Configure",
  OPTIONNAL: "Optionnal",
  GENERAL: "General",
  PROFILE: "Profile",
  FIELDS_MAPPING: "my.field.name",
  SAMPLES: "no sample | sample | samples",
  RESULTS: "no result | result | results",
  TRUE: "Yes",
  FALSE: "No",
  BUDGET: "budget",
  BUDGET_ALLOCATED: "budget allocated",
  FREE: "Free",
  FIXED: "Fixed",
  ESTIMATION: "Estimation",
  DAY: "day | day | days",
  LOGOUT: "Logout",
  PASS: "Pass",
  ARCHIVE: "Archive",
  DISARCHIVE: "Disarchive",
  REQUIRED: "Required",
  PRICE: "Price",
  DUPLICATE: "Duplicate",
  DUPLICATE_ICON: "mdi-content-duplicate",
  TOTAL_DUPLICATION:
    "No duplication | Total: 1 duplication | Total: {n} duplications",
  NUMBER_DUPLICATION: "Number of duplication",
  NUMBER_DUPLICATION_HOW: "Duplicate jobs by a number.",
  CITIES_DUPLICATION: "Duplication by cities",
  CITIES_DUPLICATION_HOW:
    "Duplicate jobs and replace cities for each duplication.",
  BY_CITIES: "By cities",
  BY_NUMBER: "By number",
  EXPAND: "Click to expand",
  ACTIONS: "actions",
  OR: "or",
  SEE_ALL: "See all",
  MODIFY: "Modify",
  NO_MESSAGE: "No message",
  NEXT_REFRESH: "Next update: {date}",
  MORE_ACTIONS: "More actions ...",
  REFRESH_LIST: "Refresh list",
  LAST_UPDATE: "Last modification",
  SWITCH_HTML_OFF: "Edit content",
  SWITCH_HTML_ON: "Show HTML",
  SELECT_ALL: "All/None",
  RELOAD: "Reload",
  PAUSE: "Pause",
  CREDITS: "credits",
  LOCALE_CURRENCY_SIGN: "€",
  CONTINUE: "Continue",
  NEXT: "Next",
  PREVIOUS: "Previous",
  SAVE: "Save",
  CONFIRM_MULTIPLE_ITEMS:
    "Are you sure you want to apply these changes to {n} items?",
  CONFIRM: "Confirm",
  ALL: "All",
  HELP: "Help",
  CLOSE: "Close",
  STATUS_ITEMS: [
    { value: 1, text: "Active" },
    { value: 0, text: "Archived" },
    { value: -1, text: "Deleted" },
  ],
  SEPARATE_JOB_FEED: "Separate job from its feed",
  LIST_PRODUCTS: "List of products",
  LIST_SESSIONS: "History of sessions",
  NOT_AVAILABLE: "Not available for the moment",
  MISSING_DATA: "The parsed job is missing current data",
  NO_DATA: "No data available",
  HR_TRADING_DESK: "HR Trading",
  MATCHING: "Matching",
  DOWNLOAD: "Download",
  TITLES: {
    CHOOSE: "Choose",
    YOUR_CV: "your CV",
    YOUR_JOB: "your job",
    OR: "or",
    YOUR_CV_FILE: "your CV file",
    YOUR_JOB_FILE: "your job file",
  },
  LABELS: {
    TITLE: "Title",
    COMPANY: "Company",
    OFFICE: "Office",
    CREATED_AT: "Created at",
    SCORE: "Score",
    ACTIONS: "Actions",
    YEAR: "year",
    YEARS: "years",
    MONTH: "month",
    MONTHES: "months",
    UPLOAD_TITLE_CV: "Choose a CV of the pdf format",
    UPLOAD_TITLE_JOB: "Choose a Job of the the pdf format",
  },
  BUTTON: {
    BACK: "Back",
    LIKE: "Like",
    DISLIKE: "Dislike",
    DETAILED_VIEW: "Detailed view",
  },
  TOOLTIP: {
    RATING: "Rating",
    DOWNLOAD: "Download",
    MATCHING: "Matching",
    DELETE: "Remove",
  },
  ANOTHER: {
    LANG: "en",
  },
  OTHERS: "other | other | others ",
  RATE_PARSING_RESULT: "Rate the parsing result:",
  NO_EXAMPLE: "no example",
  NOT_FOUND: {
    LOCATION: "Location not found",
  },
  DISABLE: "Disable",
  ENABLE: "Enable",
};
