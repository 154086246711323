function transformationData(blockKeys, i) {
  const obj = {}
  blockKeys.forEach((key) => {
    if (
      typeof i[key] !== "object" ||
      (Array.isArray(i[key]) && key === "missions")
    ) {
      obj[key] = i[key]
      return
    }

    if (key === "occupations") {
      const value = i[key].map((item) => item.plaintext || item.ref.value)
      obj[key] = value.join(", ")
      return
    }

    if (key !== "occupations" && ("plaintext" in i[key] || "ref" in i[key])) {
      const value = i[key].plaintext || i[key].ref.value
      obj[key] = value
      return
    }

    if (
      typeof i[key][0] === "object" &&
      ("plaintext" in i[key][0] || "ref" in i[key][0])
    ) {
      const value = i[key][0].plaintext || i[key][0].ref.value
      obj[key] = value
      return
    }

    obj[key] = i[key][0] || ""
  })

  return obj
}

export default transformationData
