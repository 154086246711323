import { render, staticRenderFns } from "./Dialog.vue?vue&type=template&id=51d54e9e&"
import script from "./Dialog.vue?vue&type=script&lang=js&"
export * from "./Dialog.vue?vue&type=script&lang=js&"
import style0 from "./Dialog.vue?vue&type=style&index=0&id=51d54e9e&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLazy } from 'vuetify/lib/components/VLazy';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSystemBar } from 'vuetify/lib/components/VSystemBar';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VCard,VDialog,VDivider,VIcon,VLazy,VSpacer,VSystemBar,VToolbar,VToolbarTitle})
