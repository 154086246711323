function getInterests(cv) {
  const { interests } = cv.interests.infos;
  let res = [];

  if (interests && interests.length > 0) {
    interests.forEach(item => {
      if (!item.type.length) {
        res.push(item.plaintext);
        return;
      }

      item.type.map(typeItem => {
        res = typeItem.value
          ? res.concat(typeItem.value)
          : res
      });
    });
  }

  return res.join(', ');
}

export default getInterests;
