import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locales from '@/locales'
Vue.use(VueI18n)

const i18n = new VueI18n({
  fallbackLocale: 'en',
  locale: 'en',
  silentFallbackWarn: process.env.NODE_ENV && process.env.NODE_ENV === 'production',
  silentTranslationWarn: process.env.NODE_ENV && process.env.NODE_ENV === 'production',
  messages: locales
})

const navLang = navigator.language.split('-')[0] || navigator.language
i18n.locale = i18n.availableLocales.indexOf(navLang) !== -1 ? navLang : i18n.fallbackLocale

export default i18n
