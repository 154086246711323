import * as types from "@/store/mutations.types"
import services from "@/plugins/services"
import vueI18n from "@/plugins/vueI18n"
import moment from "@/plugins/moment"
import router from "@/router"

export default {
  async setOffice({ commit, rootState }) {
    const { data } = await services.api_user.office.search({
      where: { id: rootState.user.user.office_id },
    })
    return commit(types.SET_OFFICE, data[0])
  },
}
