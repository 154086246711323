export default {
  TITLE: "Product",
  TITLES: "Products",
  TITLE_COUNT: "No products 1 product | {n} products",
  WILL_FINISHED: "Ends {date}",
  CANCELED: "Canseled",
  LIMIT_USAGE: "Limitation of use",
  TIME_USAGE: "Usage time",
  FORM_TITLE_UPDATE: "Modify a user product",
  FORM_TITLE_NEW: "New user product",
  FORM_TITLE_CREATE: "Create a user product",
}