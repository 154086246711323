import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const options = {
  theme: {
    themes: {
      light: {
        primary: '#201F35', // '#17152E', //'#201F35',
        secondary: '#e6e6e6',
        accent: '#E0BC37',
        selected: '#E3F2FD',
        error: '#f44336',
        warning: '#FF9800',
        info: '#2196f3',
        success: '#4caf50',
        // custom
        finished: '#3F51B5'
      }
    }
  }
}

export default new Vuetify(options)
