export default {
  TITLE: "Produit",
  TITLES: "Produits",
  TITLE_COUNT: "Aucun produit | 1 produit | {n} produits",
  ICON: "mdi-cube-outline",
  RESET: "Annuler les modifications",
  SAVE: "Enregistrer",
  REMOVE: "Supprimer",
  INFO: "Informations",
  FIELD_TITLE: "Titre",
  FIELD_TITLE_PLACEHOLDER: "XProduit",
  FIELD_COUNTRY: "Pays",
  FIELD_COUNTRY_PLACEHOLDER: "Choisissez un pays",
  FIELD_DESCRIPTION: "Description",
  FIELD_DESCRIPTION_PLACEHOLDER: "XProduit permet de ...",
  FIELD_IMAGE_URL: "Image URL",
  FIELD_IMAGE_URL_PLACEHOLDER: "https://www.monimage.fr/...",
  BILL: "Facturation",
  FIELD_PRICE: "Prix (€)",
  FIELD_PRICE_PLACEHOLDER: "1000",
  FIELD_DISCOUNT: "Réduction (%)",
  FIELD_DISCOUNT_PLACEHOLDER: "0",
  FORM_TITLE_UPDATE: "Modifier un produit",
  FORM_TITLE_CREATE: "Créer un produit",
  FORM_TITLE_NEW: "Saisissez les informations du nouveau produit",
}