export default {
  TITLE: "Contract",
  TITLES: "Contract",
  TITLE_COUNT: "No contract | 1 contract | {n} contracts",
  TITLE_ACTIVE_COUNT:
    "No active contract | 1 active contract | {n} active contracts",
  ICON: "mdi-file",
  ADD: "Add contract",
  TEXT:
    "Control and quantify your budget by creating contracts that frame your costs.",

  CONTRACT_TITLES: "Contracts",
  CONTRACT_TITLE: "Contract",
  CANCELED: "Canceled",
  FINISHED: "Finished",
  NO_CONTRACT: "No contract",
  WILL_FINISHED: "Ends {date}",
  DOWNLOAD_FILE: "Download the generated file",
  NO_DATE_LIMIT: "No deadline",
  NO_LIMIT_TIME: "Do not limit the validity period",
  NO_LIMIT_VALUE: "Do not limit costs",
  DAYS_AFTER_FIRST_USE: "days after first use",
  LAST_UPDATED: "Last updated on",
  AMOUNT: "Costs",
  AMOUNT_TEXT: "Set a budget to limit your costs on the channel.",
  VALIDITY: "Period of validity",
  VALIDITY_TEXT:
    "Define a contract validity period to limit your costs over time.",
  VALIDITY_DAYS_AFTER: "days after first use.",

  FORM_TITLE_UPDATE: "Modify contract",
  FORM_TITLE_CREATE: "Add contract",
  EXPIRED: "Expired",
  ALREADY_COMMITED_COUNT: " | 1 already spent | {n} already spent"
}
