import Vue from 'vue'

const isSame = (v1, v2) => {
  if (typeof v1 === 'object') {
    return JSON.stringify(v1) === JSON.stringify(v2)
  } else {
    return v1 === v2
  }
}

const compare = (v1, v2, fields) => {
  const diff = {}
  fields.forEach(field => {
    if (field.includes('date')) {
      if (new Date(v1[field] || null).toISOString() !== new Date(v2[field] || null).toISOString()) {
        diff[field] = v2[field]
      }
    } else {
      if (!isSame(v1[field], v2[field])) diff[field] = v2[field]
    }
  })
  return diff
}

const vm = {
  install: function (Vue) {
    Vue.prototype.$compare = compare
  }
}

Vue.use(vm)

export default compare
