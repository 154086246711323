export default {
  DEFAULT_MESSAGE: "Erreur inattendue.",
  SERVICE_REQUEST_ERROR: "La requête a echoué.",
  SERVICE_REQUEST_TOO_LARGE: "Le fichier est trop volumineux.",
  GA4_IMPORT: "Nouvelles candidatures trouvées sur Google Analytics!",

  USER_SESSION: {
    EXPIRED: "La session utilisateur a expiré.",
    CHECK_ERROR: "Session utilisateur invalide",
    LOGIN: "Utilisateur connecté",
    LOGIN_ERROR: "Mot de passe ou email invalide",
    SWITCH: "Changement de compte.",
  },
  JOB: {
    REFRESH: "Analyse réussie .",
    REFRESH_ERROR: "Echec de l'analyse.",
    CREATE: "Nouvelle offre créée.",
    CREATE_ERROR: "Echec de la création.",
    UPDATE: "Mise à jour effectuée.",
    UPDATE_ERROR: "Echec de la mise à jour.",
    REMOVE: "Offre supprimée.",
    REMOVE_ERROR: "Echec de la suppression.",
  },
  CAMPAIGN: {
    REFRESH: "Relance réussie.",
    REFRESH_ERROR: "Echec de la relance.",
    CREATE: "Nouvelle campagne créée.",
    CREATE_ERROR: "Echec de la création.",
    UPDATE: "Mise à jour effectuée.",
    UPDATE_ERROR: "Echec de la mise à jour.",
    REMOVE: "Campagne supprimée.",
    REMOVE_ERROR: "Echec de la suppression.",
  },
  CAMPAIGN_JOB: {
    CREATE: "Offre importée dans la campagne.",
    CREATE_ERROR: "Echec de l'import.",
    REMOVE: "Offre supprimée de la campagne.",
    REMOVE_ERROR: "Echec de la suppression.",
  },
  CAMPAIGN_FEED: {
    CREATE: "Flux importé dans la campagne.",
    CREATE_ERROR: "Echec de l'import.",
    REMOVE: "Flux supprimé de la campagne.",
    REMOVE_ERROR: "Echec de la suppression.",
  },
  FEED: {
    UPLOAD: "Téléchargement du fichier réussie.",
    UPLOAD_ERROR: "Echec du téléchargement.",
    REFRESH: "Extraction des données réussie.",
    REFRESH_ERROR: "Echec de l'extraction des données.",
    REFRESH_FINISHED: "Mise à jour terminée",
    CREATE: "Nouveau flux créé.",
    CREATE_ERROR: "Echec de la création.",
    UPDATE: "Mise à jour effectuée.",
    UPDATE_ERROR: "Echec de la mise à jour.",
    REMOVE: "Flux supprimé.",
    REMOVE_ERROR: "Echec de la suppression.",
  },
  CHANNEL_SUBSCRIPTION: {
    CREATE: "Nouveau média créé.",
    CREATE_ERROR: "Echec de la création.",
    UPDATE: "Mise à jour effectuée.",
    UPDATE_ERROR: "Echec de la mise à jour.",
    REMOVE: "Média supprimé.",
    REMOVE_ERROR: "Echec de la suppression.",
  },
  CHANNEL_SUBSCRIPTION_CONTRACT: {
    CREATE: "Nouveau contrat créé.",
    CREATE_ERROR: "Echec de la création.",
    UPDATE: "Mise à jour effectuée.",
    UPDATE_ERROR: "Echec de la mise à jour.",
    REMOVE: "Contrat supprimé.",
    REMOVE_ERROR: "Echec de la suppression.",
  },
  USER: {
    CREATE: "Nouveau utilisateur créé.",
    CREATE_ERROR: "Echec de la création.",
    UPDATE: "Mise à jour effectuée.",
    UPDATE_ERROR: "Echec de la mise à jour.",
    REMOVE: "Utilisateur supprimé.",
    REMOVE_ERROR: "Echec de la suppression.",
  },
  COMPANY: {
    CREATE: "Nouvelle entreprise créée.",
    CREATE_ERROR: "Echec de la création.",
    UPDATE: "Mise à jour effectuée.",
    UPDATE_ERROR: "Echec de la mise à jour.",
    REMOVE: "Compagnie supprimée.",
    REMOVE_ERROR: "Echec de la suppression.",
  },
  OFFICE: {
    CREATE: "Nouveau bureau créé.",
    CREATE_ERROR: "Echec de la création.",
    UPDATE: "Mise à jour effectuée.",
    UPDATE_ERROR: "Echec de la mise à jour.",
  },
  CHANNEL: {
    CREATE: "Nouveau canal créé.",
    CREATE_ERROR: "Echec de la création.",
    UPDATE: "Mise à jour effectuée.",
    UPDATE_ERROR: "Echec de la mise à jour.",
    REMOVE: "Bureau supprimé.",
    REMOVE_ERROR: "Echec de la suppression.",
  },
  XRANKING: {
    ORGANIZATION_ERROR: "Requête echouée pour l'organisation",
    MISSING_OFFICE_ORG_MAPPING: "XRanking inactif pour ce bureau.",
    SERVER_ERROR: "Erreur du Serveur",
    FILE_PARSE_ERROR: "Erreur pendant le parsing pour le fichier téléchargé!",
    ADD_RATE_SUCCESSFULLY: "Note ajoutée avec succès",
    DELETE_CV_SUCCESSFULLY: "CV supprimé avec succès",
    ERROR_PARSING:
      "Erreur pendant le parsing, demandez de l'aide à un customer success",
    DELETE_JOB_SUCCESSFULLY: "Travail supprimé avec succès",
    UNSUPPORTED_SIZE: "La taille du fichier doit être inférieure à 30 Mo !",
  },
  CREATIVE: {
    CREATE: "Nouvelle création créée.",
    CREATE_ERROR: "Echec de la création.",
    UPDATE: "Mise à jour effectuée.",
    UPDATE_ERROR: "Echec de la mise à jour.",
    REMOVE: "Création supprimée.",
    REMOVE_ERROR: "Echec de la suppression.",
    UNSUPPORTED_FORMAT:
      "Format non supporté, seulement le format pdf est accepté",
    UNSUPPORTED_SIZE: "La taille du fichier doit être inférieure à 30 Mo !",
  },
  XPARSING: {
    PARSING_JOB_SUCCESSFULLY: "Tâche analysée avec succès",
  },
};
