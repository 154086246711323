export default {
  TITLE: "Entreprise",
  TITLES: "Entreprises",
  TITLE_COUNT: "Aucune entreprise | 1 entreprise | {n} entreprises",
  ICON: "mdi-domain",
  INFO: "Informations",
  FIELD_TITLE: "Titre",
  FIELD_IMAGE: "Image",
  FIELD_TITLE_PLACEHOLDER: "Mon entreprise",
  FIELD_DESCRIPTION: "Description",
  FIELD_DESCRIPTION_PLACEHOLDER: "Fort de ses 150 collaborateurs ...",
  CONTACT: "Contact",
  FIELD_NAME: "Nom",
  FIELD_NAME_PLACEHOLDER: "Eric Dupond",
  FIELD_EMAIL: "E-mail",
  FIELD_EMAIL_PLACEHOLDER: "eric.dupond@monentreprise.fr",
  FIELD_ADDRESS: "Adresse",
  FIELD_ADDRESS_PLACEHOLDER:
    "6 rue du géneral de Gaulle, 75001 Paris, France",
  FORM_TITLE_UPDATE: "Modifier une entreprise",
  FORM_TITLE_NEW: "Nouvel entreprise",
  FORM_TITLE_CREATE: "Créer une entreprise",
}