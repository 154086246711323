import Vue from 'vue'
import showdown from 'showdown'

const converter = new showdown.Converter({
  omitExtraWLInCodeBlocks: false,
  noHeaderId: false,
  parseImgDimensions: true,
  simplifiedAutoLink: true,
  literalMidWordUnderscores: true,
  strikethrough: true,
  tables: true,
  tablesHeaderId: false,
  ghCodeBlocks: true,
  tasklists: true,
  smoothLivePreview: true,
  prefixHeaderId: false,
  disableForced4SpacesIndentedSublists: false,
  ghCompatibleHeaderId: true,
  smartIndentationFix: true,
  simpleLineBreaks: true
})

const vm = {
  install: function (Vue) {
    Vue.prototype.$showdown = converter
  }
}

Vue.use(vm)

export default converter
