import * as types from '@/store/mutations.types'

export default {

  [types.APP_PAGE] (state, data) {
    state.page = data
  },

  [types.APP_BASE_URL] (state, data) {
    state.baseURL = data
  },

  [types.APP_CONNECTED] (state, data) {
    state.connected = data
  },

  [types.APP_READY] (state, data) {
    state.ready = data
  },

  [types.APP_REFRESH] (state, data) {
    state.refresh = data
  },

  [types.APP_WILL_REFRESH] (state, data) {
    state.willRefresh = data
  },

  [types.APP_RESTORE_MUTATION] (state/*, data*/) {
    state.restored = true
  },

  [types.APP_SETTINGS] (state, data) {
    state.settings = this.assign(state.settings, data)
  },

  [types.APP_SCREENSIZE] (state, data) {
    state.screenSize = data
  },

}
